import React, { ComponentPropsWithRef, FC } from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';
import useSelector from 'hooks/use-selector';
import { getAccentPrimary } from 'services/themes';

const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export interface ILoadingSpinnerProps {
  className?: string;
  colorOverride?: string;
  fade?: boolean;
  hidden?: boolean;
  name?: ComponentPropsWithRef<typeof Spinner>['name'];
}

const LoadingSpinner: FC<ILoadingSpinnerProps> = ({
  className = '',
  colorOverride,
  fade = true,
  hidden,
  name = 'line-scale',
}) => {
  const siteActiveColor = useSelector(getAccentPrimary);
  const color = colorOverride || siteActiveColor || 'white';
  return (
    <SpinnerContainer className={className} hidden={hidden} data-testid={'loading-spinner'}>
      <Spinner color={color} fadeIn={fade ? 'full' : 'none'} name={name} />
    </SpinnerContainer>
  );
};

export default LoadingSpinner;
