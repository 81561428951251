import React from 'react';
import PageButton from './PageButton';
import { NotActivePageButton } from './styles';

type PageButtonsProps = {
  admin?: boolean;
  onChange?: (pageIndex: number) => void;
  page: number;
  pages: number;
};

const MAX_PAGES = 9;

// default: * * * * * *
// if 4 <= current page >= (pages - 4): * * * * - * * * * *
// if 4 > current page < (pages - 4): * - * * * * - *
const PageButtons: React.FC<PageButtonsProps> = ({ pages, page, onChange, admin }) => {
  const pagesIterator = Array.from({ length: pages }, (_, i) => i);

  const renderButtons = () => {
    if (pages <= MAX_PAGES) {
      return pagesIterator.map((pageIndex) => (
        <PageButton
          key={Math.random().toString()}
          active={pageIndex === page}
          page={pageIndex}
          onChange={onChange}
          admin={admin}
        />
      ));
    }

    if (page < 4) {
      return [
        ...pagesIterator.slice(0, 4).map((pageIndex) => (
          <PageButton
            key={Math.random().toString()}
            active={pageIndex === page}
            page={pageIndex}
            onChange={onChange}
            admin={admin}
          />
        )),
        <NotActivePageButton key={Math.random().toString()} active={false} />,
        ...pagesIterator.slice(-4).map((pageIndex) => (
          <PageButton
            key={Math.random().toString()}
            active={pageIndex === page}
            page={pageIndex}
            onChange={onChange}
            admin={admin}
          />
        )),
      ];
    }

    if (page >= pages - 4) {
      return [
        ...pagesIterator.slice(0, 4).map((pageIndex) => (
          <PageButton
            key={Math.random().toString()}
            active={pageIndex === page}
            page={pageIndex}
            onChange={onChange}
            admin={admin}
          />
        )),
        <NotActivePageButton key={Math.random().toString()} active={false} />,
        ...pagesIterator.slice(-4).map((pageIndex) => (
          <PageButton
            key={Math.random().toString()}
            active={pageIndex === page}
            page={pageIndex}
            onChange={onChange}
            admin={admin}
          />
        )),
      ];
    }

    return [
      (
        <PageButton
          key={0}
          active={0 === page}
          page={0}
          onChange={onChange}
          admin={admin}
        />
      ),
      <NotActivePageButton key={Math.random().toString()} active={false} />,
      ...pagesIterator.slice(page - 2, page + 3).map((pageIndex) => (
        <PageButton
          key={Math.random().toString()}
          active={pageIndex === page}
          page={pageIndex}
          onChange={onChange}
          admin={admin}
        />
      )),
      <NotActivePageButton key={Math.random().toString()} active={false} />,
      (
        <PageButton
          key={pages - 1}
          active={pages - 1 === page}
          page={pages - 1}
          onChange={onChange}
          admin={admin}
        />
      ),
    ];
  };

  return (
    <>
    {renderButtons()}
    </>
  );
};

export default React.memo(PageButtons);
