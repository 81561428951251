import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/ui/Icon';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import TranslatedText from 'components/i18n/TranslatedText';
import { mobileOnly } from 'style/mixins';
import {
  ADMIN_ACCENT_PRIMARY,
  SURFACE_1,
  SURFACE_5,
  TEXT_100,
} from 'style/constants';
import { TEXT_LABEL_L_MEDIUM } from 'style/design-system/textStyles';

const { Option, SingleValue } = components;
const StyledSelect = styled(Select)`
  margin: 5px;
  font-size: 20px;
  padding: 8px;
  width: 120px;
  text-align: center;

  & .DropDown__control{
    box-shadow: none !important;
    cursor: pointer;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
  }

  & .DropDown__menu{
    left:0;
    box-shadow: none !important;
    margin: 0;
  }

  & .DropDown__indicators{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${SURFACE_5} !important;
    & span {
      background-color: ${SURFACE_5} !important;
    }
    .DropDown__indicator{
      padding: 0 !important;
      background-color: ${SURFACE_5} !important;
      transform: rotateX(${props => props.isActive ? '180' : 0}deg);
      & svg {
        background-color: ${SURFACE_5} !important;
        width: 24px;
        height: 24px;
      }
    }
  }

  & .DropDown__value-container{
    display: flex;
    justify-content: center;
    padding: 0 20px !important;
    height: 40px !important;
    background: ${SURFACE_5} !important;
    border-radius: 3.5px !important;
    border: none !important;
    & * {
      ${TEXT_LABEL_L_MEDIUM}
      background: ${SURFACE_5} !important;
    }
    min-width: 65px;
  }

 & .DropDown__value-label, .DropDown__option {
    color: ${TEXT_100} !important;
    cursor: pointer;
  }
  & .DropDown__control, .DropDown__menu-outer {
    border: none;
    margin-top: 1px;
  }
  & .DropDown__option{
    cursor: pointer !important;
  }
  & .DropDown__option:hover {
    opacity: .8;
  }
  &, & * {
    border-radius: 0 !important;
    background-color: ${SURFACE_1} !important;
    color: ${TEXT_100} !important;
  }

  ${mobileOnly`
    .DropDown__menu-list{
      max-height: 100px;
    }

    .DropDown__menu {
      max-height: 98px;
    }
  `}
`;

export default class DropDown extends React.Component {
  static defaultProps = {
    accentOnOpen: false,
    className: undefined,
    components: {},
    getOptionLabel: undefined,
    getOptionValue: undefined,
    isSearchable: false,
    menuPlacement: 'auto',
    translated: false,
  };

  static propTypes = {
    accentOnOpen: PropTypes.bool,
    className: PropTypes.string,
    // eslint-disable-next-line
    components: PropTypes.object,
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    isSearchable: PropTypes.bool,
    menuPlacement: PropTypes.string,
    translated: PropTypes.bool,
  };

  state = {
    isActive: false,
  };

  translateElement = (props, Component) => {
    const { data } = props;
    const { label } = data || {};
    return (
      <Component {...props}>
        <TranslatedText stringKey={label} />
      </Component>
    );
  };

  labelElement = (props) => {
    const { label } = props;
    return (
      <Option {...props}>
        <Icon color={ADMIN_ACCENT_PRIMARY} name="checkmark" />
        {` ${label}`}
      </Option>
    );
  };

  render() {
    const {
      accentOnOpen,
      className,
      components: passedComponents,
      getOptionLabel,
      getOptionValue,
      menuPlacement,
      translated,
    } = this.props;
    const labelComponents = props => this.labelElement(props);
    const translatedComponents = {};
    if (translated) {
      translatedComponents.Option = props => this.translateElement(props, Option);
      translatedComponents.SingleValue = props => this.translateElement(props, SingleValue);
    }
    const { isActive } = this.state;
    if ((getOptionLabel || getOptionValue) && translated && process.env.NODE_ENV !== 'production') {
      // TODO: support getOptionLabel and getOptionValue with translated options
      /* eslint-disable no-console */
      console.warn("DropDown: Translated options cannot use 'getOptionLabel' or 'getOptionValue'." +
        " Map your props to an object with key/label values and pass the mapped array to 'options'.");
      /* eslint-enable no-console */
      return null;
    }
    return (
      <StyledSelect
        {...this.props}
        accentOnOpen={accentOnOpen}
        className={`${className || ''} ${isActive ? 'isActive' : ''}`}
        classNamePrefix="DropDown"
        components={
          {
            Option: labelComponents,
            ...translatedComponents,
            ...passedComponents,
          }
        }
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isActive={isActive}
        menuPlacement={menuPlacement}
        onBlur={() => this.setState({ isActive: false })}
        onMenuClose={() => this.setState({ isActive: false })}
        onMenuOpen={() => this.setState({ isActive: true })}
      />
    );
  }
}
