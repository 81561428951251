import React, { PropsWithChildren } from 'react';
import PageLink from 'components/ui/PageLink';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';
import { useNavigation } from 'hooks/use-navigation';

interface GoToPageProps extends PropsWithChildren {
  pageId: string;
}

const GoToPage: React.FC<GoToPageProps> = ({ children, pageId }) => {
  const isEditing = useSelector(isEditMode);
  const { getSlugById } = useNavigation();
  const pageSlug = getSlugById(pageId);

  if (isEditing || !pageSlug) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <PageLink slug={pageSlug}>
      {children}
    </PageLink>
  );
};

export default GoToPage;
