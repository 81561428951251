import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/ui/Icon';
import { TEXT_100 } from 'style/constants';

const StyledButton = styled.button`
  color: ${TEXT_100};
  opacity: 0.5;
  font-size: 18px;
  font-weight: lighter;
  cursor: pointer;
  z-index: 1;
  pointer-events: all;
`;

export default class CloseButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    iconName: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    iconName: 'cancel-thin',
  };

  render() {
    const { className, iconName, onClick } = this.props;
    return (
      <StyledButton className={className} onClick={onClick} type="button">
        <Icon name={iconName} />
      </StyledButton>
    );
  }
}
