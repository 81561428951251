import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import inRange from 'lodash/inRange';
import clamp from 'lodash/clamp';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import PageNavigation from 'components/ui/PageNavigation';
import {
  SlideNavigationProps,
  PageRef,
} from './interfaces';
import {
  Container,
  StyledDraggable as Draggable,
  PageContainer,
  EmptyPage,
} from './styles';

const MIN_PIXEL_SLIDE_TO_SNAP = 2;

export default function SlideNavigation<T, P>({
  admin,
  className,
  hideControls,
  pageRenderer,
  onSlide: onSlideHandler,
  onSlideEnd: onSlideEndHandler,
  onSlideStart: onSlideStartHandler,
  pages,
  ...props
}: SlideNavigationProps<T, P> & P) {
  const PageRenderer = pageRenderer as ComponentType<any>;
  const pageRef = useRef<PageRef>({ left: 0, length: pages.length, number: 1, offset: 0, width: 0 });

  const [scrollPos, setScrollPos] = useState(0);

  const [page, setPage] = useState(1);

  const pageIndex = useMemo(() => page - 1, [page]);

  const getPageRef = (node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }
    if (node.offsetWidth !== pageRef.current.width) {
      pageRef.current.width = node.offsetWidth;
      pageRef.current.left = node.getBoundingClientRect().left;
    }
  };

  const onSlideStart = (x: number) => {
    onSlideStartHandler?.(x);
    const { left, width: pageWidth } = pageRef.current;
    pageRef.current.offset = Math.floor((x - left) / pageWidth) * -pageWidth;
  };

  const onSlide = (x: number) => {
    onSlideHandler?.(x);
    const { offset, width } = pageRef.current;
    setScrollPos(clamp(x, offset - width, offset + width));
  };

  const onSlideEnd = (x: number) => {
    onSlideEndHandler?.(x);
    const { length, number: pageNumber, offset } = pageRef.current;
    const pageLimitMin = offset + MIN_PIXEL_SLIDE_TO_SNAP;
    const pageLimitMax = offset - MIN_PIXEL_SLIDE_TO_SNAP;
    if (pageNumber > 1 && x > pageLimitMin) {
      return setPage(pageNumber - 1);
    }
    if (pageNumber < length && x < pageLimitMax) {
      return setPage(pageNumber + 1);
    }
    setScrollPos(offset);
  };

  const goToNextPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const goToPrevPage = useCallback(() => {
    setPage(pageIndex);
  }, [page]);

  const changePage = useCallback((currentPage: number) => {
    setPage(currentPage + 1);
  }, []);

  useEffect(() => {
    if (page > pages.length && page > 1) {
      setPage(pageIndex);
    }
    pageRef.current.number = page;
    pageRef.current.length = pages.length;
    setScrollPos(pageIndex * -pageRef.current.width);
  }, [page, pages.length, pageRef.current.number, pageRef.current.length]);

  return (
    <Container className={className}>
      {PageRenderer && (
        <PageContainer ref={getPageRef}>
          <Draggable onEnd={onSlideEnd} onMove={onSlide} onStart={onSlideStart} x={scrollPos}>
            <NonKeyedListMapper list={pages}>
              {(key: string, pageContent: T, i: number) => inRange(i, pageIndex - 1, page + 1) ? (
                <PageRenderer
                  key={key}
                  admin={admin}
                  goToNextPage={goToNextPage}
                  goToPage={changePage}
                  goToPreviousPage={goToPrevPage}
                  isCurrentPage={pageIndex === i}
                  isFirstPage={!i}
                  isLastPage={i === pages.length - 1}
                  isNextPage={pageIndex + 1 === i}
                  isPreviousPage={pageIndex - 1 === i}
                  pageContent={pageContent}
                  pageIndex={pageIndex}
                  {...props}
                />
              ) : <EmptyPage key={key} />}
            </NonKeyedListMapper>
          </Draggable>
        </PageContainer>
      )}
      {hideControls ? null : (
        <PageNavigation
          admin={admin}
          onChange={changePage}
          onNextPage={goToNextPage}
          onPreviousPage={goToPrevPage}
          page={pageIndex}
          pages={pages.length}
        />
      )}
    </Container>
  );
}
