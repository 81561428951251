import React, { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import {
  DraggableProps,
  MouseTouchEvent,
  Position,
} from './interfaces';
import { getPagePos, throttle } from './utils';
import { DraggableContent } from './styles';

export default function Draggable({ children, container, invertX, invertY, onDraw, onEnd, onMove, onStart, x = 0, y = 0, ...props }: DraggableProps) {
  const refPos = useRef<Position>({ x, y });
  const refFinalPos = useRef<Position>({ x, y });
  const refIsDragging = useRef<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const refInvertPos = useRef<Position>({ x: 0, y: 0 });

  const handlerRefs = useRef({ onDraw, onEnd, onMove, onStart });
  const containerRef = useRef(container);

  useEffect(() => {
    containerRef.current = container;
  }, [container]);

  useEffect(() => {
    handlerRefs.current = { onDraw, onEnd, onMove, onStart };
  }, [onDraw, onEnd, onMove, onStart]);

  useEffect(() => {
    if (!refIsDragging.current) {
      refPos.current.x += refFinalPos.current.x - x;
      refPos.current.y += refFinalPos.current.y - y;
    }
    refFinalPos.current = { x, y };
  }, [x, y]);

  const update = useCallback(throttle(() => {
    if (!ref.current) {
      return;
    }
    const { onDraw: draw } = handlerRefs.current;
    if (draw) {
      return draw(x, y);
    }
    ref.current.style.transform = `translate(${x}px, ${y}px)`;
  }), [x, y]);

  useEffect(() => {
    const onMouseDown = (event: MouseTouchEvent) => {
      // prevent dragging and don't disable mouse events for inputs and textareas
      if (
        !ref.current || (event.type === 'mousedown' && (event as MouseEvent).button !== 0) ||
        event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement
      ) {
        return;
      }
      const { pageX, pageY } = getPagePos(event);
      refPos.current.x = pageX - refFinalPos.current.x;
      refPos.current.y = pageY - refFinalPos.current.y;
      ref.current.style.transition = 'none';
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('touchmove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('touchend', onMouseUp);
      handlerRefs.current.onStart?.(refPos.current.x, refPos.current.y, event);
      const { right = 0, bottom = 0 } = containerRef.current?.getBoundingClientRect() || {};
      refInvertPos.current.x = right;
      refInvertPos.current.y = bottom;
      event.preventDefault();
    };

    const onMouseUp = (event: MouseTouchEvent) => {
      if (!ref.current) {
        return;
      }
      ref.current.style.transition = '';
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('touchend', onMouseUp);
      refIsDragging.current = false;
      handlerRefs.current.onEnd?.(refFinalPos.current.x, refFinalPos.current.y, event);
      event.preventDefault();
    };

    const onMouseMove = (event: MouseTouchEvent) => {
      const { pageX, pageY } = getPagePos(event);
      refIsDragging.current = true;
      const { x: right, y: bottom } = refInvertPos.current;
      const dragX = invertX ? right - pageX : pageX - refPos.current.x;
      const dragY = invertY ? bottom - pageY : pageY - refPos.current.y;
      handlerRefs.current.onMove(dragX, dragY, event);
      event.preventDefault();
    };

    ref.current?.addEventListener('mousedown', onMouseDown);
    ref.current?.addEventListener('touchstart', onMouseDown);
    update();
    return () => {
      ref.current?.removeEventListener('mousedown', onMouseDown);
      ref.current?.removeEventListener('touchstart', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('touchend', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onMouseMove);
      update.cancel();
    };
  }, []);

  useEffect(() => {
    update();
  }, [x, y]);

  return (
    <DraggableContent ref={ref} {...props}>
      {children}
    </DraggableContent>
  );
}
