import React from 'react';
import { ThreeDotsContainer, ThreeDotsButton, ThreeDotsIcon } from './styles';
import useFloatMenu from 'hooks/use-float-menu';
import CardsMenu from '../CardsMenu';

interface IMenuAction {
  dataTestId: string;
  handler: (e: React.MouseEvent) => void;
  icon: string;
  id: string;
  name: string;
}

interface IThreeDotsMenu extends React.HTMLAttributes<HTMLDivElement> {
  cardsMenuId: string;
  isAdmin: boolean;
  isFloatMenuOpen: ReturnType<typeof useFloatMenu>['isOpen'];
  menuActions: IMenuAction[];
  positionMenu: ReturnType<typeof useFloatMenu>['positionMenu'];
  threeDotsMenuId: string;
  toggleMenu: ReturnType<typeof useFloatMenu>['toggleMenu'];
}

const ThreeDotsMenu: React.FC<IThreeDotsMenu> = ({
  threeDotsMenuId,
  cardsMenuId,
  menuActions,
  toggleMenu,
  positionMenu,
  isFloatMenuOpen,
  isAdmin,
  ...props
}) => {
  return (
    <ThreeDotsContainer
      id={threeDotsMenuId}
      data-testid={threeDotsMenuId}
      {...props}
    >
      <ThreeDotsButton onClick={toggleMenu}>
        <ThreeDotsIcon isAdmin={isAdmin} />
      </ThreeDotsButton>
      {isFloatMenuOpen && (
        <CardsMenu
          ref={positionMenu}
          id={cardsMenuId}
          menuActions={menuActions}
          isAdmin={isAdmin}
        />
      )}
    </ThreeDotsContainer>
  );
} ;

export default ThreeDotsMenu;
