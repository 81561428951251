import React from 'react';
import useRichTextEditor from './use-rich-text-editor';
import { Editor as TinyMCEEditor, EditorEvent, Events } from 'tinymce';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import videoLibraryModalTypes from 'components/modals/videoLibraryModalTypes';
import { IVideo, PlayerTypes } from 'models';
import { PARAGRAPH_GROUP_ICON, TEXT_GROUP_ICON, TOOLBAR_BUTTONS_WITH_PLUGINS, ToolbarButtons, VIDEO_LIBRARY_ICON } from './utils';
import { Editor } from '@tinymce/tinymce-react';
import { useAdminTranslation } from 'hooks/use-translation';
import EmbeddedMaestroFilePlayer from './portals/EmbeddedMaestroFilePlayer';
import { isEditMode } from 'services/admin';

type IUseTinyMceProps = {
  codeInjectionClassName?: string;
  data: string;
  disableDebounce?: boolean;
  maxLength: number;
  onChange: (value: string) => void;
  toolbarButtons?: ToolbarButtons;
};

const useTinyMce = ({
  data,
  maxLength,
  onChange,
  disableDebounce,
  toolbarButtons = {},
  codeInjectionClassName,
}: IUseTinyMceProps) => {
  const [portals, setPortals] = React.useState<React.ReactNode[]>([]); // this is used to render the video portals
  const [loadingInitialData, setLoadingInitialData] = React.useState(true);

  const isEditing = useSelector(isEditMode);
  const { t } = useAdminTranslation();
  const ref = React.useRef<Editor>(null);

  const editorContainer = ref.current?.editor?.getContainer();
  const toolbarHeight = editorContainer?.querySelector('.tox-editor-header')?.clientHeight || 0;
  const iframeBodyHeight = (ref.current?.editor?.getBody()?.clientHeight || 0);

  const dispatch = useDispatch();

  // Loop through all the elements in the editor and add the code injection class
  React.useEffect(() => {
    if (!codeInjectionClassName) {
      return;
    }

    if (isEditing) {
      // We should not override styles when editing
      return;
    }

    const body = ref.current?.editor?.getBody();
    if (!body) {
      return;
    }

    const children = body.querySelectorAll('*');
    for (const child of children) {
      child.classList.add(codeInjectionClassName);
    }
  }, [codeInjectionClassName, ref.current?.editor?.getBody(), isEditing]);

  const {
    onChangeValue,
    value,
  } = useRichTextEditor({
    data,
    maxLength,
    onChange,
    disableDebounce,
  });

  const handleUpdate = (rawData: string, editor: TinyMCEEditor) => {
    if (loadingInitialData) {
      setLoadingInitialData(false);
      return;
    }

    const textContent = editor.getContent({ format: 'text' });
    onChangeValue(rawData, textContent);
  };

  const handleBeforeAddUndo = (evt: EditorEvent<Events.EditorEventMap['BeforeAddUndo']>, editor: TinyMCEEditor) => {
    const length = editor.getContent({ format: 'text' }).length;
    // note that this is the opposite test as in handleUpdate
    // because we are determining when to deny adding an undo level
    if (length > maxLength) {
      evt.preventDefault();
    }
  };

  const registerCustomIcons = React.useCallback((editor: TinyMCEEditor) => {
    editor.ui.registry.addIcon('textGroup', TEXT_GROUP_ICON);
    editor.ui.registry.addIcon('paragraphGroup', PARAGRAPH_GROUP_ICON);
    editor.ui.registry.addIcon('videoLibrary', VIDEO_LIBRARY_ICON);
  }, []);

  const registerImageLibrary = React.useCallback((editor: TinyMCEEditor) => {
    editor.ui.registry.addToggleButton('imageLibrary', {
      icon: 'image',
      tooltip: t('ADMIN_IMAGE_LIBRARY_TITLE'),
      onAction: () => {
        dispatch(showModal({
          kind: ModalKinds.imageLibrary,
          data: {
            locked: false,
            onSelect: (image: string) => {
              editor.insertContent(`<img alt="" src="${image}" /><br/>`);
            },
          },
        }));
      },
    });
  }, []);

  const registerTextGroup = React.useCallback((editor: TinyMCEEditor) => {
    const items: (keyof ToolbarButtons)[] = [
      'strikethrough',
      'subscript',
      'superscript',
      'removeformat',
    ];
    editor.ui.registry.addGroupToolbarButton('textGroup', {
      icon: 'textGroup',
      tooltip: t('TEXT_GROUP'),
      items: items.join(' '),
    });
  }, []);

  const registerParagraphGroup = React.useCallback((editor: TinyMCEEditor) => {
    const items: (keyof ToolbarButtons)[] = [
      'styles',
      'alignleft',
      'aligncenter',
      'alignright',
      'alignjustify',
      'lineheight',
      'numlist',
      'bullist',
      'outdent',
      'indent',
      'blockquote',
      'removeformat',
    ];
    editor.ui.registry.addGroupToolbarButton('paragraphGroup', {
      icon: 'paragraphGroup',
      tooltip: t('PARAGRAPH'),
      items: items.join(' '),
    });
  }, []);

  const registerVideoLibrary = React.useCallback((editor: TinyMCEEditor) => {
    editor.ui.registry.addToggleButton('videoLibrary', {
      icon: 'videoLibrary',
      tooltip: t('ADMIN_LABEL_VIDEO_LIBRARY'),
      onAction: () => {
        dispatch(showModal({
          kind: ModalKinds.videoLibraryV2,
          data: {
            type: videoLibraryModalTypes.SCHEDULE_EDIT,
            onSelectItem: (video: IVideo) => {
              if (!video.url) {
                return;
              }

              if (video.player === PlayerTypes.youtube) {
                const vQuery = new URLSearchParams(video.url.split('?')[1]);
                const url = `https://www.youtube.com/embed/${vQuery.get('v')}`;
                return editor.insertContent(`<iframe style="width: 100%; aspect-ratio: 16 / 9;" src="${url}" frameborder="0" allowfullscreen="allowfullscreen"></iframe><br/>`);
              }

              const videoId = 'v' + Math.random().toString(16).substring(2, 8);
              editor.insertContent(`<span id="${videoId}" style="width: 100%; aspect-ratio: 16 / 9;" data-src="${video.url}">&nbsp;</span><br/>`);

              const videoElements = editor.getBody()?.querySelectorAll('[data-src]');
              if (!videoElements?.length) {
                return;
              }

              setVideoElementsToPortals(videoElements);
            },
          },
        }));
      },
    });
  }, []);

  const registerImagesContextToolbar = React.useCallback((editor: TinyMCEEditor) => {
    editor.ui.registry.addContextToolbar('imagealignment', {
      predicate: (node) => node.nodeName.toLowerCase() === 'img',
      items: 'alignleft aligncenter alignright',
      position: 'node',
      scope: 'node',
    });
  }, []);

  const setup = (editor: TinyMCEEditor) => {
    registerCustomIcons(editor);

    if (toolbarButtons.imageLibrary) {
      registerImageLibrary(editor);
    }

    if (toolbarButtons.textGroup) {
      registerTextGroup(editor);
    }

    if (toolbarButtons.paragraphGroup) {
      registerParagraphGroup(editor);
    }

    if (toolbarButtons.videoLibrary) {
      registerVideoLibrary(editor);
    }

    registerImagesContextToolbar(editor);
  };

  const plugins = React.useMemo(() => {
    const neededPlugins: string[] = [];
    const entries = Object.entries(toolbarButtons) as [keyof ToolbarButtons, boolean][];

    for (const [key, entryValue] of entries) {
      if (entryValue) {
        const plugin = TOOLBAR_BUTTONS_WITH_PLUGINS.find((p) => p.items.includes(key));
        if (plugin) {
          neededPlugins.push(plugin.plugin);
        }
      }
    }

    return neededPlugins.join(' ');
  }, [toolbarButtons]);

  const toolbar1 = React.useMemo(() => {
    const toolbar: string[] = [];
    for (const [key, entryValue] of Object.entries(toolbarButtons)) {
      if (entryValue) {
        toolbar.push(key);
      }
    }
    return toolbar.join(' ');
  }, [toolbarButtons]);

  const onInit = React.useCallback((ev: any) => {
    const iframe = ev.target as HTMLIFrameElement;

    const videoElements = iframe.contentDocument?.querySelectorAll('[data-src]');
    if (!videoElements?.length) {
      return;
    }

    setVideoElementsToPortals(videoElements);
  }, []);

  const setVideoElementsToPortals = React.useCallback((videoElements: NodeListOf<Element>) => {
    setPortals([]); // clear out the old portals

    videoElements.forEach((videoContainer) => {
      setPortals(
        (prev) => [
          ...prev,
          (
            <EmbeddedMaestroFilePlayer
              key={videoContainer.id}
              nodeRef={videoContainer}
              videoUrl={(videoContainer as HTMLElement).dataset.src!}
            />
          ),
        ],
      );
    });
  }, []);

  return {
    toolbar1,
    plugins,
    setup,
    handleUpdate,
    handleBeforeAddUndo,
    value,
    iframeBodyHeight,
    toolbarHeight,
    ref,
    onInit,
    portals,
  };
};

export default useTinyMce;
