import React, {
  FC,
  KeyboardEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'hooks';
import { useSelector } from 'react-redux';
import { createMatchSelector } from 'connected-react-router';
import {
  getObject,
  getObjectLoaded,
  getBackgroundImage,
} from 'services/app/selectors';
import {
  getVodIdFromQuery,
  getPlaylistIdFromQuery,
  getTagFromQuery,
} from 'services/video';
import { receiveOverlay } from 'services/overlays';
import {
  isOverlayEmbed as isOverlayEmbedSelector,
  isTheaterEmbed,
  isVideoEmbed,
  isVideoOnlyMode as isVideoOnlyModeSelector,
} from 'services/user-layout';
import {
  getFixedGateData,
  shouldRenderChannelGate,
} from 'services/gate/selectors';
import { useAdminTranslation } from 'hooks/use-translation';
import { NAVIGATION } from 'config';
import RealtimeChannel from 'components/core/RealtimeChannel';
import { CONTENT_INNER_CONTAINER_ID } from 'global-ids';
import { GradientMask } from 'components/admin2/AccessGate/styles';
import RealtimePage from 'components/core/RealtimePage';
import { StudioGuardian } from 'components/admin2/StudioGuardian';
import {
  DebugJson,
  InnerContainer,
  OuterContainer,
  StyledNotFound,
} from './styles';
import { IObject, PageType } from 'models';
import CompositePage from 'components/objects/CompositePage';
import Channel from 'components/objects/Channel';
import LandingPage from 'components/objects/LandingPage/LandingPage';
import VideoPage from 'components/objects/VideoPage';
import IState from 'services/state';
import { useFetchContent } from './useFetchContent';
import useSetPage from 'hooks/use-set-page';
import { isMobileLayout } from 'services/device';

const matchObject = createMatchSelector({ path: '/:objectSlug' });

const matchNavigationPathname = createMatchSelector({
  path: '/:parentSlug/:childSlug',
});

export const getObjectSlug = (state: IState) => {
  let { objectSlug } = (matchObject(state)?.params ?? {}) as Record<
    string,
    string | undefined
  >;

  if (NAVIGATION) {
    const { childSlug } = (matchNavigationPathname(state)?.params ??
      {}) as Record<string, string | undefined>;

    if (childSlug) {
      objectSlug = childSlug;
    }
  }

  return objectSlug || null;
};

const Content: FC<{ className?: string }> = ({ className }) => {
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();

  const [pageLoaded, setPageLoaded] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const vodIdFromQuery = useSelector(getVodIdFromQuery);
  const playlistIdFromQuery = useSelector(getPlaylistIdFromQuery);
  const tagPlaylistTag = useSelector(getTagFromQuery);
  const backgroundImage = useSelector(getBackgroundImage);
  const currentObject = useSelector(getObject);
  const gateBackground = useSelector(getFixedGateData(t))?.background || null;
  const isPageGateOn = useSelector(shouldRenderChannelGate);
  const isOverlayEmbed = useSelector(isOverlayEmbedSelector);
  const isTheaterEmbedded = useSelector(isTheaterEmbed);
  const isVideoEmbedded = useSelector(isVideoEmbed);
  const isVideoOnlyMode = useSelector(isVideoOnlyModeSelector);
  const isMobile = useSelector(isMobileLayout);
  const loaded = useSelector(getObjectLoaded);
  const setPage = useSetPage();

  const onReceiveOverlay = useCallback(
    (overlay) => dispatch(receiveOverlay(overlay)),
    [],
  );

  const handleObjectUpdate = useCallback((object?: IObject | null, isLoaded?: boolean) => {
    if (pageLoaded !== isLoaded && typeof isLoaded === 'boolean') {
      setPageLoaded(isLoaded);
    }

    setPage(object || null, isLoaded || false);
  }, [setPage, pageLoaded]);

  const { live, offline } = currentObject?.data?.content || {};
  const isVideoContent = !!(
    live?.liveStreams ||
    (offline?.mode === 'tv' && offline?.video)
  );

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      const isInput = ['text', 'textarea'].includes(
        (event.target as HTMLInputElement).type,
      );

      if (isInput) {
        return;
      }

      if (!isVideoContent) {
        return;
      }

      switch (event.nativeEvent.code) {
        case 'Space':
        case 'ArrowLeft':
        case 'ArrowRight':
        case 'ArrowDown':
        case 'ArrowUp':
          event.preventDefault();
          break;
        default:
      }
    },
    [isVideoContent],
  );

  useFetchContent(
    getObjectSlug,
    handleObjectUpdate,
    scrollContainerRef,
    loaded,
  );

  const transparentBackground =
    (pageLoaded && isOverlayEmbed) || (isPageGateOn && !backgroundImage);

  return (
    <OuterContainer
      isMobile={isMobile}
      backgroundImage={Boolean(backgroundImage)}
      className={className}
      gateBackground={gateBackground}
      isChannelGateOn={isPageGateOn}
      transparentBackground={transparentBackground}
    >
      <InnerContainer
        ref={scrollContainerRef}
        backgroundImage={Boolean(backgroundImage)}
        data-testid="PageContainer"
        id={CONTENT_INNER_CONTAINER_ID}
        isChannelGateOn={isPageGateOn}
        isVideoOnlyMode={isVideoOnlyMode}
        onKeyDown={handleKeyDown}
        isLandingPageType={currentObject?.type === PageType.LANDING}
        isEmbedded={isOverlayEmbed || isTheaterEmbedded || isVideoEmbedded}
      >
        {loaded || currentObject ? (
            <StudioGuardian>
              {(() => {
                if (isOverlayEmbed) {
                  return <CompositePage />;
                }

                const type =
                  vodIdFromQuery ?? playlistIdFromQuery
                    ? 'videoPage'
                    : currentObject?.type;

                switch (type) {
                  case PageType.CHANNEL: {
                    return <Channel key={currentObject._id} />;
                  }
                  case PageType.LANDING: {
                    return <LandingPage />;
                  }
                  case 'videoPage': {
                    return (
                      <VideoPage
                        videoId={vodIdFromQuery}
                        playlistId={playlistIdFromQuery}
                        playlistTag={tagPlaylistTag}
                      />
                    );
                  }
                  default: {
                    // TODO: This should be an error eventually
                    return (
                      <DebugJson>
                        {JSON.stringify(currentObject?.data)}
                      </DebugJson>
                    );
                  }
                }
              })()}
            </StudioGuardian>
          ) : (
            <StyledNotFound />
          )}
        {currentObject?._id && (
          <RealtimePage id={currentObject._id} onValue={handleObjectUpdate} />
        )}
        {currentObject && (
          <RealtimeChannel
            channel={`objects:${currentObject._id}:broadcast`}
            onMessage={onReceiveOverlay}
          />
        )}
        {gateBackground?.useGradientMask && isPageGateOn && <GradientMask />}
      </InnerContainer>
    </OuterContainer>
  );
};

export default Content;
