import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import * as React from 'react';
import { createPortal } from 'react-dom';
import ToolbarPlugin from '../Toolbar';
import useFloatingToolbarActive from './use-floating-toolbar-active';
import useFloatingToolbar from './use-floating-toolbar';
import { FloatingContainer } from './styles';

export const FLOATING_TOOLBAR_ID = 'rich-text-editor-floating-toolbar';

type FloatingToolbarPluginProps = React.PropsWithChildren & { anchorElem: HTMLElement; };

const FloatingToolbar: React.FC<FloatingToolbarPluginProps> = ({ anchorElem, children }) => {
  const [editor] = useLexicalComposerContext();
  const ref = useFloatingToolbar(anchorElem);

  return (
    <FloatingContainer ref={ref} id={FLOATING_TOOLBAR_ID}>
      {editor.isEditable() ? children : null}
    </FloatingContainer>
  );
};


const FloatingToolbarPlugin: React.FC<Partial<FloatingToolbarPluginProps>> = ({ anchorElem, children }) => {
  const active = useFloatingToolbarActive();
  if (!active) {
    return null;
  }

  return createPortal(
    (
      <FloatingToolbar anchorElem={anchorElem || document.body}>
        {children}
      </FloatingToolbar>
    ), anchorElem || document.body);
};

export default FloatingToolbarPlugin;
