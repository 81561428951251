/* eslint react/destructuring-assignment: off */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import KeyedListMapper from 'components/core/KeyedListMapper';
import { isTouchDevice } from 'utils';
import { GridRowContainer } from 'components/Grid/styles';
import {
  MobileHeader,
  MobileTitle,
  MomentumSlideContainer,
  MomentumSlideSlot,
  SlideContainer,
  SliderContainer,
  SliderContainerWithTooltip,
  SlideWrapper,
  SlideWrapperWithTooltip,
  SnapSlideContainer,
  SnapSlideSlot,
  CircledButton,
  NavControls,
  HeaderWrapper,
  ButtonContainer,
  DefaultArrowIcon,
} from './styles';

export default class ScrollSlider extends Component {
  // eslint-disable-next-line id-blacklist
  static getDerivedStateFromProps({
    items: newItems,
    slidesShown: newSlidesShown,
    slideAmount,
  },
  state) {
    const { maxPosition, position } = state;
    const slidePosition = position + slideAmount;
    const nextPosition = Math.max(0, Math.min(slidePosition, newItems.length - newSlidesShown));
    const nextMaxPosition = Math.max(nextPosition + newSlidesShown, maxPosition);
    const newMaxPosition = Math.min(newItems.length, nextMaxPosition);
    return {
      maxPosition: Math.min(newItems.length, newMaxPosition),
      position: Math.min(position, newItems.length - newSlidesShown),
      slides: newItems.slice(0, Math.max(newMaxPosition, newSlidesShown)),
      slidesShown: newSlidesShown,
    };
  }

  static propTypes = {
    arrowLeftIconName: PropTypes.string.isRequired,
    arrowRightIconName: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    className: PropTypes.string,
    flushLeft: PropTypes.bool,
    fullwidth: PropTypes.bool,
    hasTooltips: PropTypes.bool,
    hideArrows: PropTypes.bool,
    isGrid: PropTypes.bool,
    isGridMobile: PropTypes.bool,
    isTouchSlider: PropTypes.bool,
    items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    minWidth: PropTypes.number,
    mobileTitle: PropTypes.string,
    renderHeader: PropTypes.func,
    slideAmount: PropTypes.number.isRequired,
    slideArrowIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    slideButton: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    slideMargin: PropTypes.number.isRequired,
    slidesHeight: PropTypes.number,
    slidesShown: PropTypes.number.isRequired,
    slideWidth: PropTypes.number.isRequired,
    useOldDesign: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    flushLeft: false,
    fullwidth: false,
    hasTooltips: false,
    hideArrows: false,
    isGrid: false,
    isGridMobile: false,
    isTouchSlider: false,
    minWidth: 0,
    mobileTitle: '',
    renderHeader: () => {},
    slideArrowIcon: null,
    slideButton: null,
    slidesHeight: null,
    useOldDesign: false,
  };

  state = {
    maxPosition: this.props.slidesShown,
    offsetBack: 0,
    offsetForward: 0,
    position: 0,
    positionInPixels: 0,
    slides: this.props.items.slice(0, this.props.slidesShown),
    touchPosition: 0,
  };

  ref = createRef();

  index = -1;

  /*
  allows us to incremete a value from [0 to N]
  used to increment the touchPosition based on the length
  of either the total items passed down in the items prop or
  visible slides loaded to the DOM.
  SEE: https://riptutorial.com/javascript/example/760/remainder---modulus----
  */

  componentDidMount() {
    const { isTouchSlider, isGridMobile } = this.props;
    const isIpad = navigator.userAgent.includes('iPad');
    if (isTouchSlider && (isGridMobile || isIpad)) {
      this.onScroll({ target: { scrollLeft: 0 } });
    }
  }

  get slideSpace() {
    const {
      slideMargin,
      slideWidth,
    } = this.props;
    return slideWidth + slideMargin;
  }

  get wrapperWidth() {
    return this.ref?.current?.getBoundingClientRect()?.width || 0;
  }

  get visibleSlides() {
    return Math.floor(this.wrapperWidth / this.slideSpace);
  }

  toRange = (value, range) => ((value % range) + range) % range;

  get positionInPixels() {
    const { position } = this.state;

    return (Math.max(0, position) * this.slideSpace) * -1;
  }

  onSlide = (offset) => {
    const { visibleSlides } = this;
    const { items, slideAmount } = this.props;
    const { maxPosition, position } = this.state;

    const lastShown = position + (visibleSlides * offset);
    if ((position === 0 && offset === -1) || (offset === 1 && lastShown >= items.length)) {
      return;
    }

    const slidePosition = position + (slideAmount * offset);
    const nextPosition = Math.max(0, Math.min(slidePosition, items.length - visibleSlides));
    const nextMaxPosition = Math.max(nextPosition + visibleSlides, maxPosition);
    this.setState({
      maxPosition: nextMaxPosition,
      position: nextPosition,
      positionInPixels: (Math.max(0, nextPosition) * this.slideSpace) * -1,
      slides: items.slice(0, nextMaxPosition),
    });
  };

  onScroll = ({ target: { scrollLeft: scrollPos } }) => {
    const { slideWidth } = this.props;
    const { offsetBack, offsetForward } = this.state;
    if (offsetBack + slideWidth > scrollPos || offsetForward - slideWidth < scrollPos) {
      const offset = this.slideSpace * 10;
      this.setState({ offsetBack: scrollPos - offset, offsetForward: scrollPos + offset });
    }
  };

  renderTouchSlider = () => {
    const {
      children,
      items,
      slidesHeight,
      slideMargin,
      slideWidth,
    } = this.props;
    const { offsetBack, offsetForward } = this.state;
    const { slideSpace } = this;
    const { length } = items;

    const scrollWidth = slideWidth * length + slideMargin * (length - 1);
    const indexFirst = offsetBack < slideSpace ? 0 : Math.floor(offsetBack / slideSpace);
    const indexLast = offsetForward > (scrollWidth + slideMargin) ?
      length :
      Math.ceil(offsetForward / slideSpace) || Math.min(length, 25);

    const visibleItems = [];
    for (let i = indexFirst; i < indexLast; i++) {
      visibleItems.push({ ...items[i], index: i });
    }

    return (
      <SlideWrapper height={slidesHeight}>
        <MomentumSlideContainer onScroll={this.onScroll}>
          <SlideContainer scrollWidth={scrollWidth} slideHeight={slidesHeight}>
            <KeyedListMapper keyField="key" list={visibleItems}>
              {(key, { index, ...value }, i) => (
                <MomentumSlideSlot
                  key={key}
                  index={index}
                  margin={slideMargin}
                  slideWidth={slideWidth}
                  width={slideWidth}
                >
                  { children(value, i) }
                </MomentumSlideSlot>
              )}
            </KeyedListMapper>
          </SlideContainer>
        </MomentumSlideContainer>
      </SlideWrapper>
    );
  };

  render() {
    const { position, slides, touchPosition, positionInPixels } = this.state;
    const {
      children,
      className,
      flushLeft,
      hasTooltips,
      isGrid,
      isGridMobile,
      isTouchSlider,
      items,
      slideButton,
      slidesHeight,
      slideMargin,
      slideWidth,
      hideArrows,
      mobileTitle,
      minWidth,
      fullwidth,
    } = this.props;
    const Button = slideButton || ButtonContainer;
    const showButtons = !hideArrows;
    const showLeftButton = showButtons && (position || touchPosition) > 0;
    let showRightButton = false;
    const containerWidth = (this.slideSpace * items.length) - this.wrapperWidth;
    showRightButton = showButtons && (positionInPixels * -1) < containerWidth;
    const isIpad = navigator.userAgent.includes('iPad');
    const renderSlider = (hasTooltip, alignRight) => {
      const Wrapper = hasTooltip ? SlideWrapperWithTooltip : SlideWrapper;
      return (
        isTouchDevice || (isTouchSlider && (isGridMobile || isIpad)) ? this.renderTouchSlider() :
          (
            <Wrapper ref={this.ref} alignRight={alignRight} height={slidesHeight}>
              <SnapSlideContainer
                containerWidth={this.slideSpace * items.length - Math.abs(containerWidth)}
                margin={slideMargin}
                translation={positionInPixels ? Math.max(positionInPixels, -containerWidth) : 0}
              >
                <KeyedListMapper keyField="key" list={slides}>
                  {(key, value, i) => (
                    <SnapSlideSlot
                      key={key}
                      width={slideWidth}
                    >
                      { children(value, i) }
                    </SnapSlideSlot>
                  )}
                </KeyedListMapper>
              </SnapSlideContainer>
            </Wrapper>
          )
      );
    };

    const renderDesktop = () => {
      const {
        renderHeader,
        useOldDesign,
        slideArrowIcon,
        arrowLeftIconName,
        arrowRightIconName,
      } = this.props;
      const InnerContainer = hasTooltips ? SliderContainerWithTooltip : SliderContainer;
      const containerProps = {
        flushLeft,
        height: slidesHeight,
      };
      const ArrowIcon = slideArrowIcon || DefaultArrowIcon;

      return (
        <GridRowContainer {...containerProps}>
          <HeaderWrapper>
            {renderHeader()}
            {!useOldDesign && (
            <NavControls>
              <CircledButton
                active={showLeftButton}
                left
                name="adminbarLt"
                onClick={isIpad ? undefined : () => this.onSlide(-1)}
              />
              <CircledButton
                active={showRightButton}
                name="adminbarGt"
                onClick={isIpad ? undefined : () => this.onSlide(1)}
              />
            </NavControls>
            )}
          </HeaderWrapper>
          <InnerContainer
            className={className}
            fullwidth={fullwidth}
            height={hasTooltips ? slidesHeight : null}
            minWidth={minWidth}
          >
            {useOldDesign && (
            <Button
              active={showLeftButton}
              left
              onClick={isIpad ? undefined : () => this.onSlide(-1)}
            >
              { showLeftButton && <ArrowIcon name={arrowLeftIconName} /> }
            </Button>
            )}
            { renderSlider(hasTooltips, showLeftButton) }
            {useOldDesign && (
            <Button active={showRightButton} onClick={isIpad ? undefined : () => this.onSlide(1)}>
              { showRightButton && <ArrowIcon name={arrowRightIconName} /> }
            </Button>
            )}
          </InnerContainer>
        </GridRowContainer>
      );
    };

    const renderMobile = () => (
      <>
        <MobileHeader>
          <MobileTitle>{ mobileTitle }</MobileTitle>
        </MobileHeader>
        { renderSlider(false) }
      </>
    );

    return isTouchDevice || (isGrid && isGridMobile) ? renderMobile() : renderDesktop();
  }
}
