import React from 'react';
import { Editor as TinyMceReactEditor, IAllProps } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { useSelector } from 'react-redux';
import { useAdminTranslation } from 'hooks/use-translation';
import { capitalize } from 'lodash';
import { getFonts } from 'services/app';
import { getTheme } from 'services/admin';
import { STATIC_BASE_URL } from 'config';
import { TEXT_HEADLINE_M } from 'style/design-system/textStyles';
import { DEFAULT_FONT_FAMILY_FORMATS } from './utils';

interface IEditor extends IAllProps {
  isEditMode: boolean;
  plugins: string;
  readOnly?: boolean;
  setup: (editor: TinyMCEEditor) => void;
  toolbar1: string;
}

const Editor = ({ readOnly, isEditMode, plugins, toolbar1, setup, ...props }: IEditor, ref: React.ForwardedRef<TinyMceReactEditor>) => {
  const theme = useSelector(getTheme);
  const fonts = useSelector(getFonts);
  const { t } = useAdminTranslation();

  // i.e. "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace"
  const fontFamilyFormats = React.useMemo(() => {
    const themeFonts = fonts
    .map((font) => {
      const name = font.name.split('-').map(capitalize).join(' ');
      return `${name}=${font.name}`;
    })
    .join('; ');

    return `${themeFonts}; ${DEFAULT_FONT_FAMILY_FORMATS}`;
  }, [fonts]);

  const fontsFaces = fonts
  .map(({ name, baseUrl }) => `
    @font-face {
      font-family: ${name};
      src: url("${baseUrl}.eot");
      src: local('☺'),
           url("${baseUrl}.woff") format('woff'),
           url("${baseUrl}.ttf") format('truetype'),
           url("${baseUrl}.svg") format('svg');
    }
  `)
  .join('\n');

  const contentStyle = `
    ${fontsFaces}
    body {
      font-family: ${theme.typography.body};
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      caret-color: auto;
      color: ${theme.colors.text200};
    }
    a {
      color: ${theme.colors.accentPrimary};
    }
    blockquote {
      ${TEXT_HEADLINE_M};
      font-size: 2rem;
    }
    h1, h2, h3, h4, h5, h6 {
      color: ${theme.colors.text100};
    }
    .mce-item-anchor {
      visibility: hidden;
    }
    img {
      height: auto;
      max-width: 100%;
    }
  `;

  const themeColorsMap = [
    theme.colors.surface1, t('SURFACE_1'),
    theme.colors.surface2, t('SURFACE_2'),
    theme.colors.surface3, t('SURFACE_3'),
    theme.colors.surface4, t('SURFACE_4'),
    theme.colors.surface5, t('SURFACE_5'),
    theme.colors.surface6, t('SURFACE_6'),
    theme.colors.accentPrimary, t('ACCENT_PRIMARY'),
    theme.colors.highlightPrimary, t('HIGHLIGHT_PRIMARY'),
  ];

  return (
    <TinyMceReactEditor
      {...props}
      // init is called once, so we need to force re-render by using the values that can change
      key={`${theme._id}-${isEditMode}-${readOnly}`}
      ref={ref}
      tinymceScriptSrc={`${STATIC_BASE_URL}/tiny-mce/tinymce.min.js`}
      disabled={readOnly || !isEditMode}
      toolbar={isEditMode}
      init={{
        menubar: false,
        plugins,
        readonly: readOnly && !isEditMode,
        toolbar_location: 'top',
        toolbar1: isEditMode ? toolbar1 : undefined,
        setup,
        color_map: themeColorsMap,
        font_family_formats: fontFamilyFormats,
        content_style: contentStyle,
      }}
    />
  );
};

export default React.forwardRef(Editor);
