import React, { MouseEventHandler, useEffect, useMemo, useRef } from 'react';
import range from 'lodash/range';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import {
  Checkmark,
  Scroller,
  ScrollerItem,
} from '../styles';
import { CalendarStateProps } from '../interfaces';

export type YearScrollerProps = Pick<CalendarStateProps, 'admin' | 'calendarYear' | 'setCalendarYear'>;

export default function YearScroller({
  admin,
  calendarYear,
  setCalendarYear,
}: YearScrollerProps) {
  const activeYearElementRef = useRef<HTMLButtonElement>(null);
  const yearRange = useMemo(() => (
    range(calendarYear - 100, calendarYear + 101)
  ), [calendarYear]);

  const changeYear: MouseEventHandler<HTMLButtonElement> = (e) => {
    const year = parseInt(e.currentTarget.dataset.year || '0', 10);
    setCalendarYear(year);
  };

  useEffect(() => {
    const activeYearElement = activeYearElementRef.current;
    if (!activeYearElement) {
      return;
    }
    activeYearElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [calendarYear]);

  return (
    <Scroller admin={admin}>
      <NonKeyedListMapper list={yearRange}>
        {(key: string, year: number) => {
          const isActive = year === calendarYear;
          return (
            <ScrollerItem
              admin={admin}
              data-year={year}
              isActive={isActive}
              key={key}
              onClick={changeYear}
              ref={isActive ? activeYearElementRef : null}
            >
              {isActive && <Checkmark admin={admin} />}
              {year}
            </ScrollerItem>
          );
        }}
      </NonKeyedListMapper>
    </Scroller>
  );
}
