import React, { PropsWithChildren } from 'react';
import { ACCENT_PRIMARY, SURFACE_2, SURFACE_5, SURFACE_6 } from 'style/constants';
import styled from 'styled-components';

interface IRadioInputCardProps extends PropsWithChildren, Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'children'> {
  onChangeChecked?: (checked: boolean) => void;
  onChangeValue?: (value: string) => void;
}

export const RadioInputCard: React.FC<IRadioInputCardProps> = ({ children, checked, onChangeChecked, onChangeValue, ...props }) => {
  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeChecked?.(e.target.checked);
    onChangeValue?.(e.target.value);
  }, [onChangeChecked, onChangeValue]);

  return (
    <Label isSelected={checked}>
      <input {...props} type="radio" checked={checked} onChange={handleChange} />
      {children}
    </Label>
  );
};

export const Label = styled.label<{ isSelected?: boolean; }>`
  width: 100%;
  border: 1px solid ${SURFACE_5};
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ isSelected }) => isSelected ? 'default' : 'pointer'};

  & input[type="radio"] {
    appearance: none;
    background-color: ${SURFACE_2};
    margin: 0;
    font: inherit;
    color: ${SURFACE_6};
    width: 20px;
    height: 20px;
    border: 0.1em solid ${SURFACE_6};
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: ${SURFACE_6};
    }


    &:checked {
      border: 0.1em solid ${ACCENT_PRIMARY};
      &::before {
        transform: scale(0.8);
        background-color: ${ACCENT_PRIMARY};
      }
    }
  }
`;
