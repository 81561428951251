import React, { MouseEventHandler, PropsWithChildren } from 'react';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import {
  StyledAdminButton,
  Form,
  Header,
  CloseButtonIcon,
  CloseIcon,
  StyledLoading,
  StyledSiteButton,
  HeaderActionsContainer,
} from './styles';
import { useAdminTranslation, type TranslationKey } from 'hooks/use-translation';
import AdminButton from 'components/admin2/ui/Button';
import Button from '../Button';
export {
  CloseButtonIcon,
  CloseIcon,
} from './styles';

type FormProps = PropsWithChildren<{
  addCloseButton?: boolean;
  addCloseButtonClick?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  headerKey?:TranslationKey;
  headerText?: string;
  loading?: boolean;
  onSubmit?: MouseEventHandler<HTMLButtonElement>;
  showForAdmin?: boolean;
  submitKey?:TranslationKey;
  submitText?: string;
  topButtonClick?: MouseEventHandler<HTMLButtonElement>;
  topButtonDisabled?:boolean;
  topButtonKey?:TranslationKey;
  topButtonText?: string;
}>;

export default function FormComponent({
  addCloseButton,
  addCloseButtonClick,
  children,
  disabled,
  headerKey,
  headerText,
  loading,
  onSubmit,
  showForAdmin,
  submitKey,
  submitText,
  topButtonClick,
  topButtonDisabled = false,
  topButtonKey,
  topButtonText,
}: FormProps) {

  const ButtonComponent = showForAdmin ? AdminButton : Button;
  const { t } = useAdminTranslation();

  return (
    <Form>
      <Header showForAdmin={showForAdmin}>
        {(headerText || headerKey) && (
          <OptionalTranslated stringKey={headerKey}>
            {headerText}
          </OptionalTranslated>
        )}
        <HeaderActionsContainer>
          {(topButtonText || topButtonKey) && (
              <OptionalTranslated
                autoFocus={false}
                component={showForAdmin ? StyledAdminButton : StyledSiteButton}
                onClick={topButtonClick}
                stringKey={topButtonKey}
                disabled={topButtonDisabled}
              >
                {topButtonText}
              </OptionalTranslated>
            )
          }
        {addCloseButton && (
          <CloseButtonIcon onClick={addCloseButtonClick}>
            <CloseIcon name="cancel-thin" />
          </CloseButtonIcon>
        )}
        </HeaderActionsContainer>
      </Header>
      {children}
      <StyledLoading loading={loading} />
      {!loading && (submitText || submitKey) && (
        <ButtonComponent disabled={disabled} onClick={onSubmit} style={{ marginTop: '10px', height: '40px' }}>
          {submitKey ? t(submitKey) : submitText}
        </ButtonComponent>
      )}
    </Form>
  );
}
