import React from 'react';
import {
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  insertList,
  ListNode,
  removeList,
  ListItemNode,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ToolbarButton } from '../../styles';
import unorderedList from 'assets/icons/rich-text-editor-v3/unorderedList.svg';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW } from 'lexical';
import { mergeRegister, $getNearestNodeOfType } from '@lexical/utils';
import useUpdateListener from '../../use-update-listener';

const useUnorderedListPluginRegisters = () => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    if (!editor.hasNode(ListNode) || !editor.hasNode(ListItemNode)) {
      throw new Error('Lexical Editor: List plugin requires ListNode and ListItemNode to be registered');
    }

    return mergeRegister(
      editor.registerCommand(INSERT_UNORDERED_LIST_COMMAND, () => {
        insertList(editor, 'bullet');
        return true;
      }, COMMAND_PRIORITY_LOW),
      editor.registerCommand(REMOVE_LIST_COMMAND, () => {
        removeList(editor);
        return true;
      }, COMMAND_PRIORITY_LOW),
    );
  }, []);
};

export const UnorderedListPlugin = () => {
  useUnorderedListPluginRegisters();
  const [active, setActive] = React.useState(false);
  const [editor] = useLexicalComposerContext();

  const update = React.useCallback(() => {
    const selection = $getSelection();
    let isActive = false;
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const node = $getNearestNodeOfType(element, ListNode);
      isActive = Boolean(node?.getListType() === 'bullet');
    }

    if (isActive !== active) setActive(isActive);
  }, [active]);

  useUpdateListener(editor, update);

  const toggle = React.useCallback(() => {
    if (active) {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    }
  }, [active, editor]);

  return (
    <ToolbarButton
      active={active}
      dangerouslySetInnerHTML={{ __html: unorderedList }}
      onClick={toggle}
    />
  );
};

export default UnorderedListPlugin;
