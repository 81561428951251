import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InsightsViewable from 'components/insights/InsightsViewable';
import { trackHeader } from 'services/insights';
import { COUNTDOWN, TIMER, ENDDATE, MESSAGE } from 'injection-classes';
import { computeTimeLeft, parseTime } from './CountdownProvider';

import {
  BellIcon,
  CountdownEndDate,
  CountdownWrapper,
  CountdownNumbersWrapper,
  Colon,
  FormattedDate,
  FormattedMonth,
  FlexWrapper,
  NumberWrapper,
  Message,
} from './styles';

const formatDateTime = (time) => {
  const fullTime = new Date(time);
  const shortHour = fullTime.getHours() % 12 || 12;
  const period = fullTime.getHours() < 12 ? 'AM' : 'PM';
  const minutes = fullTime.getMinutes().toString().padStart(2, '0');

  return `${shortHour}:${minutes} ${period}`; // 2:33 PM
};

export const CountdownNumberContainer = (props) => {
  const [diff, setDiff] = useState(0);

  const { rerenderCallback, serverOffset, startTime } = props;
  useEffect(() => {
    const interval = setInterval(() => {
      const time = computeTimeLeft(startTime, serverOffset);
      setDiff(time);
      if (time < 0) {
        rerenderCallback();
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);

  const { days, hours, minutes, seconds } = parseTime(diff);

  return (
    <CountdownNumbersWrapper>
      <BellIcon />
      <NumberWrapper>{days.toString().padStart(2, '0')}</NumberWrapper>
      <Colon>:</Colon>
      <NumberWrapper>{hours.toString().padStart(2, '0')}</NumberWrapper>
      <Colon>:</Colon>
      <NumberWrapper>{minutes.toString().padStart(2, '0')}</NumberWrapper>
      <Colon>:</Colon>
      <NumberWrapper>{seconds.toString().padStart(2, '0')}</NumberWrapper>
    </CountdownNumbersWrapper>
  );
};

CountdownNumberContainer.propTypes = {
  rerenderCallback: PropTypes.func,
  serverOffset: PropTypes.number,
  startTime: PropTypes.number,
};
class Countdown extends React.Component {
  static propTypes = {
    centered: PropTypes.bool,
    className: PropTypes.string,
    message: PropTypes.string,
    onAddToCalendar: PropTypes.func.isRequired,
    onEngage: PropTypes.func.isRequired,
    parentOrigin: PropTypes.string,
    serverOffset: PropTypes.number.isRequired,
    startTime: PropTypes.string.isRequired,
  };

  rerenderCountdown = () => {
    this.forceUpdate();
  };

  handleCalendarClick = () => {
    const { parentOrigin, onAddToCalendar, onEngage, message, startTime } = this.props;
    const origin = parentOrigin || window.location.href;

    onAddToCalendar({
      description:
        `Tune in to watch live at ${origin}`,
      endTime: formatDateTime(Date.parse(startTime) + 1000 * 60 * 60),
      engagementTrackingAction: trackHeader(
        'engaged',
        { kind: 'countdown', title: message },
        { action: 'add to calendar' },
      ),
      location: origin,
      startTime,
      title: message,
    });
    onEngage({
      kind: 'countdown',
      title: message,
    });
  };

  render() {
    const { className, startTime, message, centered, serverOffset } = this.props;
    return (computeTimeLeft(startTime, serverOffset) >= 0 && (
      <>
        <InsightsViewable
          doc={{ kind: 'countdown', title: message }}
          kingdom="header"
          visible
        />
        <CountdownWrapper
          centered={centered}
          className={`${className} ${COUNTDOWN}`}
          onClick={this.handleCalendarClick}
        >
          <CountdownEndDate className={ENDDATE}>
            <FormattedMonth show="month" timestamp={new Date(startTime)} />
            <FormattedDate show="day" timestamp={new Date(startTime)} />
          </CountdownEndDate>
          <FlexWrapper>

            {
              message && (
                <Message className={MESSAGE}>
                  {message}
                </Message>
              )
            }
            <CountdownNumberContainer
              className={TIMER}
              rerenderCallback={this.rerenderCountdown}
              serverOffset={serverOffset}
              startTime={startTime}
            />
          </FlexWrapper>
        </CountdownWrapper>
      </>
    )
    );
  }
}

Countdown.defaultProps = {
  centered: false,
  className: '',
  message: '',
  parentOrigin: undefined,
};

export default Countdown;
