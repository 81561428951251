import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, LexicalEditor, RangeSelection, createCommand } from 'lexical';
import styled from 'styled-components';
import { ToolbarButton } from '../../styles';
import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import textColorSvg from 'assets/icons/rich-text-editor-v3/textColor.svg';
import ColorPicker from 'components/admin2/ColorPicker';
import useDebounceWithFunction from 'hooks/use-debounce-with-function';
import { useSelector } from 'react-redux';
import { getText200 } from 'services/themes';
import ModalPortal from 'components/modals/ModalPortal';
import useUpdateListener from '../../use-update-listener';
import { hexFromRgb } from 'colors';

const APPLY_TEXT_COLOR_COMMAND = createCommand<{ selection: RangeSelection | null; textColor: string; }>('APPLY_TEXT_COLOR_COMMAND');

const applyTextColor = (editor: LexicalEditor, textColor: string, selection: RangeSelection | null) => {
  editor.update(() => {
    if (!selection) {
      return;
    }

    $patchStyleText(selection, {
      'color': textColor,
    });
  });
};

const useTextColorPluginRegisters = () => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    return editor.registerCommand(APPLY_TEXT_COLOR_COMMAND, ({ textColor, selection }) => {
      applyTextColor(editor, textColor, selection);
      return true;
    }, COMMAND_PRIORITY_LOW);
  }, []);
};

const TextColorPlugin = ({ inline }: { inline?: boolean; }) => {
  useTextColorPluginRegisters();
  const [editor] = useLexicalComposerContext();
  const text200 = useSelector(getText200);
  const [textColor, setTextColor] = React.useState(text200);
  const [selection, setSelection] = React.useState<RangeSelection | null>(null);

  const ColorPickerWrapper = inline ? React.Fragment : ModalPortal;

  useDebounceWithFunction(() => {
    if (!textColor) {
      return;
    }
    editor.dispatchCommand(APPLY_TEXT_COLOR_COMMAND, { textColor, selection });
  }, textColor, 200);

  const [isOpen, setIsOpen] = React.useState(false);

  const open = async () => {
    if (isOpen) {
      return;
    }

    await updateColor();
    setIsOpen(true);
  };

  const close = () => {
    if (!isOpen) {
      return;
    }

    setIsOpen(false);
  };

  const ref = React.useRef<HTMLDivElement>(null);

  const isSameColor = (first: string, second: string) => first.toLowerCase() === second.toLowerCase();

  const handleChange = (newColor: string) => {
    if (!isSameColor(newColor, textColor)) {
      setTextColor(newColor);
    }
  };

  useUpdateListener(editor, () => {
    const newSelection = $getSelection();

    if (!$isRangeSelection(newSelection)) {
      return;
    }

    setSelection(newSelection);
  });

  const updateColor = () => {
    return new Promise<void>(res => {
      editor.getEditorState().read(() => {
        const newSelection = $getSelection();

        if (!$isRangeSelection(newSelection)) {
          res();
          return;
        }

        const newTextColor = $getSelectionStyleValueForProperty(newSelection, 'color');
        if (newTextColor.startsWith('#') && !isSameColor(newTextColor, textColor)) {
          setTextColor(newTextColor);
        }

        if (newTextColor.includes('rgb')) {
          const hex = hexFromRgb(newTextColor, 1);
          if (!isSameColor(hex, textColor)) {
            setTextColor(hex);
          }
        }

        res();
      });
    });
  };

  return (
    <Wrapper ref={ref}>
      <ToolbarButton
        active={isOpen}
        aria-label="Text Color"
        dangerouslySetInnerHTML={{ __html: textColorSvg }}
        onClick={open}
      />
      <ColorPickerWrapper>
        <ColorPicker
          isOpen={isOpen}
          onClose={close}
          onResult={handleChange}
          parentRef={ref}
          color={textColor}
          titleKey="ADMIN_SETTINGS_DESIGN_LABEL_TEXT_COLOR"
          parentMargin={{
            left: -30,
            top: 35,
          }}
        />
      </ColorPickerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .color-picker-wrapper {
    top: unset;
    left: unset;
  }
`;

export default TextColorPlugin;
