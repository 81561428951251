import React, { useState } from 'react';
import useDebounceWithFunction from 'hooks/use-debounce-with-function';
import hash from 'json-stable-stringify';
import { EditorState, convertToRaw, Modifier, SelectionState } from 'draft-js';
import { toContentState } from './utils';
import draftToHtml from 'draftjs-to-html';

export const INVISIBLE_CHARACTER = '‎';

interface IUseRichTextEditorParams {
  data: string;
  disableDebounce?: boolean;
  maxLength: number;
  onChange: (state: string) => void;
}

const useRichTextEditor = ({
  data,
  maxLength,
  onChange,
  disableDebounce,
}: IUseRichTextEditorParams) => {
  const [didDiscardChanges, setDidDiscardChanges] = useState(true);
  const [value, setValue] = React.useState<string>(data);

  useDebounceWithFunction(
    () => {
      if (didDiscardChanges) {
        setDidDiscardChanges(false);
      } else {
        onChange(value);
      }
    },
    value,
    disableDebounce ? 0 : 400,
  );

  // When data changes in props (when we discard the changes)
  React.useEffect(() => {
    setDidDiscardChanges(true);
    setValue(data);
  }, [hash(data)]);

  /**
   * The Rich Text Library doesn't keep styles when we delete the whole content from the input box.
   */
  const setEmptyStyledText = (rawData: string) => {
    const contentState = toContentState(rawData);
    const oldContentState = EditorState.createWithContent(toContentState(data)).getCurrentContent();

    const emptyStyledText = Modifier.replaceText(
      contentState,
      new SelectionState({
        anchorKey: contentState.getFirstBlock().getKey(),
        anchorOffset: 0,
        focusKey: contentState.getFirstBlock().getKey(),
        focusOffset: 2,
      }),
      INVISIBLE_CHARACTER,
      oldContentState.getFirstBlock().getInlineStyleAt(0),
    );
    setValue(draftToHtml(convertToRaw(emptyStyledText)));
  };

  const onChangeValue = (rawData: string, textContent: string) => {
    if (!textContent) {
      setEmptyStyledText(rawData);
    } else if (textContent.length <= maxLength) {
      setValue(rawData);
    }
  };

  return {
    onChangeValue,
    value,
  };
};

export default useRichTextEditor;
