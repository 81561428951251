import styled, { css } from 'styled-components';
import { HIDE_SCROLLBAR } from 'style/mixins';
import { StyleProps } from './interfaces';
import { isSafari } from 'utils';

export const ScrollNavigationContainer = styled.div<{center?: boolean, wrapperSeparation?: number}>`
  ${HIDE_SCROLLBAR}
  overflow: auto hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  ${({ center }) => center && `justify-content: center;`}
  ${({ wrapperSeparation = 0 }) => isSafari && css`
    & > *:last-child {
      border-right: ${wrapperSeparation}px solid transparent;
      margin-right: 0;
    }
  `}
`;

export const ItemWrapper = styled.div<StyleProps>`
  height: 100%;
  ${({ wrapperOffset = 0, wrapperSeparation = 0 }) => css`
    width: calc(100% - ${wrapperOffset * 2}px);
    margin: 0 ${wrapperSeparation}px;
  `}
  flex-shrink: 0;
`;
