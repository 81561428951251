import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { isVideoEmbed as isVideoEmbedSelector } from 'services/user-layout';
import { StyledVideo } from './styles';
import Image from 'components/objects/Image';
import {
  getChannelOfflineMode,
  getChannelOfflineVideo,
  getChannelOfflinePlaylist,
} from 'services/app/selectors';
import { IVideo } from 'models';
import { PlayerSpot } from 'services/video';
import Skeleton from '../Skeleton';

const ChannelOfflineContent: FC<{
  loop: boolean;
  onVideoEnd?: () => void;
  video?: IVideo;
}> = ({ video, onVideoEnd, loop }) => {
  const offlineMode = useSelector(getChannelOfflineMode);
  const offlineVideo = useSelector(getChannelOfflineVideo);
  const offlinePlaylist = useSelector(getChannelOfflinePlaylist);
  const isVideoEmbed = useSelector(isVideoEmbedSelector);

  // channel has offline content configured but the CanIPlay response
  // isn't available yet
  if (offlineMode === 'tv' && (offlineVideo || offlinePlaylist) && !video) {
    return (
      <Skeleton type="video" />
    );
  }

  if (video) {
    return (
      <StyledVideo
        video={video}
        isVideoEmbed={isVideoEmbed}
        scheduledVideoStartTime={null}
        spot={PlayerSpot.tv}
        onVideoComplete={onVideoEnd}
        loop={loop}
      />
    );
  }

  return (
    <Image />
  );
};

export default ChannelOfflineContent;
