import styled from 'styled-components';
import {
  SURFACE_5,
  ADMIN_TEXT_300,
  ADMIN_SURFACE_6,
} from 'style/constants';
import { ADMIN_TEXT_LABEL_XS_BOLD } from 'style/design-system/textStyles';

export const Button = styled.div`
  ${ADMIN_TEXT_LABEL_XS_BOLD}
  color: ${ADMIN_TEXT_300};
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  height: 70px;
  border-radius: 10px;
  border: 1px solid ${SURFACE_5};
  &:hover {
    border-color: ${ADMIN_SURFACE_6};
  }
`;
