import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React from 'react';

const EditablePlugin = ({ isEditing }: { isEditing: boolean; }) => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    if (editor.isEditable() !== isEditing) {
      editor.setEditable(isEditing);
    }
  }, [editor, isEditing]);

  return null;
};

export default EditablePlugin;
