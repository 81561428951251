import React, { useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import TranslatedText from 'components/i18n/TranslatedText';
import { useAdminTranslation } from 'hooks/use-translation';

interface TimeElapsedProps extends React.HTMLAttributes<HTMLElement> {
  date?: string | number | Date;
}

const TIME_MAP = [
  ['year', 'ADMIN_SHORT_RELATIVE_TIME_X_YEARS_AGO'] as const,
  ['month', 'ADMIN_SHORT_RELATIVE_TIME_X_MONTHS_AGO'] as const,
  ['day', 'ADMIN_SHORT_RELATIVE_TIME_X_DAYS_AGO'] as const,
  ['hour', 'ADMIN_SHORT_RELATIVE_TIME_X_HOURS_AGO'] as const,
  ['minute', 'ADMIN_SHORT_RELATIVE_TIME_X_MINUTES_AGO'] as const,
  ['second', 'ADMIN_SHORT_RELATIVE_TIME_X_SECONDS_AGO'] as const,
];

const Container = styled.div`
  font-weight: 500;
`;

export default function TimeElapsed({ className, date: rawDate }: TimeElapsedProps) {
  const { t } = useAdminTranslation();
  const timeElapsed = useMemo((): string | void => {
    if (!rawDate) {
      return;
    }
    const now = moment();
    const date = moment(rawDate);
    for (const [momentKey, elapsedTimeKey] of TIME_MAP) {
      const time = now.diff(date, momentKey);
      if (time) {
        return t(elapsedTimeKey, { time });
      }
    }
  }, [rawDate]);

  if (!rawDate) {
    return null;
  }

  return timeElapsed ? (
    <Container className={className}>
      {timeElapsed}
    </Container>
  ) : <TranslatedText stringKey="ADMIN_LABEL_JUST_NOW" />;
}
