import React, { MouseEventHandler } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  DateTitle,
  Header,
  NavArrow,
  NavigationContainer,
} from '../styles';
import { CalendarStyleProps } from '../interfaces';
import type { TranslationKey } from 'hooks/use-translation';

interface DateHeaderProps extends CalendarStyleProps {
  emptyTitleKey: TranslationKey;
  navNext?: MouseEventHandler<HTMLSpanElement>;
  navPrev?: MouseEventHandler<HTMLSpanElement>;
  title?: string | number | null;
}

export default function DateHeader({
  admin,
  emptyTitleKey,
  navPrev,
  navNext,
  title,
}: DateHeaderProps) {
  return (
    <Header>
      {title ? (
        <DateTitle>
          {title}
        </DateTitle>
      ) : (
        <TranslatedText component={DateTitle} stringKey={emptyTitleKey} />
      )}
      <NavigationContainer>
        {navPrev && <NavArrow admin={admin} name="leftArrowGrad" onClick={navPrev} />}
        {navNext && <NavArrow admin={admin} name="rightArrowGrad" onClick={navNext} />}
      </NavigationContainer>
    </Header>
  );
}