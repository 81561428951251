import { Link as RouterLink } from 'react-router-dom';
import { LINK_MIXIN } from 'style/mixins';
import styled, { css } from 'styled-components';

const LINK_STYLE = css<{ plain: boolean; }>`
  ${({ plain }) => !plain ? LINK_MIXIN : css`
    text-decoration: none;
    color: inherit;
  `};
`;

export const StyledExternalLink = styled.a<{ plain: boolean; }>`
  ${LINK_STYLE}
`;

export const StyledInternalLink = styled(RouterLink)<{ plain: boolean; }>`
  ${LINK_STYLE}
`;
