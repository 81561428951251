import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getTableCellNodeFromLexicalNode,
  $insertTableRow__EXPERIMENTAL,
  $insertTableColumn__EXPERIMENTAL,
  TableCellNode,
} from '@lexical/table';
import { $getSelection, $isRangeSelection } from 'lexical';
import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  AddRowOrColumnButtonWrapper,
  PlusIcon,
  TableCellActionButton,
} from './styles';
import { STICKING_PADDING } from './TableActionMenuButton';

export type TableCellOrientation = 'horizontal' | 'vertical';

export function TableAddRowOrColumnButton({
  orientation = 'horizontal',
  anchorElem,
  sticking = false,
}: {
  anchorElem: HTMLElement;
  orientation: TableCellOrientation;
  sticking?: boolean;
}): JSX.Element {
  const [editor] = useLexicalComposerContext();

  const menuButtonRef = useRef(null);
  const menuRootRef = useRef(null);

  const [tableCellNode, setTableMenuCellNode] =
    useState<TableCellNode | null>(null);
  const [tableRect, setTableRect] = useState<DOMRect | null>(null);

  const placeButtonInLocation = useCallback(() => {
    const menu = menuButtonRef.current;
    const selection = $getSelection();
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (selection == null || menu == null) {
      setTableMenuCellNode(null);
      return;
    }

    const rootElement = editor.getRootElement();

    if (
      $isRangeSelection(selection) &&
      rootElement !== null &&
      nativeSelection !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(
        selection.anchor.getNode(),
      );

      if (tableCellNodeFromSelection == null) {
        setTableMenuCellNode(null);
        return;
      }

      const tableCellParentNodeDOM = editor.getElementByKey(
        tableCellNodeFromSelection.getKey(),
      );

      if (tableCellParentNodeDOM == null) {
        setTableMenuCellNode(null);
        return;
      }

      setTableMenuCellNode(tableCellNodeFromSelection);
      if (!tableRect) {
        setTableRect(
          tableCellParentNodeDOM.closest('table')?.getBoundingClientRect() ||
            null,
        );
      }
    } else if (!activeElement) {
      setTableRect(null);
      setTableMenuCellNode(null);
    }
  }, [editor]);

  /**
   * This effect fixes a bug where the toolbar changes its position,
   * and the button is not placed correctly.
   */
  useEffect(() => {
    const rootElement = editor.getRootElement();
    const table = rootElement?.querySelector('table');
    if (table) {
      setTableRect(table.getBoundingClientRect());
    }
  }, [sticking]);

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        placeButtonInLocation();
      });
    });
  }, [editor]);

  useEffect(() => {
    const menuButtonDOM = menuButtonRef.current as HTMLButtonElement | null;
    const anchorRect = anchorElem.getBoundingClientRect();
    const padding = !sticking ? STICKING_PADDING : 0;

    if (menuButtonDOM != null && tableRect) {
      if (tableCellNode != null) {
        menuButtonDOM.style.opacity = '1';
        if (orientation === 'vertical') {
          menuButtonDOM.style.transform = `translate(0px, ${
            tableRect.bottom - anchorRect.top + padding
          }px)`;
        } else {
          const horizontalPosition = tableRect.right - anchorRect.left;
          const verticalPosition = tableRect.top - anchorRect.top + padding;
          menuButtonDOM.style.transform = `translate(${horizontalPosition}px, ${verticalPosition}px)`;
        }
      } else {
        menuButtonDOM.style.opacity = '0';
      }
    }
  }, [menuButtonRef.current, tableRect, orientation, sticking, tableCellNode]);

  const handleClick = useCallback(() => {
    editor.update(() => {
      if (orientation === 'vertical') {
        $insertTableRow__EXPERIMENTAL(true);
      } else {
        $insertTableColumn__EXPERIMENTAL(true);
      }
    });
  }, [editor, orientation]);

  return (
    <AddRowOrColumnButtonWrapper ref={menuButtonRef}>
      <TableCellActionButton onClick={handleClick} ref={menuRootRef}>
        <PlusIcon />
      </TableCellActionButton>
    </AddRowOrColumnButtonWrapper>
  );
}
