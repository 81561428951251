import React, { useCallback } from 'react';
import {
  PageNavigationStyles,
  PageButton as Button,
} from './styles';

export interface PageButtonProps extends PageNavigationStyles {
  onChange?: (pageIndex: number) => void;
  page?: number;
}

export default function PageButton({ active, page = 0, onChange, ...props }: PageButtonProps) {
  const handleClick = useCallback(() => {
    if (active || !onChange) {
      return;
    }
    onChange(page);
  }, [active, page, onChange]);

  return <Button active={active} onClick={handleClick} {...props} />;
}
