import { Moment } from 'moment-timezone';
import { WeekDay } from 'hooks/use-calendar';
import type { TranslationKey } from 'hooks/use-translation';

export interface CalendarStyleProps {
  admin?: boolean;
  blur?: boolean;
  className?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  isOpen?: boolean;
  maxCols?: number;
  noMargin?: boolean;
}

export interface CalendarProps extends CalendarStyleProps, RangePickerProps {
  calendarPosition?: 'left' | 'right' | 'top';
  closeOnPick?: boolean;
  date?: Moment | null;
  defaultPicker?: Picker;
  expandableBackGroundColor?: string,
  footerTextKey?: TranslationKey;
  hideClearButton?: boolean;
  iconButtonRight?: string,
  id?: string;
  placeholder?: string;
  startingWeekDay?: WeekDay;
  isOutsideRange?(date: Moment): boolean;
  onClear?(): void;
  onDateChange?(date?: Moment | null): void;
  onDatesChange?(startDate?: Moment | null, endDate?: Moment | null): void;
}

export interface RangePickerProps extends CalendarStyleProps {
  endDate?: Moment | null;
  startDate?: Moment | null;
  onDatesChange?(startDate?: Moment | null, endDate?: Moment | null): void;
}

export interface DayCalendarProps {
  monthYearTitle: string;
  nextMonth(): void;
  nextYear(): void;
  prevMonth(): void;
  prevYear(): void;
}

export type Dispatcher<T> = React.Dispatch<React.SetStateAction<T>>;

export enum Picker {
  day = 'day',
  month = 'month',
  quarter = 'quarter',
  week = 'week',
  year = 'year',
}

export interface CalendarStateProps extends CalendarStyleProps {
  calendarMonth: number;
  calendarYear: number;
  dateTitle?: string | null;
  isMonthYearSelectorVisible: boolean;
  setCalendarMonth: Dispatcher<number>;
  setCalendarYear: Dispatcher<number>;
  setCurrentPicker: Dispatcher<Picker>;
  showDefaultPicker(): void;
  toggleMonthYearSelector(): void;
}
