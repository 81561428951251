import React from 'react';
import { CardsMenuContainer, MenuAction, MenuActionIcon, MenuActionName } from './styles';

export interface IMenuAction {
  dataTestId: string;
  handler: (e: React.MouseEvent) => void;
  icon: string;
  id: string;
  name: string;
}

interface ICardsMenu {
  id: string;
  isAdmin: boolean;
  menuActions: IMenuAction[];
}

const CardsMenu = React.forwardRef(({
  id,
  menuActions,
  isAdmin,
}: ICardsMenu, ref) => {
  return (
    <CardsMenuContainer
      ref={ref as any}
      id={id}
      data-testid={id}
      isAdmin={isAdmin}
    >
      {menuActions.map((menuAction) => (
        <MenuAction onClick={menuAction.handler} key={menuAction.id} isAdmin={isAdmin} data-testid={menuAction.dataTestId}>
          <MenuActionIcon iconName={menuAction.icon} isAdmin={isAdmin}/>
          <MenuActionName isAdmin={isAdmin}>{menuAction.name}</MenuActionName>
        </MenuAction>
      ))}
    </CardsMenuContainer>
  );
});

export default CardsMenu;
