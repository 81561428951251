import { SURFACE_5, TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import { SINGLE_LINE_ELLIPSIS } from 'style/mixins';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  border-top: 1px solid ${SURFACE_5};
  border-bottom: 1px solid ${SURFACE_5};
  margin-bottom: 16px;
`;

export const ItemName = styled.span`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_200};
  ${SINGLE_LINE_ELLIPSIS}
  max-width: 70%;
`;

export const ItemPrice = styled.span`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_200};
  text-transform: uppercase;
`;

export const Total = styled.h4<{ uppercase: boolean }>`
  margin: 0;
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_100};

  text-transform: ${props => props.uppercase ? 'uppercase' : 'none'};
`;
