import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, ElementFormatType, ElementNode, FORMAT_ELEMENT_COMMAND } from 'lexical';
import { ToolbarButton } from '../../styles';
import useUpdateListener from '../../use-update-listener';
import { $getNearestNodeOfType } from '@lexical/utils';

type AlignPluginProps = {
  formatType: ElementFormatType;
  icon: string;
};

const AlignPlugin: React.FC<AlignPluginProps> = ({ formatType, icon }) => {
  const [active, setActive] = React.useState(false);
  const [editor] = useLexicalComposerContext();

  const onClick = React.useCallback(() => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, formatType);
  }, [editor]);

  const update = React.useCallback(() => {
    const selection = $getSelection();
    let isActive = false;

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const node = $getNearestNodeOfType(element, ElementNode);
      isActive = node?.getFormatType() === formatType;
    }

    if (isActive !== active) setActive(isActive);
  }, [active]);

  useUpdateListener(editor, update);

  return (
    <ToolbarButton
      aria-label={`Format ${formatType} Align`}
      active={active}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  );
};

export default AlignPlugin;
