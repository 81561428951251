import styled, { css } from 'styled-components';
import ChannelSelector from 'components/ui/ChannelSelector';
import { ACCENT_PRIMARY, TEXT_100, SURFACE_1 } from 'style/constants';
import Icon from 'components/ui/Icon';
import { rgba } from 'colors';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';

interface StyleProps {
  active?: boolean;
  addInlinePadding?: boolean;
  channelSelectActive?: boolean;
  fullscreenActive?: boolean;
  isAdmin?: boolean;
  isEditing?: boolean;
  isSimpleModeEnabled?: boolean;
  left?: boolean;
  maxWidth?: number;
  slideWidth: number;
}

export const ChannelSelectWrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChannelSelectContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 0 0 auto;
  position: relative;
  height: 100%;
  color: ${TEXT_100};
  margin-top: auto;
`;

export const ChannelSelectItem = styled(ChannelSelector)<StyleProps>`
  margin-bottom: 17px;
  width: ${props => props.slideWidth}px;
`;

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<StyleProps>`
  align-items: center;
  padding: 20px;
  padding-right: 0;
  & > div > div > div > div:first-child {
    padding-right: 0;
  }
  display: ${({ isAdmin, channelSelectActive, isSimpleModeEnabled, isEditing }) => {
    const shouldRender = isSimpleModeEnabled ? isAdmin : isEditing;
    return (shouldRender || channelSelectActive) ? 'flex' : 'none';
  }};
  min-height: 183px;
  &:empty {
    border-bottom: none !important;
  }
`;

export const SlideButton = styled.div<StyleProps>`
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};
  font-size: 22px;
  width: 93px;
  position: absolute;
  z-index: 1;
  top: 0;
  ${({ left }) => !left && 'right: 0;'}
  ${({ left }) => !left && 'justify-content: end;'}
  height: calc(100% - 17px);
  display: ${({ active }) => (active ? 'flex' : 'none')};
  align-items: center;
  ${(props) => css`
    background: linear-gradient(
      ${props.left ? '90deg' : '270deg'},
      ${SURFACE_1} -5%,
      ${rgba(SURFACE_1(props), 0.62)} 54.94%,
      ${rgba(SURFACE_1(props), 0.08)} 93.18%
    );
  `};

  border-radius: 5px;
  padding: 10.5px;

  &:hover {
    & svg path {
      fill: ${ACCENT_PRIMARY};
    }
  }
  & svg path {
    fill: ${TEXT_100};
  }
`;

export const ChannelSelectArrowIcon = styled(Icon)`
  display: block;
`;
