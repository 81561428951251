import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import {
  ADMIN_SURFACE_4,
  TEXT_100,
} from 'style/constants';
import {
  CIRCLE,
  FLEX_CENTER,
} from 'style/mixins';

const VARIANT_MAP = {
  circle: CIRCLE,
};

export interface ImageStyleProps {
  radius?: string;
  spacing?: string;
  variant?: keyof typeof VARIANT_MAP;
}

export const StyledImg = styled.img.attrs((props) => ({
  radius: '44px',
  ...props,
}))<ImageStyleProps>`
  ${({ spacing, variant }) => css`
    ${variant && VARIANT_MAP[variant]}
    ${spacing && `margin: ${spacing};`}
  `}
  object-fit: cover;
`;

export const FallbackIcon = styled(Icon).attrs(props => ({
  color: TEXT_100(props),
  radius: '44px',
  ...props,
}))<ImageStyleProps>`
  ${FLEX_CENTER}
  background: ${ADMIN_SURFACE_4};
  & > svg {
    width: 18px;
    height: 18px;
  }
  font-size: 18px;
  ${({ spacing, variant }) => css`
    ${variant && VARIANT_MAP[variant]}
    ${spacing && `margin: ${spacing};`}
  `}
`;
