/* tslint:disable:jsx-boolean-value */
import React, { MouseEventHandler, PropsWithChildren } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { Transition } from 'react-transition-group';
import DateHeader from './DateHeader';
import {
  ActionButton,
  ActionButtonArrow,
  ActionButtonText,
  CalendarContainer,
  Header,
  Title,
  TransitionContent,
} from '../styles';
import { CalendarStyleProps } from '../interfaces';
import { TranslationKey } from 'hooks/use-translation';

export interface ContainerProps extends PropsWithChildren<CalendarStyleProps> {
  buttonText?: string | number;
  containerTitleKey: TranslationKey;
  isMonthYearSelectorVisible: boolean;
  navEmptyTitleKey?: TranslationKey;
  navNext?: MouseEventHandler<HTMLSpanElement>;
  navPrev?: MouseEventHandler<HTMLSpanElement>;
  navTitle?: string | number | null;
  onActionClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function Container({
  admin,
  buttonText,
  children,
  containerTitleKey,
  isMonthYearSelectorVisible,
  navEmptyTitleKey = 'ADMIN_LABEL_DATE_FORMAT',
  navNext,
  navPrev,
  navTitle,
  onActionClick,
}: ContainerProps){
  return (
    <CalendarContainer admin={admin} blur={isMonthYearSelectorVisible}>
      <Header>
        <TranslatedText component={Title} stringKey={containerTitleKey} />
        {buttonText && (
          <ActionButton onClick={onActionClick}>
            <ActionButtonText>{buttonText}</ActionButtonText>
            <ActionButtonArrow admin={admin} up={isMonthYearSelectorVisible} />
          </ActionButton>
        )}
      </Header>
      <DateHeader admin={admin} emptyTitleKey={navEmptyTitleKey} navNext={navNext} navPrev={navPrev} title={navTitle} />
      <Transition appear in timeout={0}>
        {(status) => (
          <TransitionContent status={status}>
            {children}
          </TransitionContent>
        )}
      </Transition>
    </CalendarContainer>
  );
}
