import { darken, lighten } from 'colors';
import { CTAButtonProps, CTASize, CTA_SIZES_DESIGN_TOKENS_MAP, CTA_SIZES_HEIGHT_PX_MAP, CTA_SIZES_INLINE_PADDING_MAP } from 'components/admin-bridge/CTAEditorModal/utils';
import { createTextContrastOnBackgroundColor } from 'services/themes/utils';
import { ACCENT_PRIMARY, BUTTON_BORDER_RADIUS, HIGHLIGHT_PRIMARY, HIGHLIGHT_TERTIARY, OPTIONAL_DYNAMIC_THEME_COLOR, TEXT_100, TEXT_500 } from 'style/constants';
import { SINGLE_LINE_ELLIPSIS, desktopOnly, mobileOnly } from 'style/mixins';
import styled, { DefaultTheme, FlattenInterpolation, ThemeProps, css } from 'styled-components';
import { ObjectValues } from 'utils';

export const CTA_VARIANT = {
  Outline: 'outline',
  Solid: 'solid',
} as const;

export type CTAVariant = ObjectValues<typeof CTA_VARIANT>;

export const CTA_BUTTON_MAX_TEXT_LENGTH = 25;

type VariantMapProps = {
  foregroundColor: {
    default: string;
    focus: string;
    hover: string;
  }
  textColor: string;
};

const CTA_VARIANT_MAP: Record<CTAVariant, (props: VariantMapProps) => FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  outline: ({ foregroundColor, textColor }: VariantMapProps) => css`
    background-color: transparent;
    outline: 1px solid ${foregroundColor.default};
    color: ${textColor};
    &:focus {
      outline: 1px solid ${foregroundColor.focus} !important;
    }

    &:hover {
      outline: 1px solid ${foregroundColor.hover} !important;
    }
  `,
  solid: ({ foregroundColor, textColor }: VariantMapProps) => css`
    background-color: ${foregroundColor.default};
    color: ${textColor};

    &:hover {
      background-color: ${foregroundColor.hover};
    }

    &:focus {
      background-color: ${foregroundColor.focus};
    }
  `,
};

type StyledButtonProps = {
  /**
   * The container that holds the button. Used to change colors of the button if it's using the same background color as the button
   */
  containerBackgroundColor?: string;
  fontSize?: CTAButtonProps['fontSize'];
  height: CTAButtonProps['height'];
  variant: CTAButtonProps['variant'];
};

const getStyleFromHeightSize = (size: CTASize) => css`
  height: ${CTA_SIZES_HEIGHT_PX_MAP[size]};
  ${CTA_SIZES_DESIGN_TOKENS_MAP[size]}
  padding-inline: ${CTA_SIZES_INLINE_PADDING_MAP[size]};
`;

export const CTAButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${BUTTON_BORDER_RADIUS};
  ${SINGLE_LINE_ELLIPSIS}

  ${({ variant, containerBackgroundColor, ...props }) => {
    const text100 = TEXT_100(props);
    const text500 = TEXT_500(props);
    const highlightPrimary = HIGHLIGHT_PRIMARY(props);
    const highlightTertiary = HIGHLIGHT_TERTIARY(props);

    if (variant === 'solid') {
      let backgroundColor = ACCENT_PRIMARY(props);
      let textColor = createTextContrastOnBackgroundColor(backgroundColor, text100, text500);

      // if the container is using the accentPrimary, calculate wether to use highlightPrimary or highlightTertiary
      if (containerBackgroundColor === 'accentPrimary' || containerBackgroundColor === backgroundColor) {
        backgroundColor = createTextContrastOnBackgroundColor(backgroundColor, highlightPrimary, highlightTertiary);
        textColor = createTextContrastOnBackgroundColor(backgroundColor, text100, text500);
      }
      return CTA_VARIANT_MAP[variant]({
        foregroundColor: {
          default: backgroundColor,
          focus: darken(backgroundColor, 0.05),
          hover: lighten(backgroundColor, 0.05),
        },
        textColor,
      });
    }

    if (variant === 'outline') {
      const containerColor = OPTIONAL_DYNAMIC_THEME_COLOR(containerBackgroundColor || 'transparent')(props);
      const textColor = createTextContrastOnBackgroundColor(containerColor, text100, text500);
      let hoverColor = ACCENT_PRIMARY(props);

      if (containerColor === hoverColor) {
        hoverColor = highlightTertiary;
      }

      return CTA_VARIANT_MAP[variant]({
        foregroundColor: {
          default: highlightPrimary,
          focus: darken(highlightPrimary, 0.05),
          hover: hoverColor,
        },
        textColor,
      });
    }
  }}

  ${({ height }) => mobileOnly`${getStyleFromHeightSize(height.mobile)}`}
  ${({ height, fontSize }) => desktopOnly`
    ${getStyleFromHeightSize(height.desktop)}
    ${fontSize && css`font-size: ${fontSize}px;`}
  `}
  ${({ disabled }) => disabled && css`opacity: 0.4;`}
`;
