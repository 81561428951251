import TranslatedText from 'components/i18n/TranslatedText';
import useUserAchievementPoints from 'hooks/use-user-achievement-points';
import { USER_ACHIEVEMENT_POINTS } from 'injection-classes';
import React, { FC } from 'react';
import styled from 'styled-components';
import {
  TEXT_H6,
} from 'style/mixins';

const formatPoints = (points: number) => Number(points).toLocaleString();

const AchievementPoints = styled.div`
  ${TEXT_H6}
  margin-top: 5px;
`;

const AchievementPointsHeader: FC = () => {
  const [userPoints, userPointsLoaded] = useUserAchievementPoints();

  return (
    <>
      {
        userPointsLoaded && typeof userPoints === 'number' && (
          <AchievementPoints className={USER_ACHIEVEMENT_POINTS}>
            {formatPoints(userPoints)}
            {' '}
            <TranslatedText stringKey="LABEL_POINTS" />
          </AchievementPoints>
        )
      }
    </>
  );
};

export default AchievementPointsHeader;
