import { LIVE_INDICATOR_RED } from 'style/constants';
import styled from 'styled-components';

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${LIVE_INDICATOR_RED};
`;

export default Dot;
