import { useEffect, useRef, FC } from 'react';
import ReactDOM from 'react-dom';

type FontLoaderProps = {
  fonts?: {
    baseUrl: string;
    name: string;
  }[];
};

const FontLoader: FC<FontLoaderProps> = ({ fonts = [] }) => {
  const styleTagRef = useRef<HTMLStyleElement | null>(null);

  useEffect(
    () => {
      const tag = document.createElement('style');
      document.head.appendChild(tag);
      styleTagRef.current = tag;
      return () => {
        document.head.removeChild(styleTagRef.current!);
        styleTagRef.current = null;
      };
    },
    [],
  );

  const styleNode = styleTagRef.current;

  if (!styleNode)
    return null;

  // The local smiley thing forces the font not to load from the user's local computer:
  // https://www.paulirish.com/2010/font-face-gotchas/#smiley
  // Yes, this is stupid.
  // Yes, I hate it.
  // Yes, we need it.
  // - Andy
  const styleTagContents = fonts
    .map(({ name, baseUrl }) => `
      @font-face {
        font-family: ${name};
        src: url("${baseUrl}.eot");
        src: local('☺'),
             url("${baseUrl}.woff") format('woff'),
             url("${baseUrl}.ttf") format('truetype'),
             url("${baseUrl}.svg") format('svg');
        font-weight: normal;
        font-style: normal;
      }
    `)
    .join('\n');

  return ReactDOM.createPortal(
    styleTagContents,
    styleNode,
  );
};

export default FontLoader;
