import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { FONT_FAMILY_BODY, SURFACE_2, TEXT_100, TEXT_300 } from 'style/constants';
import styled, { ThemedStyledProps } from 'styled-components';

const style = (props: ThemedStyledProps<any, any>) => ({
  base: {
    fontFamily: FONT_FAMILY_BODY(props),
    color: TEXT_100(props),
    backgroundColor: 'transparent',
    fontSize: '14px',
    lineHeight: '35px',
    '::placeholder': {
      color: TEXT_300(props),
    },
  },
  invalid: {
    color: TEXT_100(props),
  },
});

export const StyledCardNumberElement = styled(CardNumberElement).attrs(props => ({
  options: {
    style: style(props),
  },
}))`
  all: unset;
  width: 100%;
  height: 35px;
  background-color: transparent;
`;

export const StyledCardExpiryElement = styled(CardExpiryElement).attrs(props => ({
  options: {
    style: style(props),
  },
}))`
  all: unset;
  width: 100%;
  height: 35px;
  background-color: transparent;
`;

export const StyledCardCvcElement = styled(CardCvcElement).attrs(props => ({
  options: {
    style: style(props),
  },
}))`
  all: unset;
  width: 100%;
  height: 35px;
  background-color: transparent;
`;
