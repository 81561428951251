import { ACCENT_PRIMARY, TEXT_100, TEXT_200 } from 'style/constants';
import { TEXT_BODY_XS_MEDIUM, TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const PaymentMethodInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
`;

export const CardInfo = styled.div`
  ${TEXT_LABEL_M_BOLD}
  color: ${TEXT_100};
`;

export const CardOwnerInfo = styled.div`
  ${TEXT_BODY_XS_MEDIUM}
  color: ${TEXT_200};
`;

export const BrandImage = styled.img`
  width: 34px;
  height: 24px;
  border-radius: 3px;
`;

export const PrimaryLabel = styled.span`
  color: ${ACCENT_PRIMARY};
`;
