import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $deleteTableColumn__EXPERIMENTAL,
  $deleteTableRow__EXPERIMENTAL,
  $insertTableColumn__EXPERIMENTAL,
  $insertTableRow__EXPERIMENTAL,
  TableCellNode,
} from '@lexical/table';
import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  AddTableElementIcon,
  DeleteIcon,
  DropdownItem,
  DropdownWrapper,
  ItemText,
} from './styles';
import { TableCellOrientation } from './TableActionMenuButton';
import { useAdminTranslation } from 'hooks/use-translation';

type TableCellActionMenuProps = Readonly<{
  contextRef: { current: null | HTMLElement };
  onClose: () => void;
  orientation: TableCellOrientation;
  setIsMenuOpen: (isOpen: boolean) => void;
  tableCellNode: TableCellNode;
}>;

export function TableActionMenu({
  onClose,
  tableCellNode: _tableCellNode,
  setIsMenuOpen,
  contextRef,
  orientation,
}: TableCellActionMenuProps) {
  const [editor] = useLexicalComposerContext();
  const dropDownRef = useRef<HTMLDivElement | null>(null);
  const [tableCellNode, updateTableCellNode] = useState(_tableCellNode);
  const { t } = useAdminTranslation();

  useEffect(() => {
    return editor.registerMutationListener(TableCellNode, (nodeMutations) => {
      const nodeUpdated =
        nodeMutations.get(tableCellNode.getKey()) === 'updated';

      if (nodeUpdated) {
        editor.getEditorState().read(() => {
          updateTableCellNode(tableCellNode.getLatest());
        });
      }
    });
  }, [editor, tableCellNode]);

  useEffect(() => {
    const menuButtonElement = contextRef.current;
    const dropDownElement = dropDownRef.current;
    const rootElement = editor.getRootElement();

    if (
      menuButtonElement != null &&
      dropDownElement != null &&
      rootElement != null
    ) {
      const rootEleRect = rootElement.getBoundingClientRect();
      const menuButtonRect = menuButtonElement.getBoundingClientRect();
      dropDownElement.style.opacity = '1';
      const dropDownElementRect = dropDownElement.getBoundingClientRect();
      const margin = 5;
      let leftPosition = menuButtonRect.right + margin;
      if (
        leftPosition + dropDownElementRect.width > window.innerWidth ||
        leftPosition + dropDownElementRect.width > rootEleRect.right
      ) {
        const position =
          menuButtonRect.left - dropDownElementRect.width - margin;
        leftPosition = (position < 0 ? margin : position) + window.scrollX;
      }
      dropDownElement.style.left = `${leftPosition + window.scrollX}px`;

      let topPosition = menuButtonRect.top;
      if (topPosition + dropDownElementRect.height > window.innerHeight) {
        const position = menuButtonRect.bottom - dropDownElementRect.height;
        topPosition = (position < 0 ? margin : position) + window.scrollY;
      }
      dropDownElement.style.top = `${topPosition + +window.scrollY}px`;
    }
  }, [contextRef, dropDownRef, editor]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropDownRef.current != null &&
        contextRef.current != null &&
        !dropDownRef.current.contains(event.target as Node) &&
        !contextRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    }

    window.addEventListener('click', handleClickOutside);

    return () => window.removeEventListener('click', handleClickOutside);
  }, [setIsMenuOpen, contextRef]);

  const insertTableRowAtSelection = useCallback(
    (shouldInsertAfter: boolean) => () => {
      editor.update(() => {
        $insertTableRow__EXPERIMENTAL(shouldInsertAfter);
        onClose();
      });
    },
    [editor, onClose],
  );

  const insertTableColumnAtSelection = useCallback(
    (shouldInsertAfter: boolean) =>  () => {
      editor.update(() => {
        $insertTableColumn__EXPERIMENTAL(shouldInsertAfter);
        onClose();
      });
    },
    [editor, onClose],
  );

  const deleteTableRowAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableRow__EXPERIMENTAL();
      onClose();
    });
  }, [editor, onClose]);

  const deleteTableColumnAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableColumn__EXPERIMENTAL();
      onClose();
    });
  }, [editor, onClose]);

  const renderRowCommands = useCallback(() => {
    if (orientation === 'vertical') {
      return (
        <>
          <DropdownItem
            onClick={insertTableRowAtSelection(false)}
            data-test-id="table-insert-row-above"
          >
            <AddTableElementIcon />
            <ItemText>{t('ADMIN_RICHTEXT_TABLE_INSERT_ROW_ABOVE')}</ItemText>
          </DropdownItem>
          <DropdownItem
            onClick={insertTableRowAtSelection(true)}
            data-test-id="table-insert-row-below"
          >
            <AddTableElementIcon rotateX={2} />
            <ItemText>{t('ADMIN_RICHTEXT_TABLE_INSERT_ROW_BELOW')}</ItemText>
          </DropdownItem>
          <DropdownItem
            onClick={deleteTableRowAtSelection}
            data-test-id="table-delete-rows"
          >
            <DeleteIcon />
            <ItemText>{t('ADMIN_RICHTEXT_TABLE_DELETE_ROW')}</ItemText>
          </DropdownItem>
        </>
      );
    }
    return null;
  }, [deleteTableRowAtSelection, insertTableRowAtSelection, orientation]);

  const renderColumnCommands = useCallback(() => {
    if (orientation === 'horizontal') {
      return (
        <>
          <DropdownItem
            type="button"
            onClick={insertTableColumnAtSelection(false)}
            data-test-id="table-insert-column-before"
          >
            <AddTableElementIcon rotateY={2} />
            <ItemText>{t('ADMIN_RICHTEXT_TABLE_INSERT_COLUMN_LEFT')}</ItemText>
          </DropdownItem>
          <DropdownItem
            type="button"
            className="item"
            onClick={insertTableColumnAtSelection(true)}
            data-test-id="table-insert-column-after"
          >
            <AddTableElementIcon/>
            <ItemText>{t('ADMIN_RICHTEXT_TABLE_INSERT_COLUMN_RIGHT')}</ItemText>
          </DropdownItem>
          <DropdownItem
            onClick={deleteTableColumnAtSelection}
            data-test-id="table-delete-columns"
          >
            <DeleteIcon />
            <ItemText>{t('ADMIN_RICHTEXT_TABLE_DELETE_COLUMN')}</ItemText>
          </DropdownItem>
        </>
      );
    }
    return null;
  }, [deleteTableColumnAtSelection, insertTableColumnAtSelection, orientation]);

  return createPortal(
    (
    <DropdownWrapper ref={dropDownRef}>
      {renderRowCommands()}
      {renderColumnCommands()}
    </DropdownWrapper>
    ),
    document.body,
  );
}
