import { Klass, LexicalNode, LexicalNodeReplacement, TextNode } from 'lexical';
import { HeadingNode } from '@lexical/rich-text';
import { ListNode, ListItemNode } from '@lexical/list';
import { LinkNode } from '@lexical/link';
import ExtendedTextNode from './nodes/ExtendedTextNode';
import { VideoNode } from './nodes/VideoNode';
import { ImageNode } from './nodes/ImageNode';
import ExtendedLinkNode from './nodes/ExtendedLinkNode';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';


/**
 * Add third party nodes or our custom ones here.
 */
const nodesConfig: ReadonlyArray<Klass<LexicalNode> | LexicalNodeReplacement> = [
  HeadingNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  ListNode,
  ListItemNode,
  ExtendedTextNode,
  // Next line is important to enhance the TextNode with our custom ExtendedTextNode
  {
    replace: TextNode,
    with: (node: TextNode) => {
      return new ExtendedTextNode(node.__text);
    },
  },
  VideoNode,
  ImageNode,
  LinkNode,
  ExtendedLinkNode,
  {
    replace: LinkNode,
    with: node => {
      return new ExtendedLinkNode(node.getURL(), 'inherit');
    },
  },
];

export default nodesConfig;
