import React from 'react';
import { StyledToolbarButton, LineHeightOptionsWrapper } from './styles';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LINE_HEIGHT_OPTIONS } from './utils';
import useUpdateListener from 'components/ui/RichTextEditorV3/use-update-listener';
import { $getSelection, $isRangeSelection } from 'lexical';
import { $getSelectionStyleValueForProperty } from '@lexical/selection';
import { APPLY_LINE_HEIGHT_COMMAND } from '.';

type LineHeightOptionProps = {
  lineHeight: string;
};

const LineHeightOption: React.FC<LineHeightOptionProps> = ({ lineHeight }) => {
  const [editor] = useLexicalComposerContext();
  const [active, setActive] = React.useState(false);

  const update = () => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }

    const newLineHeight = $getSelectionStyleValueForProperty(selection, 'line-height');
    const lineHeightValue = newLineHeight ? newLineHeight.replace('em', '') : null;
    setActive(lineHeightValue === lineHeight);
  };

  useUpdateListener(editor, update);

  const onClick = () => {
    editor.dispatchCommand(APPLY_LINE_HEIGHT_COMMAND, {
      lineHeight: active ? 'unset' : `${lineHeight}em`,
    });
  };

  return (
    <StyledToolbarButton active={active} onClick={onClick}>
      {lineHeight}
    </StyledToolbarButton>
  );
};

const LineHeightOptions = () => {
  return (
    <LineHeightOptionsWrapper>
      {LINE_HEIGHT_OPTIONS.map((lineHeight, key) => <LineHeightOption key={key} lineHeight={lineHeight} />)}
    </LineHeightOptionsWrapper>
  );
};

export default LineHeightOptions;
