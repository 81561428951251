import React, { MouseEventHandler, useEffect, useRef } from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import {
  Checkmark,
  Scroller,
  ScrollerItem,
} from '../styles';
import { CalendarStateProps } from '../interfaces';

export interface MonthScrollerProps extends Pick<CalendarStateProps, 'admin' | 'calendarMonth' | 'setCalendarMonth'> {
  monthsShort: string[];
}

export default function MonthScroller({
  admin,
  calendarMonth,
  monthsShort,
  setCalendarMonth,
}: MonthScrollerProps) {
  const activeMonthElementRef = useRef<HTMLButtonElement>(null);
  const changeMonth: MouseEventHandler<HTMLButtonElement> = (e) => {
    const monthIndex = parseInt(e.currentTarget.dataset.index || '0', 10);
    setCalendarMonth(monthIndex);
  };

  useEffect(() => {
    const activeMonthElement = activeMonthElementRef.current;
    if (!activeMonthElement) {
      return;
    }
    activeMonthElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [calendarMonth]);

  return (
    <Scroller admin={admin}>
      <NonKeyedListMapper list={monthsShort}>
        {(key: string, month: string, index: number) => {
          const isActive = index === calendarMonth;
          return (
            <ScrollerItem
              admin={admin}
              data-index={index}
              isActive={isActive}
              key={key}
              onClick={changeMonth}
              ref={isActive ? activeMonthElementRef : null}
            >
              {isActive && <Checkmark admin={admin} />}
              {month}
            </ScrollerItem>
          );
        }}
      </NonKeyedListMapper>
    </Scroller>
  );
}
