import styled, { css } from 'styled-components';
import { ACCENT_PRIMARY, ADMIN_ALERT_ERROR, TEXT_100, ADMIN_ACCENT_PRIMARY, ADMIN_TEXT_100, ALERT_ERROR } from 'style/constants';
import { IconSvg } from '../Icon';
import { ADMIN_TEXT_BODY_S_REGULAR, TEXT_BODY_S_REGULAR } from 'style/design-system/textStyles';

export interface InputContainerProps {
  marginLeft?: number;
  marginRight?: number;
  required?: boolean;
  showForAdmin?: boolean;
  width?: number;
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${({ width = 0 }) => width}%;
  color: ${(props) => props.showForAdmin ? ADMIN_TEXT_100(props) : TEXT_100(props)};
  margin-bottom: 10px;
  ${({ marginLeft = 0, marginRight = 0 }) => css`
    margin-right: ${marginRight}px;
    margin-left: ${marginLeft}px;
  `}
  & > label:first-child {
    ${props => props.showForAdmin ? ADMIN_TEXT_BODY_S_REGULAR : TEXT_BODY_S_REGULAR}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    font-size: 13px;
    ${({ required, ...props }) => required && css`
      &:after {
        content: "*";
        color: ${props.showForAdmin ? ADMIN_ACCENT_PRIMARY(props) : ACCENT_PRIMARY(props)};
        margin-left: 4px;
      }
    `}
  }
`;

export const Container = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

interface IShowForAdminProps {
  showForAdmin?: boolean;
}

export const StyledError = styled.span<IShowForAdminProps>`
  ${props => props.showForAdmin ? ADMIN_TEXT_BODY_S_REGULAR : TEXT_BODY_S_REGULAR}
  font-size: 10px;
  color: ${(props) => props.showForAdmin ? ADMIN_ALERT_ERROR : ALERT_ERROR};
`;

export const StyledIcon = styled(IconSvg)<IShowForAdminProps>`
  margin: 0px 5px 0px 5px;
  svg {
    width: 10px;
    height: 10px;

    path {
      fill: ${(props) => props.showForAdmin ? ADMIN_ALERT_ERROR : ALERT_ERROR};
    }
  }
`;
