/* tslint:disable: jsx-boolean-value */
import React, { KeyboardEventHandler, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import clamp from 'lodash/clamp';
import isNil from 'lodash/isNil';
import {
  Progress,
  ProgressProps,
  StyledDraggable,
  Track,
} from './styles';

interface SliderProps extends ProgressProps {
  className?: string;
  decreaseKey?: string;
  defaultValue?: number;
  disableKeyboardControls?: boolean;
  increaseKey?: string;
  limit?: number;
  max?: number;
  min?: number;
  navigationStep?: number;
  onAfterChange?: (value: number) => void;
  onBeforeChange?: (value: number) => void;
  onChange?: (value: number) => void;
  secondaryValue?: number;
  value?: number;
}

export default function SliderComponent({
  className,
  defaultValue = 0,
  disableKeyboardControls,
  fixedLength,
  min = 0,
  max = 100,
  navigationStep = 10,
  onAfterChange,
  onBeforeChange,
  onChange,
  progressColor,
  value = 0,
  vertical,
  secondaryValue,
  showThumb,
  sliderLength: size = '100%',
  smooth,
  trackBackground,
  ...props
}: SliderProps) {
  const { limit = max } = props;
  const [sliderLength, setSliderLength] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const [minSliderLength, maxSliderLength] = useMemo(() => [
    min * sliderLength / limit,
    max * sliderLength / limit,
  ], [min, max, sliderLength, limit]);

  const [decreaseKey, increaseKey] = useMemo(() => {
    const {
      decreaseKey: decrease = vertical ? 'ArrowDown' : 'ArrowLeft',
      increaseKey: increase = vertical ? 'ArrowUp' : 'ArrowRight',
    } = props;
    return [decrease, increase];
  }, [vertical, props.decreaseKey, props.increaseKey]);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = ({ key }) => {
    const update = (nextValue: number) => {
      const clampedValue = clamp(transformValue(value) + nextValue, min, max);
      onBeforeChange?.(clampedValue);
      onChange?.(clampedValue);
      onAfterChange?.(clampedValue);
    };
    if (key === decreaseKey) {
      update(-navigationStep);
    } else if (key === increaseKey) {
      update(navigationStep);
    }
  };

  const keyboardControlsProps = useMemo(() => disableKeyboardControls ? ({}) : ({
    onKeyDown: handleKeyDown,
    tabIndex: -1,
  }), [disableKeyboardControls]);

  const sliderLengthRef = useRef({ max: 0, min: 0 });
  const limitRef = useRef({ min, max });
  const verticalRef = useRef(vertical);

  const getSliderLength = (node: HTMLDivElement | null) => {
    const trackLength = node?.getBoundingClientRect()[vertical ? 'height' : 'width'];
    if (trackLength) {
      setSliderLength(Math.max(trackLength, 0));
    }
  };

  const clampValue = (x: number) => {
    const { min: minLength, max: maxLength } = sliderLengthRef.current;
    return clamp(x, minLength, maxLength);
  };

  const pixelValue = useMemo(() => {
    return max && Math.abs(clampValue(value * maxSliderLength / max));
  }, [value, maxSliderLength, max]);

  const percentage = useMemo(() => {
    return value && max && clamp(value / max * 100, 0, 100);
  }, [value, max]);

  const secondaryPercentage = useMemo(() => {
    return secondaryValue && max && clamp(secondaryValue / max * 100, 0, 100);
  }, [secondaryValue, max]);

  const axisProp = useMemo(() => {
    return {
      [vertical ? 'y' : 'x']: pixelValue,
    };
  }, [pixelValue, vertical]);

  /**
   * Transforms pixel value to value in min-max range.
   *
   * @param {number} x - X mouse coord
   * @param {number=} y - Y mouse coord
   */
  const transformValue = (x: number, y?: number) => {
    const { max: maxLength } = sliderLengthRef.current;
    const { max: maxLimit } = limitRef.current;
    const axis = getAxis(x, y);
    const transformed = maxLength && clampValue(vertical ? -axis : axis) * maxLimit / maxLength;
    return transformed;
  };

  const getAxis = (x: number, y?: number) => {
    return y !== undefined && verticalRef.current ? -y : x;
  };

  const handleStartDrag = () => {
    setIsDragging(true);
  };

  const handleDrag = (x: number, y: number) => {
    onChange?.(transformValue(x, y));
  };

  const handleEndDrag = (x: number, y: number) => {
    setIsDragging(false);
    onAfterChange?.(transformValue(x, y));
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = ({ button, clientX, clientY, currentTarget }) => {
    currentTarget.focus();
    if (isNil(button) || button === 0) {
      const { left, bottom } = currentTarget.getBoundingClientRect();
      const x = clientX - left;
      const y = bottom - clientY;
      const newValue = transformValue(x, y);
      onBeforeChange?.(newValue);
      onChange?.(newValue);
    }
  };

  /**
   * Dragger component handles mouse events by adding them to the document directly,
   * so we need to use references here since values from hooks won't be updated in
   * the dragger event handlers
   */
  useEffect(() => {
    sliderLengthRef.current.min = minSliderLength;
    sliderLengthRef.current.max = maxSliderLength;
    verticalRef.current = vertical;
  }, [minSliderLength, maxSliderLength, vertical]);

  useEffect(() => {
    limitRef.current.min = min;
    limitRef.current.max = max;
  }, [min, max]);

  return (
    <Track
      ref={getSliderLength}
      className={className}
      fixedLength={fixedLength}
      onPointerDown={handleClick}
      vertical={vertical}
      sliderLength={size}
      trackBackground={trackBackground}
      {...keyboardControlsProps}
    >
      <Progress
        isDragging={isDragging}
        maxSliderLength={maxSliderLength}
        progress={percentage}
        progressColor={progressColor}
        vertical={vertical}
        showThumb={showThumb}
        sliderLength={size}
        smooth={smooth}
      />
      {!isNil(secondaryValue) && (
        <Progress
          maxSliderLength={maxSliderLength}
          progress={secondaryPercentage}
          vertical={vertical}
          secondaryProgress
          sliderLength={size}
        />
      )}
      <StyledDraggable
        invertY
        onEnd={handleEndDrag}
        onMove={handleDrag}
        onStart={handleStartDrag}
        sliderLength={size}
        vertical={vertical}
        {...axisProp}
      />
    </Track>
  );
}
