import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';
import {
  SURFACE_1,
  ADMIN_TEXT_100,
  TEXT_100,
  MAESTRO_BLACK,
} from 'style/constants';
import {
  TRIANGLE, TriangleProps,
} from 'style/mixins';
import { ADMIN_TEXT_LABEL_S_MEDIUM, TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';

const AbsolutePositionMap = {
  top: css`
    bottom: 100%;
    margin-bottom: 12px;
    right: 50%;
    transform: translateX(50%);
  `,
  right: css`
    left: 100%;
    margin-left: 12px;
  `,
  bottom: css`
    top: 100%;
    margin-top: 12px;
    right: 50%;
    transform: translateX(50%);
  `,
  left: css`
    right: 100%;
    margin-right: 12px;
  `,
};

const AlignMap = {
  top: css<TooltipStyleProps>`
    ${({ containerDimensions: { top } }) => `
      top: ${top}px;
    `}
  `,
  right: css<TooltipStyleProps>`
    ${({ containerDimensions: { width, x }, position }) => `
      left: ${x}px;
      transform: translate(0%, ${position === 'top' ? -100 : 0}%);
      & div:after {
        left: ${width / 2}px;
      }
    `}
  `,
  bottom: css<TooltipStyleProps>`
    ${({ containerDimensions: { bottom } }) => `
      top: ${bottom}px;
    `}
  `,
  left: css<TooltipStyleProps>`
    ${({ containerDimensions: { width, x }, position }) => `
      left: ${x + width}px;
      transform: translate(-100%, ${position === 'top' ? -100 : 0}%);
      & div:after {
        left: calc(100% - ${width / 2}px);
      }
    `}
  `,
};

const FixedPositionMap = {
  top: css<TooltipStyleProps>`
    ${({ containerDimensions: { width, x, y }, separation }) => `
      top: ${y - separation}px;
      left: ${x + (width / 2)}px;
      transform: translate(-50%, -100%);
    `}
  `,
  right: css<TooltipStyleProps>`
    ${({ containerDimensions: { bottom, height, right }, separation }) => `
      top: ${bottom - (height / 2)}px;
      left: ${right +  separation}px;
      transform: translateY(-50%);
    `}
  `,
  bottom: css<TooltipStyleProps>`
    ${({ containerDimensions: { bottom, width, x }, separation }) => `
      top: ${bottom + separation}px;
      left: ${x + (width / 2)}px;
      transform: translateX(-50%);
    `}
  `,
  left: css<TooltipStyleProps>`
    ${({ containerDimensions: { bottom, height, x }, separation }) => `
      top: ${bottom - (height / 2)}px;
      left: ${x -  separation}px;
      transform: translate(-100%, -50%);
    `}
  `,
};

export type PositionKey = keyof typeof FixedPositionMap;

const DirectionMap: Record<string, PositionKey> = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

export interface TooltipStyleProps {
  admin?: boolean;
  align?: PositionKey;
  background?: string | ((props: ThemeProps<DefaultTheme>) => string);
  containerDimensions: DOMRect;
  position: PositionKey;
  separation: number;
  textColor?: ReturnType<typeof css> | string;
  tooltipArrowCss?: ReturnType<typeof css> | string;
  tooltipCss?: ReturnType<typeof css> | string;
  tooltipWrapperCss?: ReturnType<typeof css> | string;
  useAbsolutePosition?: boolean;
}

export const Tooltip = styled.div.attrs(({ background, position }: TooltipStyleProps) => ({
  triangleColor: background || MAESTRO_BLACK,
  triangleDirection: DirectionMap[position],
  triangleHeight: 7,
  triangleWidth: 11,
}))<TooltipStyleProps & TriangleProps>`
  ${({ admin, background, textColor, ...props }) => css`
    background: ${background || (admin ? MAESTRO_BLACK : SURFACE_1(props))};
    color: ${textColor || (admin ? ADMIN_TEXT_100 : TEXT_100)};
    ${admin ? ADMIN_TEXT_LABEL_S_MEDIUM : TEXT_LABEL_S_MEDIUM}
  `}
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  min-width: min-content;
  &:after {
    ${TRIANGLE}
    content: "";
    position: absolute;
    ${({ position }) => css`
      ${(position === 'top' || position === 'bottom') ? `
        left: 50%;
        transform: translateX(-50%);
      ` : 'transform: translateY(12.5%);'}
      ${position}: 100%;
    `}
    ${({ tooltipArrowCss }) => tooltipArrowCss}
  }
  ${({ tooltipCss }) => tooltipCss}
`;

export const TooltipWrapper = styled.div<TooltipStyleProps>`
  z-index: 999999;
  white-space: nowrap;
  ${({ align, position, useAbsolutePosition }) => useAbsolutePosition ? css`
    position: absolute;
    ${AbsolutePositionMap[position]}
  ` : css`
    position: fixed;
    ${FixedPositionMap[position]}
    ${align && AlignMap[align]}
  `}
  ${({ tooltipWrapperCss }) => tooltipWrapperCss}
`;
