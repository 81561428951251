import { $getSelection, $isParagraphNode, $isRangeSelection, $isTextNode } from 'lexical';
import React from 'react';
import { getSelectedNodeFromSelection } from '../../utils';
import { mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FLOATING_TOOLBAR_ID } from '.';
import { useSelectionContext } from '../../SelectionContext';

/**
 * Hook to determine if the floating toolbar should be active or not
 */
const useFloatingToolbarActive = () => {
  const [editor] = useLexicalComposerContext();
  const [isText, setIsText] = React.useState(false); // Only show when selecting texts
  const { selection: contextSelection } = useSelectionContext();

  const update = React.useCallback(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection();
      const nativeSelection = window.getSelection();
      const rootElement = editor.getRootElement();
      const toolbarElement = document.getElementById(FLOATING_TOOLBAR_ID);

      if (nativeSelection !== null && !contextSelection && (!$isRangeSelection(selection) || rootElement === null || (!rootElement.contains(nativeSelection.anchorNode) && !toolbarElement?.contains(nativeSelection.anchorNode)))) {
        setIsText(false);
        return;
      }

      if (!$isRangeSelection(selection)) {
        return;
      }

      const node = getSelectedNodeFromSelection(selection);
      let isTextResult = $isTextNode(node) || $isParagraphNode(node);

      const rawTextContent = selection.getTextContent().replace(/\n/g, '');
      if (!selection.isCollapsed() && rawTextContent === '') {
        isTextResult = false;
      }

      setIsText(isTextResult);
    });
  }, [editor, contextSelection]);

  React.useEffect(() => {
    document.addEventListener('selectionchange', update);
    return () => {
      document.removeEventListener('selectionchange', update);
    };
  }, [update]);

  React.useEffect(() => {
    if (!isText) {
      return;
    }

    const onClickEvent = (e: MouseEvent) => {
      const node = e.target as Node;
      editor.getEditorState().read(() => {
        const rootElement = editor.getRootElement();
        const toolbarElement = document.getElementById(FLOATING_TOOLBAR_ID);
        if (rootElement === null || toolbarElement === null) {
          return;
        }

        // Bad checks but it works for now. :sad:
        const isNodeInputElement = node.nodeName === 'INPUT' || node.nodeName === 'TEXTAREA';
        const isDropdown = (node as HTMLElement).classList.contains('dropdown-title');
        if (isNodeInputElement || isDropdown || rootElement.contains(node) || toolbarElement.contains(node)) {
          return;
        }

        const nativeSelection = window.getSelection();
        nativeSelection?.removeAllRanges();
        setIsText(false);
      });
    };
    document.addEventListener('click', onClickEvent);
    return () => {
      document.removeEventListener('click', onClickEvent);
    };
  }, [isText]);

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(update),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false);
        }
      }),
    );
  }, [editor, update]);

  return isText;
};

export default useFloatingToolbarActive;
