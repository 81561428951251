import styled from 'styled-components';
import Draggable from '../Draggable';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageContainer = styled.div`
  overflow: hidden;
  padding-top: 5px;
`;

export const EmptyPage = styled.div`
  width: 100%;
`;

export const StyledDraggable = styled(Draggable)<{disableTransition?: boolean}>`
  display: flex;
  ${({ disableTransition }) => !disableTransition && `
    transition: transform 0.5s;
  `}
  & > * {
    min-width: 100%;
  }
`;
