import PieTimer from 'components/ui/PieTimer';
import useDispatch from 'hooks/use-dispatch';
import useSelector from 'hooks/use-selector';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useState } from 'react';
import { getWelcomeScreen } from 'services/app/selectors';
import { isWelcomeScreenActive, setWelcomeScreenSeen } from 'services/welcome-screen';
import { mobileOnly } from 'style/mixins';
import styled from 'styled-components';

interface IStyledProps {
  image?: string;
  mobileImage?: string;
}

const StyledWelcomeScreen = styled.div<IStyledProps>`
  background-image: url(${(props) => props.image || ''});
  background-color: black;
  background-position: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  ${mobileOnly`
    background-image: url(${
      // @ts-ignore
      (props) => props.mobileImage || props.image || ''
    });
  `}
`;

const WelcomeScreen = () => {
  const dispatch = useDispatch();
  const [ disabled, setDisabled ] = useState(false);
  const welcomeScreenActive = useSelector(isWelcomeScreenActive);
  const welcomeScreen = useSelector(getWelcomeScreen, isEqual);

  useEffect(() => {
    if (disabled) {
      // dispatch(setWelcomeScreenSeen()); TODO: We have a ticket to remove all this code https://maestrojira.atlassian.net/browse/NS-9214
      return;
    }
    if (!welcomeScreenActive) {
      setDisabled(true);
      return;
    }
    const timeout = setTimeout(() => {
      setDisabled(true);
    }, 1000 * welcomeScreen.duration);
    return () => clearTimeout(timeout);
  }, [
    disabled,
    welcomeScreenActive,
    JSON.stringify(welcomeScreen),
  ]);

  return welcomeScreenActive ? (
    <StyledWelcomeScreen
      image={welcomeScreen.image}
      mobileImage={welcomeScreen.mobileImage}
    >
      <PieTimer duration={welcomeScreen.duration} />
    </StyledWelcomeScreen>
  ) : null;
};

WelcomeScreen.defaultProps = {};

export default WelcomeScreen;
