import React, { MouseEventHandler, useMemo } from 'react';
import PageButton, { PageButtonProps } from './PageButton';
import {
  Navigation,
  NavButton,
  PageNavigationStyles,
  PageSelector,
  NotActivePageButton,
} from './styles';
import PageButtons from './PageButtons';

interface PageNavigationProps extends PageNavigationStyles, PageButtonProps {
  className?: string;
  onNextPage?: MouseEventHandler<HTMLDivElement>;
  onPreviousPage?: MouseEventHandler<HTMLDivElement>;
  pages?: number;
}

export default function PageNavigation({
  admin,
  className,
  onChange,
  onNextPage,
  onPreviousPage,
  page = 0,
  pages = 0,
}: PageNavigationProps) {

  return pages > 1 ? (
    <Navigation admin={admin} className={className}>
      <NavButton name="adminbarLt" disabled={page - 1 < 0} onClick={onPreviousPage} />
      <PageSelector>
        <PageButtons page={page} pages={pages} onChange={onChange} admin={admin} />
      </PageSelector>
      <NavButton name="adminbarGt" disabled={page + 1 === pages} onClick={onNextPage} />
    </Navigation>
  ) : null;
}
