import React, { useMemo } from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import {
  Container,
  Item,
} from './styles';

interface GridProps<T> {
  children?: ((item: T, index: number) => React.ReactNode | null) | React.ReactNode;
  columns?: number;
  items?: T[];
  spacing?: string;
}

export default function Grid<T>({ columns = 3, children, items, ...props }: GridProps<T>) {
  const basis = useMemo(() => {
    return columns ? 100 / columns : 100;
  }, [columns]);

  return (
    <Container basis={basis} {...props}>
      {typeof children === 'function' && items ? (
        <NonKeyedListMapper list={items}>
          {(key: string, item: T, index: number) => {
            const position = (index + 1) % columns;
            return (
              <Item key={key} position={position === 1 ? 'first' : position ? 'middle' : 'last'}>
                {children(item, index)}
              </Item>
            );
          }}
        </NonKeyedListMapper>
      ) : React.Children.map(children, Child => {
        return <Item>{Child}</Item>;
      })}
    </Container>
  );
}
