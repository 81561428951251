// tslint:disable:no-bitwise
import { TextFormatType } from 'lexical';

const IS_BOLD = 1;
const IS_ITALIC = 1 << 1;
const IS_STRIKETHROUGH = 1 << 2;
const IS_UNDERLINE = 1 << 3;
const IS_CODE = 1 << 4;
const IS_SUBSCRIPT = 1 << 5;
const IS_SUPERSCRIPT = 1 << 6;
const IS_HIGHLIGHT = 1 << 7;

export const TEXT_TYPE_TO_FORMAT: Record<TextFormatType, number> = {
  bold: IS_BOLD,
  code: IS_CODE,
  highlight: IS_HIGHLIGHT,
  italic: IS_ITALIC,
  strikethrough: IS_STRIKETHROUGH,
  subscript: IS_SUBSCRIPT,
  superscript: IS_SUPERSCRIPT,
  underline: IS_UNDERLINE,
};

export const FORMAT_TO_TEXT_TYPE: Record<number, TextFormatType> = {
  [IS_BOLD]: 'bold',
  [IS_CODE]: 'code',
  [IS_HIGHLIGHT]: 'highlight',
  [IS_ITALIC]: 'italic',
  [IS_STRIKETHROUGH]: 'strikethrough',
  [IS_SUBSCRIPT]: 'subscript',
  [IS_SUPERSCRIPT]: 'superscript',
  [IS_UNDERLINE]: 'underline',
};
