import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { mobileOnly, desktopOnly } from 'style/mixins';
import {
  MAESTRO_BLACK,
  MAESTRO_WHITE,
} from 'style/constants';

export const Muted = styled.div`
  position: absolute;
  width: 100%;
  height: 85%;
  z-index: 99;
  ${mobileOnly`
    height: 80%;
  `}
  ${desktopOnly`
    &:hover {
      cursor: pointer;
    };
  `}
`;

export const Container = styled.div`
  margin-top: 3%;
  margin-left: 5%;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${MAESTRO_WHITE};
  color: ${MAESTRO_BLACK};
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  ${mobileOnly`
    width: 170px;
    height: 35px;
  `}
  ${desktopOnly`
    width: fit-content;
    padding: 8px;
    height: 50px;
    :hover {
      cursor: pointer;
    };
  `}
`;

export const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;
