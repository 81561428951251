import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startCase } from 'lodash';
import TranslatedText from 'components/i18n/TranslatedText';
import ExpandableCard from 'components/admin2/ui/ExpandableCard';
import Form from 'components/ui/Form';
import { PaymentMethod } from '@stripe/stripe-js';
import {
  editPaymentMethod,
  getEditPaymentMethodPending,
  IEditPaymentMethodPayload,
  submitDefaultPaymentMethod,
  removePaymentConfirm,
  removePayment,
  getEditPaymentMethodSuccess,
  clearBillingInfo,
  setTaxRate,
  setBillingStateCode,
  fetchTaxRate,
  setBillingCountryCode,
  setBillingPostalCode,
} from 'services/billing';
import { PrimaryButton, StyledLabeledInput, StyledTextInput } from './styles';
import {
  regexExpirationDate,
  regexName,
} from 'components/payment/BillingInfoForm/constants';
import moment from 'moment';
import { TranslationKey, useAdminTranslation } from 'hooks/use-translation';
import { StyledSelect } from 'components/payment/BillingInfoForm/styles';
import assets from '../../../assets/countries.json';
import { COUNTRIES_WITH_STATE_CODE_TAX, IStateCountryCode } from 'components/payment/BillingInfoForm';
import { getTaxes } from 'services/app';
import FormInput from 'components/ui/v2/Inputs';
import TextInput from 'components/admin2/TextInput';
import ErrorLabelComponent from 'components/admin2/ui/ErrorLabel';

const getCountryLabelFromCode = (code: string): string => {
  const country = assets.countries.find((countryItem) => countryItem.code === code);
  return country?.name ?? '';
};

const getStateLabelFromCode = (code: string): string => {
  const state = assets.countries
    .find((country) => country.code === code)
    ?.provinces.find((st) => st.code === code);
  return state?.name ?? '';
};

const getStateList = (countryName: string) => {
  return assets.countries
    .find((country) => country.code === countryName)
    ?.provinces.map((state) => ({ label: state.name, value: state.code }));
};

interface PaymentMethodCardProps {
  card: PaymentMethod.Card;
  country?: string;
  disabled?: boolean;
  disabledRemoveButton?: boolean;
  id: string;
  isPrimary?: boolean;
  name: string;
  postalCode?: string;
  showForAdmin?: boolean;
  state?: string;
}

export default function PaymentMethodCard({
  id,
  isPrimary = false,
  country = '',
  state,
  postalCode = '',
  card: { last4, funding = 'unknown', exp_month: expMonth, exp_year: expYear },
  disabled,
  disabledRemoveButton = false,
  name,
  showForAdmin,
}: PaymentMethodCardProps) {
  const dispatch = useDispatch();
  const editPaymentSuccess = useSelector(getEditPaymentMethodSuccess);
  const [formComplete, setFormComplete] = useState(false);
  const [inputName, setInputName] = useState(name || '');
  const [expirationDate, setExpirationDate] = useState(
    `${expMonth.toString().padStart(2, '0')}/${expYear.toString().substr(2, 4)}`,
  );
  const [countrySelectValue, setCountrySelectValue] = useState({
    label: getCountryLabelFromCode(country),
    value: country,
  });
  const [postalCodeInput, setPostalCodeInput] = useState<string>(postalCode);
  const [stateName, setStateName] = useState<IStateCountryCode | undefined>(
    state ? { label: getStateLabelFromCode(state), value: state } : undefined,
  );

  const [postalCodeError, setPostalCodeError] = useState('');
  const [errorName, setErrorName] = useState({ message: '', hasError: false });
  const [formUpdated, setFormUpdated] = useState(false);
  const [errorExpirationDate, setErrorExpirationDate] = useState({
    message: '',
    hasError: false,
  });
  const [forceToggle, setForceToggle] = useState(false);
  const editPaymentMethodPending = useSelector(getEditPaymentMethodPending);
  const primaryLabelKey = isPrimary
    ? 'PAYMENT_METHOD_LABEL_PRIMARY'
    : 'PAYMENT_METHOD_LABEL_MAKE_PRIMARY';
  const cardNumber = `**** **** **** ${last4}`;
  const { t } = useAdminTranslation();
  const COUNTRY_LIST = useMemo(() => {
    return assets.countries.map((countryEntry) => ({
      label: countryEntry.name,
      value: countryEntry.code,
    }));
  }, []);

  const postalCodeValidations: { [key: string]: RegExp } = {
    US: /^[0-9]{5}$/,
    BR: /^[0-9]{5}-[0-9]{3}$/,
  };

  const collectTaxes = useSelector(getTaxes);

  useMemo(() => {
    const oldExpDate = `${expMonth.toString().padStart(2, '0')}/${expYear
      .toString()
      .substr(2, 4)}`;
    if (inputName !== name || expirationDate !== oldExpDate) {
      setFormUpdated(true);
    }
  }, [inputName, expirationDate]);

  const stateList = useMemo(() => {
    return getStateList(countrySelectValue.value);
  }
  , [countrySelectValue.value]);

  const statePlaceholder = useMemo(() => {
    return countrySelectValue?.value === 'CA' ? 'BILLING_PROVINCE' : 'BILLING_STATE';
  }
  , [countrySelectValue.value, t]);

  const doesCountryHaveStateCode = useMemo(() => {
    return COUNTRIES_WITH_STATE_CODE_TAX.includes(countrySelectValue.value);
  },
  [countrySelectValue.value]);

  useEffect(() => {
    validateName();
  }, [inputName]);

  useEffect(() => {
    validateExpirationDate();
  }, [expirationDate]);

  useEffect(() => {
    checkPostalCodeComplete();
  }, [postalCodeInput]);

  useEffect(() => {
    validateFormcomplete();
  }, [errorName.hasError, errorExpirationDate.hasError, postalCodeError, stateName?.value]);

  useEffect(() => {
    if (formUpdated && editPaymentSuccess) {
      handleClickClose();
      setFormUpdated(false);
    }
  }, [editPaymentSuccess]);

  useEffect(() => {
    if (formUpdated) {
      dispatch(clearBillingInfo());
    }
  }, [formUpdated]);

  useEffect(()=> {
    if(stateName?.value !== '' && collectTaxes) {
      dispatch(setTaxRate({ taxRate: null }));
    }

  },[stateName]);

  useEffect(() => {
    // Pre-fill Billing Address in Store state
    dispatch(setBillingCountryCode(country));
    dispatch(setBillingPostalCode(postalCode));
    if (doesCountryHaveStateCode && state) {
      dispatch(setBillingStateCode(state));
    }
  }, []);

  const validateName = (): void => {
    if (!inputName?.match(regexName)) {
      setErrorName({ message: t('BILLING_INVALID_INPUT'), hasError: true });
    } else {
      setErrorName({ message: '', hasError: false });
    }
  };

  const validExpirationDate = (): boolean => {
    const [month, year] = getMonthAndYearFromString(expirationDate);
    const creditCardDate = moment(`${year}-${month}`, 'YYMM');
    const today = moment();

    return moment(today, 'YYMM').isBefore(creditCardDate);
  };

  const validateExpirationDate = (): void => {
    if (!expirationDate?.match(regexExpirationDate)) {
      // FIXME: the existing value for the BILLING_ERROR_MESSAGE_EXPIRATION_DATE does not
      // match its label
      setErrorExpirationDate({
        message: t('BILLING_ERROR_MESSAGE_EXPIRATION_DATE'),
        hasError: true,
      });
    } else if (!validExpirationDate()) {
      setErrorExpirationDate({
        message: t('ADMIN_BILLING_INVALID_EXPIRATION_DATE'),
        hasError: true,
      });
    } else {
      setErrorExpirationDate({ message: '', hasError: false });
    }
  };

  const checkPostalCodeComplete = useCallback((): boolean => {
    if (!postalCodeInput) {
      return false;
    }
    const surpassMaxLength = postalCodeInput?.length > 9;
    const hasSpecificValidation = postalCodeValidations[countrySelectValue.value] !== undefined;
    const match = hasSpecificValidation ? new RegExp(postalCodeValidations[countrySelectValue.value]).test(postalCodeInput) : true;
    if (!match || surpassMaxLength) {
      setPostalCodeError(t('ADMIN_BILLING_INVALID_POSTAL_CODE'));
      return false;
    }
    setPostalCodeError('');
    return true;
  }, [postalCodeInput, countrySelectValue, t]);

  const checkStateCodeComplete = (): boolean => {
    const countryCode = countrySelectValue.value;
    if (
      (countryCode && doesCountryHaveStateCode && stateName?.value && stateName?.value !== '') ||
      countryCode && !doesCountryHaveStateCode
    ) {
      return true;
    }
    return false;
  };

  const validateFormcomplete = (): void => {
    if (
      errorName.hasError ||
      errorExpirationDate.hasError ||
      !checkPostalCodeComplete() ||
      (doesCountryHaveStateCode && !checkStateCodeComplete())
    ) {
      setFormComplete(false);
    } else {
      setFormComplete(true);
    }
  };

  const handleStateCodeChange = (e: { label: string, value: string }) => {
    const value = e.value;
    setStateName(e);
    dispatch(setBillingStateCode(value.toUpperCase()));
  };

  const PrimaryAction = (
    <TranslatedText stringKey={primaryLabelKey}>
      {(text: string) => (
        <PrimaryButton
          onClick={handleMakeDefault}
          isPrimary={isPrimary}
          showForAdmin={showForAdmin}
        >
          {text}
        </PrimaryButton>
      )}
    </TranslatedText>
  );

  const handleMakeDefault = () => {
    dispatch(submitDefaultPaymentMethod(id));
  };

  const handleRemove = () => {
    handleClickClose();
    const removeSubscription = () => {
      dispatch(removePayment(id));
    };

    dispatch(
      removePaymentConfirm({
        removeFunction: removeSubscription,
        id: disabledRemoveButton ? id : null,
      }),
    );
  };

  const handleExpiryChange = (value: string): void => {
    setExpirationDate(addSlashToExpirationDate(value.slice(0, 5)));
  };

  const addSlashToExpirationDate = (date: string): string => {
    if (date.length !== 2 || date.length < expirationDate.length) {
      return date;
    }
    return date.concat('/');
  };

  const getMonthAndYearFromString = (date: string): Array<string> | string => {
    if (!date) {
      return '';
    }
    return date.split('/');
  };

  const handleEditPaymentMethod = (): void => {
    if (!formComplete) {
      return;
    }
    const [month, year] = getMonthAndYearFromString(expirationDate);

    const payload: IEditPaymentMethodPayload = {
      paymentMethodId: id,
      name: inputName,
      expirationMonth: Number(month),
      expirationYear: Number(year),
      countryCode: countrySelectValue.value,
      postalCode: postalCodeInput,
      ...(stateName?.value && { state: stateName.value }),
    };
    dispatch(editPaymentMethod(payload));

    if (collectTaxes && doesCountryHaveStateCode) {
      dispatch(fetchTaxRate());
    }
  };

  const expandableCardProps = {
    disabled,
    expandTextKey: 'ADMIN_LABEL_EDIT' as TranslationKey,
    subtitle: `${startCase(funding)} Card • `,
    subtitleChildren: PrimaryAction,
    title: cardNumber,
    variant: isPrimary ? '' : 'secondary',
    showForAdmin,
  };

  const handleClickClose = () => {
    setForceToggle(!forceToggle);
  };

  const handleCountryCodeChange = (e: any) => {
    const value = e.value;
    setStateName(undefined);
    setCountrySelectValue({ value, label: e.label });
    dispatch(setBillingCountryCode(value.toUpperCase()));
  };

  const handlePostalCodeChange = (value: string) => {
    setPostalCodeInput(value.toUpperCase());
    dispatch(setBillingPostalCode(value));
  };

  const renderName = () => {
    if (!showForAdmin) {
      return (
        <FormInput.Root error={errorName.hasError}>
          <FormInput.FieldSet>
            <FormInput.Legend>{t('NAME')}</FormInput.Legend>
            <FormInput.TextInput
              value={inputName}
              onChange={setInputName}
            />
          </FormInput.FieldSet>
          {errorName.message && <FormInput.SupportingText>{errorName.message}</FormInput.SupportingText>}
        </FormInput.Root>
      );
    }

    return (
      <>
        <StyledTextInput
          labelKey="NAME"
          value={inputName}
          onChange={setInputName}
          error={errorName.hasError}
          padding="0"
        />
        {errorName.message && <ErrorLabelComponent errorMsg={errorName.message} />}
      </>
    );
  };

  const renderAreaCode = () => {
    if (!showForAdmin) {
      return (
        <FormInput.Root error={!!postalCodeError}>
          <FormInput.FieldSet>
            <FormInput.Legend>{t('AREA_CODE')}</FormInput.Legend>
            <FormInput.TextInput
              value={postalCodeInput}
              onChange={handlePostalCodeChange}
            />
          </FormInput.FieldSet>
          {postalCodeError && <FormInput.SupportingText>{postalCodeError}</FormInput.SupportingText>}
        </FormInput.Root>
      );
    }

    return (
      <>
        <StyledTextInput
          labelKey="AREA_CODE"
          value={postalCodeInput}
          onChange={handlePostalCodeChange}
          error={!!postalCodeError}
          padding="0"
        />
        {postalCodeError && <ErrorLabelComponent errorMsg={postalCodeError} />}
      </>
    );
  };

  const renderExpiration = () => {
    if (!showForAdmin) {
      return (
        <FormInput.Root error={errorExpirationDate.hasError}>
          <FormInput.FieldSet>
            <FormInput.Legend>{t('EXPIRATION')}</FormInput.Legend>
            <FormInput.TextInput
              value={expirationDate}
              onChange={handleExpiryChange}
              maxLength={5}
            />
          </FormInput.FieldSet>
          {errorExpirationDate.hasError && (
            <FormInput.SupportingText>{errorExpirationDate.message}</FormInput.SupportingText>
          )}
        </FormInput.Root>
      );
    }

    return (
      <>
        <StyledTextInput
          labelKey="EXPIRATION"
          value={expirationDate}
          onChange={handleExpiryChange}
          error={errorExpirationDate.hasError}
          placeholderKey="ADMIN_BILLING_FORM_CARD_EXPIRATION_PLACEHOLDER"
          padding="0"
        />
        {errorExpirationDate.hasError && <ErrorLabelComponent errorMsg={errorExpirationDate.message} />}
      </>
    );
  };

  return (
    <ExpandableCard forceToggle={forceToggle} {...expandableCardProps}>
      <Form
        addCloseButton={true}
        addCloseButtonClick={handleClickClose}
        headerKey="EDIT_PAYMENT_METHOD"
        loading={editPaymentMethodPending}
        submitKey="ACTION_SAVE"
        topButtonClick={handleRemove}
        topButtonKey="REMOVE"
        onSubmit={handleEditPaymentMethod}
        showForAdmin={showForAdmin}
      >
        <ExpandableCard
          {...expandableCardProps}
          disabled={true}
          variant={isPrimary ? 'secondary' : ''}
        />
        {renderName()}
        <StyledLabeledInput
          data-testid="countryBillingDropdown"
          label="BILLING_COUNTRY"
          showForAdmin={showForAdmin}
        >
          <StyledSelect
            showForAdmin={showForAdmin}
            name="countryCode"
            placeholder={t('ADMIN_BILLING_FORM_SELECT_COUNTRY_PLACEHOLDER')}
            isSearchable={true}
            options={COUNTRY_LIST}
            isLoading={!COUNTRY_LIST}
            onChange={handleCountryCodeChange}
            value={countrySelectValue}
          />
        </StyledLabeledInput>
        {doesCountryHaveStateCode && (
          <StyledLabeledInput data-testid="stateBillingInput" showForAdmin={showForAdmin} label={statePlaceholder}>
            <StyledSelect
              showForAdmin={showForAdmin}
              name="stateCode"
              value={stateName}
              placeholder={`${t(statePlaceholder)}...`}
              isSearchable={true}
              options={stateList}
              isLoading={!stateList}
              onChange={handleStateCodeChange}
            />
          </StyledLabeledInput>
        )}
        {renderAreaCode()}
        {renderExpiration()}
      </Form>
    </ExpandableCard>
  );
}
