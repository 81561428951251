import styled, { css } from 'styled-components';
import { IconButton } from 'components/admin2/ui/Button';
import { ImageProps } from './interfaces';
import {
  CIRCLE_IMAGE,
  FLEX_CENTER,
} from 'style/mixins';
import {
  ACCENT_PRIMARY,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_TEXT_100,
  TEXT_100,
} from 'style/constants';

interface HighlightStyle {
  disableHighlight?: boolean;
  highlight?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px;
`;

export const GalleryImage = styled.div<Omit<ImageProps, 'highlight'> & {highlight?: boolean, src?: string}>`
  ${CIRCLE_IMAGE}
  ${FLEX_CENTER}
  margin-left: 33px;
  cursor: pointer;
  position: relative;
  ${({ admin, highlight, ...props }) => css`
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 100%;
      position: absolute;
      border: ${highlight ? '2px solid' : 'none'};
      border-color: ${admin ? ADMIN_ACCENT_PRIMARY : ACCENT_PRIMARY} !important;
      pointer-events: none;
    }
    &:hover:after {
      border: 2px solid;
    }
  `};
  ${({ highlight, disableHighlight }) => css`
    &:hover ${HoverOnlyIcon} {
      display: ${highlight ? 'none' : 'flex'};
      ${disableHighlight && 'display: flex;'}
    }
  `}
  &:first-child {
    margin: 0;
  }
`;

const buttonColor = css<{admin?: boolean}>`
  background-color: ${({ admin }) => admin ? ADMIN_ACCENT_PRIMARY : ACCENT_PRIMARY};
  border-radius: 50%;
  & svg {
    width: 10px;
    height: 10px;
  }
`;

export const CheckIcon = styled(IconButton as any).attrs(({ admin, ...props }) => ({
  color: admin ? ADMIN_TEXT_100(props) : TEXT_100(props),
  name: 'successCheckmark',
}))`
  pointer-events: none;
  &, & > span {
    ${buttonColor}
  }
`;

export const HoverOnlyIcon = styled(IconButton as any).attrs(({ admin, ...props }) => ({
  color: admin ? ADMIN_TEXT_100(props) : TEXT_100(props),
  containerCss: css`
    &, & > span {
      ${buttonColor}
    }
    position: absolute;
    display: none;
  `,
}))<HighlightStyle>``;

export const ImageWrapper = styled.div<HighlightStyle & { admin?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ admin, highlight, disableHighlight }) => css`
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 100%;
      position: absolute;
      border: ${highlight ? '2px solid' : 'none'};
      border-color: ${admin ? ADMIN_ACCENT_PRIMARY : ACCENT_PRIMARY} !important;
    }
    &:hover:after {
      border: 2px solid;
    }
  `};
  ${({ highlight, disableHighlight }) => css`
    &:hover ${HoverOnlyIcon} {
      display: ${highlight ? 'none' : 'flex'};
      ${disableHighlight && 'display: flex;'}
    }
  `}
`;
