import styled, { css } from 'styled-components';
import Button from 'components/ui/Button';
import { keyframes } from 'styled-components';
import { ACCENT_PRIMARY, SURFACE_1, SURFACE_2, TEXT_100 } from 'style/constants';
import { TEXT_LABEL_M_BOLD, TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import { offset } from 'colors';
import { desktopOnly, mobileOnly } from 'style/mixins';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const LINEAR_GRADIENT_OFFSETS = [0, 0, 0, 0.5, 1, 1.5, 0];

export const StartForFreeButton = styled(Button)`
  display: flex;
  height: 40px;
  align-items: center;
  border-radius: 50px;
  background: ${ACCENT_PRIMARY};
  color: ${TEXT_100} !important;
  text-transform: uppercase;
  transition: background 0.15s linear 0s, box-shadow 0.15s linear 0s;
  box-shadow: ${ACCENT_PRIMARY} 0px 0px 2px 0px;
  z-index: 0;
  overflow: hidden;
  position: relative;
  flex: 1;

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 60px;
    background-image: ${props => {
      const accentPrimary = ACCENT_PRIMARY(props);
      const colors = LINEAR_GRADIENT_OFFSETS.map(amount => offset(accentPrimary, amount));
      return `linear-gradient(45deg, ${colors.join(', ')})`;
    }};
    background-size: 300%;
    filter: blur(10px);
    opacity: 0;
    z-index: -1;
    transition: all 0.4s;
    animation: ${gradientAnimation} 6s linear infinite;
  }

  ${mobileOnly`
    ${TEXT_LABEL_M_BOLD}
    padding-inline: 24px;
  `}
  ${desktopOnly`
    padding: 7px 30px;
    ${TEXT_LABEL_M_BOLD}
  `}
`;

export const LoginButton = styled(Button)`
  width: 140px;
  display: flex;
  height: 40px;
  align-items: center;
  border-radius: 50px;
  background-color: ${SURFACE_2};
  color: ${TEXT_100} !important;
  text-transform: uppercase;
  transition: box-shadow 0.15s linear 0s;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px inset;
  flex: 1;


  &:hover {
    background-color: ${SURFACE_2};
    box-shadow: ${ACCENT_PRIMARY} 0px 0px 0px 1px inset;
  }

  ${mobileOnly`
    ${TEXT_LABEL_M_BOLD}
    padding-inline: 24px;
  `}
  ${desktopOnly`
    padding: 7px 30px;
    ${TEXT_LABEL_M_MEDIUM}
  `}
`;

export const Wrapper = styled.div<{ isChannel: boolean; }>`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  justify-content: center;

  ${({ isChannel }) => isChannel && css`
    background-color: ${SURFACE_2};
    padding: 5px 0;
  `}

  ${mobileOnly`
    padding: 10px 20px 10px 20px;
    background: ${SURFACE_1};
  `}
`;
