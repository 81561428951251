import React from 'react';
import MonthScroller, { MonthScrollerProps } from './MonthScroller';
import YearScroller, { YearScrollerProps } from './YearScroller';
import { ScrollerContainer, ScrollerWrapper } from '../styles';

interface MonthYearSelectorProps extends MonthScrollerProps, YearScrollerProps {
  renderMonths?: boolean;
}

export default function MonthYearSelector({ renderMonths, ...props }: MonthYearSelectorProps){
  return (
    <ScrollerWrapper {...props}>
      <ScrollerContainer {...props}>
        {renderMonths && <MonthScroller {...props} />}
        <YearScroller {...props} />
      </ScrollerContainer>
    </ScrollerWrapper>
  );
}
