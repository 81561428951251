import useLinkInputValidation from 'hooks/use-link-input-validation';
import useToggle from 'hooks/use-toggle';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAccentPrimary } from 'services/themes';

const useLinkForm = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const accentPrimary = useSelector(getAccentPrimary);
  const [linkColor, setLinkColor] = React.useState(accentPrimary);
  const [isColorPickerOpen, toggleColorPicker] = useToggle(false);

  const { linkUrl, linkUrlStatus, onChange } = useLinkInputValidation('');

  const onChangeLinkColor = (newColor: string) => {
    if (newColor !== linkColor) {
      setLinkColor(newColor);
    }
  };

  return {
    linkColor,
    onChangeLinkColor,
    linkUrl,
    linkUrlStatus,
    onChangeLinkUrl: onChange,
    isColorPickerOpen,
    toggleColorPicker,
    buttonRef,
    inputRef,
  };
};

export default useLinkForm;
