import { ToolbarButton } from 'components/ui/RichTextEditorV3/styles';
import { ADMIN_SURFACE_4 } from 'style/constants';
import { ADMIN_TEXT_LABEL_L_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const LineHeightOptionsWrapper = styled.div`
  position: relative;
  background-color: ${ADMIN_SURFACE_4};
  padding: 7.5px 8px 7.5px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
`;

export const StyledToolbarButton = styled(ToolbarButton)`
  ${ADMIN_TEXT_LABEL_L_MEDIUM}
`;
