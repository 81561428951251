import React from 'react';
import { Toolbar, ToolbarSection } from '../../styles';
import OrderedListPlugin from './OrderedListPlugin';
import UnorderedListPlugin from './UnorderedListPlugin';
import FontSizePlugin from './FontSizePlugin';
import TextColorPlugin from './TextColorPlugin';
import FontFamilyPlugin from './FontFamilyPlugin';
import bold from 'assets/icons/rich-text-editor-v3/bold.svg';
import italic from 'assets/icons/rich-text-editor-v3/italic.svg';
import underline from 'assets/icons/rich-text-editor-v3/underline.svg';
import FormatTextPlugin from './FormatTextPlugin';
import LineHeightPlugin from './LineHeightPlugin';
import LinkPlugin from './LinkPlugin';

const InlineToolbar: React.FC = () => {
  return (
    <Toolbar className="editor-toolbar">
      <ToolbarSection>
        <FontFamilyPlugin />
        <FontSizePlugin />
        <FormatTextPlugin formatType="bold" icon={bold} />
        <FormatTextPlugin formatType="italic" icon={italic} />
        <FormatTextPlugin formatType="underline" icon={underline} />
        <TextColorPlugin inline={true} />
      </ToolbarSection>
      <ToolbarSection>
        <LineHeightPlugin />
        <OrderedListPlugin />
        <UnorderedListPlugin />
        <LinkPlugin />
      </ToolbarSection>
    </Toolbar>
  );
};

export default InlineToolbar;
