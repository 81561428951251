import Button from 'components/admin2/ui/Button';
import { SURFACE_6, TEXT_100 } from 'style/constants';
import { TEXT_LABEL_M_BOLD } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const StyledButton = styled(Button).attrs(props => ({
  background: 'transparent',
  textColor: TEXT_100(props),
  hoverBackground: 'transparent',
  hoverTextColor: TEXT_100(props),
}))`
  height: auto;
  width: 100%;
  padding: 13px 24px 13px 16px;
  border: 1px solid ${SURFACE_6};
  ${TEXT_LABEL_M_BOLD}
`;
