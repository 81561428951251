import styled, { css } from 'styled-components';
import Draggable from 'components/ui/Draggable';
import { ACCENT_PRIMARY, TEXT_100, ADMIN_SURFACE_3 } from 'style/constants';
import { CIRCLE, mobileOnly } from 'style/mixins';
import { ComponentType } from 'react';

export interface VerticalProps {
  fixedLength?: boolean;
  sliderLength?: string;
  vertical?: boolean;
}

export interface ProgressProps extends VerticalProps {
  isDragging?: boolean;
  maxSliderLength?: number;
  progress?: number;
  progressColor?: string;
  secondaryProgress?: boolean;
  showThumb?: boolean;
  smooth?: boolean;
  trackBackground?: string;
}

const VerticalStyle = css<VerticalProps>`
  ${({ fixedLength, vertical, sliderLength = '100%' }) => vertical ? css`
    width: 5px;
    ${fixedLength && 'min-'}height: ${sliderLength};
    flex-direction: column;
  ` : css`
    ${fixedLength && 'min-'}width: ${sliderLength};
    height: 5px;
  `}
`;

export const Track = styled.div<ProgressProps>`
  ${VerticalStyle}
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${({ trackBackground }) => trackBackground || TEXT_100};
  border-radius: 4px;
  &:hover > div:first-child:after {
    display: block !important;
  }
`;

export const StyledDraggable = styled(Draggable)<VerticalProps>`
  ${VerticalStyle}
  background: transparent;
  z-index: 1;
  transform: none !important;
  ${({ vertical }) => mobileOnly`
    ${vertical ? 'width' : 'height'}: 35px;
  `}
`;

export const Progress = styled(Track as ComponentType<ProgressProps>).attrs({
  radius: '12px',
})`
  position: absolute;
  left: 0;
  max-width: 100%;
  ${({ secondaryProgress, progressColor = ACCENT_PRIMARY }) => css`
    background: ${secondaryProgress ? ADMIN_SURFACE_3 : progressColor};
    z-index: ${secondaryProgress ? 0 : 1};
  `};
  &:after {
    pointer-events: none;
    content: "";
    display: ${({ isDragging, showThumb }) => showThumb || isDragging ? 'block' : 'none'};
    ${CIRCLE}
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border: none;
    background: ${({ progressColor = ACCENT_PRIMARY }) => progressColor};
    cursor: pointer;
    position: absolute;
  }
  ${({ vertical, sliderLength = '100%', smooth, progress = 0 }) => {
    const size = vertical ? 'height' : 'width';
    const otherSize = vertical ? 'width' : 'height';
    const direction = vertical ? 'top' : 'right';
    return css`
      ${size}: ${progress}%;
      ${otherSize} 5px;
      ${sliderLength && `max-height: ${sliderLength};`}
      ${vertical && 'bottom: 0;'}
      ${smooth && `transition: ${size} 1s;`}
      &:after {
        ${direction}: -7px;
      }
    `;
  }}
`;
