import usePageBlockBackgroundColorOptions from 'components/admin-bridge/EditorModal/ModalComponents/BackgroundColorOptions/use-page-block-background-color-options';
import { HTTPS_PREFIX } from 'hooks/use-link-input-validation';
import React from 'react';
import { CheckIcon, HttpPrefix, Input, InputContainer, LinkIcon, LinkMenu, RemoveIcon, StatusContainer } from './styles';
import { ToolbarButton } from 'components/ui/RichTextEditorV3/styles';
import ColorOption from 'components/ui/ColorOption';
import ColorPicker from 'components/admin2/ColorPicker';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EXTENDED_LINK_TOGGLE_COMMAND } from '.';
import useLinkForm from './use-link-form';
import useLinkListeners from './use-link-listeners';
import { cleanAndFormatUrl, removeHttpsFromUrl } from 'utils';
import styled from 'styled-components';

const LinkForm = () => {
  const [editor] = useLexicalComposerContext();
  const {
    buttonRef,
    inputRef,
    isColorPickerOpen,
    linkColor,
    linkUrl,
    linkUrlStatus,
    onChangeLinkColor,
    onChangeLinkUrl,
    toggleColorPicker,
  } = useLinkForm();
  const { colors } = usePageBlockBackgroundColorOptions(linkColor);
  const selection = useLinkListeners({
    linkColor,
    linkUrl,
    onChangeLinkColor,
    onChangeLinkUrl,
  });

  const  handleOnLinkUrlChange = React.useCallback((value: string) => (
    onChangeLinkUrl(removeHttpsFromUrl(value))),
    [removeHttpsFromUrl, onChangeLinkUrl],
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (linkUrlStatus !== 'valid') {
      return;
    }

    editor.dispatchCommand(EXTENDED_LINK_TOGGLE_COMMAND, {
      url: cleanAndFormatUrl(linkUrl),
      linkColor,
      selection,
    });
  };

  const handleClear = () => {
    editor.dispatchCommand(EXTENDED_LINK_TOGGLE_COMMAND, {
      url: null,
      linkColor,
      selection,
    });
    onChangeLinkUrl('');
    inputRef.current?.focus();
  };

  return (
    <LinkMenu onSubmit={handleSubmit}>
      <InputContainer>
        <HttpPrefix linkUrlStatus={linkUrlStatus} value={linkUrl}>
          {HTTPS_PREFIX}
        </HttpPrefix>
        <Input
          setInputRef={inputRef}
          padding="0"
          linkUrlStatus={linkUrlStatus}
          value={linkUrl}
          placeholderText="Enter URL"
          onChange={handleOnLinkUrlChange}
        />
        {
          linkUrl.length > 0 && (
          <StatusContainer>
            <RemoveIcon onClick={handleClear} />
            {
              linkUrlStatus === 'valid' && (
                <button type="submit">
                  <CheckIcon />
                </button>
              )
            }
          </StatusContainer>
        )}
      </InputContainer>
      <ToolbarButton
        type="button"
        active={isColorPickerOpen}
        onClick={toggleColorPicker}
        ref={buttonRef}
      >
        <ColorOption color={linkColor} />
      </ToolbarButton>
      <Wrapper>
        <ColorPicker
          isOpen={isColorPickerOpen}
          onClose={toggleColorPicker}
          onResult={onChangeLinkColor}
          parentRef={buttonRef}
          color={linkColor}
          titleKey="LINK_COLOR"
          quickAccessColors={colors}
          parentMargin={{
            left: 45,
            top: 0,
          }}
        />
      </Wrapper>
    </LinkMenu>
  );
};

const Wrapper = styled.div`
  & .color-picker-wrapper {
    position: absolute;
    top: 55px;
    left: 0;
  }
`;

export default LinkForm;
