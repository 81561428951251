import React, { useMemo } from 'react';
import CustomLink from './CustomLink';
import ExternalLink from './ExternalLink';
import InternalLink from './InternalLink';
import { isExternal } from './utils';
import { ObjectValues } from 'utils';
import { ILinkProps } from './types';

const LINK_TYPE = {
  CUSTOM_LINK: 'customLink',
  EXTERNAL_LINK: 'externalLink',
  INTERNAL_LINK: 'internalLink',
} as const;

type LinkType = ObjectValues<typeof LINK_TYPE>;

const Link = ({
  children,
  clearQuery,
  href,
  query,
  target,
  ...props
}: ILinkProps) => {
  const linkType = useMemo<LinkType>(() => {
    if (!href && !query) {
      return LINK_TYPE.CUSTOM_LINK;
    } else if (href && isExternal(href)) {
      return LINK_TYPE.EXTERNAL_LINK;
    } else {
      return LINK_TYPE.INTERNAL_LINK;
    }
  }, [href, query]);

  const isGeoFencedLink = useMemo(() => {
    if (!window.INIT.GEO_FENCED_SLUGS) {
      return false;
    }
    // geoBlocked channels should go through external link to hit fastly
    const isGeoBlocked = window.INIT.GEO_FENCED_SLUGS.find((path: string) => {
      if (!href) {
        return false;
      }
      return href.includes(path);
    });
    return !!isGeoBlocked;
  }, [href]);

  switch (linkType) {
    case LINK_TYPE.CUSTOM_LINK: {
      return (
        <CustomLink
          {...props}
          href={href}
        >
          {children}
        </CustomLink>
      );
    }
    case LINK_TYPE.EXTERNAL_LINK: {
      return (
        <ExternalLink
          {...props}
          href={href}
          target={href?.includes(window.location.host) ? '_self' : target}
          query={query}
        >
          {children}
        </ExternalLink>
      );
    }
    case LINK_TYPE.INTERNAL_LINK: {
      return (
        <InternalLink
          {...props}
          href={href}
          clearQuery={clearQuery}
          target={isGeoFencedLink ? '_blank' : target}
          query={query}
        >
          {children}
        </InternalLink>
      );
    }
    default: {
      return null;
    }
  }
};

export default Link;
