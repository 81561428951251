import styled from 'styled-components';
import Link from 'components/ui/Link';
import {
  ACCENT_PRIMARY, TEXT_300,
} from 'style/constants';
import { desktopOnly, FLEX_CENTER, mobileOnly, TEXT_H7 } from 'style/mixins';

export const PolicyWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  ${desktopOnly`
    flex-flow: row nowrap;
  `}

  ${mobileOnly`
    flex-flow: column nowrap;
  `}
  & > *:first-child {
    margin-right: 7px;
  }
`;

export const StyledLink = styled(Link).attrs({
  plain: true,
})`
  &&, &.plain {
    ${TEXT_H7}
    font-size: 12px;
    line-height: 14px;
    height: 14px;
    ${FLEX_CENTER}
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
    padding: 0px 15px;
    color: ${TEXT_300};
    &:hover {
      color: ${ACCENT_PRIMARY};
    }
  }
`;
