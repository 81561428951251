import React from 'react';
import Grid from 'components/ui/Grid';
import Image from './Image';
import {
  Container,
} from './styles';
import {
  GalleryPageProps,
  IndexedImage,
} from './interfaces';

export default function GalleryPage({ admin, highlight, hoverIcon, imageTooltip, imageTooltipKey, isTooltipVisible, pageContent, onSelect }: GalleryPageProps) {
  return (
    <Container>
      <Grid
        items={pageContent}
        spacing="13px"
      >
        {({ index, ...image }: IndexedImage) => (
          <Image
            admin={admin}
            disableHighlight={!highlight}
            highlight={highlight}
            hoverIcon={hoverIcon}
            image={image}
            imageTooltip={imageTooltip}
            imageTooltipKey={imageTooltipKey}
            index={index}
            isTooltipVisible={isTooltipVisible}
            onSelect={onSelect}
          />
        )}
      </Grid>
    </Container>
  );
}
