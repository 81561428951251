import { ToolbarButton } from 'components/ui/RichTextEditorV3/styles';
import linkIcon from 'assets/icons/link-outline.svg';
import React from 'react';
import useFloatMenu from 'hooks/use-float-menu';
import { Wrapper } from './styles';
import LinkForm from './LinkForm';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isRangeSelection, COMMAND_PRIORITY_LOW, RangeSelection, createCommand } from 'lexical';
import { LinkNode } from '@lexical/link';
import { $getNearestNodeOfType } from '@lexical/utils';
import ExtendedLinkNode from 'components/ui/RichTextEditorV3/nodes/ExtendedLinkNode';
import { toggleLink } from './toggleLink';
import { getRectPositionCheckingBounds } from 'dom-utils';

export const EXTENDED_LINK_TOGGLE_COMMAND = createCommand<{ linkColor: string; selection: RangeSelection | null; url: string | null; }>('EXTENDED_LINK_TOGGLE_COMMAND');

const useLinkPluginRegister = () => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    if (!editor.hasNode(LinkNode)) {
      throw new Error('LinkNode is not registered');
    }

    editor.registerCommand(EXTENDED_LINK_TOGGLE_COMMAND, ({ linkColor, url, selection }) => {
      toggleLink(url, selection);

      if (url !== null && $isRangeSelection(selection)) {
        const node = $getNearestNodeOfType(selection.anchor.getNode(), ExtendedLinkNode);
        node?.setLinkColor(linkColor);
      }

      return true;
    }, COMMAND_PRIORITY_LOW);
  });
};

const LinkPlugin = () => {
  useLinkPluginRegister();
  const setMenuPosition = React.useCallback((menu: HTMLDivElement) => {
    const menuRect = menu.getBoundingClientRect();
    const rect = getRectPositionCheckingBounds({ elem: menu, margin: 20 });

    menu.style.left = `${Math.min(rect.left, -(menuRect.width / 2))}px`;
    menu.style.top = `55px`;
    menu.style.position = 'absolute';
    menu.style.zIndex = '1';
  }, []);
  const { containerRef, isOpen, positionMenu, toggleMenu } = useFloatMenu(setMenuPosition);

  return (
    <Wrapper ref={containerRef}>
      <ToolbarButton
        active={isOpen}
        onClick={toggleMenu}
        dangerouslySetInnerHTML={{ __html: linkIcon }}
      />
      {isOpen && (
        <div ref={positionMenu}>
          <LinkForm />
        </div>
      )}
    </Wrapper>
  );
};

export default LinkPlugin;
