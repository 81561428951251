import Header from 'components/header/Header';
import { HEADER_HEIGHT_WITH_UNITS } from 'style/constants';
import styled from 'styled-components';
import Gate from '../../objects/Gate';
import { HIDE_SCROLLBAR, mobileLandscapeOnly } from 'style/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${mobileLandscapeOnly`height: 100%;`};
`;

export const StyledHeader = styled(Header)`
  height: ${HEADER_HEIGHT_WITH_UNITS};
  width: 100%;
  max-width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin: 0 !important;
`;

export const StyledGate = styled(Gate)`
  flex-grow: 1;
  z-index: 1;
  ${HIDE_SCROLLBAR};
`;
