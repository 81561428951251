import React, { memo } from 'react';
import { Blur, Content, Message, MessageContainer, Rotate, StyledIcon, Wrapper } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import { Portal } from 'react-portal';

const LandingPageLandscapeWarning = () => {
  const { t } = useAdminTranslation();

  return (
    <Portal>
    <Wrapper>
    <Rotate>
      <Blur />
      <Content>
        <MessageContainer>
          <StyledIcon />
          <Message>
            {t('LANDING_PAGE_LANDSCAPE_MESSAGE')}
          </Message>
        </MessageContainer>
      </Content>
    </Rotate>
    </Wrapper>
    </Portal>
  );
};

export default memo(LandingPageLandscapeWarning);
