import React, { ComponentType, lazy, PropsWithChildren, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device';
import { desktopOnly } from 'style/mixins';
import VideoSkeleton from './VideoSkeleton';
import styled from 'styled-components';

interface SkeletonProps {
  compact?: boolean;
  height?: string;
  hidden?: boolean;
  type?: string;
}
interface SkeletonMap {
  [key: string]: () => Promise<any>;
}

interface LoadedSkeletonMap {
  [key: string]: ComponentType<any>;
}

const SkeletonContainer: any = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: ${({ height }: SkeletonProps) => height};
  width: 100%;
  > * {
    flex: 1;
  }
  ${({ compact }: SkeletonProps) => compact && desktopOnly`
    min-height: 87.5%;
  ` as any}
`;

const SKELETON_MAP: SkeletonMap = {
  mobileHeader: () => import('./MobileHeaderSkeleton'),
  panels: () => import('./PanelSkeleton'),
  sidebarAuthHeader: () => import('./AuthHeaderSkeleton'),
};

const LOADED_SKELETON_MAP: LoadedSkeletonMap = {
  video: VideoSkeleton,
};

interface IContainerProps {
  height?: string;
  hidden?: boolean;
  isDesktopVideo: boolean;
}

const Container: React.FC<PropsWithChildren<IContainerProps>> = ({ isDesktopVideo, height, hidden, children, ...props }) => {
  if (isDesktopVideo) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }

  return (
    <SkeletonContainer height={height} hidden={hidden} {...props}>
      {children}
    </SkeletonContainer>
  );
};

export default function Skeleton({ height = '100%', hidden, type = 'panels', ...props }: SkeletonProps) {
  const loader = SKELETON_MAP[type];
  const isMobile = useSelector(isMobileLayout);
  const isDesktopVideo = useMemo(() => (
    type === 'video' && !isMobile
  ), [type, isMobile]);

  const SkeletonContent = loader ? lazy(loader) : LOADED_SKELETON_MAP[type];
  return SkeletonContent ? (
    <Container isDesktopVideo={isDesktopVideo} height={height} hidden={hidden} {...props}>
      <Suspense fallback={null}>
        <SkeletonContent {...props} />
      </Suspense>
    </Container>
  ) : null;
}
