import React, { MouseEventHandler, useMemo } from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import hash from 'json-stable-stringify';
import chunk from 'lodash/chunk';
import moment from 'moment-timezone';
import inRange from 'lodash/inRange';
import CalendarContainer from './Container';
import {
  Checkmark,
  QuarterButton,
  QuarterContainer,
  QuarterRow,
  QuarterText,
  QuarterWrapper,
  Radio,
} from '../styles';
import { CalendarStateProps, RangePickerProps, DayCalendarProps } from '../interfaces';

interface QuarterPickerProps extends CalendarStateProps, RangePickerProps, DayCalendarProps {
  monthsShort: string[];
}

export default function QuarterPicker({
  admin,
  calendarMonth,
  calendarYear,
  dateTitle,
  endDate,
  isMonthYearSelectorVisible,
  monthsShort,
  nextYear,
  onDatesChange,
  prevYear,
  toggleMonthYearSelector,
  startDate,
}: QuarterPickerProps){
  const quarters = useMemo(() => (
    chunk(monthsShort, 3)
  ), [hash(monthsShort)]);

  const selectedQuarter = useMemo(() => {
    if (!startDate || !endDate) {
      return -1;
    }
    for (let index = 0; index < quarters.length; index++) {
      const start = index * 3;
      const end = start + 2;
      if (
        calendarYear === startDate.year() &&
        calendarYear === endDate.year() &&
        inRange(Math.abs(start - startDate.month()), 0, 3) &&
        inRange(Math.abs(end - endDate.month()), 0, 3)
      ) {
        return index;
      }
    }
    return -1;
  }, [startDate?.valueOf(), endDate?.valueOf(), calendarMonth, calendarYear]);

  const selectQuarter: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!onDatesChange) {
      return;
    }
    const index = parseInt(e.currentTarget.dataset.quarter || '0', 10);
    const start = index * 3;
    const end = start + 2;
    onDatesChange(
      moment([calendarYear, start, 1]).startOf('day'),
      moment([calendarYear, end]).endOf('month').endOf('day'),
    );
  };

  return (
    <CalendarContainer
      admin={admin}
      buttonText={calendarYear}
      containerTitleKey="ADMIN_LABEL_SELECT_QUARTER"
      isMonthYearSelectorVisible={isMonthYearSelectorVisible}
      navNext={nextYear}
      navPrev={prevYear}
      navTitle={dateTitle}
      onActionClick={toggleMonthYearSelector}
    >
      <QuarterWrapper>
        <NonKeyedListMapper list={quarters}>
          {(quarterKey: string, quarter: string[], quarterIndex: number) => (
            <QuarterRow
              key={quarterKey}
              admin={admin}
              data-quarter={quarterIndex}
              isActive={selectedQuarter === quarterIndex}
              onClick={selectQuarter}
            >
              {selectedQuarter === quarterIndex ? <Checkmark admin={admin} /> : <Radio />}
              <QuarterContainer>
                <QuarterText>Q{quarterIndex + 1}</QuarterText>
                <NonKeyedListMapper list={quarter}>
                  {(monthKey: string, month: string) => (
                    <QuarterButton key={monthKey}>
                      {month}
                    </QuarterButton>
                  )}
                </NonKeyedListMapper>
              </QuarterContainer>
            </QuarterRow>
          )}
        </NonKeyedListMapper>
      </QuarterWrapper>
    </CalendarContainer>
  );
}
