import { ComponentProps, ComponentType } from 'react';
import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import withTooltip from 'components/core/withTooltip';
import IconTextButton from 'components/admin2/ui/IconButton';
import AdminButton, {
  CloseButton as AdminCloseButton,
  IconButton as AdminIconButton,
} from 'components/admin2/ui/Button';
import {
  ACCENT_PRIMARY,
  ACCENT_SECONDARY,
  MAESTRO_BLACK,
  MAESTRO_WHITE,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
  BODY_FONT_FAMILY,
  SURFACE_1,
  SURFACE_2,
  TEXT_100,
} from 'style/constants';
import {
  TEXT_BUTTON_NORMAL,
  DISABLED_PROP,
  NO_HIGHLIGHT,
  SINGLE_LINE_ELLIPSIS,
} from 'style/mixins';
import {
  transparentButton,
  ButtonStyleProps,
  VARIANTS,
} from './utils';

export const BASE_BUTTON = css<ButtonStyleProps>`
  ${NO_HIGHLIGHT}
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0 10px;
  border-radius: 4px;
  cursor: pointer;
  & svg {
    width: 24px;
    height: 24px;
  }
  ${SINGLE_LINE_ELLIPSIS}
  ${TEXT_BUTTON_NORMAL}
  ${({ textColor }) => textColor && css`
    & svg > path {
      fill: ${textColor};
    }
  `};
  font-family: ${BODY_FONT_FAMILY};
  font-weight: 700;
  border-radius: 5px;
  ${({ variant = 'default' }) => (VARIANTS[variant] || VARIANTS.default) as any};
`;

export const BaseButton = styled.button.attrs((props) => ({
  admin: false,
  background: ACCENT_PRIMARY(props),
  textColor: TEXT_CONTRAST_ON_ACCENT_PRIMARY(props),
  hoverBackground: ACCENT_SECONDARY(props),
  hoverTextColor: TEXT_CONTRAST_ON_ACCENT_PRIMARY(props),
  ...props,
}))`${BASE_BUTTON}`;

export const Button = withTooltip(BaseButton);

export const EmptyButton = styled.button.attrs({ admin: false })`${BASE_BUTTON}`;

export const IconButtonBase = styled(Icon).attrs((props: any) => ({
  color: props.variant === 'transparent' ? props.textColor || TEXT_CONTRAST_ON_ACCENT_PRIMARY(props) : undefined,
  ...props,
}))`
  ${BASE_BUTTON}
  padding: 0;
  min-width: 31px;
` as ComponentType<ComponentProps<typeof Icon> & ButtonStyleProps>;

export const TransparentIconButton = transparentButton();

export const IconButton = styled(AdminIconButton).attrs((props) => ({
  admin: false,
  background: ACCENT_PRIMARY(props),
  textColor: TEXT_CONTRAST_ON_ACCENT_PRIMARY(props),
  hoverBackground: ACCENT_SECONDARY(props),
  hoverTextColor: TEXT_CONTRAST_ON_ACCENT_PRIMARY(props),
  ...props,
}))`` as typeof AdminIconButton;

export const TextIconButton = withTooltip(styled(IconTextButton).attrs({
  button: BaseButton as typeof AdminButton,
})``);

export const CopyButton = styled(IconButton as any).attrs({
  name: 'copy',
  tooltipKey: 'LABEL_COPY',
})`` as ComponentType<Omit<ComponentProps<typeof IconButton>, 'name'>>;

export const CloseButton = styled(AdminCloseButton).attrs(props => ({
  admin: false,
  tooltipBackground: MAESTRO_BLACK,
  tooltipTextColor: MAESTRO_WHITE,
  color: SURFACE_1(props),
  hoverBackground: SURFACE_2(props),
  textColor: TEXT_100(props),
  hoverTextColor: TEXT_100(props),
}))``;

export const SidebarIcon = styled(TransparentIconButton).attrs({
  textColor: MAESTRO_WHITE,
  hoverBackground: MAESTRO_WHITE,
  hoverTextColor: MAESTRO_BLACK,
})`
  &:hover svg > path {
    stroke: ${MAESTRO_BLACK};
    fill: ${MAESTRO_BLACK};
  }
`;

export const AirplayButton = transparentButton({
  buttonProps: () => ({
    textColor: MAESTRO_WHITE,
    hoverBackground: MAESTRO_WHITE,
    hoverTextColor: MAESTRO_BLACK,
  }),
  iconName: 'airplay',
  tooltipKey: 'LABEL_AIRPLAY',
});

export const ChromecastButton = transparentButton({
  buttonProps: () => ({
    textColor: MAESTRO_WHITE,
    hoverBackground: MAESTRO_WHITE,
    hoverTextColor: MAESTRO_BLACK,
  }),
  iconName: 'chromecast',
  tooltipKey: 'LABEL_CHROMECAST',
});

const ArrowButton = css`
  ${DISABLED_PROP}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  heigth: 25px;
  & svg {
    width: 6px;
    height: 10px;
  }
`;

export const LeftButton = styled(Icon).attrs(props => ({
  color: ACCENT_PRIMARY(props),
  name: 'arrowLeft',
  variant: 'transparent',
}))`${ArrowButton}`;

export const RightButton = styled(Icon).attrs(props => ({
  color: ACCENT_PRIMARY(props),
  name: 'arrowRight',
  variant: 'transparent',
}))`${ArrowButton}`;

export default Button;
