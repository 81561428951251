import React, { PropsWithChildren } from 'react';
import Link from 'components/ui/Link';
import { OpenLinkBehaviorData } from '../../../admin-bridge/CTAEditorModal/utils';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';

interface OpenLinkProps extends PropsWithChildren {
  behavior: OpenLinkBehaviorData;
}

const OpenLink: React.FC<OpenLinkProps> = ({ children, behavior }) => {
  const isEditing = useSelector(isEditMode);

  if (isEditing) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <Link
      target={behavior.openInNewTab ? '_blank' : '_self'}
      href={behavior.url}
    >
      {children}
    </Link>
  );
};

export default OpenLink;
