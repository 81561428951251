import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND } from 'lexical';
import React from 'react';
import { getDOMRangeRect, setFloatingElemPosition } from '../../utils';
import { mergeRegister } from '@lexical/utils';
import { CONTENT_INNER_CONTAINER_ID } from 'global-ids';

const useFloatingToolbar = (anchorElement: HTMLElement) => {
  const [editor] = useLexicalComposerContext();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const contentRoot = document.getElementById(CONTENT_INNER_CONTAINER_ID);

  function mouseMoveListener(e: MouseEvent) {
    if (
      ref?.current &&
      (e.buttons === 1 || e.buttons === 3)
    ) {
      if (ref.current.style.pointerEvents !== 'none') {
        const x = e.clientX;
        const y = e.clientY;
        const elementUnderMouse = document.elementFromPoint(x, y);

        if (!ref.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          ref.current.style.pointerEvents = 'none';
        }
      }
    }
  }
  function mouseUpListener(e: MouseEvent) {
    if (ref?.current) {
      if (ref.current.style.pointerEvents !== 'auto') {
        ref.current.style.pointerEvents = 'auto';
      }
    }
  }

  React.useEffect(() => {
    if (!contentRoot) {
      return;
    }

    const onScroll = () => {
      editor.getEditorState().read(updateFloatingToolbar);
    };

    contentRoot.addEventListener('scroll', onScroll);
    return () => {
      contentRoot.removeEventListener('scroll', onScroll);
    };
  }, [contentRoot]);

  React.useEffect(() => {
    if (ref?.current) {
      document.addEventListener('mousemove', mouseMoveListener);
      document.addEventListener('mouseup', mouseUpListener);

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener);
        document.removeEventListener('mouseup', mouseUpListener);
      };
    }
  }, [ref]);

  const updateFloatingToolbar = React.useCallback(() => {
    const selection = $getSelection();

    const currentRef = ref.current;
    const nativeSelection = window.getSelection();

    if (currentRef === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);

      setFloatingElemPosition(rangeRect, currentRef, anchorElement);
    }
  }, [editor, anchorElement]);

  React.useEffect(() => {
    editor.getEditorState().read(updateFloatingToolbar);
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(updateFloatingToolbar);
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, updateFloatingToolbar]);

  return ref;
};

export default useFloatingToolbar;
