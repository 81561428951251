import styled from 'styled-components';

export const POSITION_MAP: Record<string, string> = {
  first: 'flex-start',
  middle: 'center',
  last: 'flex-end',
};

export const Item = styled.div<{position?: string}>`
  display: flex;
  justify-content: ${({ position = 'middle' }) => POSITION_MAP[position]};
`;

export const Container = styled.div<{basis: number, spacing?: string}>`
  display: flex;
  flex-wrap: wrap;
  & > ${Item} {
    flex-basis: ${({ basis }) => basis}%;
    margin-bottom: ${({ spacing }) => spacing};
  }
`;
