import useSelector from 'hooks/use-selector';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import qs from 'qs';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { getQuery } from 'services/app-router';

import { StyledInternalLink } from './styles';
import { ILinkProps } from './types';

const InternalLink = ({
  children,
  clearQuery,
  href,
  onClick: onLinkClick,
  plain = true,
  query,
  target,
  ...props
}: ILinkProps) => {
  const currentQuery = useSelector(getQuery, isEqual);

  const hrefValue = useMemo<string>(() => {
    const previous = clearQuery ? {} : { ...currentQuery };
    delete previous.v;
    delete previous.p;

    const search = { ...previous, ...query };
    return isEmpty(search) ? href! : `${href}?${qs.stringify(search)}`;
  }, [currentQuery, clearQuery, query, href]);

  const onClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (onLinkClick) {
        onLinkClick(event, hrefValue);
      }
    },
    [hrefValue, onLinkClick],
  );

  return (
    <StyledInternalLink
      {...props}
      onClick={onClick}
      rel="noopener"
      target={target === '_self' ? undefined : target} // defaults to _self
      to={hrefValue}
      plain={plain}
    >
      {children}
    </StyledInternalLink>
  );
};

export default InternalLink;
