import { ReactPlayerWrapper } from 'components/Video/video-player/ReactPlayer';
import { PlayerTypes } from 'models';
import React from 'react';
import { Portal } from 'react-portal';

type EmbeddedMaestroFilePlayerProps = {
  nodeRef: Element;
  videoUrl: string;
};

const EmbeddedMaestroFilePlayer = ({ videoUrl, nodeRef }: EmbeddedMaestroFilePlayerProps) => {
  return (
    <Portal node={nodeRef}>
      <ReactPlayerWrapper playerType={PlayerTypes.file} playing={true} url={videoUrl} controls={true} />
    </Portal>
  );
};

export default EmbeddedMaestroFilePlayer;
