import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_ACCENT_PRIMARY } from 'style/constants';
import { SINGLE_LINE_ELLIPSIS } from 'style/mixins';
import { bw } from 'colors';
import { ADMIN_TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';

export interface StyleProps {
  background?: string;
  iconColor?: string;
  iconHeight?: string;
  iconWidth?: string;
  textColor?: string;
}

export const IconLabelContainer = styled.div<StyleProps>`
  display: flex;
  height: 15px;
  align-items: center;
  border-radius: 4.5px;
  padding: 0 7px;
  & > *:nth-child(2) {
    margin-left: 4px;
  }
  ${({ background, textColor, ...props }) => css`
    background: ${background || ADMIN_ACCENT_PRIMARY};
    color: ${textColor || bw(background || ADMIN_ACCENT_PRIMARY(props))};
  `}
`;

export const StyledIcon = styled(Icon).attrs<StyleProps>(props => ({
  color: props.color || bw(props.background || ADMIN_ACCENT_PRIMARY(props)),
}))<StyleProps>`
  width: ${({ iconHeight = '8px', iconWidth = '8px' }) => `
    & svg {
      width: ${iconWidth};
      height: ${iconHeight};
    }
  `};
  display: flex;
  align-items: center;
  font-size: 8px;
`;

export const Text = styled.div`
  ${SINGLE_LINE_ELLIPSIS}
  ${ADMIN_TEXT_LABEL_XS_MEDIUM}
`;
