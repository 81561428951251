import styled from 'styled-components';
import {
  ADMIN_INPUT_BORDER_ERROR,
  INPUT_BORDER_ERROR,
} from 'style/mixins';
import Select from 'react-select';
import { ACCENT_PRIMARY, ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_1, ADMIN_SURFACE_2, ADMIN_SURFACE_3, ADMIN_SURFACE_5, ADMIN_TEXT_100, ADMIN_TEXT_200, ADMIN_TEXT_300, MAESTRO_BLACK, MAESTRO_WHITE, SURFACE_1, SURFACE_3, SURFACE_4, SURFACE_5, TEXT_100, TEXT_200, TEXT_300 } from 'style/constants';
import { ADMIN_TEXT_BODY_S_MEDIUM, ADMIN_TEXT_BODY_XS_MEDIUM, TEXT_BODY_S_MEDIUM, TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import { bw, darken, offset, rgba } from 'colors';

interface InputProps {
  error: boolean;
  showForAdmin?: boolean;
}

export const BillingInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const NameContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const CountryCodeContainer = styled.div`
  width: 100%;
  border-radius: 3.5px;
  margin-top: 7px;
`;

export const PostalCodeContainer = styled.div`
  width: 100%;
  border-radius: 3.5px;
`;

export const StateCodeContainer = styled.div`
  width: 100%;
  border-radius: 3.5px;
`;

export const StyledInputContainer = styled.input<InputProps>`
  ${props => props.showForAdmin ? ADMIN_TEXT_BODY_XS_MEDIUM : TEXT_BODY_XS_MEDIUM}
  ${props => props.showForAdmin ? ADMIN_INPUT_BORDER_ERROR : INPUT_BORDER_ERROR}
  background-color: ${(props) => props.showForAdmin ? ADMIN_SURFACE_3 : darken(SURFACE_3(props), 0.1)};
  box-sizing: border-box;
  min-height: 38px;
  border: none;
  border-radius: 1px;
  width: 100%;
  padding-left: 10px;
  flex: 1;
  color: ${props => props.showForAdmin ? ADMIN_TEXT_100 : TEXT_100};
  border-radius: 3.5px;
  &::placeholder {
    color: ${(props) => props.showForAdmin ? ADMIN_TEXT_300 : rgba(bw(SURFACE_1(props)), 0.5)} !important;
  }
`;

export const StyledSelect = styled(Select).attrs(props => ({
  styles: {
    container: (provided: any) => ({
      ...provided,
      color: props.showForAdmin ? ADMIN_TEXT_100(props) : TEXT_100(props),
      fontSize: '14px',
      height: '50px',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: props.showForAdmin ? ADMIN_TEXT_100(props) : TEXT_100(props),
    }),
    input: (provided: any) => ({
      ...provided,
      color: props.showForAdmin ? ADMIN_TEXT_100(props) : TEXT_100(props),
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: props.showForAdmin ? ADMIN_TEXT_300(props) : rgba(bw(SURFACE_1(props)), 0.5),
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: props.showForAdmin ? ADMIN_SURFACE_2(props) : darken(SURFACE_3(props), 0.1),
      minHeight: '80px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor:
        (state.isSelected || state.isFocused) ? props.showForAdmin ? ADMIN_ACCENT_PRIMARY(props) : ACCENT_PRIMARY(props) : null,
        color: (state.isSelected || state.isFocused) ? props.showForAdmin ? bw(ADMIN_ACCENT_PRIMARY(props)) : bw(ACCENT_PRIMARY(props)) : null,
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: props.showForAdmin ? ADMIN_SURFACE_3(props) : SURFACE_3(props),
      borderStyle: 'solid !important',
      borderColor: props.showForAdmin ? ADMIN_TEXT_200(props) : TEXT_200(props),
      borderRadius: '3.5px !important',
      boxShadow: 'none !important',
      height: '60px',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      '[class*=indicatorSeparator]': {
        backgroundColor: `${props.showForAdmin ? ADMIN_SURFACE_3(props) : SURFACE_3(props)} !important`,
      },
      '[class*=indicatorContainer]': {
        color: `${props.showForAdmin ? ADMIN_TEXT_200(props) : TEXT_200(props)} !important`,
        cursor: 'pointer',
      },
      '[class*=indicatorContainer]:hover': {
        color: `${props.showForAdmin ? ADMIN_ACCENT_PRIMARY(props) : ACCENT_PRIMARY(props)} !important`,
      },
    }),
  },
}))`
  ${props => props.showForAdmin ? ADMIN_TEXT_BODY_S_MEDIUM : TEXT_BODY_S_MEDIUM}
  & > div {
    background-color: ${({ showForAdmin }) => showForAdmin ? ADMIN_SURFACE_5 : SURFACE_4};
  }
  width: 100% !important;
  flex: 1;
  border-radius: 3.5px;
`;

export const Spacer = styled.div`
  margin: auto;
  flex: 1;
`;
