import React, { PropsWithChildren } from 'react';
import {
  ChannelTextWrapper,
  SvgContainer,
  ChannelImage,
  EllipsisText,
} from './styles';
import defaultChannelSvg from 'assets/icons/default-video-card.svg';

export type ChannelRenderProps = PropsWithChildren<{
  active: boolean;
  image: string | null;
  index?: number;
  itemPadding?: string;
  maxTextLines: number;
  name: string;
  noLink?: boolean;
}>;

export default function ChannelRender({
  image,
  index,
  itemPadding,
  active,
  maxTextLines,
  name,
  noLink,
  children,
}: ChannelRenderProps) {
  return (
    <ChannelTextWrapper noLink={noLink}>
      {image ? <ChannelImage alt="" src={image} /> : (
        <>
          <SvgContainer
            dangerouslySetInnerHTML={{ __html: defaultChannelSvg }}
            data-testid={active ? 'channelSelectList-active' : `channelSelectList-${index}`}
            itemPadding={itemPadding}
          />
          <EllipsisText maxLines={maxTextLines}>{name}</EllipsisText>
        </>
      )}
      {children}
    </ChannelTextWrapper>
  );
}
