// Scraped using code in scripts/scrape-icon-names
const fontelloIconStrings = [
  'airport',
  'amazon',
  'amex',
  'android',
  'angellist',
  'apple',
  'area-chart',
  'arrow-combo',
  'art-gallery',
  'article',
  'at',
  'attention',
  'award',
  'bandcamp',
  'bar-chart',
  'bar',
  'basket',
  'beatport',
  'behance',
  'bicycle',
  'block',
  'book-open',
  'book',
  'bookmark',
  'bookmarks',
  'box',
  'brush',
  'bug',
  'bus',
  'cab',
  'calendar',
  'camera',
  'campsite',
  'cancel-thin',
  'cancel',
  'chat',
  'check-empty',
  'check',
  'chrome',
  'circle_empty',
  'circle_filled_check',
  'circle-check',
  'circle-empty',
  'circle-thin',
  'circle-x',
  'circle',
  'clock',
  'cloud',
  'cloudapp',
  'code',
  'cog',
  'cogs',
  'columns',
  'comment',
  'credit-card',
  'crop',
  'dailymotion',
  'digg',
  'discover',
  'doc-landscape',
  'docs',
  'dollar',
  'down-open-thin',
  'down-open',
  'download',
  'dropbox',
  'email',
  'euro',
  'exchange',
  'eye',
  'facebook-squared',
  'facebook',
  'fast-bw',
  'fast-fw',
  'ferry',
  'film',
  'fire',
  'flag',
  'flickr',
  'flight',
  'food',
  'football',
  'gamepad',
  'garden',
  'git',
  'github-circled',
  'github-squared',
  'github',
  'globe',
  'google',
  'googleplus-circled',
  'googleplus-squared',
  'googleplus',
  'grooveshark',
  'guidedog',
  'happy',
  'headphones',
  'heart-empty',
  'heart',
  'heliport',
  'help-circled',
  'help',
  'html5',
  'icon_filter',
  'ie',
  'info-circled',
  'info',
  'instagram',
  'itunes',
  'lastfm-circled',
  'lastfm',
  'leaf',
  'left-open-big',
  'left-open-mini',
  'left-open',
  'line-chart',
  'link',
  'linkedin-circled',
  'linkedin-squared',
  'linkedin',
  'list-bullet',
  'list',
  'location',
  'lock-open',
  'lock',
  'map',
  'mastercard',
  'medkit',
  'megaphone',
  'mic',
  'minus-circle',
  'minus',
  'mixcloud',
  'mobile',
  'monitor',
  'move',
  'newspaper',
  'note-beamed',
  'note',
  'ok',
  'pause',
  'paw',
  'paypal',
  'pencil',
  'phone',
  'picture',
  'pictures',
  'pie-chart',
  'pinterest-circled',
  'pinterest-squared',
  'pinterest',
  'play-1',
  'play-circled',
  'play',
  'playstv',
  'plus-circle',
  'plus-thin',
  'plus',
  'podcast',
  'pound',
  'power',
  'quote-left',
  'quote-right',
  'reddit',
  'reply',
  'resident-advisor',
  'resize-full',
  'resize-small',
  'retweet',
  'right-hand',
  'right-open-big',
  'right-open-mini',
  'right-open',
  'road',
  'rocket',
  'rss',
  'school',
  'search',
  'share',
  'shazam',
  'shield',
  'skype',
  'snapchat',
  'soundcloud',
  'spin1',
  'spin2',
  'spin3',
  'spotify-circled',
  'spotify',
  'star-empty',
  'star',
  'steam',
  'stop',
  'subway',
  't-shirt',
  'tags',
  'th-large',
  'theatre',
  'thumbs-down',
  'thumbs-up',
  'ticket',
  'to-end-alt',
  'to-end',
  'to-start-alt',
  'to-start',
  'train',
  'trash',
  'tumblr-circled',
  'tumblr-squared',
  'tumblr',
  'twitch',
  'twitter-circled',
  'twitter-filled',
  'twitter-squared',
  'twitter',
  'unhappy',
  'up-open-thin',
  'up-open',
  'upload',
  'user-add',
  'user',
  'users',
  'vcard',
  'vevo',
  'video',
  'videocam',
  'vimeo-circled',
  'vimeo-squared',
  'vimeo',
  'vine',
  'visa',
  'vk',
  'volume-down',
  'volume-off',
  'volume-up',
  'water',
  'wheelchair',
  'windows',
  'wordpress',
  'wrench',
  'yelp',
  'youtube-play',
  'youtube-squared',
  'youtube',
  'zoom-in',
  'zoom-out',
] as const;

export default fontelloIconStrings;
