import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { SERVICE_BASE_URL } from 'config';
import styled from 'styled-components';
import TranslatedText from 'components/i18n/TranslatedText';
import Button from 'components/ui/Button';
import {
  TEXT_H5,
} from 'style/mixins';
import { ACCENT_PRIMARY, TEXT_100 } from 'style/constants';

const FlexContainer = styled.div`
  display: flex;
  flex: 2;
  justify-content: space-between;
`;

const NoticeTextContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NoticeSubtitle = styled.div`
  ${TEXT_H5}
  margin-top: 4px;
`;

const Email = styled.span`
`;

const CtaContainer = styled.div`
  align-items: center;
  display: flex;
`;

const CtaButton = styled(Button)`
  background: ${TEXT_100};
  border-radius: 2px;
  color: ${ACCENT_PRIMARY};
  opacity: ${props => props.disabled ? '0.3' : '1'};
  padding: 6px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  text-transform: uppercase;
`;

export default class EmailVerificationNotice extends React.Component {
  static propTypes = {
    email: PropTypes.string,
    siteId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    email: '',
  };

  state = {
    hasEmailBeenReSent: false,
  };

  resendVerificationEmail = async () => {
    const { siteId, email } = this.props;

    try {
      await axios({
        data: {
          domainUrl: window.location.href,
          email,
          siteId,
        },
        method: 'post',
        url: `${SERVICE_BASE_URL}/email-validation/v1/resend`,
      });
      this.setState({ hasEmailBeenReSent: true });
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  };

  render() {
    const { email } = this.props;
    const { hasEmailBeenReSent } = this.state;

    return (
      <FlexContainer>
        <NoticeTextContainer>
          { hasEmailBeenReSent ?
            <TranslatedText stringKey="SUCCESS_PLEASE_CHECK_YOUR_EMAIL_AGAIN" /> :
            <TranslatedText stringKey="PLEASE_VERIFY_YOUR_EMAIL_TO_ACCESS" />}
          <NoticeSubtitle>
            <TranslatedText stringKey="AN_EMAIL_CONTAINING_VERIFICATION_INSTRUCTIONS_WAS_SENT_TO" />
            <Email> { email }.</Email>
          </NoticeSubtitle>
        </NoticeTextContainer>
        <CtaContainer>
          <CtaButton disabled={hasEmailBeenReSent} onClick={this.resendVerificationEmail}>
            <TranslatedText stringKey="RESEND_VERIFICATION_EMAIL" />
          </CtaButton>
        </CtaContainer>
      </FlexContainer>
    );
  }
}

