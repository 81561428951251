import Icon from 'components/admin2/Icon';
import {
  ACCENT_PRIMARY,
  HEADER_HEIGHT_WITH_UNITS,
  HIGHLIGHT_PRIMARY,
  SURFACE_1,
  SURFACE_2,
  TEXT_100,
} from 'style/constants';
import {
  TEXT_ELLIPSIS,
  TEXT_H5_BOLD,
  TEXT_LABEL7,
  mobileLandscapeOnly,
} from 'style/mixins';
import styled, { css } from 'styled-components';
import SidebarActionButton from '../SidebarActionButton';

export const AuthHeaderWrapper = styled.div<{
  isHeader?: boolean;
  isShopifyConnected?: boolean;
  isSidebarVisible?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${HEADER_HEIGHT_WITH_UNITS};
  padding-left: 20px;
  ${({ isSidebarVisible }) => isSidebarVisible && 'padding-right: 20px;'}
  ${mobileLandscapeOnly` padding-right: 20px; `}
    background-color: ${(props) =>
    props.isHeader ? 'transparent' : SURFACE_2(props)};
  color: ${(props) =>
    props.isHeader ? TEXT_100(props) : HIGHLIGHT_PRIMARY(props)};
  justify-content: space-between;
  ${({ isShopifyConnected }) =>
    !isShopifyConnected &&
    css`
      cursor: pointer;
      justify-content: flex-end !important;
    `}
`;

export const UserContainer = styled.div<{
  isHeader?: boolean;
  isShopifyConnected?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  ${({ isShopifyConnected }) =>
    !isShopifyConnected &&
    css`
      gap: 10px;
      flex-direction: row-reverse;
    `}
  ${({ isHeader }) =>
    isHeader &&
    css`
      margin-right: 15px;
      flex-direction: row-reverse;
    `};
`;

export const ProfPic = styled.div<{ src: string | null }>`
  background: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 44px;
  height: 44px;
  width: 44px;
  position: relative;
  border: 1px solid ${ACCENT_PRIMARY};
`;

export const ProfilePicContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)<any>`
  position: relative;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 44px;
  cursor: pointer;
`;

export const StyledSidebarActionButton = styled(SidebarActionButton)`
  height: ${HEADER_HEIGHT_WITH_UNITS};
  padding: 0 15px;
  ${(props) => props.isHeader && 'background-color: transparent'};
`;

export const UsernameWrapper = styled.div`
  ${TEXT_H5_BOLD}
  ${TEXT_ELLIPSIS}
    color: ${TEXT_100};
  cursor: pointer;
`;
