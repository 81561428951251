import React from 'react';
import { Container } from './styles';
import useTinyMce from './use-tiny-mce';
import { IRichTextEditorV2Props, PREDEFINED_TOOLBAR_BUTTONS } from './utils';
import styled, { css } from 'styled-components';
import Editor from './Editor';

const IFRAME_HEIGHT_OFFSET = 100;

const FullEditor: React.FC<IRichTextEditorV2Props> = ({
  data,
  onChange,
  maxLength = Infinity,
  readOnly,
  isEditMode,
  disableDebounce,
  onReady,
  ...props
}) => {
  const {
    handleBeforeAddUndo,
    handleUpdate,
    plugins,
    value,
    setup,
    iframeBodyHeight,
    toolbarHeight,
    toolbar1,
    ref,
    portals,
    onInit,
  } = useTinyMce({
    data,
    maxLength,
    onChange,
    disableDebounce,
    toolbarButtons: PREDEFINED_TOOLBAR_BUTTONS.fullEditor,
  });
  const [initialized, setInitialized] = React.useState(false);
  const height = iframeBodyHeight + (toolbarHeight || 0) + IFRAME_HEIGHT_OFFSET;

  if (height > IFRAME_HEIGHT_OFFSET && !initialized) {
    setInitialized(true);
    onReady?.();
  }

  return (
    <FullEditorContainer {...props} height={height} isEditing={isEditMode}>
      <Editor
        ref={ref}
        value={value}
        onEditorChange={handleUpdate}
        onBeforeAddUndo={handleBeforeAddUndo}
        isEditMode={isEditMode}
        inline={false}
        plugins={plugins}
        readOnly={readOnly}
        setup={setup}
        toolbar1={toolbar1}
        onInit={onInit}
      />
      {portals}
    </FullEditorContainer>
  );
};

const FullEditorContainer = styled(Container)<{ height: number; }>`
  & .tox-tinymce {
    min-height: 200px !important;
    ${({ height }) => height && css`height: ${height}px !important;`}
    & .tox-editor-header {
      & .tox-toolbar {
        & button[title="Align left"], button[title="Insert/edit link"], button[title="Undo"] {
          margin-left: auto;
        }
        width: 100%;
      }
    }
  }
`;

export default FullEditor;
