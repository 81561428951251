import styled from 'styled-components';
import Label from 'components/admin2/ui/Label';
import {
  ACCENT_PRIMARY,
  ALERT_ERROR,
  ALERT_CONFIRMATION,
} from 'style/constants';

export const SuccessLabel = styled(Label).attrs(props => ({
  color: ACCENT_PRIMARY(props),
  iconColor: ALERT_CONFIRMATION(props),
  icon: 'checkmarkCircle',
  iconSize: 9,
  iconSpacing: 6.5,
  textSize: 'h6',
  ...props,
}))``;

export const ErrorLabel = styled(Label).attrs(props => ({
  color: ALERT_ERROR(props),
  icon: 'warning',
  iconSize: 12,
  iconSpacing: 6,
  textSize: 'h5',
  ...props,
}))``;
