import { hasOwn } from 'utils';
import {
  CHAT_ID,
  IFRAME_ID,
  IMAGE_V2_ID,
  SCHEDULE_ID,
  SOCIAL_ID,
  CHANNEL_SELECT_ID,
  PEOPLE_ID,
  PLAYLIST_ID,
  TWITCH_CHAT_ID,
  MULTIPLE_CHOICE_ID,
  LEADERBOARD_ID,
  QUEST_ID,
  PLAY_BY_PLAY_ID,
  FORTNITE_DUOS_MATCH_ID,
  FORTNITE_SINGLES_MATCH_ID,
  FORTNITE_DUOS_LEADERBOARD_ID,
  FORTNITE_PLAY_BY_PLAY_ID,
  PUBG_MATCH_ID,
  SHOPIFY_ID,
  TIPS_ID,
  WELCOME_PANEL_ID,
} from 'components/objects/PanelV2/constants';

export const PANEL_BUTTON_WIDTH = 38;
export const PANEL_BUTTON_SEPARATION = 6;
export const DEFAULT_ICON = 'circle-empty';
export const WRAPPER_WIDTH = PANEL_BUTTON_WIDTH + (PANEL_BUTTON_SEPARATION * 2);

// TODO: Make sure this has everything
export const ICON_MAP = {
  [CHANNEL_SELECT_ID]: 'newspaper',
  [CHAT_ID]: 'chat',
  [FORTNITE_DUOS_LEADERBOARD_ID]: 'bar-chart',
  [FORTNITE_DUOS_MATCH_ID]: 'gamer',
  [FORTNITE_PLAY_BY_PLAY_ID]: 'gamer',
  [FORTNITE_SINGLES_MATCH_ID]: 'gamer',
  [IFRAME_ID]: 'link',
  [IMAGE_V2_ID]: 'pictures',
  [LEADERBOARD_ID]: 'bar-chart',
  [MULTIPLE_CHOICE_ID]: 'check',
  [PEOPLE_ID]: 'user',
  [PLAY_BY_PLAY_ID]: 'gamer',
  [PLAYLIST_ID]: 'list-bullet',
  [PUBG_MATCH_ID]: 'gamer',
  [QUEST_ID]: 'award',
  [SCHEDULE_ID]: 'calendar',
  [WELCOME_PANEL_ID]: 'check',
  [SHOPIFY_ID]: 'shopify',
  [SOCIAL_ID]: 'twitter',
  [TWITCH_CHAT_ID]: 'twitch',
  [TIPS_ID]: 'tips',
};

// /pkg/fontello/maestro-icons/public/demo.html
export const getIcon = (kind: keyof typeof ICON_MAP, loaded: boolean, fallback: string) => {
  if (!loaded) {
    return DEFAULT_ICON; // TODO: Decide on this
  }
  if (fallback !== undefined) {
    return fallback;
  }
  return hasOwn(ICON_MAP, kind) ? ICON_MAP[kind] : DEFAULT_ICON;
};
