import React, { FocusEventHandler, forwardRef } from 'react';
import { hasOwn } from 'utils';
import IconFontello from './IconFontello';
import IconSvg, { SVG_ICON_MAP } from './IconSvg';

export { IconSvg };

export interface IProps {
  admin?: boolean;
  backgroundColor?: string;
  className?: string;
  color?: string;
  'data-testid'?: string;
  keyValue?: string;
  name: string;
  onBlur?: FocusEventHandler;
  onClick?: (e?: any) => void;
  onFocus?: FocusEventHandler;
  style?: Record<string, string>;
  tabIndex?: number;
  title?: string;
}

const Icon = forwardRef<HTMLSpanElement, IProps>(({
  admin,
  className,
  color,
  backgroundColor,
  name,
  onBlur,
  onClick,
  onFocus,
  style,
  tabIndex,
  title,
  keyValue,
  ...domProps
}, ref) => {
  if (hasOwn(SVG_ICON_MAP, name)) {
    return (
      <IconSvg
        keyValue={keyValue || ''}
        admin={admin}
        className={className}
        color={color}
        data-testid={domProps['data-testid']}
        backgroundColor={backgroundColor}
        name={name}
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
        tabIndex={tabIndex}
        ref={ref}
      />
    );
  }
  return (
    <IconFontello
      admin={admin}
      className={className}
      color={color}
      name={name}
      onClick={onClick}
      ref={ref}
      style={style}
      title={title}
      {...domProps}
    />
  );
});

Icon.defaultProps = {
  admin: false,
  className: '',
  style: {},
  title: undefined,
};

export default Icon;
