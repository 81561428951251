import React, { MouseEventHandler, useMemo } from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import moment from 'moment-timezone';
import CalendarContainer from './Container';
import {
  ButtonsGrid,
  GridButton,
} from '../styles';
import { CalendarStateProps, DayCalendarProps, RangePickerProps } from '../interfaces';
import { useEffect } from 'react';

interface MonthPickerProps extends CalendarStateProps, RangePickerProps, DayCalendarProps {
  monthsLong: string[];
  monthsShort: string[];
}

export default function MonthPicker({
  admin,
  calendarMonth,
  calendarYear,
  endDate,
  isMonthYearSelectorVisible,
  monthsLong,
  monthsShort,
  onDatesChange,
  setCalendarMonth,
  setCalendarYear,
  startDate,
  toggleMonthYearSelector,
}: MonthPickerProps){
  const navTitle = useMemo(() => (
    `${monthsLong[calendarMonth]} ${calendarYear}`
  ), [calendarYear, calendarMonth]);

  const nextYear = () => {
    setCalendarYear(year => year + 1);
  };

  const prevYear = () => {
    const prev = calendarYear - 1;
    if (prev > 0) {
      setCalendarYear(prev);
    }
  };

  const selectMonth: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!onDatesChange) {
      return;
    }
    const monthIndex = parseInt(e.currentTarget.dataset.index || '0', 10);
    const start = moment([calendarYear, monthIndex, 1]).startOf('day');
    onDatesChange(
      start,
      moment([calendarYear, monthIndex, start.daysInMonth()]).endOf('day'),
    );
  };

  useEffect(() => {
    if (startDate || endDate) {
      setCalendarMonth(startDate?.month() || endDate?.month() || calendarMonth);
    }
  }, [startDate?.valueOf(), endDate?.valueOf()]);

  return (
    <CalendarContainer
      admin={admin}
      buttonText={calendarYear}
      containerTitleKey="ADMIN_LABEL_SELECT_MONTH"
      isMonthYearSelectorVisible={isMonthYearSelectorVisible}
      navNext={nextYear}
      navPrev={prevYear}
      navTitle={navTitle}
      onActionClick={toggleMonthYearSelector}
    >
      <ButtonsGrid>
        <NonKeyedListMapper list={monthsShort}>
          {(key: string, month: string, index: number) => (
            <GridButton
              admin={admin}
              data-index={index}
              isActive={index === calendarMonth}
              key={key}
              onClick={selectMonth}
            >
              {month}
            </GridButton>
          )}
        </NonKeyedListMapper>
      </ButtonsGrid>
    </CalendarContainer>
  );
}
