import useLexicalEditable from '@lexical/react/useLexicalEditable';
import * as React from 'react';
import { ReactPortal } from 'react';
import { createPortal } from 'react-dom';
import { TableCellActionMenuContainer } from './TableActionMenuButton';
import { TableAddRowOrColumnButton } from './TableAddRowOrColumnButton';

export default function TableActionMenuPlugin({
  anchorElem = document.body,
  sticking,
}: {
  anchorElem?: HTMLElement;
  sticking?: boolean;
}): null | ReactPortal {
  const isEditable = useLexicalEditable();
  return createPortal(
    isEditable ? (
      <>
        {/* Render Row/Column options menu */}
        <TableCellActionMenuContainer
          anchorElem={anchorElem}
          orientation="vertical"
          sticking={sticking}
        />
        <TableCellActionMenuContainer
          anchorElem={anchorElem}
          orientation="horizontal"
          sticking={sticking}
        />
        {/* Render buttons to add rows/columns */}
        <TableAddRowOrColumnButton
          orientation="horizontal"
          anchorElem={anchorElem}
          sticking={sticking}
        />
        <TableAddRowOrColumnButton
          orientation="vertical"
          anchorElem={anchorElem}
          sticking={sticking}
        />
      </>
    ) : null,
    anchorElem,
  ) as React.ReactPortal;
}
