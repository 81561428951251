import React, { Children, cloneElement, UIEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import range from 'lodash/range';
import clamp from 'lodash/clamp';
import debounce from 'lodash/debounce';
import useCooldown from 'hooks/use-cooldown';
import { closestNumber, styleVisibleElements } from 'utils';
import { ScrollNavigationProps } from './interfaces';
import {
  ScrollNavigationContainer,
  ItemWrapper,
} from './styles';

export type { StyleProps, ScrollNavigationItemProps, ScrollNavigationProps } from './interfaces';

const scrollDelay = 300;

export default function ScrollNavigation<T>({
  children,
  className,
  pages,
  snapAcceleration = 15,
  wrapperOffset = 0,
  wrapperSeparation = 0,
}: ScrollNavigationProps<T>) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const accelerationRef = useRef(0);
  const ignoreSnap = useRef(false);
  const { finishCooldown, isCooldownFinished } = useCooldown(2e3);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, scrollWidth: 0 });
  const [pageIndex, setPageIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);


  const maxSnapAcceleration = useMemo(() => (
    Math.max(snapAcceleration, 2)
  ), [snapAcceleration]);

  const scrollOffset = useMemo(() => (
    (wrapperOffset * 2) - (wrapperSeparation * 2)
  ), [wrapperOffset, wrapperSeparation]);

  const scrollStep = useMemo(() => (
    dimensions.width - scrollOffset
  ), [dimensions.width, scrollOffset]);

  const snapPoints = useMemo(() => {
    const scrollHalfOffset = scrollOffset / 2;
    const points = [0, ...range(scrollStep - scrollHalfOffset, dimensions.scrollWidth - scrollHalfOffset, scrollStep)];
    points[points.length - 1] -= scrollHalfOffset;
    return points;
  }, [dimensions.scrollWidth, scrollOffset, scrollStep]);

  const {
    isFirstPage,
    isLastPage,
   } = useMemo(() => ({
    isFirstPage: !pageIndex,
    isLastPage: pageIndex === pages.length - 1,
   }), [pageIndex, pages.length]);

   const handleSnap = (scrollContainer: HTMLDivElement) => {
    if (!isCooldownFinished() || ignoreSnap.current) {
      return;
    }
    const { scrollLeft } = scrollContainer;
    const newOffset = closestNumber([...snapPoints], scrollLeft);
    const newIndex = snapPoints.findIndex(point => point === newOffset);
    const decimal = (newOffset ? parseFloat((scrollLeft / newOffset).toFixed(2)) : scrollLeft) - 1;
    setPageIndex(currentIndex => clamp(newIndex === currentIndex && decimal ? newIndex + (decimal < 0 ? -1 : 1) : newIndex, 0, pages.length - 1));
  };

  const endScroll = useCallback(debounce(() => {
    setIsScrolling(false);
  }, 1e3), [setIsScrolling]);

  const resetOverflow = () => {
    if (containerRef.current) {
      containerRef.current.style.overflow = '';
    }
  };

  const scrollTo = (offset = 0) => {
    if (!containerRef.current) {
      return;
    }
    // Fix chromuim browsers retaining user's scroll acceleration
    // and messing with the scroll position set in scrollTo
    containerRef.current.style.overflow = 'hidden';
    containerRef.current.scrollTo({ left: offset, behavior: 'smooth' });
    setTimeout(() => {
      resetOverflow();
    }, scrollDelay);
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = ({ currentTarget: scrollContainer }) => {
    const lastSnapPoint = snapPoints[snapPoints.length - 1];
    if (!isScrolling) {
      setIsScrolling(true);
    }

    styleVisibleElements({
      horizontal: true,
      scrollContainer,
    }, (style, isVisible) => {
      style.opacity = isVisible ? '' : '0.5';
    });

    const acceleration = scrollContainer.scrollLeft - accelerationRef.current;
    accelerationRef.current = scrollContainer.scrollLeft;

    if (
      !isMouseDown && (
        Math.abs(acceleration) < maxSnapAcceleration ||
        scrollContainer.scrollLeft <= 0 ||
        scrollContainer.scrollLeft >= lastSnapPoint - 50
      )
    ) {
      handleSnap(scrollContainer);
    }
    endScroll();
  };

  const handleMouseDown = () => {
    finishCooldown();
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const goToNextPage = () => {
    ignoreSnap.current = true;
    if (pageIndex + 1 < pages.length) {
      setPageIndex(page => page + 1);
    }
  };

  const goToPage = (page: number) => {
    if (page <= 0 && page < pages.length) {
      setPageIndex(page);
    }
  };

  const goToPrevPage = () => {
    ignoreSnap.current = true;
    if (pageIndex - 1 >= 0) {
      setPageIndex(page => page - 1);
    }
  };

  const getContainerRef = (node: HTMLDivElement | null) => {
    containerRef.current = node;
    if (!node) {
      return;
    }
    const width = node.getBoundingClientRect().width;
    const { scrollWidth } = node;
    if (width !== dimensions.width || scrollWidth !== dimensions.scrollWidth) {
      setDimensions({ width, scrollWidth });
    }
  };

  useEffect(() => {
    scrollTo(snapPoints[pageIndex]);
    setTimeout(() => ignoreSnap.current = false, scrollDelay);
  }, [pageIndex]);

  useEffect(() => {
    if (containerRef.current && !isMouseDown && !isScrolling) {
      containerRef.current.style.overflow = '';
      handleSnap(containerRef.current);
    }
  }, [isMouseDown, isScrolling]);

  return (
    <ScrollNavigationContainer
      center={pages.length === 1}
      className={className}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onScroll={handleScroll}
      onTouchEnd={handleMouseUp}
      onTouchMove={resetOverflow}
      onTouchStart={handleMouseDown}
      ref={getContainerRef}
      wrapperSeparation={wrapperSeparation}
    >
      <NonKeyedListMapper list={pages}>
        {(key: string, pageContent: T, index: number) => (
          <ItemWrapper
            isCurrentPage={pageIndex === index}
            key={key}
            wrapperOffset={wrapperOffset}
            wrapperSeparation={wrapperSeparation}
          >
            {cloneElement(Children.only(children), {
              isCurrentPage: pageIndex === index,
              isFirstPage,
              isLastPage,
              isNextPage: pageIndex === index - 1,
              isPrevPage: pageIndex === index + 1,
              isScrolling,
              pageContent,
              goToNextPage,
              goToPage,
              goToPrevPage,
              pageIndex,
            })}
          </ItemWrapper>
        )}
      </NonKeyedListMapper>
    </ScrollNavigationContainer>
  );
}
