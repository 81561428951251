import { ACCENT_PRIMARY, SURFACE_6 } from 'style/constants';
import styled from 'styled-components';

export const Color = styled.div<{ color: string; isDisabled?: boolean; selected?: boolean; }>`
  position: relative;
  width: 31.44px;
  height: 31.44px;
  padding: 3px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid ${({ selected }) => selected ? ACCENT_PRIMARY : SURFACE_6};

  cursor: pointer;

  &::before {
    content: "";
    background: ${({ color }) => color};
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  ${({ isDisabled }) => isDisabled && `
    opacity: 0.2;
    user-select: none;
    cursor: default;
  `}
`;
