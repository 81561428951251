import React from 'react';
import { IVideo } from 'models';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection } from 'lexical';
import { ToolbarButton } from '../../styles';
import { $createVideoNode } from '../../nodes/VideoNode';
import { useDispatch } from 'react-redux';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import videoLibraryModalTypes from 'components/modals/videoLibraryModalTypes';
import videoLibraryIcon from 'assets/icons/rich-text-editor-v3/videoLibrary.svg';

const VideoPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const dispatch = useDispatch();

  const onClick = React.useCallback(() => {
    dispatch(
      showModal({
        kind: ModalKinds.videoLibraryV2,
        data: {
          type: videoLibraryModalTypes.SCHEDULE_EDIT,
          onSelectItem: (video: IVideo) => {
            editor.update(() => {
              const selection = $getSelection();

              if (!selection) {
                return;
              }

              selection.insertNodes([$createVideoNode(video._id!)]);
            });
          },
        },
      }),
    );
  }, [editor]);

  return (
    <ToolbarButton
      aria-label="Insert Video"
      active={false}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: videoLibraryIcon }}
    />
  );
};

export default VideoPlugin;
