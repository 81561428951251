import React from 'react';
import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { rgba } from 'colors';
import { FLEX_CENTER, HIDE_SCROLLBAR, TEXT_LABEL3 } from 'style/mixins';
import {
  ACCENT_PRIMARY,
  SURFACE_4,
  TEXT_100,
  HIGHLIGHT_PRIMARY,
  HIGHLIGHT_SECONDARY,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
} from 'style/constants';
import { PANEL_BUTTON_WIDTH, PANEL_BUTTON_SEPARATION } from './utils';

interface StyleProps {
  active?: boolean;
  isActive?: boolean;
  left?: boolean;
  minWidth?: number;
  showBack?: boolean;
  showMore?: boolean;
  visible?: boolean;
  width?: number;
}

export const Container = styled.div`
  background-color: ${SURFACE_4};
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  ${HIDE_SCROLLBAR}
  overflow: auto hidden;
  position: relative;
`;

export const PanelButton = styled(Icon).attrs<StyleProps>((props) => ({
  color: HIGHLIGHT_PRIMARY(props),
}))<StyleProps>`
  ${TEXT_LABEL3}
  ${FLEX_CENTER}
  color: ${HIGHLIGHT_PRIMARY};
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  flex: 1;
  ${({ isActive }) => isActive ? css`
    background: ${props => props.theme.site.colors.surface5};
    color: ${HIGHLIGHT_PRIMARY};
  ` : css`
    opacity: 0.5;
  `}
  & > svg {
    width: 18px;
    height: 18px;
  }
`;

export const PanelButtonWrapper = styled.div<StyleProps>`
  ${FLEX_CENTER}
  flex: 1;
  position: relative;
  padding: 8px 0;
  margin: 0 ${PANEL_BUTTON_SEPARATION}px;
  min-width: ${PANEL_BUTTON_WIDTH}px;
  ${({ active }) => active && css`
    border-bottom: 2px solid ${ACCENT_PRIMARY};
  `};
`;

export const PanelButtonTransform = styled.div<StyleProps>`
  transition: transform 0.25s ease-out;
  flex: 1;
  min-width: ${({ minWidth }) => minWidth}px;
`;

const StyledMoreButton = styled(PanelButton)<StyleProps>`
  position: absolute;
  ${({ left }) => left ? 'left' : 'right'}: 0;
  top: 0;
  bottom: 0;
  border-radius: 0;
  width: ${({ visible }) => visible ? 36 : 0 }px;
  overflow: hidden;
  transition: width 0.5s;
  opacity: 1;
  height: 100%;
  z-index: 10;
  background: transparent;
  color: ${props => rgba(TEXT_100(props), 0.5)}
`;

interface StyledMoreButtonProps {
  children: JSX.Element;
  isMobile: boolean;
  left?: boolean;
  onClick: () => void;
  title: string;
  visible?: boolean;
}

export const MoreButton = (props: StyledMoreButtonProps) =>
  <StyledMoreButton {...props as any} />;

export const IconWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const Badge = styled.div.attrs(() => ({
  children: '!',
}))`
  background: ${ACCENT_PRIMARY};
  color: ${TEXT_CONTRAST_ON_ACCENT_PRIMARY};
  border-radius: 50%;
  font-family: sans-serif;
  font-size: 10px;
  line-height: 10px;
  width: 11px;
  height: 11px;
  position: absolute;
  right: -5px;
  top: -5px;
`;

export const StyledIcon = styled(Icon).attrs((props) => ({
  color: HIGHLIGHT_PRIMARY(props),
}))<StyleProps>`
  ${TEXT_LABEL3}
  color: ${HIGHLIGHT_PRIMARY};
  & > svg {
    width: 18px;
    height: 18px;
    ${({ isActive }) => !isActive && css`
    & > path {
      fill: ${HIGHLIGHT_SECONDARY};
    }
  `}
  }
`;

export const Wrapper = styled.div<StyleProps>`
  position: relative;
  display: flex;
  flex: 1;
  overflow: visible;
  max-width: 100%;
  min-width: 0;
  transition: padding-left 0.5s, padding-right 0.5s;
`;
