import React, { ReactNode } from 'react';
import { DecoratorNode, NodeKey, SerializedLexicalNode } from 'lexical';
import Player from 'components/Video/video-player/Player';
import { PlayerSpot } from 'services/video';
import { useCanIPlay } from 'services/caniplay/useCanIPlay';
import Skeleton from 'components/ui/Skeleton';

export type SerializedVideoNode = SerializedLexicalNode & {
  type: 'video';
  videoId: string;
};

export class VideoNode extends DecoratorNode<ReactNode> {
  private videoId: string;

  constructor(id: string, key?: NodeKey) {
    super(key);
    this.videoId = id;
  }

  static clone(node: VideoNode): VideoNode {
    return new VideoNode(node.videoId, node.__key);
  }

  static getType() {
    return 'video' as const;
  }

  static importJSON(serializedNode: SerializedVideoNode): VideoNode {
    return new VideoNode(serializedNode.videoId);
  }

  createDOM(): HTMLElement {
    const videoDiv = document.createElement('div');
    videoDiv.style.width = '100%';
    videoDiv.style.aspectRatio = '16 / 9';

    return videoDiv;
  }

  decorate(): ReactNode {
    return <WithCanIPlay videoId={this.videoId} />;
  }

  exportJSON(): SerializedVideoNode {
    return {
      videoId: this.videoId,
      type: VideoNode.getType(),
      version: 1,
    };
  }

  updateDOM(): false {
    return false;
  }
}

export function $createVideoNode(id: string): VideoNode {
  return new VideoNode(id);
}

export const WithCanIPlay = ({ videoId }: { videoId: string }) => {
  const result = useCanIPlay(videoId);

  if (!result?.video) {
    return <Skeleton type="video" />;
  }

  return (
    <Player
      spot={PlayerSpot.tv}
      scheduledVideoStartTime={null}
      video={result?.video}
      loop={false}
      autoPlay={false}
    />
  );
};
