import { connect } from 'react-redux';
import { showModal } from 'services/modals';
import { trackHeader } from 'services/insights';
import { getParentOrigin } from 'services/iframe/selectors';
import { getTimeOffset } from 'services/app/selectors';
import Countdown from './view';

const mapStateToProps = state => ({
  parentOrigin: getParentOrigin(state),
  serverOffset: getTimeOffset(state),
});

const mapDispatchToProps = dispatch => ({
  onAddToCalendar: data => dispatch(showModal({ data, kind: 'addToCalendar' })),
  onEngage: data => dispatch(trackHeader('engage', data, {})),
});

export default connect(mapStateToProps, mapDispatchToProps)(Countdown);
