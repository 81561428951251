import React from 'react';
import { Container, NeedHelpIcon, NeedHelpText } from './styles';
import { useAdminTranslation } from 'hooks/use-translation';

const NeedHelpButton: React.FC<{}> = () => {
  const { t } = useAdminTranslation();
  return (
    <Container href="https://help.maestro.io/" target="_blank">
      <NeedHelpText>
        {t('ADMIN_LABEL_NEED_HELP_WITHOUT_QUESTION_MARK')}
      </NeedHelpText>
      <NeedHelpIcon />
    </Container>
  );
};

export default NeedHelpButton;
