import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { Muted, StyledIcon, Container } from './styles';

interface IProps {
  handleClick: () => void;
  mobile: boolean;
}

const MuteWarning = ({ mobile, handleClick }: IProps ) => {
  return (
    <Muted data-testid="clickToUnmuteButton" onClick={handleClick}>
      <Container>
        <StyledIcon color="black" name="mute" />
        <TranslatedText stringKey={mobile ? 'TAP_TO_UNMUTE' : 'CLICK_TO_UNMUTE'} />
      </Container>
    </Muted>
  );
};

export default MuteWarning;
