import styled from 'styled-components';
import {
  ADMIN_SURFACE_5,
  ADMIN_TEXT_200,
  ADMIN_ACCENT_PRIMARY,
  TEXT_100,
} from 'style/constants';
import Icon from 'components/ui/Icon';

export const CardContainer = styled.div<{ selected: boolean; width?: string }>`
  position: relative;
  display: flex;
  width: ${({ width }) => ( width ? width : '291px')};
  height: 197px;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  background: ${ADMIN_SURFACE_5};
  cursor: pointer;
  border: 2px solid
    ${({ selected }) => (selected ? ADMIN_ACCENT_PRIMARY : ADMIN_SURFACE_5)};

  svg {
    width: 35px;
  }

  &:hover {
    border: 2px solid ${ADMIN_ACCENT_PRIMARY};
  }
`;

export const CheckMarkIcon = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 15px;

  svg {
    height: 23px;
  }

  svg circle {
    fill: ${ADMIN_ACCENT_PRIMARY};
  }

  svg path {
    fill: ${TEXT_100};
  }
`;

export const GenericIcon = styled(Icon)`
  width: 37px;
  height: 28px;
`;

export const CardTitle = styled.strong`
  font-size: 19px;
  line-height: 19px;
  font-style: normal;
  margin-top: 25px;
`;

export const CardDescription = styled.p`
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
  color: ${ADMIN_TEXT_200};
`;
