import React from 'react';
import ChannelLiveStateProvider from 'components/ui/ChannelLiveStateProvider';
import LiveBadge from '../LiveBadge';

type ChannelLiveBadgeProps = {
  className?: string;
  id: string;
  onIsLiveUpdate?: (isLive: boolean) => void;
};

export default function ChannelLiveBadge(props: ChannelLiveBadgeProps) {
  const { id, onIsLiveUpdate } = props;

  if (!id) {
    return null;
  }

  return (
    <ChannelLiveStateProvider id={id}>
      {(isLive) => {
        if (onIsLiveUpdate) {
          onIsLiveUpdate(isLive);
        }
        return isLive && <LiveBadge.Default />;
      }}
    </ChannelLiveStateProvider>
  );
}
