import styled from 'styled-components';
import { VideoObject as Video } from 'components/Video/video-object';
import { desktopOnly, mobileOnly } from 'style/mixins';
import { isSafari } from 'utils';

export const StyledVideo = styled(Video)<{ isEmbed?: boolean, isVideoEmbed: boolean }>`
  flex-grow: 1;
  ${desktopOnly`
    ${({ isVideoEmbed }) => isVideoEmbed ? 'background: black;' : 'border-radius: 5px;'}
    overflow: hidden;
    min-height: 0;
    margin: ${isSafari ? 0 : '0 0 auto 0'} !important;
  `}
  ${mobileOnly`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin: 0;
  `}
`;
