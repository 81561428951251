import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { DEFAULT_VIDEO_PLACEHOLDER_ICON } from 'shared/constants';
import { MAESTRO_WHITE } from 'style/constants';
import { supportsAspectRatioCSS } from 'utils';

type ThumbnailProps = PropsWithChildren<{
  className?: string;
  radius?: string;
  renderPlayIcon?: boolean;
  src?: string | null;
} & ({ height?: never, width?: string } | { height?: string, width?: never })>;

export default function Thumbnail({
  children,
  className,
  height,
  radius = '10px',
  renderPlayIcon,
  src,
  width,
}: ThumbnailProps) {
  return (
    <ThumbnailContainer
      className={className}
      radius={radius}
      src={src || DEFAULT_VIDEO_PLACEHOLDER_ICON}
      width={width ?? height ? width : '100%'}
      height={height}
    >
      {renderPlayIcon && <PlayIcon />}
      {children}
    </ThumbnailContainer>
  );
}

const ThumbnailContainer = styled.div<{
  height?: string;
  radius: string;
  src: string;
  width?: string;
}>`
  background-image: url("${({ src }) => src}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${supportsAspectRatioCSS ? 'aspect-ratio: 16 / 9;' : 'padding-bottom: 56.25%;'}
  position: relative;
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
  border-radius: ${({ radius }) => radius};
`;

const PlayIcon = styled(Icon).attrs(() => ({
  color: MAESTRO_WHITE, // Video control icon colors should be always fixed white
  name: 'playLarge',
}))`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  & svg {
    width: 29px;
    height: 29px;
  }
`;
