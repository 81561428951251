import t from 'prop-types';
import { useSelector } from 'hooks';
import { isQuestPanelClaimable } from 'services/quest/selectors';

const propTypes = {
  children: t.func.isRequired,
  panelId: t.string.isRequired,
  panelType: t.string.isRequired,
};

export default function PanelBadgeProvider({ children: render, panelId }) {
  const hasLegacyBadge = useSelector(state => (
    isQuestPanelClaimable(state, panelId)
  ));

  return render(hasLegacyBadge);
}

PanelBadgeProvider.propTypes = propTypes;
