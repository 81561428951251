import React from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import { useAdminTranslation } from 'hooks/use-translation';
import { BrandImage, MainInfo, PaymentMethodNumber, PaymentMethodType, PrimaryLabel, StyledButton, Wrapper } from './styles';
import { BRAND_MAP } from '../constants';
import { useSelector } from 'react-redux';
import { getDefaultPayment } from 'services/billing';

interface ISinglePaymentMethodCardProps {
  onChange?: () => void;
  paymentMethod: PaymentMethod
}

const SinglePaymentMethodCard: React.FC<ISinglePaymentMethodCardProps> = ({ onChange, paymentMethod }) => {
  const defaultPaymentMethodId = useSelector(getDefaultPayment);
  const { t } = useAdminTranslation();

  const branchLogo = React.useMemo(() => {
    if (!paymentMethod?.card?.brand) {
      return null;
    }
    return BRAND_MAP[paymentMethod.card.brand.toLowerCase()];
  }, [paymentMethod]);

  const isPrimary = paymentMethod.id === defaultPaymentMethodId;

  return (
    <Wrapper>
      {branchLogo && <BrandImage src={branchLogo} />}
      <MainInfo>
        <PaymentMethodNumber>{t('ADMIN_LABEL_CARD_ENDING_IN')}&nbsp;{paymentMethod?.card?.last4}</PaymentMethodNumber>
        <PaymentMethodType>
          {t('CREDIT_CARD')}
          &nbsp;
          {isPrimary && <PrimaryLabel>{t('PAYMENT_METHOD_LABEL_PRIMARY')}</PrimaryLabel>}
        </PaymentMethodType>
      </MainInfo>
      {onChange && <StyledButton onClick={onChange}>{t('CHANGE')}</StyledButton>}
    </Wrapper>
  );
};

export default SinglePaymentMethodCard;
