import { ToolbarButton } from 'components/ui/RichTextEditorV3/styles';
import textMoreIcon from 'assets/icons/rich-text-editor-v3/textMore.svg';
import React from 'react';
import useFloatMenu from 'hooks/use-float-menu';
import { Wrapper } from './styles';
import LineHeightOptions from './LineHeightOptions';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, LexicalEditor, createCommand } from 'lexical';
import { $patchStyleText } from '@lexical/selection';
import { getRectPositionCheckingBounds } from 'dom-utils';

export const APPLY_LINE_HEIGHT_COMMAND = createCommand<{ lineHeight: string; }>('APPLY_LINE_HEIGHT_COMMAND');

const applyLineHeight = (editor: LexicalEditor, lineHeight: string) => {
  editor.update(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }

    $patchStyleText(selection, {
      'line-height': lineHeight,
      'display': lineHeight === 'unset' ? 'unset' : 'block',
    });
  });
};

const useLineHeightPluginRegisters = () => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    return editor.registerCommand(APPLY_LINE_HEIGHT_COMMAND, ({ lineHeight }) => {
      applyLineHeight(editor, lineHeight);
      return true;
    }, COMMAND_PRIORITY_LOW);
  }, []);
};

const LineHeightPlugin = () => {
  useLineHeightPluginRegisters();

  const setMenuPosition = React.useCallback((menu: HTMLDivElement) => {
    const result = getRectPositionCheckingBounds({
      elem: menu,
      margin: 10,
    });

    menu.style.top = '55px';
    menu.style.left = `${result.left}px`;
    menu.style.position = 'absolute';
    menu.style.zIndex = '1';
  }, []);
  const { containerRef, isOpen, positionMenu, toggleMenu } = useFloatMenu(setMenuPosition);

  return (
    <Wrapper ref={containerRef}>
      <ToolbarButton
        active={isOpen}
        onClick={toggleMenu}
        dangerouslySetInnerHTML={{ __html: textMoreIcon }}
      />
      {isOpen && (
        <div ref={positionMenu}>
          <LineHeightOptions />
        </div>
      )}
    </Wrapper>
  );
};

export default LineHeightPlugin;
