import React from 'react';
import styled from 'styled-components';
import { LIVE_INDICATOR_RED, MAESTRO_WHITE } from 'style/constants';
import { useAdminTranslation } from 'hooks/use-translation';
import Dot from './Dot';
import { ADMIN_TEXT_LABEL_XS_BOLD } from 'style/design-system/textStyles';

const Text = styled.span`
  text-align: center;
  ${ADMIN_TEXT_LABEL_XS_BOLD}
  color: ${MAESTRO_WHITE};
  text-transform: uppercase;
`;

const Container = styled.div`
  display: flex;
  height: 12px;
  padding: 1px 5px;
  align-items: center;
  gap: 4px;
  border-radius: 3.322px;
  background: ${LIVE_INDICATOR_RED};
`;

const WhiteDot = styled(Dot)`
  background: ${MAESTRO_WHITE};
`;

const Default: React.FC = () => {
  const { t } = useAdminTranslation();
  return (
    <Container className="live">
      <WhiteDot />
      <Text>{t('LIVE')}</Text>
    </Container>
  );
};

export default Default;
