import React, { HTMLAttributes } from 'react';
import ModalPortal from 'components/modals/ModalPortal';
import ColorPicker from 'components/admin2/ColorPicker';
import { Color } from './styles';
import { TI18nKey } from 'components/i18n/TranslatedText/i18nKey';


interface IColorOptionProps extends HTMLAttributes<HTMLDivElement> {
  color: string;
  colorOptionRef?: React.RefObject<HTMLDivElement>;
  colorPicker?: {
    backgroundColor?: string;
    color: string;
    isOpen: boolean;
    onClose: () => void;
    onResult: (color: string) => void;
    parentRef: React.RefObject<HTMLDivElement>;
    titleKey: TI18nKey;
    useModalPortal: boolean;
  };
  isDisabled?: boolean;
  selected?: boolean;
}

const ColorOption: React.FC<IColorOptionProps> = ({ colorOptionRef, color, selected, colorPicker, children, isDisabled, ...rest }) => {
  const ColorPickerWrapper = colorPicker?.useModalPortal ? ModalPortal : React.Fragment;

  return (
    <>
      <Color
        {...rest}
        ref={colorOptionRef}
        selected={selected}
        color={color}
        isDisabled={isDisabled}
      >
        {children}
      </Color>
      {
        !!colorPicker?.parentRef && (
          <ColorPickerWrapper>
            <ColorPicker
              color={colorPicker.color}
              isOpen={colorPicker.isOpen}
              onClose={colorPicker.onClose}
              onResult={colorPicker.onResult}
              parentRef={colorPicker.parentRef}
              titleKey={colorPicker.titleKey}
              backgroundColor={colorPicker.backgroundColor}
            />
          </ColorPickerWrapper>
        )
      }
    </>
  );
};

export default ColorOption;
