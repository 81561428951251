import React from 'react';
import FormInput from 'components/ui/v2/Inputs';
import { StyledCardExpiryElement } from './styles';
import { StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js';
import { useAdminTranslation } from 'hooks/use-translation';
import useToggle from 'hooks/use-toggle';

type ExpiryProps = {
  error: string | undefined;
  onChange: (error: string | undefined, complete: boolean) => void;
};

const Expiry: React.FC<ExpiryProps> = ({ onChange, error }) => {
  const { t } = useAdminTranslation();
  const [focused, toggle] = useToggle(false);
  const handleExpiryChange = React.useCallback((event: StripeCardExpiryElementChangeEvent) => {
    onChange(event.error?.message, event.complete);
  }, [onChange]);

  return (
    <FormInput.Root error={!!error}>
      <FormInput.FieldSet focused={focused}>
        <FormInput.Legend>{t('EXP')}</FormInput.Legend>
        <StyledCardExpiryElement onChange={handleExpiryChange} onFocus={toggle} onBlur={toggle} />
      </FormInput.FieldSet>
      {error && <FormInput.SupportingText>{error}</FormInput.SupportingText>}
    </FormInput.Root>
  );
};

export default Expiry;
