export const isExternal = (href: string): boolean => {
  // we arn't going anywhere might aswell stick around
  if (!href) {
    return false;
  }

  // Protocol-less external URLs
  if (/^\/\//.test(href)) {
    return true;
  }

  // media
  if (/media\/[0-9a-f]{24}/.test(href)) {
    return false;
  }

  // anchor tags
  if (/^#/.test(href)) {
    return false;
  }

  // Full URLs without protocol e.g. '//www.google.com'
  if (/^\/\//.test(href)) {
    return true;
  }

  // starts with /
  if (/^\//.test(href)) {
    return false;
  }
  return true;
};
