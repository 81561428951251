import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  MAESTRO_WHITE,
  ADMIN_ALERT_WARNING,
  ADMIN_ALERT_ERROR,
  ADMIN_ALERT_CONFIRMATION,
  TITLE_FONT_FAMILY,
} from 'style/constants';
import {
  TEXT_LABEL1,
} from 'style/mixins';

/**
|--------------------------------------------------
| Use: <HexIcon type="warning/success/error" />
| If no type is provided, icon defaults to exclamation
| point and AdminTextColorContent for color
|--------------------------------------------------
*/

const ICON_TYPES = {
  success: '✓',
  warning: '!',
};

const ICON_COLOR = {
  error: ADMIN_ALERT_ERROR,
  success: ADMIN_ALERT_CONFIRMATION,
  warning: ADMIN_ALERT_WARNING,
};

const iconColorFromProps = ({ type }) => ICON_COLOR[type] || MAESTRO_WHITE;
const iconFromProps = ({ type }) => ICON_TYPES[type] || ICON_TYPES.warning;

const rectCss = css`
  border-left: solid 2.5px ${iconColorFromProps};
  border-right: solid 2.5px ${iconColorFromProps};
  height: 28px;
  position: absolute;
  width: 50px;
`;

const LeftRect = styled.div`
  ${rectCss}
  transform: rotate(-60deg);
`;

const RightRect = styled.div`
  ${rectCss}
  transform: rotate(60deg);
`;

const CenterRect = styled.div`
  ${rectCss}
  ${TEXT_LABEL1}
  align-items: center;
  color: ${iconColorFromProps};
  display: flex;
  font-family: ${TITLE_FONT_FAMILY};
  justify-content: center;
`;

const HexIconContainer = styled.div`
  height: 50px;
  margin: 10px 0 10px 0;
  position: relative;
  width: 50px;
`;

const HexIcon = props => (
  <HexIconContainer>
    <LeftRect {...props} />
    <CenterRect {...props}>
      { iconFromProps(props) }
    </CenterRect>
    <RightRect {...props} />
  </HexIconContainer>
);

HexIcon.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'success']),
};

HexIcon.defaultProps = {
  type: null,
};

export default HexIcon;
