import styled from 'styled-components';
import {
  mobileOnly,
  desktopOnly,
  HIDE_SCROLLBAR,
  THIN_SCROLL_BAR,
} from 'style/mixins';
import { bw, darken, rgba } from 'colors';
import { SURFACE_1, SURFACE_3, TEXT_100 } from 'style/constants';

export const Container = styled.div`
  ${THIN_SCROLL_BAR}
  display: flex;
  flex-direction: column;
  ${desktopOnly`
    max-width: 536px;
    min-width: 536px;
  `};
  height: 100%;
  padding: 40px;
  background: ${SURFACE_3};
  overflow-y: scroll;
  ${mobileOnly`
    width: 100%;
    padding: 30px;
  `};
  ${HIDE_SCROLLBAR};
`;

export const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => darken(SURFACE_3(props), 0.1)};
  color: ${TEXT_100};
  border-radius: 5px;
  height: 40px;
  padding: 13px 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
`;

export const SubscriptionHeader = styled.header`
  color: ${TEXT_100};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SubscriptionName = styled.strong`
  font-size: 22px;
  line-height: 25px;
  margin-right: 5px;
  max-width: 60%;
`;

export const SubscriptionPrice = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 14px;
  text-align: right;
  text-transform: uppercase;
`;

export const SubscriptionDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => rgba(bw(SURFACE_1(props)), 0.5)};
  margin-bottom: 26px;
  word-break: break-all;
`;

export const PaymentText = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: ${TEXT_100};
  margin-bottom: 15px;
`;

export const SubscriptionSuffix = styled.span`
  text-transform: capitalize;
`;
