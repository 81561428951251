import React from 'react';
import { useSelector } from 'hooks';
import { GRID_REGION } from 'global-ids';
import { getPendingOrActualLandingPageContent, isEditMode } from 'services/admin/selectors';
import { isUserAdmin } from 'services/auth';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import useFeatureGateEnabled, { Feature } from 'hooks/use-feature-gate-enabled';
import { StyledQuickEditOverlay } from './styles';
import AdminGridController from 'components/admin-bridge/AdminGridController';
import GridView from 'components/Grid/GridView';

const LandingPageGrid = () => {
  const landingContent = useSelector(getPendingOrActualLandingPageContent);
  const hasContent = Boolean(landingContent && landingContent?.length);
  const isAdmin = useSelector(isUserAdmin);
  const isEditing = useSelector(isEditMode);
  const isSimpleModeEnabled = useFeatureGateEnabled({
    feature: Feature.SIMPLE_MODE,
    type: 'feature',
  });

  const renderLandingPageGrid = React.useCallback(() => {
    const shouldRenderEditable = isSimpleModeEnabled ? isAdmin : isEditing;
    if (shouldRenderEditable) {
      return <AdminGridController />;
    }

    return <GridView />;
  }, [isAdmin, isEditing, isSimpleModeEnabled]);

  return (
    <StyledQuickEditOverlay
      testId="editModeLandingContent"
      // the page blocks already have the quick edit overlay wrapping it,
      // so only render the overlay if there is no landing content
      as={hasContent ? 'section' : QuickEditOverlay}
      data-testid="editModeLandingContent"
      hasContent={hasContent}
      id={GRID_REGION}
      titleKey="ADMIN_LABEL_LANDING_PAGE_CONTENT"
    >
      {renderLandingPageGrid()}
    </StyledQuickEditOverlay>
  );
};

export default LandingPageGrid;
