import React from 'react';
import {
  Button,
  StyledLabel,
} from './styles';
import useClipboardWithMessage from 'hooks/use-clipboard-with-message';
import type { TranslationKey } from 'hooks/use-translation';

interface CopyButtonProps {
  admin?: boolean;
  copyText: string;
  label?: string;
  labelKey?: TranslationKey;
}

export default function CopyButton({
  admin = false,
  copyText,
  label,
  labelKey,
  ...props
}: CopyButtonProps) {
  const [copy, tooltip] = useClipboardWithMessage(copyText, { copyMsg: copyText });

  return (
    <Button
      isAdmin={admin}
      onClick={copy}
      tooltip={tooltip}
      {...props}
    >
      <StyledLabel
        admin={admin}
        icon="copy"
        label={label}
        labelKey={labelKey}
      />
    </Button>
  );
}
