import styled, { css, ThemeProps, DefaultTheme } from 'styled-components';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import {
  desktopOnly,
  mobileOnly,
  INPUT_BORDER_ERROR,
  ADMIN_INPUT_BORDER_ERROR,
} from 'style/mixins';
import LabeledInput from 'components/ui/LabeledInput';
import Icon from 'components/ui/Icon';
import { ADMIN_ALERT_ERROR, ADMIN_SURFACE_3, ADMIN_SURFACE_4, ADMIN_TEXT_100, ADMIN_TEXT_300, ALERT_ERROR, MAESTRO_WHITE, SURFACE_1, SURFACE_3, TEXT_100, TEXT_300, TEXT_CONTRAST_ON_ACCENT_PRIMARY } from 'style/constants';
import { ADMIN_TEXT_BODY_S_REGULAR, TEXT_BODY_S_REGULAR, TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_BOLD } from 'style/design-system/textStyles';
import { bw, darken, rgba } from 'colors';
import Button from 'components/ui/Button';
import AdminButton from 'components/admin2/ui/Button';

interface InputProps {
  error: boolean;
}

interface IShowForAdminProps {
  showForAdmin?: boolean;
}

export const StyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 15px;
  margin-bottom: 15px;
  gap: 15px;
`;

export const CreditCardNumberContainer = styled.div<InputProps & IShowForAdminProps>`
  ${props => props.showForAdmin ? ADMIN_INPUT_BORDER_ERROR : INPUT_BORDER_ERROR}
  height: 35px;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.showForAdmin ? ADMIN_SURFACE_4(props) : darken(SURFACE_3(props), 0.1)};
  border-radius: 3.5px;
`;

export const CreditCardDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  ${desktopOnly`
    margin-top: 10px;
  `}
  ${mobileOnly`
    flex-direction: column;
    & > div {
      margin: 0;
    }
  `}
`;

const CARD_NUMBER_ELEMENT_STYLE = (props: ThemeProps<DefaultTheme> & IShowForAdminProps) => ({
  style: {
    base: {
      color: props.showForAdmin ? ADMIN_TEXT_100(props) : TEXT_100(props),
      fontSize: '14px',
      lineHeight: '35px',
      '::placeholder': {
        color: props.showForAdmin ? ADMIN_TEXT_300(props) : TEXT_300(props),
      },
    },
    invalid: {
      color: `${props.showForAdmin ? ADMIN_ALERT_ERROR(props) : ADMIN_ALERT_ERROR(props)}`,
    },
  },
});

export const StyledCreditCardNumber = styled(CardNumberElement).attrs(props => ({
  options: CARD_NUMBER_ELEMENT_STYLE(props),
}))<IShowForAdminProps>`
  ${props => props.showForAdmin ? ADMIN_TEXT_BODY_S_REGULAR : TEXT_BODY_S_REGULAR}
  background-color: ${(props) => props.showForAdmin ? ADMIN_SURFACE_3(props) : darken(SURFACE_3(props), 0.1)};
  border: none;
  border-radius: 3.5px;
  color: ${(props) => props.showForAdmin ? ADMIN_TEXT_100(props) : TEXT_100(props)};
  height: 35px;
  &::placeholder {
    color: ${(props) => props.showForAdmin ? ADMIN_TEXT_100(props) : rgba(bw(SURFACE_1(props)), 0.5)} !important;
  }
  flex-grow: 1;
  padding-left: 10px;
`;

export const StyledCardExpiry = styled(CardExpiryElement).attrs<{ showFormAdmin?: boolean; }>(props => ({
  options: CARD_NUMBER_ELEMENT_STYLE(props),
}))<InputProps & IShowForAdminProps>`
  ${props => props.showForAdmin ? ADMIN_TEXT_BODY_S_REGULAR : TEXT_BODY_S_REGULAR}
  ${props => props.showForAdmin ? ADMIN_INPUT_BORDER_ERROR : INPUT_BORDER_ERROR}
  background-color: ${(props) => props.showForAdmin ? ADMIN_SURFACE_3(props) : darken(SURFACE_3(props), 0.1)};
  border: none;
  border-radius: 3.5px;
  color: ${(props) => props.showForAdmin ? ADMIN_TEXT_100(props) : TEXT_100(props)};
  height: 35px;
  padding-left: 10px;
  &::placeholder {
    color: ${(props) => props.showForAdmin ? ADMIN_TEXT_100(props) : rgba(bw(SURFACE_1(props)), 0.5)} !important;
  }
  flex-grow: 1;
`;

export const StyledCardCvc = styled(CardCvcElement).attrs<IShowForAdminProps>(props => ({
  options: CARD_NUMBER_ELEMENT_STYLE(props),
}))<InputProps & IShowForAdminProps>`
  background-color: ${(props) => props.showForAdmin ? ADMIN_SURFACE_3(props) : SURFACE_3(props)};
  ${props => props.showForAdmin ? ADMIN_TEXT_BODY_S_REGULAR : TEXT_BODY_S_REGULAR}
  ${props => props.showForAdmin ? ADMIN_INPUT_BORDER_ERROR : INPUT_BORDER_ERROR}
  background-color: ${(props) => props.showForAdmin ? ADMIN_SURFACE_3(props) : darken(SURFACE_3(props), 0.1)};
  border: none;
  border-radius: 3.5px;
  color: ${(props) => props.showForAdmin ? ADMIN_TEXT_100(props) : TEXT_100(props)};
  height: 35px;
  padding-left: 10px;
  &::placeholder {
    color: ${(props) => props.showForAdmin ? ADMIN_TEXT_100(props) : rgba(bw(SURFACE_1(props)), 0.5)} !important;
  }
  flex-grow: 1;
`;

export const CheckboxContainer = styled.div<IShowForAdminProps>`
  padding-left: 5px;
  & span {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    color: ${props => props.showForAdmin ? ADMIN_TEXT_100(props) : TEXT_100(props)};
  }
`;

export const SubmitButton = styled(Button)<{hasError: boolean, loading: boolean}>`
  ${TEXT_BODY_S_BOLD}
  margin-top: ${({ hasError }) => hasError ? '15px' : '30px' };
  height: 40px;
`;

export const AdminSubmitButton = styled(AdminButton)<{hasError: boolean, loading: boolean}>`
  ${ADMIN_TEXT_BODY_S_BOLD}
  margin-top: ${({ hasError }) => hasError ? '15px' : '30px' };
  height: 40px;
`;

export const StyledLabeledInput = styled(LabeledInput)`
  margin-bottom: 10px;
`;

export const ErrorIcon = styled(Icon).attrs({
  name: 'warning',
})<IShowForAdminProps>`
  display: flex;
  margin-right: 6px;
  & svg {
    width: 10px;
    height: 8px;

    & > path {
      color: ${(props => props.showForAdmin ? ADMIN_ALERT_ERROR(props) : ALERT_ERROR(props))};
    }
  }
`;

export const ErrorLabel = styled.div<IShowForAdminProps>`
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props => props.showForAdmin ? ADMIN_ALERT_ERROR(props) : ALERT_ERROR(props))};
`;

export const DummyInput = styled.input.attrs({
  tabIndex: -1,
  type: 'text',
})`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  height: 0;
  width: 0;
`;

