import React from 'react';
import { formatPrice } from 'shared/string-utils';
import { Wrapper, Item, ItemName, ItemPrice, Total, Items } from './styles';
import { useEndUserTranslationWithConditionalLocalization } from 'components/objects/Panel/utils';
import { isEditMode } from 'services/admin';
import { useSelector } from 'react-redux';
import { DynamicTranslationType } from 'hooks/use-translation';

interface PurchaseSummaryItem {
  name: string;
  price: number;
}

interface IPurchaseSummaryProps {
  currency?: string;
  hideSummary?: boolean;
  items: PurchaseSummaryItem[];
}

const PurchaseSummary: React.FC<IPurchaseSummaryProps> = ({ currency = 'USD', items, hideSummary = false, ...props }) => {
  const { endUserT } = useEndUserTranslationWithConditionalLocalization();
  const isEditing = useSelector(isEditMode);
  const { total, itemsWithFormattedPrice } = React.useMemo(() => {
    const sum = items.reduce((acc, item) => acc + item.price, 0);
    const serializedItems = items.map((item) => ({
      ...item,
      price: formatPrice(currency, item.price),
    }));

    return {
      total: formatPrice(currency, sum),
      itemsWithFormattedPrice: serializedItems,
    };
  }, [items]);

  return (
    <Wrapper {...props} data-testid="paymentFlowPurchaseSummary">
      {!hideSummary && (
        <Items>
          {itemsWithFormattedPrice.map((item, index) => (
            <Item key={index} data-testid={`paymentFlowPurchaseSummaryItem-${index}`}>
              <ItemName data-testid={`paymentFlowPurchaseSummaryItemName-${index}`}>{item.name}</ItemName>
              <ItemPrice data-testid={`paymentFlowPurchaseSummaryItemPrice-${index}`}>{item.price}</ItemPrice>
            </Item>
          ))}
        </Items>
      )}
      <Item>
        <Total uppercase={false}>{endUserT([DynamicTranslationType.tipTotal], ['TOTAL_DUE'], isEditing)}</Total>
        <Total uppercase={true} data-testid="paymentFlowPurchaseSummaryTotal">{total}</Total>
      </Item>
    </Wrapper>
  );
};

export default PurchaseSummary;
