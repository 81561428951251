import React, { PropsWithChildren } from 'react';
import {
  Tooltip,
  TooltipStyleProps,
  TooltipWrapper,
} from './styles';

export type { TooltipStyleProps, PositionKey } from './styles';

interface TooltipProps extends PropsWithChildren<TooltipStyleProps> {
  className?: string
}

export default function TooltipComponent({ children, className, ...props }: TooltipProps) {
  return (
    <TooltipWrapper className={className} {...props}>
      <Tooltip {...props}>
        {children}
      </Tooltip>
    </TooltipWrapper>
  );
}
