import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethod } from '@stripe/stripe-js';
import useFocusArea from 'hooks/use-focus-area';
import BillingInfoForm from 'components/payment/BillingInfoForm';
import TranslatedText from 'components/i18n/TranslatedText';
import CreditCardForm, { IHandleSubmitCreditCardFormParams } from 'components/payment/CreditCardForm';
import AddPaymentButton from 'components/payment-methods/AddPaymentButton';
import PaymentMethodCard from 'components/payment-methods/PaymentMethodCard';
import { ADMIN_BAR_FOOTER_ID } from 'components/admin-bridge/AdminBar/constants';
import { getActiveRecurringSubscriptions } from 'services/gate/selectors';
import { USER_PROFILE_PAYMENT_METHODS } from 'injection-classes';
import {
  getEditPaymentMethodSuccess,
  submitPaymentMethod,
  getPaymentMethodSuccess,
  getPrimaryPaymentSuccess,
  getRemovePaymentSuccess,
  clearBillingInfo,
  addRegionRenderingStripeCreditCard,
  removeRegionRenderingStripeCreditCard,
  getPaymentsLoading,
  RegionRenderingStripeCreditCardEnum,
} from 'services/billing';
import {
  HeaderContainer,
  PaymentContainer,
  CardContainer,
  StyledMethodTitle,
  FormTitle,
  LabelUpdated,
  CustomIcon,
  HeaderCardContainer,
  CloseButtonIcon,
  CloseIcon,
  Wrapper,
} from './styles';
import { isUserAdmin } from 'services/auth';
import { TranslationKey } from 'hooks/use-translation';

interface IProps {
  addCard?: boolean;
  defaultPaymentId: string;
  hideTitle?: boolean;
  payObjects: PaymentMethod[];
  shouldRenderMakePrimaryPaymentMethodCheckbox?: boolean;
  shouldRenderSavePaymentMethodCheckbox?: boolean;
  showForAdmin?: boolean;
}

const PaymentMethodContainer = ({
  addCard,
  defaultPaymentId,
  payObjects,
  showForAdmin,
  hideTitle,
  shouldRenderSavePaymentMethodCheckbox = false,
  shouldRenderMakePrimaryPaymentMethodCheckbox = false,
}: IProps) => {
  const paymentsLoading = useSelector(getPaymentsLoading);
  const editPaymentSuccess = useSelector(getEditPaymentMethodSuccess);
  const paymentMethodSuccess = useSelector(getPaymentMethodSuccess);
  const activeRecurringSubscriptions = useSelector(getActiveRecurringSubscriptions);
  const primaryPaymentSuccess = useSelector(getPrimaryPaymentSuccess);
  const removePaymentSuccess = useSelector(getRemovePaymentSuccess);
  const isAdmin = useSelector(isUserAdmin);
  const [addMode, setAddMode] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!paymentsLoading) {
      setAddMode(false);
      dispatch(removeRegionRenderingStripeCreditCard(RegionRenderingStripeCreditCardEnum.PAYMENT_METHODS_V1)); // eslint-disable-line max-len
    }
  }, [paymentsLoading]);

  useEffect(() => {
    return () => {
      dispatch(removeRegionRenderingStripeCreditCard(RegionRenderingStripeCreditCardEnum.PAYMENT_METHODS_V1)); // eslint-disable-line max-len
    };
  }, []);

  const lastFeedbackKey: TranslationKey | null = React.useMemo(() => {
    return paymentMethodSuccess
      ? 'ADMIN_SETTINGS_BILLING_PAYMENT_METHOD_ADDED'
        : removePaymentSuccess
          ? 'ADMIN_LABEL_REMOVE_PAYMENT_METHOD'
          : primaryPaymentSuccess
            ? 'ADMIN_LABEL_PRIMARY_UPDATED'
            : editPaymentSuccess
              ? 'ADMIN_LABEL_PRIMARY_DETAILS_UPDATED'
              : null;
  }, [
    paymentMethodSuccess,
    removePaymentSuccess,
    primaryPaymentSuccess,
    editPaymentSuccess,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearBillingInfo());
    };
  }, []);

  const handleAddClick = (): void => {
    dispatch(addRegionRenderingStripeCreditCard(RegionRenderingStripeCreditCardEnum.PAYMENT_METHODS_V1));
    setAddMode(true);
  };

  const handleClose = useCallback(() => {
    setAddMode(false);
    dispatch(removeRegionRenderingStripeCreditCard(RegionRenderingStripeCreditCardEnum.PAYMENT_METHODS_V1));
    dispatch(clearBillingInfo());
  }, []);

  const handleSubmit = (params: IHandleSubmitCreditCardFormParams) => {
    const { cardNumber, stripe, makePrimaryPaymentMethod } = params;
    dispatch(submitPaymentMethod({
      element: cardNumber,
      stripe,
      ...(shouldRenderMakePrimaryPaymentMethodCheckbox && { makePrimaryPaymentMethod }),
    }));
  };

  const containerRef = useFocusArea<HTMLDivElement>({
    onExit: handleClose,
    active: addMode,
    ignoreIDs: [ADMIN_BAR_FOOTER_ID],
  });

  const renderPayObjects = () => {
    const disabledRemoveButton = !isAdmin
      ? activeRecurringSubscriptions.length > 0 && payObjects.length === 1
      : payObjects.length <= 1;
    if (payObjects && payObjects.length > 0) {
      return payObjects.map((payObject: PaymentMethod) => payObject.card && (
        <PaymentMethodCard
          key={payObject.id}
          id={payObject.id}
          card={payObject.card}
          showForAdmin={showForAdmin}
          country={payObject.billing_details.address!.country!}
          postalCode={payObject.billing_details.address!.postal_code!}
          state={payObject.billing_details.address!.state!}
          name={payObject.billing_details.name!}
          isPrimary={defaultPaymentId === payObject.id}
          disabledRemoveButton={disabledRemoveButton}
        />
      ));
    }
    return null;
  };

  const renderSuccessMessages = () => {
    if (!lastFeedbackKey) {
      return null;
    }

    return (
      <LabelUpdated showForAdmin={showForAdmin}>
        <CustomIcon
          name="circledCheckmark"
          showForAdmin={showForAdmin}
        />
        <TranslatedText stringKey={lastFeedbackKey} />
      </LabelUpdated>
    );
  };

  return (!showForAdmin && isAdmin) ? null : (
    <Wrapper className={USER_PROFILE_PAYMENT_METHODS}>
      {!hideTitle && (
        <HeaderContainer>
          <TranslatedText
            component={StyledMethodTitle}
            stringKey="ADMIN_SETTINGS_BILLING_PAYMENT_METHOD_HEADER"
          />
        </HeaderContainer>
      )}
      <PaymentContainer>
        {renderSuccessMessages()}
        {renderPayObjects()}
      </PaymentContainer>
      { addMode ? (
        <CardContainer ref={containerRef} showForAdmin={showForAdmin}>
          <HeaderCardContainer showForAdmin={showForAdmin}>
            <TranslatedText component={FormTitle} componentProps={{ showForAdmin }} stringKey="ADMIN_LABEL_ADD_NEW_PAYMENT_METHOD" />
            <CloseButtonIcon onClick={handleClose}>
              <CloseIcon name="cancel-thin" />
            </CloseButtonIcon>
          </HeaderCardContainer>
          <BillingInfoForm noTaxOverride={true} showForAdmin={showForAdmin} />
          <CreditCardForm
            loading={paymentsLoading}
            regionRenderingStripeCreditCard={RegionRenderingStripeCreditCardEnum.PAYMENT_METHODS_V1}
            addCard={addCard}
            noTaxOverride={true}
            showForAdmin={showForAdmin}
            shouldRenderSavePaymentMethodCheckbox={shouldRenderSavePaymentMethodCheckbox}
            shouldRenderMakePrimaryPaymentMethodCheckbox={shouldRenderMakePrimaryPaymentMethodCheckbox}
            submitButtonLoadingStringKey="SAVING_CARD"
            submitButtonStringKey="BILLING_SAVE_PAYMENT"
            onSubmit={handleSubmit}
          />
        </CardContainer>
      ) : <AddPaymentButton onClick={handleAddClick} />}

    </Wrapper>
  );
};

export default PaymentMethodContainer;
