import { SerializedEditorState, SerializedParagraphNode, TextFormatType } from 'lexical';
import { SerializedHeadingNode } from '@lexical/rich-text';
import { SerializedExtendedTextNode } from './nodes/ExtendedTextNode';
import { SerializedVideoNode } from './nodes/VideoNode';
import { TEXT_TYPE_TO_FORMAT } from './textTypeToFormat';

type ParagraphNode = SerializedParagraphNode & {
  type: 'paragraph';
};

type HeadingNode = SerializedHeadingNode & {
  type: 'heading';
};

type ChildNode = ParagraphNode | SerializedExtendedTextNode | SerializedVideoNode | HeadingNode;

export type DefaultEditorState = SerializedEditorState<ChildNode>;

type WithChildren<T> = T & { children: ChildNode[] };

export const getDefaultHeadingNode = ({
  children,
  tag,
 }: WithChildren<{
  tag: HeadingNode['tag'];
}>): HeadingNode => {
  return {
    type: 'heading',
    children,
    direction: 'ltr',
    format: 'left',
    indent: 0,
    tag,
    version: 1,
  };
};

export const getDefaultTextNode = ({
  text,
  fontSize,
  textFormatType,
}: {
  fontSize?: number;
  text: string;
  textFormatType?: TextFormatType;
}): SerializedExtendedTextNode => {
  let style = '';
  if (fontSize) {
    style += `font-size: ${fontSize}px; line-height: normal;`;
  }

  return {
    type: 'extended-text',
    text,
    style,
    version: 1,
    detail: 0,
    format: textFormatType ? TEXT_TYPE_TO_FORMAT[textFormatType] : 0,
    mode: 'normal',
  };
};

export const getDefaultParagraphNode = (children: ChildNode[]): ParagraphNode => {
  return {
    type: 'paragraph',
    children,
    direction: 'ltr',
    format: 'left',
    indent: 0,
    version: 1,
  };
};

export const getDefaultEditorState = (children: ChildNode[]): string => {
  return stringify({
    root: {
      children: [getDefaultParagraphNode(children)],
      type: 'root',
      direction: 'ltr',
      format: 'left',
      indent: 0,
      version: 1,
    },
  });
};

const stringify = (data: DefaultEditorState): string => JSON.stringify(data);
