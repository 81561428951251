import styled from 'styled-components';

export interface CarouselStyleProps {
  childWidth?: number;
  singlePage?: boolean;
  translation?: number;
}

export const CarouselContainer = styled.div<CarouselStyleProps>`
  display: flex;
  align-items: center;
  ${({ singlePage }) => singlePage && `
    justify-content: center;
  `}
`;

export const CarouselContent = styled.div<CarouselStyleProps>`
  display: flex;
  max-width: calc(100% - 50px);
  height: 100%;
  flex: 1;
  overflow: hidden;
  & > * {
    transition: transform 0.5s;
    transform: translateX(${({ translation }) => translation}%);
    ${({ childWidth }) => `
      width: ${childWidth}%;
      max-width: ${childWidth}%;
      min-width: ${childWidth}%;
    `}
  }
`;

export const CarouselItem = styled.div`
  display: flex;
`;
