/* tslint:disable: jsx-boolean-value */
import React, { useCallback, useMemo } from 'react';
import hash from 'json-stable-stringify';
import {
  GalleryImage,
  CheckIcon,
  HoverOnlyIcon,
} from './styles';
import { ImageProps } from './interfaces';

export default function Image({
  admin,
  onSelect,
  highlight,
  hoverIcon,
  imageTooltip,
  imageTooltipKey,
  image,
  index = 0,
  isTooltipVisible,
  ...props
}: ImageProps) {
  const handleSelect = useCallback(() => {
    if (!(onSelect && image)) {
      return;
    }
    onSelect(image, index);
  }, [onSelect, hash(image), index]);
  const shouldHighlight = useMemo(() => highlight?.(image), [hash(image), highlight]);

  return (
    <GalleryImage
      admin={admin}
      disableHighlight={!highlight}
      highlight={shouldHighlight}
      onClick={handleSelect}
      src={image?.url}
      {...props}
    >
      {shouldHighlight && <CheckIcon admin={admin} />}
      {hoverIcon && (
        <HoverOnlyIcon
          admin={admin}
          disableHighlight={!highlight}
          name={hoverIcon}
          tooltip={isTooltipVisible ? imageTooltip : undefined}
          tooltipKey={isTooltipVisible ? imageTooltipKey : undefined}
          useAbsolutePosition
        />
      )}
    </GalleryImage>
  );
}
