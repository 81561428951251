import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getTableCellNodeFromLexicalNode,
  TableCellNode,
} from '@lexical/table';
import { $getSelection, $isRangeSelection } from 'lexical';
import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  TableActionMenuWrapper,
  TableCellActionButton,
  ThreeDotsIcon,
} from './styles';
import { TableActionMenu } from './TableActionMenu';

const DEFAULT_CELL_SPACING = 32;
export const STICKING_PADDING = 125;

export type TableCellOrientation = 'horizontal' | 'vertical';

export function TableCellActionMenuContainer({
  anchorElem,
  orientation = 'horizontal',
  sticking = false,
}: {
  anchorElem: HTMLElement;
  orientation: TableCellOrientation;
  sticking?: boolean;
}): JSX.Element {
  const [editor] = useLexicalComposerContext();

  const menuButtonRef = useRef(null);
  const menuRootRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [tableCellNode, setTableMenuCellNode] =
    useState<TableCellNode | null>(null);

  const [tableRect, setTableRect] = useState<DOMRect | null>(null);

  const moveMenu = useCallback(() => {
    const menu = menuButtonRef.current;
    const selection = $getSelection();
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (selection == null || menu == null) {
      setTableMenuCellNode(null);
      return;
    }

    const rootElement = editor.getRootElement();

    if (
      $isRangeSelection(selection) &&
      rootElement !== null &&
      nativeSelection !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(
        selection.anchor.getNode(),
      );

      if (tableCellNodeFromSelection == null) {
        setTableMenuCellNode(null);
        return;
      }

      const tableCellParentNodeDOM = editor.getElementByKey(
        tableCellNodeFromSelection.getKey(),
      );

      if (tableCellParentNodeDOM == null) {
        setTableMenuCellNode(null);
        return;
      }

      setTableMenuCellNode(tableCellNodeFromSelection);
      if (!tableRect) {
        setTableRect(
          tableCellParentNodeDOM.closest('table')?.getBoundingClientRect() ||
            null,
        );
      }
    } else if (!activeElement) {
      setTableMenuCellNode(null);
    }
  }, [editor]);

  useEffect(() => {
    const rootElement = editor.getRootElement();
    const table = rootElement?.querySelector('table');
    if (table) {
      setTableRect(table.getBoundingClientRect());
    }
  }, [sticking]);

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        moveMenu();
      });
    });
  }, [editor, moveMenu]);

  useEffect(() => {
    const menuButtonDOM = menuButtonRef.current as HTMLButtonElement | null;

    if (menuButtonDOM != null && tableCellNode != null) {
      const tableCellNodeDOM = editor.getElementByKey(tableCellNode.getKey());

      if (tableCellNodeDOM != null) {
        const tableCellRect = tableCellNodeDOM.getBoundingClientRect();
        const menuRect = menuButtonDOM.getBoundingClientRect();
        const anchorRect = anchorElem.getBoundingClientRect();
        const notStickingToolbarPadding = !sticking ? STICKING_PADDING : 0;

        let top: number;
        if (orientation === 'vertical') {
          // Center the button vertically within the table cell
          top = tableCellRect.top + (tableCellRect.height / 2) - (menuRect.height / 2) - anchorRect.top + notStickingToolbarPadding;
        } else {
          // Place the button at the middle top of the table cell
          top = (tableRect?.top || 0) - anchorRect.top - DEFAULT_CELL_SPACING + notStickingToolbarPadding;
        }

        const left = orientation === 'vertical'
          ? -DEFAULT_CELL_SPACING
          : tableCellRect.left + tableCellRect.width / 2 - menuRect.width / 2 - anchorRect.left;

        menuButtonDOM.style.opacity = '1';
        menuButtonDOM.style.transform = `translate(${left}px, ${top}px)`;
      } else {
        menuButtonDOM.style.opacity = '0';
      }
    }
  }, [
    menuButtonRef,
    tableCellNode,
    editor,
    anchorElem,
    orientation,
    tableRect,
    sticking,
  ]);


  const prevTableCellDOM = useRef(tableCellNode);

  useEffect(() => {
    if (prevTableCellDOM.current !== tableCellNode) {
      setIsMenuOpen(false);
    }

    prevTableCellDOM.current = tableCellNode;
  }, [prevTableCellDOM, tableCellNode]);

  const onMenuToggle = useCallback((flag: boolean) => () => {
    setIsMenuOpen(flag);
  }, [isMenuOpen]);

  return (
    <TableActionMenuWrapper ref={menuButtonRef}>
      {tableCellNode != null && (
        <>
          <TableCellActionButton
            type="button"
            onClick={onMenuToggle(!isMenuOpen)}
            ref={menuRootRef}
          >
            <ThreeDotsIcon />
          </TableCellActionButton>
          {isMenuOpen && (
            <TableActionMenu
              contextRef={menuRootRef}
              setIsMenuOpen={setIsMenuOpen}
              onClose={onMenuToggle(false)}
              tableCellNode={tableCellNode}
              orientation={orientation}
            />
          )}
        </>
      )}
    </TableActionMenuWrapper>
  );
}
