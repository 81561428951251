import React, { ErrorInfo, useEffect } from 'react';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { SURFACE_5, ACCENT_PRIMARY, BODY_FONT_FAMILY, TEXT_100, SURFACE_1, TEXT_200 } from 'style/constants';
import TranslatedText from 'components/i18n/TranslatedText';
import { useSelector } from 'react-redux';
import { getSavedTheme } from 'services/themes';

interface IErrorDisplayProps {
  docTitle?: string;
  error?: Error | null;
  errorInfo?: ErrorInfo | null;
  errorNameKey?: string;
  errorSubtitleKey?: string;
  errorTitleKey?: string;
  iconName?: string;
  includeReloadButton?: boolean;
}

export default function ({
  error,
  errorInfo,
  errorNameKey,
  errorSubtitleKey,
  errorTitleKey = 'ADMIN_LABEL_ERROR',
  iconName,
  includeReloadButton = false,
}: IErrorDisplayProps) {
  const savedTheme = useSelector(getSavedTheme);

  const refresh = () => {
    location.reload();
  };

  useEffect(() => {
    if (error || errorInfo) {
      // tslint:disable-next-line:no-console
      console.error(error ?? '', errorInfo ?? '');
    }
  }, [error, errorInfo]);

  return savedTheme?._id ? (
    <ErrorDisplay>
      <ContentWrapper fontSizeRatio={FONT_SIZE_RATIO[savedTheme?.typography?.body]}>
        {iconName && <ErrorIcon name={iconName} />}
        {errorNameKey && <TranslatedText component={AccentText} stringKey={errorNameKey} />}
        <TextWrapper>
          <TranslatedText
            component={Title}
            stringKey={errorTitleKey}
          />
          {errorSubtitleKey && (
            <TranslatedText
              component={Subtitle}
              stringKey={errorSubtitleKey}
            />
          )}
        </TextWrapper>
        {includeReloadButton && <RefreshIcon onClick={refresh} />}
      </ContentWrapper>
    </ErrorDisplay>
  ) : null;
}

const FONT_SIZE_RATIO: Record<string, string> = {
  'bebas': '4 / 5',
  'blender-pro-medium': '4 / 5',
  'burbank-big-regular-black': '4 / 5',
  'burbank-big-regular-bold': '4 / 5',
  'burbank-small-black': '4 / 5',
  'burbank-small-bold': '4 / 5',
  'burbank-small-medium': '4 / 5',
  'chivo-black-italic': '4 / 5',
  'chivo-black': '4 / 5',
  'chivo-bold-italic': '4 / 5',
  'chivo-bold': '4 / 5',
  'chivo-italic': '4 / 5',
  'chivo-light-italic': '4 / 5',
  'chivo-light': '4 / 5',
  'chivo-regular': '4 / 5',
  'die-nasty-regular': '2 / 3',
  'din-bold': '4 / 5',
  'din-light': '4 / 5',
  'din-medium': '4 / 5',
  'druk-wide-bold': '2 / 4',
  'flama-bold': '4 / 5',
  'flama-book': '4 / 5',
  'forward-rough': '4 / 5',
  'futura-lt-bold': '2 / 3',
  'futura-lt-book': '2 / 3',
  'futura-lt-light': '2 / 3',
  'futura-lt-regular': '2 / 3',
  'futura-no2-demi': '2 / 3',
  'gotham-bold': '2 / 3',
  'gotham-book': '2 / 3',
  'haas-grot-disp-r-app-45-light': '4 / 5',
  'haas-grot-disp-r-app-55-roman': '4 / 5',
  'haas-grot-disp-r-app-65-medium': '4 / 5',
  'haas-grot-disp-r-app-75-bold': '4 / 5',
  'heading-now-63-book': '4 / 5',
  'heading-now-76-bold': '2 / 3',
  'helvetica-lt-condensed-bold': '4 / 5',
  'helvetica-lt-std-bold': '4 / 5',
  'helvetica-lt-std-roman': '4 / 5',
  'hm-ampersand-bold': '4 / 5',
  'hm-ampersand-demi-bold': '4 / 5',
  'hm-ampersand-light': '4 / 5',
  'hm-ampersand-regular': '4 / 5',
  'hm-amperserif-regular': '4 / 5',
  'industry-black': '4 / 5',
  'industry-blackitalic': '4 / 5',
  'industry-bold': '4 / 5',
  'industry-bolditalic': '4 / 5',
  'industry-book': '4 / 5',
  'industry-bookitalic': '4 / 5',
  'industry-demi': '4 / 5',
  'industry-demiitalic': '4 / 5',
  'industry-light': '4 / 5',
  'industry-lightitalic': '4 / 5',
  'industry-medium': '4 / 5',
  'industry-mediumitalic': '4 / 5',
  'itc-avant-garde-pro-bold': '4 / 5',
  'itc-avant-garde-pro-book': '4 / 5',
  'media-sans-extended-bold': '2 / 4',
  'microgramma-std-bold-extended': '2 / 3',
  'montserrat-bold': '4 / 5',
  'montserrat-regular': '4 / 5',
  'morning-brew-regular': '4 / 5',
  'nexa-light': '4 / 5',
  'NIS_JYAU': '4 / 5',
  'noto-sans-bold-italic': '4 / 5',
  'noto-sans-bold': '4 / 5',
  'noto-sans-italic': '4 / 5',
  'noto-sans-light-italic': '4 / 5',
  'noto-sans-light': '4 / 5',
  'noto-sans-regular': '4 / 5',
  'open-sans-bold': '4 / 5',
  'open-sans-light': '4 / 5',
  'open-sans-regular': '4 / 5',
  'oswald-bold': '4 / 5',
  'pinterest-sans-bold': '4 / 5',
  'pinterest-sans-regular': '4 / 5',
  'pix-m-8pt': '2 / 3',
  'prime-light': '4 / 5',
  'prime-regular': '4 / 5',
  'promesh-regular': '2 / 3',
  'proxima-nova-black': '4 / 5',
  'proxima-nova-bold': '4 / 5',
  'proxima-nova-condensed-bold': '4 / 5',
  'proxima-nova-extra-bold': '4 / 5',
  'proxima-nova-light-italics': '4 / 5',
  'proxima-nova-light': '4 / 5',
  'proxima-nova-regular': '4 / 5',
  'proxima-nova-semi-bold': '4 / 5',
  'proxima-nova-thin': '4 / 5',
  'raleway-bold': '4 / 5',
  'raleway-light': '4 / 5',
  'raleway-regular': '4 / 5',
  'roboto-black': '4 / 5',
  'roboto-regular': '4 / 5',
  'roboto-thin': '4 / 5',
  'saol-display-semibold': '4 / 5',
  'saol-text-bold': '4 / 5',
  'shrimp-regular': '4 / 5',
  'sky-text-medium': '4 / 5',
  'sky-text-regular': '4 / 5',
  'source-sans-pro-bold': '4 / 5',
  'source-sans-pro-regular': '4 / 5',
  'suisse-intl-book': '4 / 5',
  'tex-gyre-adventor-bold-italic': '4 / 5',
  'tex-gyre-adventor-bold': '4 / 5',
  'tex-gyre-adventor-italic': '4 / 5',
  'tex-gyre-adventor-regular': '4 / 5',
  'titillium-regular': '4 / 5',
  'trade-gothic-bold': '4 / 5',
  'trade-gothic': '4 / 5',
};

const ErrorDisplay = styled.div`
  width: 100vw;
  height: 100vh;
  font-family: ${BODY_FONT_FAMILY};
  background: ${SURFACE_5};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: clamp(0.5rem, 2vw, 1.125rem);
`;

const ContentWrapper = styled.div<{fontSizeRatio?: string}>`
  width: min-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: clamp(0.5rem, 2vw, 1.125rem);
  --font-size-ratio: calc(${({ fontSizeRatio = '1' }) => fontSizeRatio});
`;

const ErrorIcon = styled(Icon)`
  & svg {
    width: clamp(155px, 45vw, 622px);
    height: clamp(104.5px, 30.345vw, 419.51px);
  }
`;

const AccentText = styled.p`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: calc(clamp(0.43rem, 1.44vw, 1.25rem) * var(--font-size-ratio));
  font-weight: 800;
  color: ${ACCENT_PRIMARY};
  line-height: 1.36;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.03rem;
`;

const Title = styled.p`
  margin: 0;
  text-align: center;
  font-size: calc(clamp(1rem, 3.32vw, 2.875rem) * var(--font-size-ratio));
  font-weight: 700;
  color: ${TEXT_100};
  line-height: 1.37;
  width: max-content;
`;

const Subtitle = styled.p`
  margin: 0;
  text-align: center;
  font-size: calc(clamp(0.36rem, 1.63vw, 1.5rem) * var(--font-size-ratio));
  font-weight: 800;
  color: ${TEXT_200};
  line-height: 1.375;
  width: 100%;
`;

const RefreshIcon = styled(Icon).attrs(props => ({
  color: SURFACE_1(props),
  name: 'refresh',
}))`
  margin-top: clamp(1.25rem, 5vw, 5rem);
  & svg {
    width: clamp(0.625rem, 2vw, 2.53rem);
    height: clamp(0.625rem, 2vw, 2.53rem);
  }
  cursor: pointer;
  &:hover path {
    fill: ${ACCENT_PRIMARY};
  }
`;
