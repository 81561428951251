import { connect } from 'react-redux';
import { replace } from 'services/app-router';
import { onVideoLinkClick } from 'services/third-party-analytics';

import VideoLink from './view';

const mapDispatchToProps = dispatch => ({
  goToVideo: (tagValue, playlistId) => {
    dispatch(replace({ query: { p: playlistId, v: tagValue } }));
    dispatch(onVideoLinkClick(tagValue));
  },
});

export default connect(null, mapDispatchToProps)(VideoLink);
