import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import Icon from 'components/ui/Icon';
import Expandable from 'components/ui/Expandable';
import { rgba } from 'colors';
import { DownArrow, GradientArrow, RemoveButton } from 'components/admin2/ui/Button';
import { lightenDarken } from 'colors';
import {
  ACCENT_PRIMARY,
  SURFACE_1,
  SURFACE_2,
  ADMIN_TEXT_200,
  ADMIN_SURFACE_3,
  ADMIN_SURFACE_1,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_300,
  ADMIN_TEXT_100,
  SURFACE_3,
  TEXT_100,
  TEXT_200,
  HIGHLIGHT_PRIMARY,
  ADMIN_ACCENT_PRIMARY,
} from 'style/constants';
import {
  FLEX_CENTER,
  CIRCLE,
  ADMIN_THIN_SCROLL_BAR,
  THIN_SCROLL_BAR,
} from 'style/mixins';
import { CalendarStyleProps } from './interfaces';
import {
  ADMIN_TEXT_BODY_S_REGULAR,
  ADMIN_TEXT_BODY_XS_BOLD,
  ADMIN_TEXT_BODY_XS_MEDIUM,
  ADMIN_TEXT_LABEL_L_MEDIUM,
  TEXT_BODY_S_REGULAR,
  TEXT_BODY_XS_BOLD,
  TEXT_BODY_XS_MEDIUM,
  TEXT_LABEL_L_MEDIUM,
} from 'style/design-system/textStyles';

type StyledProps = ThemedStyledProps<Pick<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, 'key' | keyof HTMLAttributes<HTMLElement>> & CalendarStyleProps, DefaultTheme>;

const CalendarDropDownBackground = ({ admin }: StyledProps) => admin ? ADMIN_SURFACE_3 : SURFACE_1;
const CalendarBackground = ({ admin }: StyledProps) => admin ? ADMIN_SURFACE_1 : SURFACE_1;
const CalendarTextColor = ({ admin }: StyledProps) => admin ? ADMIN_TEXT_100 : TEXT_100;
const ButtonHover = ({ admin, ...props }: StyledProps) => admin ? ADMIN_SURFACE_5 : lightenDarken({
  hex: SURFACE_3(props),
  percentage: 0.3,
});
const ActiveColor = ({ admin }: StyledProps) => admin ? ADMIN_ACCENT_PRIMARY : ACCENT_PRIMARY;
const ActiveTextColor = ({ admin }: StyledProps) => admin ? ADMIN_TEXT_100 : TEXT_100;
const CalendarButtonText = ({ admin }: StyledProps) => admin ? ADMIN_TEXT_BODY_XS_MEDIUM : TEXT_BODY_XS_MEDIUM;
const CalendarButtonBackground = ({ admin }: StyledProps) => admin ? ADMIN_SURFACE_5 : SURFACE_2;
const CalendarRadioColor = ({ admin }: StyledProps) => admin ? ADMIN_SURFACE_5 : TEXT_200;
const CalendarButtonTextColor = ({ admin }: StyledProps) => admin ? ADMIN_TEXT_300 : HIGHLIGHT_PRIMARY;
const PassiveTextColor = ({ admin }: StyledProps) => admin ? ADMIN_TEXT_300 : TEXT_200;
const QuarterBorder = ({ admin }: StyledProps) => admin ? ADMIN_TEXT_200 : TEXT_200;
const QuarterButtonColor = ({ admin }: StyledProps) => admin ? ADMIN_SURFACE_5 : SURFACE_2;

export const Header = styled.div<CalendarStyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Title = styled.span<CalendarStyleProps>``;

export const DateTitle = styled.span<CalendarStyleProps>``;

export const DayButton = styled.button<CalendarStyleProps>`
  ${FLEX_CENTER}
  height: 32px;
  border-radius: 4px;
  padding: 6px;
  width: calc(100%/${({ maxCols = 7 }) => maxCols});
  ${({ isActive }) => isActive && css`
    color: ${ActiveTextColor} !important;
    background: ${ActiveColor} !important;
  `};
  ${({ isDisabled }) => isDisabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export const WeekDay = styled(DayButton as any)`` as typeof DayButton;

export const ButtonsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const GridButton = styled.button<CalendarStyleProps>`
  ${FLEX_CENTER}
  width: 70px;
  height: 28px;
  border: 1px solid;
  margin-bottom: 15px;
  border-radius: 5px;
  ${CalendarButtonText}
  ${({ isActive }) => isActive ? css`
    color: ${ActiveTextColor} !important;
    background: ${ActiveColor} !important;
    border-color: ${ActiveColor};
  ` : css`
    border-color: ${PassiveTextColor};
    color: ${PassiveTextColor};
    &:hover {
      border-color: ${ActiveColor};
      color: ${ActiveColor} !important;
    }
  `};
`;

export const DaysContainer = styled.div<CalendarStyleProps>`
  ${({ noMargin }) => !noMargin && 'margin-bottom: 15px;'}
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Radio = styled.div.attrs({
  radius: '16px',
}) <CalendarStyleProps>`
  ${FLEX_CENTER}
  width: calc(100%/8);
  &:after {
    content: "";
    display: block;
    ${CIRCLE}
  }
`;

export const Checkmark = styled(Icon).attrs(props => ({
  color: props.admin ? ADMIN_ACCENT_PRIMARY(props) : ACCENT_PRIMARY(props),
  name: 'checkmark',
}))`
  ${FLEX_CENTER}
  width: calc(100%/8);
  height: 100%;
  border-radius: 5px;
  & svg {
    display: block;
    width: 16px;
    height: 16px;
  }
`;

export const WeekRow = styled.div<CalendarStyleProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 32px;
  border-radius: 5px;
  border: 1px solid transparent;
  overflow: hidden;
  &:hover {
    border-color: ${ActiveColor};
  }
  & ${DayButton} {
    background: transparent !important;
    border-color: transparent !important;
    height: 30px;
    border-radius: 0;
  }
  ${({ isActive }) => isActive && css`
    border-color: ${CalendarButtonTextColor} !important;
    & > *:not(:first-child) {
      background: ${ActiveColor} !important;
    }
  `}
`;

export const NavigationContainer = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavArrow = styled(Icon).attrs(props => ({
  arrowColor: props.admin ? ADMIN_ACCENT_PRIMARY(props) : ACCENT_PRIMARY(props),
}))`
  ${GradientArrow}
  margin: -25px;
`;

export const CalendarButton = styled.button<CalendarStyleProps>`
  height: 40px;
  width: 100%;
  padding: 0 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  ${({ isOpen }) => isOpen && css`
    background: ${CalendarDropDownBackground} !important;
    border-radius: 4px 4px 0 0;
  `}
`;

export const ActionButton = styled.button`
  height: 25px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  ${ADMIN_ACCENT_PRIMARY}
`;

export const ActionButtonText = styled.span``;

export const ActionButtonArrow = styled(DownArrow as any).attrs(props => ({
  color: props.admin ? ADMIN_ACCENT_PRIMARY(props) : ACCENT_PRIMARY(props),
  size: 10,
}))`
  margin-left: 12px;
` as typeof DownArrow;

export const CalendarButtonTitle = styled.span`
  margin-right: auto;
`;

export const CalendarButtonArrow = styled(DownArrow as any).attrs(props => ({
  color: props.admin ? ADMIN_TEXT_100(props) : HIGHLIGHT_PRIMARY(props),
  size: 10,
}))`` as typeof DownArrow;

export const StyledExpandable = styled(Expandable)<{
  calendarPosition?: 'left' | 'right' | 'top',
}>`
  border-radius: 0 0 5px 5px;
  width: 100%;
  min-width: 256px;
  position: absolute;
  z-index: 1;
  ${({ calendarPosition }) => calendarPosition === 'top' ? css`
    bottom: 100%;
  ` : css`
    top: 100%;
  `};
  ${({ calendarPosition }) => calendarPosition === 'left' && css`
    left: 0;
  `};
  ${({ calendarPosition }) => calendarPosition === 'right' && css`
    right: 0;
  `};
`;

export const QuarterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 197px;
`;

export const QuarterContainer = styled.div`
  border: 1px solid;
  padding: 5px;
  height: 38px;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 5px;
`;

export const QuarterButton = styled.button`
  height: 28px;
  border-radius: 5px;
  padding: 6px 5px;
  &:not(:last-child) {
    margin-right: 5px;
  }
  flex: 1;
`;

export const QuarterText = styled.div`
  margin: 0 10px;
  width: 17px;
`;

export const QuarterRow = styled.div<CalendarStyleProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isActive }) => isActive && css`
    & ${QuarterContainer as any} {
      border-color: ${CalendarButtonTextColor} !important;
      & ${QuarterButton as any} {
        background: ${ActiveColor} !important;
        color: ${CalendarButtonTextColor} !important;
      }
      & ${QuarterText as any} {
        color: ${CalendarButtonTextColor} !important;
      }
    }
  `}
`;

export const CalendarContainer = styled.div<CalendarStyleProps>`
  width: 100%;
  padding: 16px;
  max-width: 256px;
  margin: 0 auto;
  & > *:not(:first-child) {
    ${({ blur }) => blur && 'filter: blur(4px);'}
  }
  && ${Title} {
    ${({ admin }) => admin ? ADMIN_TEXT_LABEL_L_MEDIUM : TEXT_LABEL_L_MEDIUM}
    color: ${CalendarTextColor};
  }
  && ${DateTitle} {
    ${({ admin }) => admin ? ADMIN_TEXT_BODY_S_REGULAR : TEXT_BODY_S_REGULAR}
  }
  && ${DayButton} {
    ${CalendarButtonText}
    color: ${CalendarTextColor};
    background: ${CalendarBackground};
    &:hover {
      background: ${ButtonHover};
    }
  }
  && ${WeekDay} {
    ${({ admin }) => admin ? ADMIN_TEXT_BODY_XS_BOLD : TEXT_BODY_XS_BOLD}
    color: ${CalendarTextColor} !important;
    background: ${CalendarBackground} !important;
  }
  && ${DateTitle}, && ${Header} {
    color: ${CalendarTextColor};
  }
`;

export const TransitionContent = styled.div<{ status: string }>`
  opacity: ${({ status }) => status === 'entered' ? 1 : 0};
  transform: scale(${({ status }) => status === 'entered' ? 1 : 0});
  transition: opacity 0.5s, transform 0.5s;
`;

export const CalendarWrapper = styled.div<CalendarStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${({ isDisabled }) => isDisabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}
  && ${StyledExpandable} {
    background: ${CalendarBackground};
  }
  && ${CalendarButton}, && ${ActionButton} {
    ${CalendarButtonText}
    background: ${CalendarButtonBackground};
    color: ${CalendarButtonTextColor};
  }
  && ${ActionButtonText} {
    color: ${PassiveTextColor};
  }
  && ${Radio} {
    &:after {
      border: 1px solid ${CalendarRadioColor};
    }
  }
  && ${QuarterRow} {
    &:hover ${QuarterContainer} {
      border-color: ${ActiveColor};
      & ${QuarterButton} {
        color: ${CalendarButtonTextColor};
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          border-radius: 5px;
          border: 1px solid ${ActiveColor};
        }
      }
      & ${QuarterText} {
        color: ${CalendarButtonTextColor};
      }
    }
  }
  && ${QuarterContainer} {
    border-color: ${QuarterBorder};
  }
  && ${QuarterButton} {
    ${CalendarButtonText}
    background: ${QuarterButtonColor};
    color: ${PassiveTextColor};
  }
  && ${QuarterText} {
    ${CalendarButtonText}
    color: ${QuarterBorder};
  }
`;

export const ClearButton = styled(RemoveButton)`
  margin-right: 10px;
`;

export const ScrollerWrapper = styled.div`
  ${FLEX_CENTER}
  position: absolute;
  background: ${(props) => {
    const color = CalendarBackground(props);
    return rgba(typeof color === 'string' ? color : color(props), 0.7);
  }};
  height: calc(100% - 50px);
  top: 50px;
  width: 100%;
  padding: 0 10px;
`;

export const ScrollerContainer = styled.div`
  display: flex;
  background: ${CalendarBackground};
  height: 75%;
  flex: 1;
  & > * {
    flex: 1;
  }
`;

export const ScrollerItem = styled.button<CalendarStyleProps>`
  ${FLEX_CENTER}
  ${CalendarButtonText}
  min-height: 35px;
  width: 100%;
  & > ${Checkmark} {
    margin-left: -16px;
    & svg {
      width: 10px;
      height: 7px;
    }
  }
  ${({ isActive }) => isActive && css`
    color: ${ActiveColor} !important;
  `}
`;

export const Scroller = styled.div<CalendarStyleProps>`
  ${({ admin }) => admin ? ADMIN_THIN_SCROLL_BAR : THIN_SCROLL_BAR}
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden auto;
  & ${ScrollerItem} {
    color: ${PassiveTextColor};
    &:hover {
      color: ${CalendarButtonTextColor};
    }
  }
`;

export const StyledSubText = styled.span`
  ${ADMIN_TEXT_BODY_XS_MEDIUM}
  color: ${ADMIN_TEXT_200};
`;

export const StyledIcon = styled(Icon)`
  display: flex;
  padding: 0.4em;
  background-color: ${ADMIN_ACCENT_PRIMARY};
  border-radius: 0.5em;
`;
