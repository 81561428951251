import React from 'react';
import FormInput from 'components/ui/v2/Inputs';
import { StyledCardCvcElement } from './styles';
import { StripeCardCvcElementChangeEvent } from '@stripe/stripe-js';
import { useAdminTranslation } from 'hooks/use-translation';
import useToggle from 'hooks/use-toggle';

type CVCProps = {
  error: string | undefined;
  onChange: (error: string | undefined, complete: boolean) => void;
};

const CVC: React.FC<CVCProps> = ({ onChange, error }) => {
  const { t } = useAdminTranslation();
  const [focused, toggle] = useToggle(false);
  const handleCVCChange = React.useCallback((event: StripeCardCvcElementChangeEvent) => {
    onChange(event.error?.message, event.complete);
  }, [onChange]);

  return (
    <FormInput.Root error={!!error}>
      <FormInput.FieldSet focused={focused}>
        <FormInput.Legend>{t('CODE')}</FormInput.Legend>
        <StyledCardCvcElement onChange={handleCVCChange} onFocus={toggle} onBlur={toggle} />
      </FormInput.FieldSet>
      {error && <FormInput.SupportingText>{error}</FormInput.SupportingText>}
    </FormInput.Root>
  );
};

export default CVC;
