import { connect } from 'react-redux';
import { getUserEmail } from 'services/auth';
import { getSiteId } from 'services/app/selectors';
import EmailVerificationNotice from './view';

const mapStateToProps = state => ({
  email: getUserEmail(state),
  siteId: getSiteId(state),
});

export default connect(mapStateToProps)(EmailVerificationNotice);
