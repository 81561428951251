import styled, { IStyledProps } from 'styled-components';
import Icon from 'components/ui/Icon';
import { ADMIN_HIGHLIGHT_PRIMARY, ADMIN_SURFACE_3, ADMIN_SURFACE_5, ADMIN_TEXT_100 } from 'style/constants';
import { HIGHLIGHT_PRIMARY, SURFACE_3, SURFACE_5, TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import { TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';

export const CardsMenuContainer = styled.div<{ isAdmin: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: ${({ isAdmin }) => isAdmin ? ADMIN_SURFACE_3 : SURFACE_3};
  padding: 10px;
  gap: 6px;
  width: max-content;
`;

export const MenuAction = styled.div<{ isAdmin: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 5px 12px 6px 4px;
  gap: 4px;
  cursor: pointer;
  width: 100%;

  border-radius: 50px;
  &:hover {
    background: ${({ isAdmin }) => isAdmin ? ADMIN_SURFACE_5 : SURFACE_5};
  }
`;

export const MenuActionIcon = styled(Icon).attrs((props: IStyledProps & {
  iconName: string,
  isAdmin: boolean,
}) => ({
  name: props.iconName,
  color: props.isAdmin ? ADMIN_HIGHLIGHT_PRIMARY(props) : HIGHLIGHT_PRIMARY(props),
}))<{ iconName: string, isAdmin: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;

  & > svg {
    width: 14px;
    height: 14px;
  }
  margin: 0 4.67px;
`;

export const MenuActionName = styled.span<{ isAdmin: boolean }>`
  color: ${({ isAdmin }) => isAdmin ? ADMIN_TEXT_100 : TEXT_100};
  ${({ isAdmin }) => isAdmin ? ADMIN_TEXT_LABEL_S_MEDIUM : TEXT_LABEL_S_MEDIUM}
`;
