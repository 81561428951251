import React from 'react';
import { Container, CirclePercent, Progress, Counter, ProgressPosition, Rectangle } from './styles';

interface IProps {
  second: number;
  showSeconds?: boolean;
  size?: number;
  totalSeconds: number;
}

const SpinnerCircle = ({ second = 0, showSeconds = false, size = 48, totalSeconds = 0 }: IProps) => {
  const step = totalSeconds ? 100/totalSeconds : 0;
  const seconds = totalSeconds - second;
  const percent = seconds * step;

  return (
    <Container size={size}>
      <CirclePercent>
        <Progress position={`${percent * -3.6}`} />
        <ProgressPosition position="left" display={percent > 50} />
        <ProgressPosition position="right" display={percent <= 50} />
        <ProgressPosition position="bottom" display={percent > 70} />
      </CirclePercent>
      <Counter data-percent={showSeconds ? `${seconds}s` : ''} />
      <Rectangle display={percent > 50} />
    </Container>
  );
};

export default SpinnerCircle;
