import styled, { css } from 'styled-components';
import withTooltip, { withTooltipProps } from 'components/core/withTooltip';
import {
  ButtonProps,
  BASE_BUTTON as ADMIN_BASE_BUTTON,
} from 'components/admin2/ui/Button';
import {
  ACCENT_PRIMARY,
  TEXT_100,
  TEXT_500,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
} from 'style/constants';
import SingleIconButton from './SingleIconButton';
import { desktopOnly } from 'style/mixins';
import React from 'react';

interface TransparentIconProps extends withTooltipProps {
  iconName?: string;
  style?: React.CSSProperties;
  buttonProps?(props: Record<string, any>): object;
}

type CssVar = typeof ACCENT_PRIMARY | string;

type GetButtonStyleFn = {
  [key in 'background' | 'borderColor' | 'color' | 'hoverBackground' | 'hoverBorderColor' | 'hoverColor']?: CssVar;
};

export const getButtonStyle = ({
  background = 'transparent',
  borderColor,
  color = TEXT_500,
  hoverBackground = 'transparent',
  hoverBorderColor,
  hoverColor = TEXT_500,
}: GetButtonStyleFn) => {
  const hoverStyle = css<ButtonStyleProps>`
    background: ${props => props.hoverBackground || hoverBackground};
    ${hoverBorderColor && css<ButtonStyleProps>`
      &::after {
        border-color: ${props => props.hoverBorderColor || hoverBorderColor};
      }
    `}
    color: ${props => props.hoverTextColor || hoverColor};
    & svg > path {
      fill: ${props => props.hoverTextColor || hoverColor} !important;
    }
  }`;
  return css<ButtonStyleProps>`
    background: ${(props) => props.background || background};
    ${borderColor && css<ButtonStyleProps>`
      position: relative;
      &::after {
        content: "";
        border-radius: 5px;
        position: absolute;
        border: 1px solid ${props => props.borderColor || borderColor};
        pointer-events: none;
        left: 0;
        top: 0;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
      }
    `}
    color: ${(props) => props.textColor || color};
    & svg > path {
      fill: ${(props) => props.textColor || color};
    }
    ${({ hovering }) => hovering && hoverStyle}
    ${desktopOnly`
      &:hover {
        ${hoverStyle}
      }
    `}
  `;
};

export const VARIANTS = {
  borderOnly: getButtonStyle({
    borderColor: ACCENT_PRIMARY,
    color: TEXT_100,
    hoverBackground: ACCENT_PRIMARY,
    hoverBorderColor: ACCENT_PRIMARY,
    hoverColor: TEXT_CONTRAST_ON_ACCENT_PRIMARY,
  }),
  default: css`
    ${ADMIN_BASE_BUTTON}
    /* TODO: Set border-radius and text-transform based on the site theme button style */
    border-radius: 5px;
    text-transform: unset:
  `,
  solidHover: getButtonStyle({
    background: ACCENT_PRIMARY,
    color: TEXT_CONTRAST_ON_ACCENT_PRIMARY,
    hoverBackground: TEXT_CONTRAST_ON_ACCENT_PRIMARY,
    hoverColor: ACCENT_PRIMARY,
  }),
  transparent: getButtonStyle({
    background: 'transparent',
    color: TEXT_100,
    hoverBackground: ACCENT_PRIMARY,
    hoverColor: TEXT_100,
  }),
};

export interface ButtonStyleProps extends Omit<ButtonProps, 'variant'> {
  hovering?: boolean;
  variant?: keyof typeof VARIANTS;
}

export const transparentButton = ({
  buttonProps,
  iconName,
  style,
  ...tooltipProps
}: TransparentIconProps = {}) => withTooltip(styled(SingleIconButton).attrs(props => ({
  hovering: props.hovering,
  name: props.name || iconName,
  ...buttonProps?.(props),
}))`${style}`, {
  tooltipCss: 'white-space: nowrap;',
  ...tooltipProps,
});
