import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ActionsContainer,
  Action,
  ActionMenu,
  Container,
  Dot,
} from './styles';
import TranslatedText from 'components/i18n/TranslatedText';
import { setHomePage } from 'services/admin/actions';

interface IProps {
  pageId: string;
  showCardMenu: boolean;
}

const CardMenu = ({ pageId, showCardMenu }: IProps) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const [showActions, setShowActions] = useState(false);
  const [isCardMenuActive, setIsCardMenuActive] = useState(showCardMenu);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      // @ts-ignore
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowActions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  const handleSetHomeAction = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsCardMenuActive(false);
    dispatch(setHomePage({ pageId }));
  };
  const handleMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  return (
    <Container ref={containerRef}>
      {isCardMenuActive && (
        <>
          {
            showActions && (
              <ActionsContainer>
                <Action onClick={handleSetHomeAction}>
                  <TranslatedText stringKey="ADMIN_ACTION_SET_AS_HOME" />
                </Action>
              </ActionsContainer>
            )
          }
          <ActionMenu onClick={handleMenuClick}>
            <Dot />
            <Dot />
            <Dot />
          </ActionMenu>
        </>
      )}
    </Container>
  );
};

export default CardMenu;
