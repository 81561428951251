import { useIsChannelLive } from 'hooks/use-is-channel-live';

interface ChannelLiveStateProviderProps {
  id: string;
  type?: string;
  children(live: boolean): any;
}

export default function ChannelLiveStateProvider({ children: render, id }: ChannelLiveStateProviderProps) {
  const isChannelLive = useIsChannelLive(id);
  return render(isChannelLive);
}
