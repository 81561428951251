/* eslint react/destructuring-assignment: off */
import PropTypes from 'prop-types';
import React from 'react';
import { Transition } from 'react-transition-group';
import styled, { css, keyframes } from 'styled-components';

import { SIDEBAR_PANEL_ROOT, PANELS } from 'injection-classes';
import FeatureGate, { Feature } from 'components/core/FeatureGate';
import Card from 'components/cards/Card';
import RealtimeDocument from 'components/core/RealtimeDocument';
import Link from 'components/ui/Link';
import TranslatedText from 'components/i18n/TranslatedText';
import Panel, { PanelView } from 'components/objects/Panel';
import PanelSelector, { ICON_MAP } from 'components/ui/PanelSelector';
import { StyledIcon } from 'components/ui/PanelSelector/styles';
import { isActivePanel } from 'components/objects/PanelV2/utils';
import SidebarAuthHeader from 'components/ui/SidebarAuthHeader';
import MobileChannelSelectButton from 'components/mobile/MobileChannelSelect/ChannelSelectButton';
import { dedupedBy } from 'array-utils';
import { SIDEBAR_ID } from 'global-ids';
import {
  ADMIN_HEADER_HEIGHT,
  CURRENT_SIDEBAR_WIDTH_PX,
  SPACING_SMALL,
  ADMIN_BAR_WIDTH_SM_PX,
  MAESTRO_WHITE,
  ADMIN_SURFACE_1,
  ADMIN_FONT_FAMILY,
  TEXT_400,
  SURFACE_3,
  SURFACE_4,
  TEXT_100,
} from 'style/constants';
import {
  BACKGROUND_CONTENT,
  SIDEBAR_MOBILE_PANELS_EMBED_LAYOUT,
  SIDEBAR_DESKTOP_MAX_WIDTH_LAYOUT,
  mobileOnly,
} from 'style/mixins';
import { POWERED_BY_MAESTRO_LINK } from 'shared/constants';
import PreviewPanel from 'components/objects/Panel/PreviewPanel';
import Icon from 'components/ui/Icon';
import { rgba } from 'colors';
import { forceVisibilityInDOM } from 'utils';
import PanelsV2 from 'components/objects/PanelV2';
import { TEXT_BODY_XS_MEDIUM } from 'style/design-system/textStyles';
import { withT } from 'hooks/use-translation';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import { FORTNITE_LEADERBOARD_BLOCK_PANEL_ID, QUESTS_BLOCK_PANEL_ID, SHOPIFY_BLOCK_PANEL_ID } from 'components/objects/PanelV2/constants';
import PreviewCard from './PreviewCard';
import NoPanels from './NoPanels';

const DESKTOP_HIDDEN_STYLE = {
  transition: 'width 0.25s ease-in',
  width: 0,
};

const DESKTOP_SHOWN_STYLE = {
  transition: 'width 0.25s ease-out',
};

const SLIDE_MS = 250;
const PROFILE = 'profile';

const getStyle = (hidden, isDesktopLayout) => {
  if (isDesktopLayout) {
    return hidden ? DESKTOP_HIDDEN_STYLE : DESKTOP_SHOWN_STYLE;
  }
  return hidden ? { display: 'none' } : {};
};

const PreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 13px;
  background-color: ${SURFACE_4};
`;

const StyledQuickEditOverlay = styled(QuickEditOverlay)`
  background-color: ${ADMIN_SURFACE_1};
  color: ${TEXT_100};
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  position: relative;
  ${props => props.isMobilePanelEmbedLayout ? SIDEBAR_MOBILE_PANELS_EMBED_LAYOUT : SIDEBAR_DESKTOP_MAX_WIDTH_LAYOUT}
  filter: blur(${props => props.blur ? '2px' : '0px'});
  border: none;
  border-left: 1px solid ${SURFACE_4};
`;

const SidebarBody = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
`;

const StyledSidebarAuthHeader = styled(SidebarAuthHeader)`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
`;

const PanelSelectorWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const StyledPanelSelector = styled(PanelSelector)`
  flex-shrink: 0;
  height: 100%;
`;

const Panels = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  ${mobileOnly`
    overflow: auto;
  `};
  scrollbar-width: thin;
  ${BACKGROUND_CONTENT};
`;

const SidebarFooter = styled.div`
  ${TEXT_BODY_XS_MEDIUM}
  flex-shrink: 0;
  padding: ${SPACING_SMALL};
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border-top: 0.5px solid ${SURFACE_4};
  color: ${TEXT_400};
  background-color: ${SURFACE_3};
`;

const styleCard = Component => styled(Component)`
  display: flex;
  flex: 0 0 auto;
  overflow-y: auto;
  ${mobileOnly`
    flex-shrink: 1;
  `};
`;

const StyledCard = styleCard(Card);
const StyledPreviewCard = styleCard(PreviewCard);

// TODO: Constant for header height
// TODO: this straight up looks bad https://www.wrike.com/open.htm?id=295255876
export const EditSheetContainer = styled.div`
  font-family: ${ADMIN_FONT_FAMILY};
  position: fixed;
  top: ${ADMIN_HEADER_HEIGHT};
  bottom: 0px;
  right: ${CURRENT_SIDEBAR_WIDTH_PX}px;
  ${({ blur }) => blur ?
    css`
    background: transparent;
    ` :
    css`
    background: rgba(0, 0, 0, 0.5);
    `}
  ${({ sidebarVisible, state }) => !sidebarVisible && state.includes('exit') && css`
    margin-right: -${CURRENT_SIDEBAR_WIDTH_PX}px;
  `}
  overflow: hidden;
  z-index: 3;
  visibility: hidden;
  transition: all 0.4s;
  ${({ state }) => state === 'entered' && css`
    visibility: visible;
  `}
  ${({ adminBarWidth }) => css`
    left: ${adminBarWidth || ADMIN_BAR_WIDTH_SM_PX}px;
  `}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const ExitEditSidebar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
`;

const Blackout = styled.div`
  animation: 0.3s ease-out ${fadeIn};
  background-color: rgba(0, 0, 0, ${props => props.invisible ? '0' : '0.5'});
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
`;

const CaptureClick = styled.div`
  position: absolute;
  height: 100%;
`;

const StopClicks = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  pointer-events: none;
  flex: 1;
  min-width: 0;
  max-width: 100%;
`;

export const CustomPanelWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const ClosePanelButtonWrapper = styled.div`
  height: 19px;
  margin: 15.5px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledCloseButton = styled(Icon).attrs(() => ({
  color: MAESTRO_WHITE,
  name: 'cancel-thin',
}))`
  line-height: 0;
  cursor: pointer;
  z-index: 2;
  transition: opacity 1s, max-height 1s, overflow 1s;
  background: ${rgba('#070707', 0.3)};
  width: 19px;
  height: 19px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  ${({ collapsed }) => mobileOnly`
    opacity: ${collapsed ? 0 : 1};
    max-height: ${collapsed ? 0 : 50}px;
    overflow: ${collapsed ? 'hidden' : 'visible'};
  `}
`;

class Sidebar extends React.Component {
  static propTypes = {
    activePanel: PropTypes.string,
    adminBarWidth: PropTypes.number.isRequired,
    blur: PropTypes.bool.isRequired,
    channelGateOn: PropTypes.bool,
    className: PropTypes.string,
    customPanels: PropTypes.arrayOf(PropTypes.object).isRequired,
    disablePanels: PropTypes.func.isRequired,
    editing: PropTypes.bool,
    enablePanels: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
    inEditMode: PropTypes.bool.isRequired,
    isCardOpen: PropTypes.bool.isRequired,
    isChannelSelectorSidebarButtonVisible: PropTypes.bool.isRequired,
    isDesktopLayout: PropTypes.bool.isRequired,
    isLogoTarget: PropTypes.bool.isRequired,
    isMobileLayout: PropTypes.bool.isRequired,
    isMobilePanelEmbedLayout: PropTypes.bool.isRequired,
    isSidebarModalOpened: PropTypes.bool.isRequired,
    isSidebarVisible: PropTypes.bool.isRequired,
    mockCardRenderer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    mockPanelRenderer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    onCloseCustomPanel: PropTypes.func.isRequired,
    onSetActivePanel: PropTypes.func.isRequired,
    otherRegionEditing: PropTypes.bool,
    panelState: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    previewCardRenderer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    previewPanelRenderer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    renderer: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          arrayId: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
    sidebarWidth: PropTypes.number.isRequired,
    titleTextColor: PropTypes.string,
  };

  static defaultProps = {
    activePanel: null,
    channelGateOn: false,
    className: '',
    editing: false,
    hidden: false,
    mockCardRenderer: null,
    mockPanelRenderer: null,
    otherRegionEditing: false,
    panelState: null,
    previewCardRenderer: null,
    previewPanelRenderer: null,
    renderer: null,
    titleTextColor: MAESTRO_WHITE,
  };

  componentWillUnmount() {
    this.props.onSetActivePanel(null);
  }

  get items() {
    const { renderer } = this.props;

    return renderer?.items || [];
  }

  get hidePanels() {
    const { hidden, isMobileLayout, isCardOpen, customPanels, adReplacesPanels } = this.props;
    const customPanelData = customPanels[customPanels.length - 1];
    // conditionally allow some customPanels to appear over the card
    const panelOverCard = Boolean(customPanelData?.overCard);
    return hidden || (isMobileLayout && isCardOpen && !panelOverCard) || adReplacesPanels;
  }

  selectPanel = (activeId) => {
    this.props.onSetActivePanel(activeId);
  };

  renderCard = () => {
    const {
      customPanels,
      hidden,
      previewCardRenderer,
      renderer,
      adReplacesPanels,
      mockCardRenderer,
      channelGateOn,
    } = this.props;

    // get custom panel data
    const customPanelData = customPanels[customPanels.length - 1];

    if (channelGateOn && customPanelData?.doc?.data?.kind !== PROFILE) {
      return null;
    }

    if (previewCardRenderer || mockCardRenderer) {
      return <StyledPreviewCard renderer={mockCardRenderer || previewCardRenderer} />;
    }

    const { cardId } = renderer || {};
    if (!cardId) {
      return null;
    }

    // conditionally allow some customPanels to appear over the card
    const panelOverCard = Boolean(customPanelData?.overCard);
    const hideCard = panelOverCard || adReplacesPanels;

    return (
      <RealtimeDocument collection="objects" id={cardId}>
        {(cardDoc) => <StyledCard doc={cardDoc} hidden={hideCard || hidden} />}
      </RealtimeDocument>
    );
  };

  renderCustomPanel = ({ id, doc } = {}, hidePanels) => {
    const { isMobileLayout, hidden } = this.props;
    if (id) {
      return <Panel key={id} collection="objects" hidden={hidden || hidePanels} id={id} />;
    }

    return (
      doc && (
        <PanelView active doc={doc} hidden={hidden || hidePanels} isMobileLayout={isMobileLayout} />
      )
    );
  };

  renderPanelHeader() {
    const {
      isMobileLayout,
      renderer,
      previewPanelRenderer,
      mockPanelRenderer,
      channelGateOn,
      customPanels,
    } = this.props;
    if (channelGateOn) {
      const customPanelData = customPanels[customPanels.length - 1];
      if (channelGateOn && customPanelData?.doc?.data?.kind !== PROFILE) {
        return null;
      }
    }

    const previewRenderer = previewPanelRenderer || mockPanelRenderer;
    const activePanelId = this.getActivePanelId();

    if (previewRenderer) {
      return (
        <PreviewWrapper>
          <StyledIcon
            isActive
            name={
                mockPanelRenderer?.iconName ||
                previewPanelRenderer.iconName ||
                ICON_MAP[previewRenderer.panelType]
              }
          />
        </PreviewWrapper>
      );
    }
    if (this.hidePanels) {
      return null;
    }

    return (
      <PanelSelectorWrapper>
        <MobileChannelSelectButton
          center={!renderer?.items?.length}
          padding="6px 8px 7px 8px"
        />
        {renderer?.items?.length ? (
          <StyledPanelSelector
            activeId={activePanelId}
            isMobileLayout={isMobileLayout}
            onSelect={this.selectPanel}
          />
        ) : null}
      </PanelSelectorWrapper>
    );
  }

  renderPanel = () => {
    const {
      customPanels,
      editing,
      previewPanelRenderer,
      mockPanelRenderer,
      channelGateOn,
      onCloseCustomPanel,
    } = this.props;

    const { panelType } = previewPanelRenderer || {};

    const useFragment = [
      SHOPIFY_BLOCK_PANEL_ID,
      FORTNITE_LEADERBOARD_BLOCK_PANEL_ID,
      QUESTS_BLOCK_PANEL_ID,
    ].includes(panelType);

    const getWrapper = () => {
      if (useFragment) {
        return React.Fragment;
      }

      return StopClicks;
    };
    const Wrapper = getWrapper();

    // get custom panel data
    if (editing && (mockPanelRenderer || previewPanelRenderer)) {
      return (
        <Wrapper>
          <PreviewPanel
            renderer={mockPanelRenderer || previewPanelRenderer}
            useMockPanel={!previewPanelRenderer}
          />
        </Wrapper>
      );
    }
    const customPanelData = customPanels[customPanels.length - 1];

    if (channelGateOn && customPanelData?.doc?.data?.kind !== PROFILE) {
      return null;
    }

    const isCTSProfile = customPanelData?.doc?.data?.kind === 'profile';

    const activePanels = this.getActivatedPanels().filter(p => p.id === this.getActivePanelId());

    return (
      <>
        {
          (!activePanels.length && !customPanelData) ? <NoPanels /> : activePanels
            .map((panelRenderer) => {
              const { id } = panelRenderer;
              return (
                <Panel
                  key={id}
                  collection="objects"
                  fade
                  hidden={this.hidePanels}
                  id={id}
                />
              );
            })
        }
        {customPanelData && (
          <CustomPanelWrapper>
            {!isCTSProfile && (
              <ClosePanelButtonWrapper>
                <StyledCloseButton onClick={() => onCloseCustomPanel()} />
              </ClosePanelButtonWrapper>
            )}
            {this.renderCustomPanel(customPanelData, this.hidePanels)}
          </CustomPanelWrapper>
        )}
      </>
    );
  };

  renderSidebarFooter() {
    const { isMobileLayout } = this.props;

    if (isMobileLayout) {
      return null;
    }

    return (
      <FeatureGate feature={Feature.HIDE_MAESTRO_LOGO}>
        {(hideMaestroLogo) => hideMaestroLogo ? null : (
          <SidebarFooter
            ref={(ref) => forceVisibilityInDOM(ref, this.props.titleTextColor)}
          >
            <Link href={POWERED_BY_MAESTRO_LINK} plain>
              <TranslatedText stringKey="POWERED_BY_MAESTRO" />
            </Link>
          </SidebarFooter>
        )}
      </FeatureGate>
    );
  }

  getActivatedPanels = () => {
    const { items } = this;
    const activePanels = dedupedBy(items, 'id').filter(panel => isActivePanel(panel));
    return activePanels;
  };

  getActivePanelId = () => {
    const { customPanels, activePanel } = this.props;
    const customPanelData = customPanels[customPanels.length - 1];
    const activeId = (customPanelData && 'custom-panel') || activePanel;
    return activeId;
  };

  renderSidebarBody = () => {
    const activatedPanels = this.getActivatedPanels();

    return (
      <SidebarBody>
        {this.renderCard()}
        {!this.hidePanels && activatedPanels.length > 1 && this.renderPanelHeader()}
        <Panels className={`${SIDEBAR_PANEL_ROOT} ${PANELS}`}>
          {this.renderPanel()}
        </Panels>
      </SidebarBody>
    );
  };

  renderDefault() {
    const {
      className,
      customPanels,
      editing,
      hidden,
      isDesktopLayout,
      isLogoTarget,
      isMobilePanelEmbedLayout,
      inEditMode,
      renderer,
      otherRegionEditing,
      disablePanels,
      panelState,
      isSidebarModalOpened,
      isChannelSelectorSidebarButtonVisible,
      isMobileLayout,
      enablePanels,
    } = this.props;

    const { items } = this;

    if (
      !(items.length || customPanels.length || renderer?.cardId) &&
      !inEditMode &&
      !(isMobileLayout && isChannelSelectorSidebarButtonVisible)
    ) {
      return null;
    }

    const showPanel = panelState.state !== 'showing';

    const panelFooter = this.renderSidebarFooter();

    return (
      <StyledQuickEditOverlay
        blur={isSidebarModalOpened}
        className={className}
        editing={editing}
        hidden={!isChannelSelectorSidebarButtonVisible && showPanel}
        id={SIDEBAR_ID}
        isMobilePanelEmbedLayout={isMobilePanelEmbedLayout}
        onEdit={enablePanels}
        style={getStyle(hidden, isDesktopLayout)}
        testId="editSidebarLanding"
        titleKey="ADMIN_SIDEBAR_SHEET_TITLE"
      >
        {!isMobilePanelEmbedLayout &&
          isDesktopLayout &&
          !showPanel &&
          <StyledSidebarAuthHeader />}

        {this.renderSidebarBody()}

        {panelFooter}
        {otherRegionEditing && (
          <CaptureClick onClick={!isLogoTarget ? disablePanels : undefined}>
            <Blackout invisible={isLogoTarget} />
          </CaptureClick>
        )}
      </StyledQuickEditOverlay>
    );
  }

  render() {
    const {
      adminBarWidth,
      blur,
      editing,
      isSidebarVisible,
      disablePanels,
      sidebarWidth,
    } = this.props;
    return (
      <>
        {this.renderDefault()}
        <Transition in={editing} timeout={SLIDE_MS * 0.9}>
          {(state) => (
            <EditSheetContainer
              adminBarWidth={adminBarWidth}
              blur={blur}
              sidebarVisible={isSidebarVisible}
              sidebarWidth={sidebarWidth}
              state={state}
            >
              <ExitEditSidebar onClick={disablePanels} />
              <PanelsV2 onStopEditing={disablePanels} state={state} />
            </EditSheetContainer>
          )}
        </Transition>
      </>
    );
  }
}

export default withT(Sidebar);
