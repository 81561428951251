import { SubscriptionRecurrence } from 'models/ISubscription';
import React, { useMemo } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { SubscriptionSuffix } from './styles';

interface IProps {
  formattedPrice: string;
  recurrence?: SubscriptionRecurrence;
}

export default function FormattedPriceWithSuffix({
  formattedPrice,
  recurrence,
}: IProps) {
  const isMonthlySubscription = useMemo(() => {
    return recurrence === SubscriptionRecurrence.month;
  }, [recurrence]);
  const isAnnualSubscription = useMemo(() => {
    return recurrence === SubscriptionRecurrence.year;
  }, [recurrence]);

  return (
    <>
      {`${formattedPrice}`}
      {isMonthlySubscription ? (
        <SubscriptionSuffix data-testid="monthlySubscriptionFormattedPriceSuffix">
          <TranslatedText stringKey="SUBSCRIPTION_MONTH_PRICE_SUFFIX" />
        </SubscriptionSuffix>
      ) : null}
      {isAnnualSubscription ? (
        <SubscriptionSuffix data-testid="annualSubscriptionFormattedPriceSuffix">
          <TranslatedText stringKey="SUBSCRIPTION_YEAR_PRICE_SUFFIX" />
        </SubscriptionSuffix>
      ) : null}
    </>
  );
}
