import styled, { IStyledProps } from 'styled-components';
import { ADMIN_HIGHLIGHT_SECONDARY, HIGHLIGHT_SECONDARY } from 'style/constants';
import Icon from 'components/ui/Icon';

export const ThreeDotsContainer = styled.div`
  position: relative;
`;

export const ThreeDotsButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
`;

export const ThreeDotsIcon = styled(Icon).attrs((props: IStyledProps & {
  isAdmin: boolean,
}) => ({
  color: props.isAdmin ? ADMIN_HIGHLIGHT_SECONDARY(props) : HIGHLIGHT_SECONDARY(props),
  name: 'threeDots',
}))<{ isAdmin: boolean }>`
  width: fit-content;
  & svg {
    display: block;
    width: 14px;
    height: 14px;

    margin-right: 0;
  }

  transform: rotate(90deg);
`;
