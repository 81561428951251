import React, { ComponentProps } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  Button,
} from './styles';

type AddPaymentButtonProps = ComponentProps<typeof Button> & Partial<Pick<ComponentProps<typeof TranslatedText>, 'stringKey'>>;

export default function AddPaymentButton({ children, stringKey = 'ADD_NEW_PAYMENT_METHOD', ...props }: AddPaymentButtonProps) {
  return children ? <Button {...props}>{children}</Button> : (
    <TranslatedText component={Button} componentProps={props} stringKey={stringKey} />
  );
}
