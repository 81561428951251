import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import withPadding from 'components/core/withPadding';
import withLabel from 'components/core/withLabel';
import useFocusArea from 'hooks/use-focus-area';
import moment from 'moment-timezone';
import useCalendar from 'hooks/use-calendar';
import DayPicker from './picker/DayPicker';
import WeekPicker from './picker/WeekPicker';
import QuarterPicker from './picker/QuarterPicker';
import MonthPicker from './picker/MonthPicker';
import YearPicker from './picker/YearPicker';
import MonthYearSelector from './picker/MonthYearSelector';
import {
  CalendarButton,
  CalendarButtonArrow,
  CalendarButtonTitle,
  CalendarWrapper,
  ClearButton,
  StyledExpandable,
  StyledIcon,
} from './styles';
import { CalendarProps, Picker } from './interfaces';
import { useAdminTranslation } from 'hooks/use-translation';

export type {
  CalendarProps,
  CalendarStateProps,
  CalendarStyleProps,
  DayCalendarProps,
  RangePickerProps,
  Dispatcher,
} from './interfaces';

export {
  Picker,
} from './interfaces';

function CalendarComponent(props: CalendarProps) {
  const {
    admin,
    className,
    closeOnPick = true,
    date,
    defaultPicker = Picker.day,
    endDate,
    expandableBackGroundColor,
    footerTextKey,
    hideClearButton,
    id,
    isDisabled,
    isOutsideRange,
    onClear,
    onDateChange,
    onDatesChange,
    placeholder,
    startDate,
    startingWeekDay = 'Mo',
    iconButtonRight,
    calendarPosition,
  } = props;
  const calendarDate = useMemo(() => (
    date || startDate || moment()
  ), [date?.valueOf(), startDate?.valueOf()]);
  const { t } = useAdminTranslation();

  const selectedDate = useMemo(() => date ? ({
    day: date.date(),
    month: date.month(),
    year: date.year(),
  }) : undefined, [date?.valueOf()]);

  const [calendarMonth, setCalendarMonth] = useState(calendarDate.month());
  const [calendarYear, setCalendarYear] = useState(calendarDate.year());

  const {
    monthsLong,
    monthsShort,
    visibleDays,
    weekDays,
  } = useCalendar({
    isOutsideRange,
    month: calendarMonth,
    selectedDate,
    startingWeekDay,
    year: calendarYear,
  });

  const [isOpen, setOpen] = useState(false);
  const [currentPicker, setCurrentPicker] = useState(defaultPicker);
  const [isMonthYearSelectorVisible, setIsMonthYearSelectorVisible] = useState(false);
  const renderMonthSelector = useMemo(() => (
    defaultPicker === Picker.day ||
    defaultPicker === Picker.week
  ), [defaultPicker]);
  const expandableRef = useFocusArea<HTMLDivElement>({
    onExit: () => setOpen(false),
    active: isOpen,
  });

  const toggleOpen = useCallback(() => {
    setOpen(open => !open);
  }, [setOpen]);

  const clearDate: MouseEventHandler = (e) => {
    e.stopPropagation();
    onDateChange?.();
    onDatesChange?.();
    onClear?.();
  };

  const dateTitle = useMemo(() => {
    switch (defaultPicker) {
      case Picker.day:
        return date && t('LABEL_DATE_FORMAT_FULL', {
          day: date.date(),
          month: monthsLong[date.month()],
          year: date.year(),
        });
      case Picker.week:
        if (!(startDate && endDate)) {
          return placeholder;
        }
        const startFormat = startDate.format('MMMM Do');
        const endFormat = endDate.format('Do, YYYY');
        return `${startFormat} - ${endFormat}`;
      case Picker.month:
        if (!(startDate && endDate)) {
          return placeholder;
        }
        return `${monthsShort[calendarMonth]} ${calendarYear}`;
      case Picker.quarter:
        if (!(startDate && endDate)) {
          return placeholder;
        }
        const month = startDate.month();
        const quarterIndex = Math.floor(month / 3);
        const firstMonth = quarterIndex * 3;
        const quarter = monthsShort.slice(firstMonth, firstMonth + 3);
        return `Q${quarterIndex + 1} - ${quarter.join(' | ')} /${startDate.format('YY')}`;
      case Picker.year:
        if (!(startDate && endDate)) {
          return placeholder;
        }
        return calendarYear.toString();
      default:
        return placeholder;
    }
  }, [date?.valueOf(), startDate?.valueOf(), endDate?.valueOf(), monthsShort, calendarMonth, calendarYear, defaultPicker]);

  const monthYearTitle = useMemo(() => {
    const monthYear = moment([calendarYear, calendarMonth]);
    return t('LABEL_DATE_FORMAT_MONTH_YEAR', {
      month: monthsLong[monthYear.month()],
      year: monthYear.year(),
    });
  }, [calendarMonth, calendarYear]);

  const nextMonth = () => {
    const next = calendarMonth + 1;
    if (next > 11) {
      setCalendarMonth(0);
      setCalendarYear(year => year + 1);
      return;
    }
    setCalendarMonth(next);
  };

  const prevMonth = () => {
    const prev = calendarMonth - 1;
    if (prev < 0) {
      setCalendarMonth(11);
      setCalendarYear(year => year - 1);
      return;
    }
    setCalendarMonth(prev);
  };

  const nextYear = () => {
    setCalendarYear(year => year + 1);
  };

  const prevYear = () => {
    const prev = calendarYear - 1;
    if (prev > 0) {
      setCalendarYear(prev);
    }
  };

  const toggleMonthYearSelector = () => {
    setIsMonthYearSelectorVisible((isVisible) => !isVisible);
  };

  const showDefaultPicker = () => {
    setCurrentPicker(defaultPicker);
  };

  const renderPicker = () => {
    const pickerProps = {
      ...props,
      calendarMonth,
      calendarYear,
      dateTitle,
      isMonthYearSelectorVisible,
      setCalendarMonth,
      setCalendarYear,
      setCurrentPicker,
      showDefaultPicker,
      toggleMonthYearSelector,
    };
    const dayCalendarProps = {
      ...pickerProps,
      footerTextKey,
      monthYearTitle,
      nextMonth,
      nextYear,
      prevMonth,
      prevYear,
      visibleDays,
      weekDays,
    } as const;
    const rangeDateProps = {
      endDate,
      onDatesChange,
      startDate,
    };
    switch (currentPicker) {
      case Picker.day:
        return (
          <DayPicker
            monthsShort={monthsShort}
            {...dayCalendarProps}
          />
        );
      case Picker.week:
        return (
          <WeekPicker
            {...dayCalendarProps}
            {...rangeDateProps}
          />
        );
      case Picker.quarter:
        return (
          <QuarterPicker
            monthsShort={monthsShort}
            {...dayCalendarProps}
            {...rangeDateProps}
          />
        );
      case Picker.month:
        return (
          <MonthPicker
            monthsLong={monthsLong}
            monthsShort={monthsShort}
            {...dayCalendarProps}
            {...rangeDateProps}
          />
        );
      case Picker.year:
        return (
          <YearPicker
            {...dayCalendarProps}
            {...rangeDateProps}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setCurrentPicker(defaultPicker);
    setCalendarYear(calendarDate.year());
    setCalendarMonth(calendarDate.month());
    setIsMonthYearSelectorVisible(false);
  }, [isOpen]);

  useEffect(() => {
    if (closeOnPick) setOpen(false);
  }, [selectedDate]);

  useEffect(() => {
    setIsMonthYearSelectorVisible(false);
  }, [calendarMonth, calendarYear]);

  const renderRightButtonIcon = () => iconButtonRight ?
    <StyledIcon name={iconButtonRight} /> : <CalendarButtonArrow admin={admin} up={isOpen} />;

  return (
    <CalendarWrapper data-testid={'calendar'} admin={admin} className={className} id={id} isDisabled={isDisabled} ref={expandableRef}>
      <CalendarButton data-testid={'calendar-button'} admin={admin} isOpen={isOpen} onClick={toggleOpen}>
        {dateTitle ? (
          <CalendarButtonTitle>{dateTitle}</CalendarButtonTitle>
        ) : (
            <TranslatedText component={CalendarButtonTitle} stringKey="ADMIN_LABEL_DATE_FORMAT" />
          )}
        {!hideClearButton && <ClearButton onClick={clearDate} />}
        {renderRightButtonIcon()}
      </CalendarButton>
      <StyledExpandable isExpanded={isOpen} calendarPosition={calendarPosition} backgroundColor={expandableBackGroundColor}>
        {renderPicker()}
        {isMonthYearSelectorVisible && (
          <MonthYearSelector
            admin={admin}
            calendarMonth={calendarMonth}
            calendarYear={calendarYear}
            monthsShort={monthsShort}
            renderMonths={renderMonthSelector}
            setCalendarMonth={setCalendarMonth}
            setCalendarYear={setCalendarYear}
          />
        )}
      </StyledExpandable>
    </CalendarWrapper>
  );
}

export default withPadding(withLabel(CalendarComponent));
