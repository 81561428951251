import visa from 'assets/visa.png';
import mastercard from 'assets/mastercard.png';
import amex from 'assets/amex.png';
import { PaymentMethod } from '@stripe/stripe-js';
import { IBillingInfo } from 'services/billing';

export const BRAND_MAP = {
  visa,
  mastercard,
  amex,
};

export const makeBillingInfoFromPaymentMethod = (pm: PaymentMethod): IBillingInfo => {
  return {
    complete: true,
    countryCode: pm.billing_details.address?.country || '',
    error: null,
    markDefaultPaymentMethod: false,
    name: pm.billing_details.name || '',
    postalCode: pm.billing_details.address?.postal_code || '',
    stateCode: pm.billing_details.address?.state || '',
  };
};
