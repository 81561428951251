import React from 'react';
import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { ACCENT_PRIMARY, SURFACE_2, SURFACE_5, TEXT_100 } from 'style/constants';
import { bw } from 'colors';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onChangeChecked?: (checked: boolean) => void;
  padding?: number;
  size: number;
}

const Label = styled.label<CheckboxProps>`
  border: 1px solid ${({ checked }) => checked ? ACCENT_PRIMARY : SURFACE_5};
  background-color: ${({ checked }) => checked ? ACCENT_PRIMARY : SURFACE_2};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding}px;
  cursor: pointer;
  ${({ size }) => `
    min-width: ${size}px;
    min-height: ${size}px;
    max-width: ${size}px;
    max-height: ${size}px;
  `}
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked + span {
    display: flex;
  }
`;

const CheckIcon = styled(Icon).attrs((props) => ({
  color:  bw(ACCENT_PRIMARY(props)),
  name: 'checkboxCheck',
}))`
  display: none;
`;

export default function Checkbox({ className, padding = 4, size = 24, children, onChange, onChangeChecked, ...props }: CheckboxProps) {
  const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    onChangeChecked?.(e.target.checked);
  }, [onChange, onChangeChecked]);

  return (
    <Label className={className} padding={padding} size={size} checked={props.checked}>
      <Input type="checkbox" {...props} onChange={handleChange} />
      <CheckIcon />
    </Label>
  );
}
