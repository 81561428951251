import React, { MouseEventHandler, useMemo, useRef } from 'react';
import PanelBadgeProvider from './PanelBadgeProvider';
import { PANEL_SELECT_ID } from 'global-ids';
import { OPTION, ACTIVE, ICON } from 'injection-classes';
import { PLATFORM } from 'config';
import { getIcon, WRAPPER_WIDTH } from './utils';
import {
  Wrapper,
  Badge,
  Container,
  PanelButton,
  PanelButtonTransform,
  PanelButtonWrapper,
} from './styles';
import IPanel from 'models/IPanel';

interface MobilePanelSelectorProps {
  activeId?: string;
  className?: string;
  hidden?: boolean;
  isMobileLayout: boolean;
  panels: IPanel<{}>[];
  onSelect(id: string): void;
}

export default function MobilePanelSelector({
  activeId = '',
  className,
  hidden,
  panels,
  onSelect,
}: MobilePanelSelectorProps) {
  const selectorWidth = useRef(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const maxButtons = useMemo(
    () => Math.round(selectorWidth.current / WRAPPER_WIDTH) - 0.5,
    [selectorWidth.current],
  );

  const minWidth = useMemo(
    () => Math.max(selectorWidth.current / maxButtons, WRAPPER_WIDTH),
    [selectorWidth.current, maxButtons],
  );

  const getContainerRef = (node: HTMLDivElement | null) => {
    containerRef.current = node;
    if (!node) {
      return;
    }
    selectorWidth.current = node.getBoundingClientRect().width;
  };

  const scrollIntoView: MouseEventHandler<HTMLDivElement> = ({
    currentTarget: target,
  }) => {
    if (!containerRef.current) {
      return;
    }

    const scrollRect = containerRef.current.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();
    const buttonLeftHidden = targetRect.left < scrollRect.left;

    if (buttonLeftHidden || targetRect.right > scrollRect.right) {
      target.scrollIntoView({
        behavior: 'smooth',
        inline: buttonLeftHidden ? 'end' : 'start',
      });
    }
  };

  return (
    <Wrapper>
      <Container
        className={className}
        hidden={hidden}
        id={PANEL_SELECT_ID}
        ref={getContainerRef}
      >
        {panels.map((doc, index) => {
          if (!doc) {
            return null;
          }

          if (
            doc?.renderer?.disableOnMobileEmbedded === true &&
            PLATFORM === 'mobile-embedded'
          ) {
            return null;
          }
          const id = doc._id;
          const panelName = doc.renderer?.panelName || doc.data?.name;
          const panelType = doc.renderer?.panelType || doc.data?.kind;
          const iconName = doc.renderer?.iconName || doc.data?.icon;
          const isActive = id === activeId;
          const fullClassName = `${OPTION} ${isActive && ACTIVE}`;
          const handleSelect: MouseEventHandler<HTMLDivElement> = (e) => {
            onSelect(id);
            scrollIntoView(e);
          };
          return (
            <PanelButtonTransform onClick={handleSelect} minWidth={minWidth} key={id}>
              <PanelButtonWrapper
                active={isActive}
                className={fullClassName}
                data-testid={`livePanelButton-${index}`}
                title={panelName || ''}
              >
                <PanelBadgeProvider panelId={id} panelType={panelType}>
                  {(hasBadge) => {
                    return (
                      <>
                        {hasBadge && <Badge />}
                        <PanelButton
                          className={ICON}
                          isActive={isActive}
                          name={getIcon(panelType as any, true, iconName)}
                        />
                      </>
                    );
                  }}
                </PanelBadgeProvider>
              </PanelButtonWrapper>
            </PanelButtonTransform>
          );
        })}
      </Container>
    </Wrapper>
  );
}
