import React from 'react';
import { BrandImage, CardInfo, CardOwnerInfo, PaymentMethodInfo } from './styles';
import { PaymentMethod } from '@stripe/stripe-js';
import { capitalize } from 'lodash';
import { BRAND_MAP } from '../constants';
import { RadioInputCard } from 'components/ui/RadioInputCard';

interface IPaymentMethodCard {
  isSelected: boolean;
  onClick: (payment: PaymentMethod) => void;
  paymentMethod: PaymentMethod;
}

const PaymentMethodCard: React.FC<IPaymentMethodCard> = ({ paymentMethod, isSelected, onClick }) => {
  const handleClick = () => {
    onClick(paymentMethod);
  };

  const { brand, expMonth, expYear, last4, name } = React.useMemo(() => {
    return {
      brand: capitalize(paymentMethod?.card?.brand),
      last4: paymentMethod?.card?.last4,
      expMonth: paymentMethod?.card?.exp_month || '',
      expYear: paymentMethod?.card?.exp_year || '',
      name: paymentMethod?.billing_details?.name || '',
    };
  }, [paymentMethod]);

  const branchLogo = React.useMemo(() => {
    return BRAND_MAP[brand.toLowerCase()];
  }, [brand]);

  return (
    <RadioInputCard name="payment-method-cards" checked={isSelected} value={paymentMethod.id} onClick={handleClick}>
      {branchLogo && <BrandImage src={branchLogo} />}
      <PaymentMethodInfo>
        <CardInfo>{brand} *{last4}</CardInfo>
        <CardOwnerInfo>{name}&nbsp;•&nbsp;{expMonth}/{expYear}</CardOwnerInfo>
      </PaymentMethodInfo>
    </RadioInputCard>
  );
};


export default PaymentMethodCard;
