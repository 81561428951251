import React, { useCallback } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import Skeleton from 'components/ui/Skeleton';
import { HEADER } from 'injection-classes';
import AchievementPointsHeader from './AchievementPointsHeader';
import FloatingCartButton from 'components/Ecommerce/Cart/FloatingCartButton';
import { getUserAvatar, getUserName } from 'services/user-profile';
import { getBusy, isLoggedIn } from 'services/auth';
import { isSiteLoginEnabled as getIsSiteLoginEnabled } from 'services/app';
import { isSidebarVisible as getIsSidebarVisible } from 'services/user-layout';
import { getIsShopifyConnected } from 'services/shopify/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { loginModal } from 'services/modals';
import { openProfilePanel } from 'services/custom-panels/actions';
import { useAdminTranslation } from 'hooks/use-translation';
import {
  AuthHeaderWrapper,
  ProfPic,
  ProfilePicContainer,
  StyledIcon,
  StyledSidebarActionButton,
  UserContainer,
  UsernameWrapper,
} from './styles';
import { IS_MARKETING_SITE } from 'config';
import MarketingAuthHeader from '../MarketingAuthHeader';

interface ISidebarAuthHeaderProps {
  className?: string;
  isHeader: boolean;
  onLogInClick: () => void;
  onProfileClick: () => void;
}

const SidebarAuthHeader: React.FC<ISidebarAuthHeaderProps> = ({
  className = '',
  isHeader = false,
}) => {
  const busy = useSelector(getBusy);
  const isSidebarVisible = useSelector(getIsSidebarVisible);
  const isSiteLoginEnabled = useSelector(getIsSiteLoginEnabled);
  const loggedIn = useSelector(isLoggedIn);
  const userAvatar = useSelector(getUserAvatar);
  const userName = useSelector(getUserName) || null;
  const isShopifyConnected = useSelector(getIsShopifyConnected);
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();

  const onLogInClick = useCallback(() => {
    dispatch(loginModal());
  }, []);

  const onProfileClick = useCallback(() => {
    dispatch(openProfilePanel());
  }, []);

  const renderAchievementPoints = useCallback(() => {
    return <AchievementPointsHeader />;
  }, []);

  const renderCartIcon = useCallback(() => {
    if (!isShopifyConnected) {
      return null;
    }
    return (
      <FloatingCartButton />
    );
  }, [isShopifyConnected]);

  const renderLoggedOutContent = React.useCallback(() => {
    if (IS_MARKETING_SITE) {
      return <MarketingAuthHeader onClick={onLogInClick} />;
    }

    return (
      <AuthHeaderWrapper
        className={className}
        data-testid={!isShopifyConnected ? 'user-login-action' : undefined}
        isHeader={isHeader}
        isSidebarVisible={isSidebarVisible}
        isShopifyConnected={isShopifyConnected}
        onClick={!isShopifyConnected ? onLogInClick : undefined}
      >
        <UserContainer
          isHeader={isHeader}
          isShopifyConnected={isShopifyConnected}
        >
          <StyledIcon
            isHeader={isHeader}
            name="user"
            onClick={!isShopifyConnected ? undefined : onLogInClick}
          />
          <UsernameWrapper
            onClick={!isShopifyConnected ? undefined : onLogInClick}
            data-testid={!isShopifyConnected ? undefined : 'user-login-action'}
          >
            {t('ACTION_LOG_IN')}
          </UsernameWrapper>
        </UserContainer>
        {renderCartIcon()}
      </AuthHeaderWrapper>
    );
  }, [
    className,
    isHeader,
    isShopifyConnected,
    isSidebarVisible,
    onLogInClick,
    renderCartIcon,
    t,
  ]);

  const renderLoggedInContent = React.useCallback(() => {
    if (!userName) {
      return <Skeleton type="sidebarAuthHeader" />;
    }

    return (
      <AuthHeaderWrapper
        isHeader={isHeader}
        isSidebarVisible={isSidebarVisible}
        isShopifyConnected={isShopifyConnected}
        onClick={!isShopifyConnected ? onProfileClick : undefined}
      >
        <UserContainer
          isHeader={isHeader}
          isShopifyConnected={isShopifyConnected}
        >
          <ProfilePicContainer
            data-testid="userProfileViewButton"
            onClick={!isShopifyConnected ? undefined : onProfileClick}
          >
            <ProfPic src={userAvatar} />
          </ProfilePicContainer>
          <UsernameWrapper
            data-testid="user-username"
            onClick={!isShopifyConnected ? undefined : onProfileClick}
          >
            {userName}
            {renderAchievementPoints()}
          </UsernameWrapper>
        </UserContainer>
        {renderCartIcon()}
      </AuthHeaderWrapper>
    );
  }, [
    isHeader,
    isShopifyConnected,
    isSidebarVisible,
    onProfileClick,
    renderAchievementPoints,
    renderCartIcon,
    userAvatar,
    userName,
  ]);

  const renderContent = React.useCallback(() => {
    if (loggedIn) {
      return renderLoggedInContent();
    }

    return renderLoggedOutContent();
  }, [loggedIn, renderLoggedInContent, renderLoggedOutContent]);

  if (busy) {
    return (
      <StyledSidebarActionButton
        className={className}
        isHeader={isHeader}
        loading={true}
      >
        <TranslatedText stringKey="LOADING" />
      </StyledSidebarActionButton>
    );
  }

  if (!isSiteLoginEnabled && !loggedIn) {
    return null;
  }
  return (
    <div className={`${className} ${HEADER}`} data-testid="sidebar-auth-header">
      {renderContent()}
    </div>
  );
};

export default SidebarAuthHeader;
