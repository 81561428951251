/* eslint react/destructuring-assignment: off */
import React from 'react';
import PropTypes from 'prop-types';

export default class CountTo extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    durationMs: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    from: PropTypes.number,
    target: PropTypes.number.isRequired,
  };

  static defaultProps = {
    durationMs: 1000,
    from: 0,
  };

  state = {
    value: this.props.from,
  };

  componentDidMount() {
    this.init({ ...this.props });
  }

  componentDidUpdate(prevProps, prevState) {
    const { value: prevValue } = prevState;
    const { from, target } = this.props;
    if (prevProps.from === from && prevProps.target === target) { return; }
    this.init({ ...this.props, from: prevValue });
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  init(props) {
    const {
      from,
      target,
      durationMs,
    } = props;

    const { value } = this.state;
    const steps = Math.ceil(durationMs / 25);
    const delay = Math.ceil(durationMs / steps);

    clearInterval(this.intervalID);
    this.intervalID = setInterval(this.tick, delay);
    this.stepAmount = (target - from) / steps;

    //  If we hit this props were reassigned, reset/start over
    if (value !== from) {
      this.setState({
        value: from,
      });
    }
  }

  tick = () => {
    const { target } = this.props;
    const { value } = this.state;

    const valueFunc = target > value ? Math.min : Math.max;

    this.setState({
      value: valueFunc(value + this.stepAmount, target),
    }, () => {
      const { value: currentValue } = this.state;
      if (currentValue === target) {
        clearInterval(this.intervalID);
      }
    });
  };

  render() {
    const { children: func } = this.props;
    const { value } = this.state;
    return func(value);
  }
}
