import styled from 'styled-components';

export interface ExpandableStyleProps {
  backgroundColor?: string;
  className?: string;
  collapsedHeight?: string;
  isExpanded?: boolean;
}

export const ExpandableWrapper = styled.div<{
  alwaysExpanded?: boolean,
  fixedExpandedHeight: string,
  height: number,
} & ExpandableStyleProps>`
  overflow: auto;
  background-color: ${({ backgroundColor }) => backgroundColor};
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  transition: height 0.5s;
  height: ${({ collapsedHeight }) => collapsedHeight};
  ${({ height, isExpanded }) => isExpanded && `
    height: ${height}px;
  `}
  ${({ fixedExpandedHeight, isExpanded }) => isExpanded && fixedExpandedHeight && `
    height: ${fixedExpandedHeight}};
  `}
  ${({ alwaysExpanded }) => alwaysExpanded && 'height: max-content;'}
  pointer-events: none;
`;

export const ExpandableContainer = styled.div<ExpandableStyleProps>`
  width: 100%;
  display: flex;
  min-height: ${({ collapsedHeight }) => collapsedHeight};
  height: max-content;
  pointer-events: all;
  & > * {
    flex: 1;
  }
`;
