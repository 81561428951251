import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  $getSelection,
  $getNodeByKey,
  DecoratorNode,
  NodeKey,
  SerializedLexicalNode,
  LexicalNode,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import useUpdateListener from '../../use-update-listener';
import { AddIcon } from 'components/page-blocks/ImageAndText/ImageEditorModal/styles';
import EditorModal from 'components/admin-bridge/EditorModal';
import OptionRow, {
  Option,
} from 'components/admin-bridge/EditorModal/ModalComponents/OptionRow';
import { $toggleNodeInSelection } from '../../utils';
import UploadZone from 'components/admin2/UploadZone';
import { useAdminTranslation } from 'hooks/use-translation';
import { ImageWrapper, StyledImage, StyledImagePlaceholder } from './styles';
import TextInput from 'components/admin2/TextInput';
import { SectionTitle } from 'components/admin-bridge/EditorModal/ModalComponents/Section';
import { useCachedCallback } from 'hooks/use-cached-callback';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';

type SerializedImageNode = SerializedLexicalNode & {
  props: ImageNodeProps;
};

export class ImageNode extends DecoratorNode<ReactNode> {
  props: ImageNodeProps;

  constructor(props: ImageNodeProps, key?: NodeKey) {
    super(key);
    this.props = props;
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(node.props, node.__key);
  }

  static getType(): string {
    return 'image';
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    return new ImageNode(serializedNode.props);
  }

  createDOM(): HTMLElement {
    const container = document.createElement('div');
    container.style.display = 'contents';
    return container;
  }

  decorate(): ReactNode {
    return <ImageComponent {...this.props} lexicalKey={this.getKey()} />;
  }

  exportJSON(): SerializedImageNode {
    return {
      props: this.props,
      type: ImageNode.getType(),
      version: 1,
    };
  }

  updateDOM(): false {
    return false;
  }
}

export type ImageNodeProps = {
  altText: string;
  imageUrl: string;
  layout: 'left' | 'center' | 'right' | 'full-width';
};

export function $createImageNode(
  props: ImageNodeProps,
  key?: string,
): ImageNode {
  return new ImageNode(props, key);
}

export function $isImageNode(node: LexicalNode | null): node is ImageNode {
  return node instanceof ImageNode;
}

export const ImageComponent = ({
  imageUrl,
  altText,
  layout,
  lexicalKey,
}: ImageNodeProps & {
  lexicalKey: string;
}) => {
  const [editor] = useLexicalComposerContext();

  const [selected, setSelected] = useState(false);

  const toggleSelection = useCallback(() => {
    editor.update(() => {
      $toggleNodeInSelection(lexicalKey);
    });
  }, [editor, lexicalKey]);

  useUpdateListener(
    editor,
    useCallback(() => {
      setSelected(
        Boolean($getNodeByKey(lexicalKey)?.isSelected($getSelection())),
      );
    }, [lexicalKey]),
  );

  const updateNodeData = useCachedCallback(
    useCallback(
      <K extends keyof ImageNodeProps>(
        key: K,
      ): ((value?: ImageNodeProps[K]) => void) => {
        return (value) => {
          editor.update(() => {
            const node = $getNodeByKey(lexicalKey);

            if (!$isImageNode(node)) {
              return;
            }

            const newNode = node.getWritable();

            switch (key) {
              case 'layout':
                newNode.props[key] = value!;
              default:
                newNode.props[key] = (value ?? '') as ImageNodeProps[K];
            }
          });
        };
      },
      [editor, lexicalKey],
    ),
  );

  const firstPopupShowed = useRef(false);

  useEffect(() => {
    if (imageUrl === '' && !firstPopupShowed.current) {
      toggleSelection();
      firstPopupShowed.current = true;
    }
  }, [imageUrl, toggleSelection]);

  const [xy, setXY] = useState({ x: NaN, y: NaN });

  const capturePosition = useCallback(
    (el: HTMLElement | null | undefined) => {
      if (!el) {
        return;
      }

      setTimeout(() => {
        const rect = el.getBoundingClientRect();

        setXY({
          y: rect.top + 40,
          x: ['left', 'center'].includes(layout)
            ? rect.left + rect.width + 40
            : layout === 'right'
            ? rect.left - 375 - 40
            : rect.left + 40,
        });
      }, 10);
    },
    [layout],
  );

  const { t } = useAdminTranslation();
  const inEditMode = useSelector(isEditMode);

  return (
    <ImageWrapper layout={layout}>
      {imageUrl === '' ? (
        <StyledImagePlaceholder
          ref={capturePosition}
          as="button"
          onClick={inEditMode ? toggleSelection : undefined}
          className={selected ? 'selected' : ''}
          layout={layout}
          isEditing={inEditMode}
          disabled={!inEditMode}
        >
          {inEditMode && (
            <>
              <AddIcon name="addImage" />
              {t('ADMIN_LABEL_ADD_IMAGE')}
            </>
          )}
        </StyledImagePlaceholder>
      ) : (
        <StyledImage
          ref={capturePosition}
          src={imageUrl}
          alt={altText}
          onClick={inEditMode ? toggleSelection : undefined}
          className={selected ? 'selected' : ''}
          layout={layout}
          isEditing={inEditMode}
        />
      )}
      {selected && !Number.isNaN(xy.x) && (
        <EditorModal
          isOpen={true}
          onClose={toggleSelection}
          initialPosition={xy}
          modalTitleKey={'ADMIN_LABEL_IMAGE_SETTINGS'}
        >
          <UploadZone
            canDrop={true}
            imagePreview={imageUrl === '' ? undefined : imageUrl}
            onClearImage={updateNodeData('imageUrl')}
            onFileSubmit={updateNodeData('imageUrl')}
          />
          <SectionTitle>{t('ADMIN_LABEL_IMAGE_ALT_TEXT')}</SectionTitle>
          <TextInput
            value={altText}
            placeholderKey="ADMIN_LABEL_IMAGE_ALT_TEXT_DESC"
            padding="0px"
            onChange={updateNodeData('altText')}
          />
          <SectionTitle>{t('ADMIN_LABEL_LAYOUT')}</SectionTitle>
          <OptionRow
            onChange={updateNodeData('layout')}
            options={alignmentOptions}
            value={layout}
            wrapText={true}
          />
        </EditorModal>
      )}
    </ImageWrapper>
  );
};

const alignmentOptions: Option<ImageNodeProps['layout']>[] = [
  { value: 'left', labelKey: 'LABEL_LEFT', icon: 'alignLeft' },
  { value: 'center', labelKey: 'LABEL_CENTER', icon: 'alignCenter' },
  { value: 'right', labelKey: 'LABEL_RIGHT', icon: 'alignRight' },
  {
    value: 'full-width',
    labelKey: 'ADMIN_LABEL_FULL_WIDTH',
    icon: 'fullWidth',
  },
];
