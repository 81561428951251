import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection } from 'lexical';
import { ToolbarButton } from '../../styles';
import imageLibraryIcon from 'assets/icons/rich-text-editor-v3/imageLibrary.svg';
import { $createImageNode } from '../../nodes/ImageNode';

const ImagePlugin = () => {
  const [editor] = useLexicalComposerContext();

  const onClick = React.useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();

      if (!selection) {
        return;
      }

      selection.insertNodes([
        $createImageNode({
          imageUrl: '',
          altText: '',
          layout: 'left',
        }),
      ]);
    });
  }, [editor]);

  return (
    <ToolbarButton
      aria-label="Insert Image"
      active={false}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: imageLibraryIcon }}
    />
  );
};

export default ImagePlugin;
