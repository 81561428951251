import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { LeftButton, RightButton } from 'components/ui/Button';
import {
  CarouselContainer,
  CarouselContent,
} from './styles';
import withPadding from 'components/core/withPadding';

type CarouselProps = PropsWithChildren<{
  className?: string;
  maxItems?: number;
}>;

function Carousel({
  children,
  className,
  maxItems = 1,
}: CarouselProps) {
  const [childCount, setChildCount] = useState(0);
  const childWidth = useMemo(() => 100 / maxItems, [maxItems]);
  const [currPageIndex, setCurrPageIndex] = useState(0);
  const prevPageIndex = useMemo(() => currPageIndex - 1, [currPageIndex]);
  const nextPageIndex = useMemo(() => currPageIndex + 1, [currPageIndex]);
  const noPrevPage = useMemo(() => prevPageIndex < 0, [prevPageIndex]);
  const noNextPage = useMemo(() => (
    maxItems * nextPageIndex >= childCount
  ), [nextPageIndex, maxItems, childCount]);
  const singlePage = useMemo(() => childCount <= maxItems, [childCount, maxItems]);

  const translation = useMemo(() => (
    currPageIndex && -Math.min(maxItems * currPageIndex, childCount - maxItems) * 100
  ), [currPageIndex, maxItems, childCount]);

  const goToPrevPage = useCallback(() => setCurrPageIndex(pageIndex => pageIndex - 1), [setCurrPageIndex]);
  const goToNextPage = useCallback(() => setCurrPageIndex(pageIndex => pageIndex + 1), [setCurrPageIndex]);

  const getChildCount = (node: HTMLDivElement | null) => {
    const count = node?.childNodes?.length;
    if (!count) {
      return;
    }
    setChildCount(count);
  };

  return (
    <CarouselContainer className={className} ref={singlePage ? getChildCount : undefined} singlePage={singlePage}>
      {singlePage ? children : (
        <>
          <LeftButton disabled={noPrevPage} disabledOpacity={0} onClick={goToPrevPage} />
          <CarouselContent ref={getChildCount} childWidth={childWidth} translation={translation}>
            {children}
          </CarouselContent>
          <RightButton disabled={noNextPage} disabledOpacity={0} onClick={goToNextPage} />
        </>
      )}
    </CarouselContainer>
  );
}

export default withPadding(Carousel, '10px');
