import React from 'react';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import {
  IconLabelContainer,
  StyledIcon,
  StyleProps,
  Text,
} from './styles';
import type { TranslationKey } from 'hooks/use-translation';

export interface IconLabelProps extends StyleProps {
  hidden?: boolean;
  icon?: string;
  text?: string;
  textKey?: TranslationKey
}

export default function IconLabel({
  hidden,
  icon,
  iconColor,
  iconHeight,
  iconWidth,
  text,
  textKey,
  ...styleProps
}: IconLabelProps) {
  return hidden ? null: (
    <IconLabelContainer {...styleProps}>
      {icon ? (
        <StyledIcon
          background={styleProps.background}
          color={iconColor}
          iconHeight={iconHeight}
          iconWidth={iconWidth}
          name={icon}
        />
      ) : null}
      {(text || textKey) ? (
        <OptionalTranslated component={Text} stringKey={textKey}>
          {text}
        </OptionalTranslated>
      ) : null}
    </IconLabelContainer>
  );
}
