import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRegionRenderingStripeCreditCard, RegionRenderingStripeCreditCardEnum } from 'services/billing';

interface IStripeCreditCardSafeViewProps {
  regionRenderingStripeCreditCard: RegionRenderingStripeCreditCardEnum;
}

const MIN_INTERVAL_TO_RENDER_STRIPE_CREDIT_CARD_ELEMENT = 0;

// Stripe only allow render one card element at a time (security reason)
// this Component in combine with RegionRenderingStripeCreditCardEnum will help to manage this
const StripeCreditCardSafeView: React.FC<PropsWithChildren<IStripeCreditCardSafeViewProps>> = ({ children, regionRenderingStripeCreditCard }) => {
  const [ready, setReady] = useState(false);
  const reduxRegionRenderingStripeCreditCard = useSelector(getRegionRenderingStripeCreditCard);

  useEffect(() => {
    if (regionRenderingStripeCreditCard !== reduxRegionRenderingStripeCreditCard) {
      setReady(false);
    } else {
      setTimeout(() => {
        setReady(true);
      }, MIN_INTERVAL_TO_RENDER_STRIPE_CREDIT_CARD_ELEMENT);
    }
  }, [regionRenderingStripeCreditCard, reduxRegionRenderingStripeCreditCard]);

  if (!ready) {
    return null;
  }

  return <>{children}</>;
};

export default StripeCreditCardSafeView;
