import Link from 'components/ui/Link';
import { useNavigation } from 'hooks/use-navigation';
import React, { useMemo, PropsWithChildren } from 'react';
import { ILinkProps } from '../Link/types';

type IPageLinkProps = PropsWithChildren<{
  onClick?: () => void;
  slug?: string;
}> & ILinkProps;

const PageLink = ({
  children,
  plain = true,
  query = {},
  slug = '',
  ...props
}: IPageLinkProps) => {
  const { getPathBySlug } = useNavigation();

  const internalRef = useMemo(() => {
    if (!slug || typeof slug !== 'string') {
      return '';
    }

    return getPathBySlug(slug);
  }, [
    slug,
    getPathBySlug,
  ]);

  return (
    <Link
      {...props}
      href={internalRef}
      plain={plain}
      query={query}
    >
      {children}
    </Link>
  );
};

export default PageLink;
