import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardNumberElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  Stripe,
} from '@stripe/stripe-js';

import { RegionRenderingStripeCreditCardEnum } from 'services/billing';
import {
  CreditCardNumberContainer,
  StyledCardCvc,
  StyledCardExpiry,
  StyledCreditCardNumber,
  StyledForm,
  SubmitButton,
  CheckboxContainer,
  StyledLabeledInput,
  ErrorIcon,
  ErrorLabel,
  AdminSubmitButton,
  DummyInput,
} from './styles';
import { getBillingInfo, getBillingInfoError, getTaxLoading } from 'services/billing/selectors';
import TranslatedText from 'components/i18n/TranslatedText';
import Checkbox from 'components/admin2/ui/Checkbox';
import { setBillingNameError } from 'services/billing/actions';
import { regexName } from 'components/payment/BillingInfoForm/constants';
import SubscriptionAmountBreakdown from '../../modals/SubscriptionPurchaseModal/AmountBreakdown';
import EllipsisLoader from 'components/admin2/ui/EllipsisLoader';
import { MAESTRO_WHITE } from 'style/constants';
import ISubscription from 'models/ISubscription';
import StripeCreditCardSafeView from '../StripeCreditCardSafeView';
import OptionalTranslated from 'components/admin2/ui/OptionalTranslated';
import { TranslationKey, useAdminTranslation } from 'hooks/use-translation';
import CardNumber from 'components/ui/v2/Inputs/CreditCard/CardNumber';
import Expiry from 'components/ui/v2/Inputs/CreditCard/Expiry';
import CVC from 'components/ui/v2/Inputs/CreditCard/CVC';

export interface IHandleSubmitCreditCardFormParams {
  cardNumber: StripeCardNumberElement;
  makePrimaryPaymentMethod?: boolean;
  savePaymentMethod?: boolean;
  stripe: Stripe;
}

interface IProps {
  addCard?: boolean;
  loading: boolean;
  noNameOverride?: boolean;
  noTaxOverride?: boolean;
  onSubmit: ({}: IHandleSubmitCreditCardFormParams) => void;
  regionRenderingStripeCreditCard: RegionRenderingStripeCreditCardEnum;
  shouldRenderMakePrimaryPaymentMethodCheckbox?: boolean;
  shouldRenderSavePaymentMethodCheckbox?: boolean;
  showForAdmin?: boolean;
  submitButtonLoadingString?: string;
  submitButtonLoadingStringKey?: TranslationKey;
  submitButtonString?: string;
  submitButtonStringKey?: TranslationKey;
  subscription?: ISubscription;
}

interface ICreditCardFormError {
  creditCardNumber?: string;
  cvc?: string;
  expirationDate?: string;
}

const CreditCardForm = ({
  addCard,
  shouldRenderSavePaymentMethodCheckbox = false,
  shouldRenderMakePrimaryPaymentMethodCheckbox = false,
  noNameOverride,
  onSubmit,
  submitButtonStringKey,
  submitButtonString,
  submitButtonLoadingStringKey,
  submitButtonLoadingString,
  showForAdmin,
  subscription,
  regionRenderingStripeCreditCard,
  loading,
}: IProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const taxLoading = useSelector(getTaxLoading);
  const [cardComplete, setCardComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);
  const [cvcComplete, setCvcComplete] = useState(false);
  const [savePaymentMethod, setSavePaymentMethod] = useState(true);
  const [makePrimaryPaymentMethod, setMakePrimaryPaymentMethod] = useState(false);
  const [errors, setErrors] = useState<ICreditCardFormError>({});
  const paymentError = useSelector(getBillingInfoError);
  const { name, complete: billingInfoComplete } = useSelector(getBillingInfo);
  const complete = cardComplete && expiryComplete && cvcComplete && billingInfoComplete;
  const paymentFlowError = paymentError && paymentError !== '' ? true : false;
  const recurrence = subscription?.recurrence;
  const isSubscription = recurrence ? recurrence !== 'once' : false;
  const { t } = useAdminTranslation();
  const dummyInputRef: React.RefObject<HTMLInputElement> = useRef(null);

  const handleCardNumberChange = (
    event: StripeCardNumberElementChangeEvent,
  ) => {
    checkCardNumber(event.error?.message, event.complete);
  };

  const checkCardNumber = (error: string | undefined, completed: boolean) => {
    if (error) {
      setErrors({
        ...errors,
        creditCardNumber: `${t('ADMIN_ERROR_INVALID_CARD_NUMBER')}. ${t('ADMIN_LABEL_PLEASE_TRY_AGAIN')}`,
      });
      return;
    }
    if (completed) {
      setCardComplete(true);
    } else {
      setCardComplete(false);
    }
    setErrors({ ...errors, creditCardNumber: undefined });
  };

  const handleCvcChange = (event: StripeCardCvcElementChangeEvent) => {
    checkCvc(event.error?.message, event.complete);
  };

  const checkCvc = (error: string | undefined, completed: boolean) => {
    if (error) {
      setErrors({ ...errors, cvc: error });
      return;
    }
    if (completed) {
      setCvcComplete(true);
    } else {
      setCvcComplete(false);
    }
    setErrors({ ...errors, cvc: undefined });
  };


  const handleExpiryChange = (event: StripeCardExpiryElementChangeEvent) => {
    checkCardExpiry(event.error?.message, event.complete);
  };

  const checkCardExpiry = (error: string | undefined, completed: boolean) => {
    if (error) {
      setErrors({ ...errors, expirationDate: error });
      return;
    }
    if (completed) {
      setExpiryComplete(true);
    } else {
      setExpiryComplete(false);
    }
    setErrors({ ...errors, expirationDate: undefined });
  };


  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if(dummyInputRef?.current) {
      /**
       * This is a hack to get around an issue on mobile safari,
       * where the keyboard stays open after submitting the form.
       */
      dummyInputRef?.current?.focus();
      dummyInputRef?.current?.blur();
    }

    if (!noNameOverride && !name?.match(regexName)) {
      dispatch(
        setBillingNameError({
          errorMsg: t('BILLING_INVALID_INPUT'),
          hasError: true,
        }),
      );
      return;
    }
    dispatch(setBillingNameError({ errorMsg: '', hasError: false }));

    if (!complete) {
      return;
    }

    if (!stripe || !elements || loading) {
      return;
    }

    const cardNumber = elements.getElement(CardNumberElement);

    if (!cardNumber) {
      return;
    }

    if (onSubmit) {
      onSubmit({
        cardNumber,
        stripe,
        ...(shouldRenderSavePaymentMethodCheckbox && { savePaymentMethod: savePaymentMethod || isSubscription }),
        ...(shouldRenderMakePrimaryPaymentMethodCheckbox && { makePrimaryPaymentMethod }),
      });
    }
  };

  const renderSavePaymentMethod = useCallback(() => {
    if (isSubscription || !shouldRenderSavePaymentMethodCheckbox) {
      return null;
    }

    const handleChange = () => {
      setSavePaymentMethod(!savePaymentMethod);
    };

    return (
      <CheckboxContainer showForAdmin={showForAdmin}>
        <Checkbox
          labelKey="SAVE_PAYMENT_METHOD"
          checked={savePaymentMethod}
          onChange={handleChange}
        />
      </CheckboxContainer>
    );
  }, [isSubscription, savePaymentMethod, shouldRenderSavePaymentMethodCheckbox, showForAdmin]);

  const renderMakePrimaryMethod = useCallback(() => {
    if (!shouldRenderMakePrimaryPaymentMethodCheckbox) {
      return null;
    }

    const handleChange = () => {
      setMakePrimaryPaymentMethod(!makePrimaryPaymentMethod);
    };

    return (
      <CheckboxContainer showForAdmin={showForAdmin}>
        <Checkbox
          labelKey="MAKE_PRIMARY_PAYMENT_METHOD"
          checked={makePrimaryPaymentMethod}
          onChange={handleChange}
        />
      </CheckboxContainer>
    );
  }, [makePrimaryPaymentMethod, shouldRenderMakePrimaryPaymentMethodCheckbox, showForAdmin]);

  const renderSubmitButtonContent = useCallback(() => {
    if (!loading) {
      return (
        <OptionalTranslated stringKey={submitButtonStringKey}>
          {submitButtonString}
        </OptionalTranslated>
      );
    }

    return (
      <>
        {!addCard ? (
          taxLoading ? (
            <TranslatedText stringKey="LOADING_BILING_DATA" />
          ) : (
            <OptionalTranslated stringKey={submitButtonLoadingStringKey}>
              {submitButtonLoadingString}
            </OptionalTranslated>
          )
        ) : null}
        <EllipsisLoader loading={true} color={MAESTRO_WHITE} />
      </>
    );
  }, [loading, taxLoading, addCard, submitButtonLoadingStringKey, submitButtonStringKey, submitButtonLoadingString, submitButtonString]);

  const Button = React.useMemo(() => showForAdmin ? AdminSubmitButton : SubmitButton, [showForAdmin]);

  const renderCardNumber = () => {
    if (!showForAdmin) {
      return <CardNumber error={errors.creditCardNumber} onChange={checkCardNumber} />;
    }

    return (
      <StyledLabeledInput
        showForAdmin={showForAdmin}
        label="CREDIT_CARD_NUMBER"
        required={true}
        error={errors.creditCardNumber}
      >
        <CreditCardNumberContainer
          showForAdmin={showForAdmin}
          data-testid="cardNumberBillingInput"
          error={!!errors.creditCardNumber}
        >
          <StyledCreditCardNumber
            showForAdmin={showForAdmin}
            onChange={handleCardNumberChange}
          />
        </CreditCardNumberContainer>
      </StyledLabeledInput>
    );
  };

  const renderCardExpiry = () => {
    if (!showForAdmin) {
      return <Expiry error={errors.expirationDate} onChange={checkCardExpiry} />;
    }

    return (
      <StyledLabeledInput
        showForAdmin={showForAdmin}
        data-testid="cardExpirationBillingInput"
        label="EXPIRATION"
        required={true}
        error={errors.expirationDate}
      >
        <StyledCardExpiry
          showForAdmin={showForAdmin}
          onChange={handleExpiryChange}
          error={!!errors.expirationDate}
        />
      </StyledLabeledInput>
    );
  };

  const renderCvc = () => {
    if (!showForAdmin) {
      return <CVC error={errors.cvc} onChange={checkCvc} />;
    }

    return (
      <StyledLabeledInput
        showForAdmin={showForAdmin}
        data-testid="cardCvcBillingInput"
        label={t('CVC')}
        required={true}
        error={errors.cvc}
      >
        <StyledCardCvc
          showForAdmin={showForAdmin}
          onChange={handleCvcChange}
          error={!!errors.cvc}
        />
      </StyledLabeledInput>
    );
  };

  return (
    <StripeCreditCardSafeView regionRenderingStripeCreditCard={regionRenderingStripeCreditCard}>
      <StyledForm onSubmit={handleSubmit}>
        {renderCardNumber()}
        {renderCardExpiry()}
        {renderCvc()}
        {renderSavePaymentMethod()}
        {renderMakePrimaryMethod()}
        {subscription && <SubscriptionAmountBreakdown />}
        {paymentFlowError && (
          <ErrorLabel showForAdmin={showForAdmin}>
            <ErrorIcon showForAdmin={showForAdmin} />
            {paymentError}
          </ErrorLabel>
        )}
        <Button
          data-testid="submitPaymentButton"
          onClick={handleSubmit}
          disabled={!complete || loading}
          loading={loading}
          hasError={paymentFlowError}
        >
          {renderSubmitButtonContent()}
        </Button>
        <DummyInput ref={dummyInputRef} />
      </StyledForm>
    </StripeCreditCardSafeView>
  );
};

export default CreditCardForm;
