import React from 'react';
import PropTypes from 'prop-types';
import ScrollSlider from 'components/ui/ScrollSlider';
import AdminChannelSelect from 'components/admin-bridge/AdminChannelSelect';
import { CHANNEL_SELECT_ID } from 'global-ids';
import RealtimePage from 'components/core/RealtimePage';
import {
  ChannelSelectWrapper,
  ChannelSelectContainer,
  ChannelSelectItem,
  StyledQuickEditOverlay,
  SlideButton,
  ChannelSelectArrowIcon,
} from './styles';

export default class ChannelSelect extends React.Component {
  static propTypes = {
    channelSelectActive: PropTypes.bool.isRequired,
    channelSelectEnabled: PropTypes.bool.isRequired,
    channelSelectPages: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.string,
      page: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        seo: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }),
        slug: PropTypes.string.isRequired,
      }).isRequired,
    })),
    className: PropTypes.string,
    contentWidth: PropTypes.number.isRequired,
    currentChannelId: PropTypes.string.isRequired,
    fullscreenActive: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isMobileLayout: PropTypes.bool.isRequired,
    isSimpleModeEnabled: PropTypes.bool.isRequired,
    isTheaterEmbed: PropTypes.bool.isRequired,
    isVideoEmbed: PropTypes.bool.isRequired,
    onEngage: PropTypes.func,
  };

  static defaultProps = {
    channelSelectPages: undefined,
    className: '',
    onEngage: null,
  };

  state = {
    isActionModalOpen: false,
  }

  getSlideDimensions = (totalChannels) => {
    if (totalChannels > 4) {
      return [234, 15];
    }

    return totalChannels === 4 ? [245, 16] : [329, 20];
  };

  renderDefault = () => {
    const {
      channelSelectEnabled,
      className,
      contentWidth,
      currentChannelId,
      fullscreenActive,
      channelSelectPages,
      onEngage,
    } = this.props;

    const filteredPages = (channelSelectPages?.pages || channelSelectPages || [])
      .filter(channel => channel?.page?._id)
      .map(channel => ({ ...channel, key: channel.page._id }));

    if (!filteredPages.length || fullscreenActive || !channelSelectEnabled) {
      return null;
    }

    const [slideWidth, itemMargin] = this.getSlideDimensions(filteredPages.length);

    // subtract margin, divide by size of width
    const totalSlidesToShow = Math.trunc((contentWidth - 63) / (slideWidth + itemMargin));
    const slidesToShow = Math.min(totalSlidesToShow, filteredPages.length);
    // TODO: Fix Arrow Render Logic
    const hideArrows = slidesToShow >= filteredPages.length;
    return (
      <ChannelSelectWrapper
        maxWidth={(filteredPages.length * (slideWidth + itemMargin)) - itemMargin}
      >
        <ChannelSelectContainer className={className} id={CHANNEL_SELECT_ID}>
          {channelSelectEnabled && (
            <ScrollSlider
              arrowLeftIconName="channelSelectArrowLeft"
              arrowRightIconName="channelSelectArrowRight"
              hasTooltips
              hideArrows={hideArrows}
              items={filteredPages}
              slideAmount={Math.max(slidesToShow - 1, 1)}
              slideArrowIcon={ChannelSelectArrowIcon}
              slideButton={SlideButton}
              slideMargin={itemMargin}
              slidesHeight={(slideWidth * (9 / 16)) + 20}
              slidesShown={slidesToShow}
              slideWidth={slideWidth}
              useOldDesign
            >
              {
                ({ image, page }) => (
                  <RealtimePage id={page._id}>
                    {
                      doc => (
                        doc && (
                          <ChannelSelectItem
                            active={page._id === currentChannelId}
                            image={image}
                            legacyImage={image}
                            onClick={doc && onEngage ? () => onEngage(doc, { action: 'select_channel', channel: page._id }) : null}
                            page={doc}
                            slideWidth={slideWidth}
                          />
                        )
                      )
                    }
                  </RealtimePage>
                )
              }
            </ScrollSlider>
          )}
        </ChannelSelectContainer>
      </ChannelSelectWrapper>
    );
  };

  handleOpenActionModal = () => {
    this.setState({
      isActionModalOpen: true,
    });
  }

  handleCloseActionModal = () => {
    this.setState({
      isActionModalOpen: false,
    });
  }

  // also backwards compatibility for redux
  renderEditable = () => {
    const { isActionModalOpen } = this.state;
    const { channelSelectPages } = this.props;

    const pages = channelSelectPages?.pages || channelSelectPages;

    return (
      <AdminChannelSelect
        isActionModalOpen={isActionModalOpen}
        onCloseActionModal={this.handleCloseActionModal}
        pages={pages}
      />
    );
  };

  renderChannelSelect = () => {
    const { isAdmin, isSimpleModeEnabled, isEditing } = this.props;
    const shouldRenderEditable = isSimpleModeEnabled ? isAdmin : isEditing;

    if (shouldRenderEditable) {
      return this.renderEditable();
    }

    return this.renderDefault();
  };

  render() {
    const {
      channelSelectActive,
      fullscreenActive,
      isMobileLayout,
      isTheaterEmbed,
      isVideoEmbed,
      isAdmin,
      isEditing,
      isSimpleModeEnabled,
    } = this.props;

    return (
      (!isMobileLayout && !isVideoEmbed && !isTheaterEmbed) && (
        <StyledQuickEditOverlay
          channelSelectActive={channelSelectActive}
          data-testid="editModeChannelNavigation"
          fullscreenActive={fullscreenActive}
          isAdmin={isAdmin}
          isEditing={isEditing}
          isSimpleModeEnabled={isSimpleModeEnabled}
          onEdit={this.handleOpenActionModal}
          titleKey="ADMIN_LABEL_CHANNEL_NAVIGATION"
        >
          {this.renderChannelSelect()}
        </StyledQuickEditOverlay>
      )
    );
  }
}
