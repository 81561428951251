import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{ hasContent: boolean;}>`
  ${({ hasContent }) => !hasContent && 'flex: 1;'};
  animation: ${fadeIn} 0.5s ease-in-out;
  margin: 0 !important;
  padding: 0;
  & > * {
    padding: 0;
    margin: 0;
  }
`;
