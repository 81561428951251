// TODO provide shit

import { msIn } from 'shared/datetime-utils';

const divmod = (x, y) => (
  {
    rem: x % y,
    val: Math.trunc(x / y),
  }
);

export const parseTime = (ms) => {
  const { val: days, rem: d } = divmod(ms, msIn.day);
  const { val: hours, rem: h } = divmod(d, msIn.hour);
  const { val: minutes, rem: m } = divmod(h, msIn.minute);
  const { val: seconds } = divmod(m, msIn.second);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const computeTimeLeft = (startTime, serverOffset) => {
  const diff = Date.parse(startTime) - Date.now() + serverOffset;
  return diff;
};
