// tslint:disable:jsx-boolean-value
import React, {
  MouseEventHandler,
  PropsWithChildren,
  useMemo,
} from 'react';
import ChannelLiveBadge from 'components/ui/ChannelLiveBadge';
import IconLabel, { IconLabelProps } from 'components/ui/IconLabel';
import { getAcronym } from 'shared/string-utils';
import { ACTIVE, CHANNEL } from 'injection-classes';
import {
  BadgeContainer,
  ChannelSelectContainer,
  Highlight,
  LinkWrap,
  StyledAspectRatio,
  StyleProps,
} from './styles';
import { AspectRatioProps } from 'components/ui/AspectRatio';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import ChannelRender from './ChannelRender';
import CardMenu from './CardMenu';
import { DynamicTranslationType, useEndUserTranslation } from 'hooks/use-translation';
import { IObject, PageType } from 'models';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin';

interface ChannelSelectorProps extends StyleProps, Pick<AspectRatioProps, 'ratio' | 'maxHeight' | 'maxWidth'> {
  acronymChannelName?: boolean;
  badges?: IconLabelProps[];
  className?: string;
  disableAspectRatio?: boolean;
  image?: string;
  index?: number;
  isExpanded?: boolean;
  legacyImage?: string | null;
  maxAcronymLength?: number;
  maxTextLines?: number;
  noLink?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  page: IObject;
  showCardMenu?: boolean;
  testId?: string;
}

export default function ChannelSelector({
  acronymChannelName,
  active = false,
  badges = [],
  children,
  className,
  disableAspectRatio,
  fixedHeight,
  fixedWidth,
  fontSize,
  index = 0,
  isAdmin,
  itemPadding,
  isExpanded = true,
  legacyImage = null,
  maxAcronymLength = 3,
  maxHeight,
  maxTextLines = 2,
  maxWidth,
  noLink,
  onClick,
  page,
  ratio,
  testId,
  showCardMenu = false,
}: PropsWithChildren<ChannelSelectorProps>) {
  if (!page) {
    return null; // prevent crashes from bad data
  }

  const { _id = '', data, seo, slug } = page;
  const image = data?.channelSelectActiveImageUrl || legacyImage || null;
  let name: string = '';
  const fullClassName = `${className} ${CHANNEL}`;
  const isEditing: boolean = useSelector(isEditMode);
  const { endUserT } = useEndUserTranslation();

  if (isEditing) {
    name = data?.name || seo?.title || slug;
  } else if (page && page.type === PageType.CHANNEL) {
    name = endUserT([ DynamicTranslationType.channelTitle, { channelId: _id } ]);
  } else if (page) {
    // non-channel pages
    name = endUserT([ DynamicTranslationType.pageTitle, { pageId: _id } ]);
  }

  if (!name) {
    name = data?.name || seo?.title || slug;
  }

  const channelName = useMemo(() => {
    if (!acronymChannelName || name.length <= maxAcronymLength) {
      return name;
    }
    return getAcronym(name, maxAcronymLength);
  }, [name, acronymChannelName]);

  const channelProps = {
    active,
    image,
    index,
    itemPadding,
    maxTextLines,
    name: channelName,
  };

  return (
    <ChannelSelectContainer
      active={active}
      className={fullClassName}
      data-testid={testId}
      fontSize={fontSize}
      isAdmin={isAdmin}
      onClick={onClick}
    >
      {active ? <Highlight className={ACTIVE} isAdmin={isAdmin} /> : null}
      <BadgeContainer>
        <ChannelLiveBadge id={_id} />
        <NonKeyedListMapper list={badges}>
          {(key: string, badgeProps: IconLabelProps) => (
            <IconLabel key={key} {...badgeProps} />
          )}
        </NonKeyedListMapper>
      </BadgeContainer>
      <StyledAspectRatio
        disabled={disableAspectRatio}
        fixedHeight={fixedHeight}
        fixedWidth={fixedWidth}
        fullwidth
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        ratio={ratio}
      >
        {noLink ? <ChannelRender {...channelProps} noLink>{children}</ChannelRender> : (
          <LinkWrap isAdmin={isAdmin} plain slug={slug}>
            <ChannelRender {...channelProps}>{children}</ChannelRender>
          </LinkWrap>
        )}
      </StyledAspectRatio>
      {isExpanded && <CardMenu pageId={_id} showCardMenu={showCardMenu} />}
    </ChannelSelectContainer>
  );
}
