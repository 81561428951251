import React, { MouseEventHandler, useMemo } from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import { DateData, WeekDay } from 'hooks/use-calendar';
import moment, { Moment } from 'moment-timezone';
import CalendarContainer from './Container';
import WeekHeader from './WeekHeader';
import {
  DayButton,
  DaysContainer,
  StyledSubText,
} from '../styles';
import { CalendarStateProps, DayCalendarProps } from '../interfaces';
import TranslatedText from 'components/i18n/TranslatedText';
import { TranslationKey, useAdminTranslation } from 'hooks/use-translation';

interface DayPickerProps extends CalendarStateProps, DayCalendarProps {
  date?: Moment | null;
  footerTextKey?: TranslationKey;
  monthsShort: string[];
  visibleDays: DateData[];
  weekDays: WeekDay[];
  onDateChange?(date?: Moment | null): void;
}

export default function DayPicker({
  admin,
  calendarMonth,
  calendarYear,
  dateTitle,
  footerTextKey,
  isMonthYearSelectorVisible,
  nextMonth,
  prevMonth,
  onDateChange,
  toggleMonthYearSelector,
  visibleDays,
  weekDays,
  monthsShort: monthsLong,
}: DayPickerProps){
  const { t } = useAdminTranslation();
  const calendarDateTitle = useMemo(() => {
    const date = moment([calendarYear, calendarMonth]);
    return t('LABEL_DATE_FORMAT_MONTH_YEAR', {
      month: monthsLong[date.month()],
      year: date.year(),
    });
  }, [calendarMonth, calendarYear]);

  const handleDayClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!onDateChange) {
      return;
    }
    const index = parseInt(e.currentTarget.dataset.index || '0', 10);
    const { day, month, year } = visibleDays[index];
    onDateChange(moment([year, month, day]).startOf('day'));
  };

  return (
    <CalendarContainer
      admin={admin}
      buttonText={calendarDateTitle}
      containerTitleKey="ADMIN_LABEL_SELECT_DATE"
      isMonthYearSelectorVisible={isMonthYearSelectorVisible}
      navNext={nextMonth}
      navPrev={prevMonth}
      navTitle={dateTitle}
      onActionClick={toggleMonthYearSelector}
    >
      <WeekHeader weekDays={weekDays} />
      <DaysContainer>
        <NonKeyedListMapper list={visibleDays}>
          {(key: string, { day, isDisabled, isSelected }: DateData, index: number) => (
            <DayButton
              data-testid={'calendar-day-button'}
              admin={admin}
              data-index={index}
              isActive={isSelected}
              isDisabled={isDisabled}
              key={key}
              onClick={handleDayClick}
            >
              {day}
            </DayButton>
          )}
        </NonKeyedListMapper>
      </DaysContainer>
      {footerTextKey && <TranslatedText component={StyledSubText} stringKey={footerTextKey} />}
    </CalendarContainer>
  );
}
