import styled, { css } from 'styled-components';
import { SURFACE_1, ACCENT_PRIMARY } from 'style/constants';

export interface CircleStyleProps {
  display?:boolean;
  position?: string;
  size?: number;
}

export const Container = styled.div<CircleStyleProps>`
  position: relative;
  width: ${({ size = 48 }) => `${size}px`};
  height: ${({ size = 48 }) => `${size}px`};
  background: ${SURFACE_1};
`;

export const CirclePercent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${SURFACE_1};
  overflow: hidden;
`;

export const Progress = styled.div<CircleStyleProps>`
  position: absolute;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  z-index: 1;
  background: ${ACCENT_PRIMARY};
  content: "";
  transform: ${({ position }) => `rotate(${position}deg)`};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  width: 50%;
  left: -50%;
  top: 0;
  right: 0;
  bottom: 0;
  display: ${({ position }) => Number(position) > -360 ? 'block' : 'none' };
`;

export const Counter = styled.div`
  position: absolute;
  box-sizing: border-box;
  z-index: 4;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0;
  &::before{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: white;
    content: attr(data-percent);
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 60%;
  }
  &::after{
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: 50%;
    background: ${SURFACE_1};
    content: "";
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const Rectangle = styled.div<CircleStyleProps>`
  width: 50%;
  height: 100%;
  background-color: ${SURFACE_1};
  z-index: 2;
  position: absolute;
  display: ${({ display }) => display ? 'block' : 'none' };
`;

export const ProgressPosition = styled.div<CircleStyleProps>`
  position: absolute;
  z-index: 3;
  top: 0;
  left:  ${({ position }) => position === 'left' ? '0' : '50%' };
  width: 50%;
  height: 100%;
  background: ${({ position }) => position === 'right' ? ACCENT_PRIMARY : SURFACE_1  };
  display: ${({ display }) => display ? 'block' : 'none' };
  content: "";
  ${({ position }) => position === 'bottom' &&
  css`
  transform-origin: 0% 50%;
  transform: rotate(90deg);
  `};
`;
