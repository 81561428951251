import styled, { css } from 'styled-components';
import ActionButton from 'components/admin2/ActionButton';
import Link from 'components/ui/Link';
import {
  ADMIN_TEXT_300,
  ADMIN_ACTION_COLOR,
  ADMIN_ACTION_COLOR_HOVER,
  TAB_PADDING,
  SPACING_X_SMALL,
  HIGHLIGHT_PRIMARY,
  SURFACE_1,
  ACCENT_PRIMARY,
  ADMIN_ALERT_ERROR,
  ADMIN_ACCENT_PRIMARY,
  SURFACE_3,
  ADMIN_HIGHLIGHT_PRIMARY,
  TEXT_300,
  ADMIN_SURFACE_1,
} from 'style/constants';
import {
  ACTION_NORMAL,
} from 'style/mixins';
import Icon from 'components/ui/Icon';
import { ADMIN_TEXT_BODY_M_MEDIUM, ADMIN_TEXT_BODY_S_BOLD, ADMIN_TEXT_BODY_S_MEDIUM, TEXT_BODY_S_MEDIUM } from 'style/design-system/textStyles';

interface IDotProps {
  userSelected: boolean | undefined;
}

interface IShowForAdminProps {
  showForAdmin?: boolean;
}

export const Wrapper = styled.div``;

export const FormTitle = styled.div<IShowForAdminProps>`
  ${(props) => props.showForAdmin ? ADMIN_TEXT_BODY_S_MEDIUM : TEXT_BODY_S_MEDIUM}
  color: ${(props) => props.showForAdmin ? ADMIN_HIGHLIGHT_PRIMARY(props) : HIGHLIGHT_PRIMARY(props)};
  margin: 5px 0px 10px 8px;
`;

export const HeaderContainer = styled.div<IShowForAdminProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.showForAdmin ? ADMIN_TEXT_300(props) : TEXT_300(props)};
  font-weight: 700;
  letter-spacing: 0em;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const CardContainer = styled.div<IShowForAdminProps>`
  padding: 10px;
  background: ${props => props.showForAdmin ? ADMIN_SURFACE_1(props) : SURFACE_1(props)};
  border-radius: 10px;
  margin-top: 5px;
  ${({ hidden }) => hidden && css`display: none;`}
`;

export const HeaderCardContainer = styled.div<IShowForAdminProps>`
  background: ${props => props.showForAdmin ? ADMIN_SURFACE_1(props) : SURFACE_1(props)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CloseButtonIcon = styled.div<IShowForAdminProps>`
  background: ${props => props.showForAdmin ? ADMIN_SURFACE_1(props) : SURFACE_1(props)};
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: black;
  border-radius: 4.54px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseIcon = styled(Icon)`
  ${ADMIN_TEXT_BODY_M_MEDIUM}
  color: rgba(0, 0, 0, 0.5);
  font-size: 0;
  svg {
    width: 8px;
    height: 8px;
  }
`;

export const StyledActionButton = styled(ActionButton)`
  margin: ${TAB_PADDING} 0 0 0;
`;

export const StyledMethodTitle = styled.div`
  ${ADMIN_TEXT_BODY_S_BOLD}
  text-transform: uppercase;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding-bottom: ${SPACING_X_SMALL};
  padding-top: ${SPACING_X_SMALL};
`;

export const Dot = styled.div<IDotProps>`
  background: ${props => (props.userSelected ? ADMIN_ACTION_COLOR : SURFACE_3(props))};
  box-shadow: 0 0 0 2px ${ADMIN_ACTION_COLOR} inset;
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin: 5px 10px 5px 10px;
  height: 12px;
  width: 12px;
`;

export const StyledLink = styled(Link)`
  && {
    color: ${ADMIN_ACTION_COLOR};
  }
  &:hover{
      color: ${ADMIN_ACTION_COLOR_HOVER};
    }
  ${ACTION_NORMAL};
`;

export const LabelUpdated = styled.div<IShowForAdminProps>`
  color: ${props => (props.showForAdmin ? ADMIN_ACCENT_PRIMARY : ACCENT_PRIMARY )};
  margin-bottom: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  width: 100%;
  font-size: 12px;
`;

export const CustomIcon = styled(Icon).attrs((props) => ({
  name: props.name,
}))<IShowForAdminProps>`
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  & > svg {
    width: 10px;
    height: 10px;
    margin-bottom: 2px;
    & > circle {
      fill: ${({ name, showForAdmin, ...props }) =>
        showForAdmin ? ADMIN_ACCENT_PRIMARY(props) : name === 'warning' ? ADMIN_ALERT_ERROR : ACCENT_PRIMARY};
    }
  }
`;
