import React, { useCallback, useState } from 'react';
import {
  StyledImg,
  ImageStyleProps,
  FallbackIcon,
} from './styles';

interface IProps extends ImageStyleProps {
  alt?: string;
  className?: string;
  fallback?: () => React.ReactElement | null;
  fallbackIcon?: string;
  src?: string;
}

const Image = React.forwardRef<HTMLImageElement, IProps>(({
  alt,
  fallback,
  fallbackIcon,
  src,
  ...otherProps
}, ref) => {
  const [error, setError] = useState<boolean>(false);

  const onError = useCallback(() => {
    setError(true);
  }, []);

  if (error || (!src && !fallbackIcon)) {
    return fallbackIcon ? <FallbackIcon name={fallbackIcon} /> : fallback!();
  }

  return src ? (
    <StyledImg alt={alt} onError={onError} ref={ref} src={src} {...otherProps} />
  ) : (
    <FallbackIcon name={fallbackIcon} {...otherProps} />
  );
});

Image.defaultProps = {
  alt: '',
  className: '',
  fallback: () => null,
  src: '',
};

export default Image;
