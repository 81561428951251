import styled, { css } from 'styled-components';
import Label from 'components/admin2/ui/Label';
import withTooltip from 'components/core/withTooltip';
import {
  SURFACE_1,
  ADMIN_SURFACE_5,
  ADMIN_TEXT_200,
  ADMIN_TEXT_100,
  TEXT_100,
} from 'style/constants';

export interface StyleProps {
  isAdmin: boolean;
}

export const Button = withTooltip(styled.button<StyleProps>`
  background: transparent;
  border: none;
  border-radius: 5px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  &:hover {
    background: ${({ isAdmin }) => isAdmin ? ADMIN_SURFACE_5 : SURFACE_1}
  }
`, {
  admin: true,
  tooltipCss: css`
    font-size: 14px;
  `,
  tooltipPosition: 'bottom',
  tooltipSeparation: 10,
});

export const StyledLabel = styled(Label).attrs((props) => ({
  color: props.admin ? ADMIN_TEXT_200(props) : TEXT_100(props),
  compact: true,
  iconColor: props.admin ? ADMIN_TEXT_100(props) : TEXT_100(props),
  iconRightAlign: true,
  iconSize: 15,
  iconSpacing: 10,
  textSize: 'h5',
  ...props,
}))`
  &, & * {
    cursor: pointer;
  }
`;
