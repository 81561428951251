// tslint:disable: no-console
import React, { useEffect, useState, useCallback, memo, FC } from 'react';
import { useSelector } from 'react-redux';
import { AdType, IAdsConfig, VastAdConfig } from 'models/IAdsConfig';
import { getAdsConfig } from 'services/ads/api';
import { isUserAdmin } from 'services/auth';
import { getCurrentPageId } from 'services/app/selectors';
import { Overlay } from './styles';
import { VastAdPlayer, VastAdPlayerProps } from 'components/VastAdPlayer';
import { narrow } from 'utils/narrow';
import { useVideoPlayerContext } from 'components/Video/video-player/Player/Context';

const AdsOverlay: FC<{ videoId?: string | null }> = memo(
  ({ videoId = null }) => {
    const isAdmin = useSelector(isUserAdmin);
    const pageId = useSelector(getCurrentPageId);
    const [ads, setAds] = useState<IAdsConfig | null>(null);
    const [isActive, setIsActive] = useState(false);
    const {
      playerState: { onPlaybackUpdate },
    } = useVideoPlayerContext();

    useEffect(() => {
      if (isAdmin) {
        return;
      }

      let cleanup = () => {
        // placeholder
      };

      getAdsConfig({
        pageId,
        videoId,
        captureCanceler: (cancel) => {
          cleanup = cancel;
        },
      })
        .then((adsData) => {
          if (!adsData?.enabled) {
            setAds(null);
            return;
          }

          setAds(adsData);
        })
        .catch((err) => {
          console.error(err);
          setAds(null);
        });

      return cleanup;
    }, [pageId, videoId, isAdmin]);

    const togglePlayPause = (playing: boolean) => {
      onPlaybackUpdate({
        playing,
      });
    };

    const prePlaySetup = useCallback<
      NonNullable<VastAdPlayerProps['onBeforePlay']>
    >(() => {
      togglePlayPause(false);
      setIsActive(true);
      return Promise.resolve();
    }, []);

    const onFinished = useCallback<
      NonNullable<VastAdPlayerProps['onFinished']>
    >((err) => {
      if (err) {
        console.error('Ad finished with error:', err);
      }

      setAds(null);
      setIsActive(false);
      togglePlayPause(true);
    }, []);

    if (!ads) {
      return null;
    }

    return (
      <Overlay data-testid="adsOverlay" $active={isActive}>
        {(() => {
          if (!videoId) {
            return null;
          }

          const config = ads?.config;

          if (narrow<VastAdConfig>(config, config?.type === AdType.vast)) {
            return (
              <VastAdPlayer
                vastUrl={config.url}
                onBeforePlay={prePlaySetup}
                onFinished={onFinished}
              />
            );
          }

          return null;
        })()}
      </Overlay>
    );
  },
);

export default AdsOverlay;
