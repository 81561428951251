import React, { PropsWithChildren, useMemo, useRef } from 'react';
import {
  AspectRatioContainer,
  AspectRatioContent,
  StyleProps,
} from './styles';
import { useSelector } from 'react-redux';
import { isTheaterEmbed, isVideoEmbed } from 'services/user-layout';

export type AspectRatioProps = PropsWithChildren<{
  className?: string;
  dangerouslySetInnerHTML?: any;
  disabled?: boolean;
  maxHeight?: number;
  ratio?: [number, number];
  onWidthChange?(width: string): void;
  onWidthPxChange?(width: number): void;
}> & StyleProps;

export default function AspectRatio({
  children,
  className,
  disabled = false,
  fullwidth,
  maxHeight,
  maxWidth,
  onWidthChange,
  onWidthPxChange,
  ratio = [16, 9],
}: AspectRatioProps) {
  const [w, h] = ratio;
  const isVideoEmbedded = useSelector(isVideoEmbed);
  const isTheaterEmbedded = useSelector(isTheaterEmbed);
  const dimensions = useRef<DOMRect>(new DOMRect());
  const aspectRatio = useMemo(() => h / w * 100, [w, h]);
  const width = useMemo(() => {
    let result = '';
    if (maxWidth) {
      result = maxWidth;
    }
    else if (maxHeight) {
      result = `${maxHeight * w / h}px`;
    }
    onWidthChange?.(result);
    return result;
  }, [maxWidth, maxHeight]);
  const height = useMemo(() => {
    const { width: currentWidth } = dimensions.current;
    if (!currentWidth) {
      return;
    }
    return currentWidth * h / w;
  }, [dimensions.current.width, h, w]);

  const getDimensions = (node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }
    dimensions.current = node.getBoundingClientRect();
    onWidthPxChange?.(dimensions.current.width);
  };

  return (
    <AspectRatioContainer className={className} disabled={disabled} fullwidth={fullwidth} maxWidth={width}>
      <AspectRatioContent
        ref={getDimensions}
        aspectRatio={aspectRatio}
        disabled={disabled}
        height={height}
        isEmbedded={isVideoEmbedded || isTheaterEmbedded}
      >
        {children}
      </AspectRatioContent>
    </AspectRatioContainer>
  );
}
