import { LargeButton } from 'components/admin2/ui/Button';
import { useAdminTranslation } from 'hooks/use-translation';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enablePanels, isEditMode } from 'services/admin';
import { TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_LABEL_L_BOLD } from 'style/design-system/textStyles';
import { THEME_PADDING } from 'style/mixins';
import styled from 'styled-components';

const NoPanels = () => {
  const isEditing = useSelector(isEditMode);
  const { t } = useAdminTranslation();
  const dispatch = useDispatch();

  const handleOpenPanelsTab = React.useCallback(() => {
    dispatch(enablePanels());
  }, [dispatch]);

  if (!isEditing) {
    return null;
  }

  return (
    <Wrapper>
      <Text>{t('START_CREATING_YOUR_INTERACTIVE_EXPERIENCE')}</Text>
      <StyledLargeButton onClick={handleOpenPanelsTab}>
        + {t('LABEL_ADD_BLOCK')}
      </StyledLargeButton>
    </Wrapper>
  );
};

export const StyledLargeButton = styled(LargeButton)`
  padding: 0 20px;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  ${THEME_PADDING}
  text-align: center;
`;

export const Text = styled.span`
  ${ADMIN_TEXT_LABEL_L_BOLD}
  color: ${TEXT_100};
`;

export default NoPanels;
