import React, { MouseEventHandler, useCallback } from 'react';
import { StyledExternalLink } from './styles';
import { CustomLinkProps } from './types';

const CustomLink = ({
  children,
  onClick: onLinkClick,
  plain = true,
  ...props
}: CustomLinkProps) => {
  const onClick = useCallback<MouseEventHandler>((event) => {
    event.preventDefault();
    if (onLinkClick) {
      onLinkClick(event);
    }
  }, [onLinkClick]);

  return (
    <StyledExternalLink
      {...props}
      plain={plain}
      href="#"
      onClick={onClick}
    >
      {children}
    </StyledExternalLink>
  );
};

export default CustomLink;
