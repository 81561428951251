import React, { PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedIn } from 'services/auth';
import { loginModal } from 'services/modals';

const OpenLoginModal: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(isLoggedIn);

  const openLoginModal = React.useCallback(() => {
    if (loggedIn) {
      return;
    }

    dispatch(loginModal());
  }, [loggedIn, dispatch]);

  return (
    <div onClick={openLoginModal} style={{ cursor: loggedIn ? 'default' : 'pointer' }}>
      {children}
    </div>
  );
};

export default OpenLoginModal;
