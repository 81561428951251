import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { Container, ErrorContainer, InputContainer, InputContainerProps, StyledError, StyledIcon } from './styles';


type DivProps = React.HTMLAttributes<HTMLDivElement>;

interface LabeledInputProps extends DivProps, InputContainerProps {
  error?: string;
  label?: string;
  showForAdmin?: boolean;
}

export default function LabeledInput({ children, label = '', error, showForAdmin, ...props }: LabeledInputProps) {
  return (
    <Container>
      <InputContainer showForAdmin={showForAdmin} {...props}>
        {label && <TranslatedText component="label" stringKey={label} />}
        {children}
        {error && error !== '' && (
          <ErrorContainer>
            <StyledIcon showForAdmin={showForAdmin} name={'warning'} tabIndex={-1} />
            <StyledError showForAdmin={showForAdmin}>{error}</StyledError>
          </ErrorContainer>
        )}
      </InputContainer>
    </Container>
  );
}
