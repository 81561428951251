import {
  $createNodeSelection,
  $getNodeByKey,
  $getSelection,
  $setSelection,
  ElementNode,
  LexicalNode,
  RangeSelection,
  TextNode,
} from 'lexical';
import { $isAtNodeEnd } from '@lexical/selection';

export function append(node: ElementNode, nodesToAppend: Array<LexicalNode>) {
  node.splice(node.getChildrenSize(), 0, nodesToAppend);
}

export function getSelectedNodeFromSelection(selection: RangeSelection): TextNode | ElementNode {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
  }
}

export const $toggleNodeInSelection = (nodeKey: string) => {
  const currentSelection = $getSelection();

    if (!currentSelection) {
      $getNodeByKey(nodeKey)?.selectStart();
      $getNodeByKey(nodeKey)?.selectEnd();
      return;
    }

    if ($getNodeByKey(nodeKey)?.isSelected(currentSelection)) {
      const selectionWithoutNode = $createNodeSelection();

      for (const n of currentSelection.getNodes()) {
        if (n.getKey() !== nodeKey) {
          selectionWithoutNode.add(n.getKey());
        }
      }

      $setSelection(selectionWithoutNode);
      return;
    }

    const newSelection = $createNodeSelection();
    newSelection.add(nodeKey);

    for (const n of currentSelection.getNodes()) {
      newSelection.add(n.getKey());
    }

    $setSelection(newSelection);
};

const VERTICAL_GAP = 10;
const HORIZONTAL_OFFSET = 5;

export function setFloatingElemPosition(
  targetRect: DOMRect | null,
  floatingElem: HTMLElement,
  anchorElem: HTMLElement,
  isLink: boolean = false,
  verticalGap: number = VERTICAL_GAP,
  horizontalOffset: number = HORIZONTAL_OFFSET,
): void {
  const scrollerElem = anchorElem.parentElement;

  if (targetRect === null || !scrollerElem) {
    floatingElem.style.opacity = '0';
    floatingElem.style.transform = 'translate(-10000px, -10000px)';
    return;
  }

  const floatingElemRect = floatingElem.getBoundingClientRect();
  const anchorElementRect = anchorElem.getBoundingClientRect();
  const editorScrollerRect = scrollerElem.getBoundingClientRect();

  let top = targetRect.top - floatingElemRect.height - verticalGap;
  let left = targetRect.left - horizontalOffset;

  if (top < editorScrollerRect.top) {
    // adjusted height for link element if the element is at top
    top +=
      floatingElemRect.height +
      targetRect.height +
      verticalGap * (isLink ? 9 : 2);
  }

  if (left + floatingElemRect.width > editorScrollerRect.right) {
    left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
  }

  top -= anchorElementRect.top;
  left -= anchorElementRect.left;

  floatingElem.style.opacity = '1';
  floatingElem.style.transform = `translate(${left}px, ${top}px)`;
}


export function getDOMRangeRect(
  nativeSelection: Selection,
  rootElement: HTMLElement,
): DOMRect {
  const domRange = nativeSelection.getRangeAt(0);

  let rect;

  if (nativeSelection.anchorNode === rootElement) {
    let inner = rootElement;
    while (inner.firstElementChild != null) {
      inner = inner.firstElementChild as HTMLElement;
    }
    rect = inner.getBoundingClientRect();
  } else {
    rect = domRange.getBoundingClientRect();
  }

  return rect;
}

export const isRoot = (node: ElementNode) => node.getKey() === 'root';
