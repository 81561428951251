import React from 'react';
import { InputProps } from '../TextInput';
import useToggle from 'hooks/use-toggle';
import FormInput from 'components/ui/v2/Inputs';

type PasswordProps = Omit<InputProps, 'type'>;

export const PasswordInput = React.forwardRef<HTMLInputElement, PasswordProps>((props, ref) => {
  const [isVisible, toggle] = useToggle(false);

  return (
    <>
      <FormInput.InputIcon name="lock" />
      <FormInput.TextInput
        ref={ref}
        type={isVisible ? 'text' : 'password'}
        {...props}
      />
      <FormInput.InputIcon cursor="pointer" onClick={toggle} name={isVisible ? 'eyeFilled' : 'eyeHidden'} />
    </>
  );
});
