import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTaxes } from 'services/app/selectors';
import {
  setBillingName,
  setBillingPostalCode,
  setBillingCountryCode,
  setBillingStateCode,
  setBillingInfoCompleted,
  getBillingInfo,
  getTaxLoading,
  fetchTaxRate,
  setTaxRate,
} from 'services/billing';
import LabeledInput from 'components/ui/LabeledInput';
import {
  BillingInfoContainer,
  CountryCodeContainer,
  PostalCodeContainer,
  StateCodeContainer,
  StyledSelect,
  StyledInputContainer,
} from './styles';
import { regexName } from './constants';
import assets from '../../../assets/countries.json';
import { useAdminTranslation } from 'hooks/use-translation';
import FormInput from 'components/ui/v2/Inputs';

interface IProps {
  noNameOverride?: boolean;
  noTaxOverride?: boolean;
  showForAdmin?: boolean;
}

export interface IStateCountryCode{
  label?: string
  value?: string,
}

export const COUNTRIES_WITH_STATE_CODE_TAX = ['US', 'CA'];

const BillingInfoForm = ({ noTaxOverride, noNameOverride, showForAdmin }: IProps) => {
  const { t } = useAdminTranslation();
  const [nameError, setNameError] = useState<string>();
  const [countrySelectValue, setCountrySelectValue] = useState<any>();
  const [postalCodeError, setPostalCodeError] = useState<string>();

  const [nameComplete, setNameComplete] = useState<boolean>(false);
  const [countryCodeComplete, setCountryCodeComplete] = useState<boolean>(false);
  const [postalCodeComplete, setPostalCodeComplete] = useState<boolean>(false);
  const [stateCodeComplete, setStateCodeComplete] = useState<boolean>(false);

  const { name, postalCode, countryCode, stateCode } = useSelector(
    getBillingInfo,
  );
  const collectTaxes = noTaxOverride ? false : useSelector(getTaxes);
  const taxLoading = useSelector(getTaxLoading);
  const dispatch = useDispatch();
  const [stateName,setStateName] = useState<IStateCountryCode | undefined>(undefined);

  const postalCodeValidations: { [key: string]: RegExp } = {
    US: /^[0-9]{5}$/,
  };

  const COUNTRY_LIST = assets.countries.map((country) => ({ label: country.name, value: country.code }));

  const STATE_LIST = useMemo(() => {
    return assets.countries
      .find((country) => country.code === countryCode)
      ?.provinces.map((state) => ({ label: state.name, value: state.code }));
  }, [countryCode]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch(setBillingName(value));
  };

  const handleCountryCodeChange = (e: any) => {
    const value = e.value;
    setCountrySelectValue({ value, label: e.label });
    setStateName(undefined);
    dispatch(setBillingCountryCode(value.toUpperCase()));
  };

  const handleStateCodeChange = (e: any) => {
    const value = e.value;
    setStateName(e);
    dispatch(setBillingStateCode(value.toUpperCase()));
  };

  const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = String(e.target.value).toUpperCase();
    dispatch(setBillingPostalCode(value));
    setPostalCodeComplete(checkPostalCodeComplete(value));
  };

  const checkNameComplete = (): boolean => {
    if (!noNameOverride && !name?.match(regexName)) {
      setNameError('Invalid name (minimum length or invalid characters)');
      return false;
    }
    setNameError(undefined);
    return true;
  };

  const checkCountryCodeComplete = (): boolean => {
    if (countryCode != null && countryCode !== '') {
      return true;
    }
    return false;
  };

  const checkStateCodeComplete = (): boolean => {
    if (
      (countryCode && COUNTRIES_WITH_STATE_CODE_TAX.includes(countryCode) && stateCode && stateCode !== '') ||
      countryCode && !COUNTRIES_WITH_STATE_CODE_TAX.includes(countryCode)
    ) {
      return true;
    }
    return false;
  };

  const checkPostalCodeComplete = (postalCodeValue: string): boolean => {
    if (postalCodeValue === '') {
      return false;
    }
    const match = new RegExp(postalCodeValidations[countryCode]).test(postalCodeValue);
    if (!match) {
      setPostalCodeError('Invalid zip/postal code');
      return false;
    }
    setPostalCodeError(undefined);
    return true;
  };

  const handleNameBlur = () => {
    setNameComplete(checkNameComplete());
  };

  useEffect(() => {
    if (noNameOverride || !name.length) {
      setNameComplete(true);
      return;
    }
    setNameComplete(checkNameComplete());
  }, [name, noNameOverride]);


  useEffect(() => {
    if (postalCode === '') {
      setPostalCodeComplete(false);
    }
    else if (postalCode && postalCode !== '') {
      setPostalCodeComplete(checkPostalCodeComplete(postalCode));
    }
    setStateCodeComplete(checkStateCodeComplete());
    setCountryCodeComplete(checkCountryCodeComplete());
  }, [countryCode]);

  useEffect(() => {
    setStateCodeComplete(checkStateCodeComplete());
  }, [stateCode]);

  useEffect(() => {
    if (postalCodeComplete && countryCodeComplete && nameComplete && stateCodeComplete) {
      dispatch(setBillingInfoCompleted(true));
    } else {
      dispatch(setBillingInfoCompleted(false));
    }
  }, [postalCodeComplete, countryCodeComplete, nameComplete, stateCodeComplete]);

  useEffect(() => {
    const zipCodeValid = Boolean(postalCode && new RegExp(postalCodeValidations[countryCode]).test(postalCode));

    const ableToFetchTaxRate = Boolean(!taxLoading && zipCodeValid && collectTaxes
      && postalCodeComplete && stateCodeComplete && countryCode);

    if (ableToFetchTaxRate) {
      dispatch(fetchTaxRate());
    }

    if (countryCode !== 'US') {
      dispatch(setTaxRate({ taxRate: null }));
    }
  }, [countryCode, postalCode, postalCodeComplete, stateCodeComplete]);

  useEffect(()=> {
    if(countryCode === '') setCountrySelectValue(null);

    if(stateName?.value !== '' && collectTaxes) {
      dispatch(setTaxRate({ taxRate: null }));
    }

  },[countryCode, stateName]);

  const renderName = () => {
    if (noNameOverride) {
      return null;
    }

    if (!showForAdmin) {

      const handleChange = (value: string) => {
        dispatch(setBillingName(value));
      };

      return (
        <FormInput.Root error={!!nameError}>
          <FormInput.FieldSet>
            <FormInput.Legend>{t('NAME')}</FormInput.Legend>
            <FormInput.TextInput
              placeholder={t('ADMIN_BILLING_FORM_CARDHOLDER_NAME_PLACEHOLDER')}
              onChange={handleChange}
              value={name}
              onBlur={handleNameBlur}
              required={true}
            />
          </FormInput.FieldSet>
          {nameError && <FormInput.SupportingText>{nameError}</FormInput.SupportingText>}
        </FormInput.Root>
      );
    }

    return (
      <LabeledInput label="NAME" error={nameError} showForAdmin={true} required={true}>
        <StyledInputContainer
          showForAdmin={true}
          placeholder={t('ADMIN_BILLING_FORM_CARDHOLDER_NAME_PLACEHOLDER')}
          onChange={handleNameChange}
          value={name}
          error={!!nameError}
          onBlur={handleNameBlur}
          required={true}
        />
      </LabeledInput>
    );
  };

  const renderZipCode = () => {
    if (!showForAdmin) {
      const handleChange = (value: string) => {
        const uppercase = value.toUpperCase();
        dispatch(setBillingPostalCode(uppercase));
        setPostalCodeComplete(checkPostalCodeComplete(uppercase));
      };

      return (
        <FormInput.Root error={!!postalCodeError}>
          <FormInput.FieldSet>
            <FormInput.Legend>{t('AREA_CODE')}</FormInput.Legend>
            <FormInput.TextInput
              onChange={handleChange}
              value={postalCode}
              required={true}
              data-testid="codeBillingInput"
              placeholder="00000"
            />
          </FormInput.FieldSet>
          {postalCodeError && <FormInput.SupportingText>{postalCodeError}</FormInput.SupportingText>}
        </FormInput.Root>
      );
    }

    return (
      <PostalCodeContainer>
        <LabeledInput label="AREA_CODE" error={postalCodeError} showForAdmin={true} required={true}>
          <StyledInputContainer
            showForAdmin={true}
            data-testid="codeBillingInput"
            placeholder="00000"
            onChange={handlePostalCodeChange}
            value={postalCode}
            error={!!postalCodeError}
          />
        </LabeledInput>
      </PostalCodeContainer>
    );
  };

  return (
    <>
      <BillingInfoContainer>
        {renderName()}
        <CountryCodeContainer>
          <LabeledInput data-testid="countryBillingDropdown" label="BILLING_COUNTRY" showForAdmin={showForAdmin} required={true}>
            <StyledSelect
              showForAdmin={showForAdmin}
              name="countryCode"
              placeholder={t('ADMIN_BILLING_FORM_SELECT_COUNTRY_PLACEHOLDER')}
              isSearchable={true}
              options={COUNTRY_LIST}
              isLoading={!COUNTRY_LIST}
              onChange={handleCountryCodeChange}
              value={countrySelectValue}
            />
          </LabeledInput>
        </CountryCodeContainer>
        {COUNTRIES_WITH_STATE_CODE_TAX.includes(countryCode) && (
          <StateCodeContainer>
            <LabeledInput data-testid="stateBillingInput" showForAdmin={showForAdmin} label={countryCode === 'CA' ? 'BILLING_PROVINCE' : 'BILLING_STATE'} required={true}>
              <StyledSelect
                showForAdmin={showForAdmin}
                name="stateCode"
                value={stateName ? stateName : countryCode === 'CA' ? 'Province...' : 'State...'}
                placeholder={countryCode === 'CA' ? 'Province...' : 'State...'}
                isSearchable={true}
                options={STATE_LIST}
                isLoading={!STATE_LIST}
                onChange={handleStateCodeChange}
              />
            </LabeledInput>
          </StateCodeContainer>
        )}
        {renderZipCode()}
      </BillingInfoContainer>
    </>
  );
};

export default BillingInfoForm;
