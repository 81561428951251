import React from 'react';

const padZero = (digit: number) => digit.toString().padStart(2, '0');

interface FormattedTimeProps {
  className?: string;
  seconds: number;
}

export default function FormattedTime({
  className,
  seconds: numSeconds,
}: FormattedTimeProps) {
  const getFormattedTime = () => {
    const prefix = numSeconds < 0 ? '-' : '';
    const absNumSeconds = Math.abs(numSeconds);

    const hours = Math.floor(absNumSeconds / 3600);
    const minutes = Math.floor((absNumSeconds % 3600) / 60);
    const seconds = Math.floor(absNumSeconds) % 60;

    return hours > 0
      ? `${prefix}${hours}:${padZero(minutes)}:${padZero(seconds)}`
      : `${prefix}${minutes}:${padZero(seconds)}`;
  };

  return (
    <span className={className}>
      {getFormattedTime()}
    </span>
  );
}
