import { ADMIN_ACCENT_PRIMARY, ADMIN_HIGHLIGHT_SECONDARY, MAESTRO_BLACK } from 'style/constants';
import { ADMIN_BUTTON_BORDER_RADIUS } from 'style/constants';
import { ADMIN_HIGHLIGHT_PRIMARY, ADMIN_SURFACE_1, ADMIN_SURFACE_2, ADMIN_SURFACE_4, ADMIN_SURFACE_5, ADMIN_SURFACE_6, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_BOLD, ADMIN_TEXT_LABEL_M_MEDIUM, ADMIN_TEXT_BODY_S_REGULAR, ADMIN_TEXT_LABEL_S_MEDIUM } from 'style/design-system/textStyles';
import { ADMIN_FIELD_TEXT_INPUT } from 'style/mixins';
import styled, { css } from 'styled-components';

const TOOLBAR_STYLES = css`
  padding: 10px;
  background: ${ADMIN_SURFACE_4};
  box-shadow: 0px 9px 27px 0px rgba(0, 0, 0, 0.37);
  row-gap: 4px;
  width: max-content;

  & .tox-toolbar__group {
    padding: 0;
    gap: 4px;
    margin: 0;
    width: 100%;
  }

  & button, div .tox-split-button {
    cursor: pointer;
    display: flex;
    min-width: 50px;
    height: 40px;
    margin: 0;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${ADMIN_SURFACE_6};
    background: ${ADMIN_SURFACE_4} !important;

    &:hover, &[aria-pressed="true"] {
      background: ${ADMIN_SURFACE_5} !important;
      & .tox-tbtn--enabled {
        background: ${ADMIN_SURFACE_5} !important;
      }
    }

    &[aria-expanded="true"] {
      & .tox-split-button__chevron {
        & svg {
          & path, & rect {
            fill: ${ADMIN_HIGHLIGHT_PRIMARY};
          }
        }
      }
    }

    & .tox-tbtn--enabled {
      background: ${ADMIN_SURFACE_4};
    }

    &:hover, &[aria-pressed="true"], .tox-tbtn--enabled {
      box-shadow: none;
    }

    & svg {
      & path:not(.tox-icon-text-color__color), & rect {
        fill: ${ADMIN_HIGHLIGHT_PRIMARY};
      }
    }

    & .tox-tbtn__select-label {
      color: ${ADMIN_HIGHLIGHT_PRIMARY};
    }

    & .tox-split-button__chevron {
      margin-right: 5px;
      & svg {
        & path, & rect {
          fill: ${ADMIN_HIGHLIGHT_SECONDARY};
        }
      }
    }
  }

  & .tox-split-button {
    width: 60px;

    & span:hover {
      background: ${ADMIN_SURFACE_5};
    }
  }

  & button[aria-disabled="true"] {
    opacity: 0.6;
    pointer-events: none;
  }

  & button[title="Formats"], button[title="Font sizes"], button[title="Fonts"], button[title="Blocks"] {
    width: fit-content !important;
    margin: 0;

    & > .tox-tbtn__select-label {
      cursor: pointer;
      width: fit-content;
      ${ADMIN_TEXT_LABEL_M_MEDIUM}
      color: ${ADMIN_TEXT_100};
    }
  }
`;
export const TINY_MCE_STYLES = css`
  width: 100%;
  & .tox-tinymce {
    border: none;
    border-radius: 0;

    & .tox-editor-header {
      background-color: transparent !important;
      padding: 0px !important;
      border: none !important;
      cursor: default;
      flex-wrap: wrap;

      // remove paddings and borders between the menu and the inline toolbar
      & .tox-toolbar-overlord {
        background-color: transparent !important;
        border: none !important;
        margin: 0 !important;
        padding: 0 !important;
      }

      & .tox-toolbar {
        ${TOOLBAR_STYLES}
      }
    }

    & iframe {
      background: transparent;
    }

    & .tox-statusbar {
      display: none;
    }
  }

  & .tox-tinymce-aux {
    // floating dropdowns
    & .tox-collection {
      ${ADMIN_TEXT_LABEL_M_BOLD}
      cursor: pointer;
      margin-top: 5px;
      padding: 0 !important;
      border: 0 !important;
      box-shadow: 0px 9px 27px 0px rgba(0, 0, 0, 0.37) !important;

      & .tox-collection__group {
        background: ${ADMIN_SURFACE_4};

        & .tox-collection__item {
          color: ${ADMIN_TEXT_100};

          & svg path {
            fill: ${ADMIN_TEXT_100};
          }
        }
        & .tox-collection__item--active, .tox-collection__item--enabled {
          background-color: ${ADMIN_SURFACE_1};
          & div {
            color: ${ADMIN_TEXT_100};
          }
          & svg path {
            fill: ${ADMIN_TEXT_100};
          }
        }
      }

    }

    // floating toolbar groups
    & .tox-toolbar__overflow, .tox-toolbar {
      ${TOOLBAR_STYLES}
      background: ${ADMIN_SURFACE_2} !important;
      padding: 10px !important;

      & .tox-toolbar__group {
        width: unset;
      }
    }

    // floating menu (color picker)
    & .tox-menu {
      padding: 10px;
      background-color: ${ADMIN_SURFACE_4} !important;

      & .tox-swatches {
        display: flex;
        flex-direction: column;
        gap: 4px;

        & .tox-swatches__row {
          gap: 4px;

          & .tox-swatch {
            border-radius: 100%;
            cursor: pointer;
            transform: none !important;
            border: 1px solid ${ADMIN_SURFACE_6};

            &:hover {
              transform: none !important;
            }
          }

          & .tox-collection__item--enabled {
            border: 1px solid ${ADMIN_ACCENT_PRIMARY};
          }
        }
      }


      & button {
        &:hover {
          background-color: ${ADMIN_SURFACE_5} !important;
        }
        & svg {
          fill: ${ADMIN_TEXT_100};
        }
      }
    }

    & .tox-dialog {
      width: fit-content !important;
      & .tox-dialog__header {
        padding: 30px 30px 0;
        background-color: ${ADMIN_SURFACE_4};

        & .tox-dialog__title {
          ${ADMIN_TEXT_LABEL_M_BOLD}
          color: ${ADMIN_TEXT_100};
        }

        & svg path {
          fill: ${ADMIN_HIGHLIGHT_PRIMARY};
        }
      }

      & .tox-dialog__body {
        padding-inline: 30px;
        background-color: ${ADMIN_SURFACE_4};
        & .tox-dialog__body-content {
          padding: 0;
          margin: 20px 0;

          & p {
            color: ${ADMIN_TEXT_100};
          }
        }

        & .tox-dialog__body-nav {
          padding-left: 0;
        }

        & .tox-form {
          gap: 20px;
          overflow: hidden;

          & .tox-label {
            ${ADMIN_TEXT_LABEL_M_MEDIUM}
            color: ${ADMIN_TEXT_100};
            margin-bottom: 12px;
          }

          & .tox-checkbox {
            ${ADMIN_TEXT_LABEL_S_MEDIUM}
            color: ${ADMIN_TEXT_100};

            & .tox-checkbox__icons {
              box-shadow: none;
            }

            & .tox-checkbox-icon__checked {
              box-shadow: none !important;
              & path {
                fill: ${ADMIN_ACCENT_PRIMARY};
              }
            }
          }

          & .tox-textfield {
            ${ADMIN_FIELD_TEXT_INPUT}
            width: 287px;

            &:focus {
              box-shadow: none;
            }
          }

          & .tox-color-picker-container {
            & .tox-textfield {
              width: unset;
            }
          }

          // hide Title field from the Link dialog
          & > div.tox-form__group:nth-child(3) {
            display: none;
          }
        }
      }

      & .tox-dialog__footer {
        background-color: ${ADMIN_SURFACE_4};
        padding: 0px 30px 30px 30px;

        & .tox-button {
          height: 40px;
          padding: 12px 24px;
          border-radius: ${ADMIN_BUTTON_BORDER_RADIUS};
          color: ${ADMIN_TEXT_100};
          align-items: center;
          display: flex;
          background-color: ${ADMIN_ACCENT_PRIMARY};
          border-color: ${ADMIN_ACCENT_PRIMARY};
          border-radius: ${ADMIN_BUTTON_BORDER_RADIUS};

          &:hover {
            background-color: ${MAESTRO_BLACK};
            color: ${ADMIN_TEXT_100};
            border-color: ${MAESTRO_BLACK};
          }
        }


        & .tox-button--secondary {
          background-color: ${ADMIN_SURFACE_5};
          border-color: ${ADMIN_SURFACE_5};

          &:hover {
            background-color: ${ADMIN_SURFACE_6};
            border-color: ${ADMIN_SURFACE_6};
          }
        }
      }

      & .tox-listbox--select {
        margin: 10px 0;
        background-color: ${ADMIN_SURFACE_5} !important;
        border-color: ${ADMIN_SURFACE_5} !important;
        & .tox-listbox__select-label {
          ${ADMIN_TEXT_BODY_S_REGULAR}
          color: ${ADMIN_TEXT_100};
        }

        & svg path {
          fill: ${ADMIN_HIGHLIGHT_PRIMARY};
        }

      }

      & .tox-dialog__body-nav-item {
        color: ${ADMIN_TEXT_100};
      }
    }

    & .tox-dialog-wrap__backdrop {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
  }
`;

export const Container = styled.div<{ isEditing: boolean; }>`
  ${TINY_MCE_STYLES}

  & .mce-content-body {
    line-height: normal;
    cursor: ${({ isEditing }) => (isEditing ? 'text' : 'default')};
    & p {
      margin: 0;
    }
  }
`;
