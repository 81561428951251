import React from 'react';
import { HighlightedPart, TextContainer } from './styles';

interface HighlightedTextProps {
  admin: boolean;
  highlight: string;
  text: string;
}

const HighlightedText = ({ text, highlight, admin }: HighlightedTextProps) => {
  if (!highlight.trim()) {
    return <TextContainer>{text}</TextContainer>;
  }

  const regex = new RegExp(`(${highlight})`, 'gi');
  return (
    <TextContainer>
      {text.split(regex).map((part, i) => (
        regex.test(part)
          ? <HighlightedPart key={i} admin={admin}>{part}</HighlightedPart>
          : <span key={i}>{part}</span>
      ))}
    </TextContainer>
  );
};

export default HighlightedText;
