import { mergeRegister } from '@lexical/utils';
import { BLUR_COMMAND, FOCUS_COMMAND, SELECTION_CHANGE_COMMAND, LexicalEditor, COMMAND_PRIORITY_LOW } from 'lexical';
import React from 'react';

const useUpdateListener = (editor: LexicalEditor, callbackFn: () => void) => {
  React.useEffect(() => {
    editor.getEditorState().read(callbackFn);
  }, [editor]);

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(callbackFn);
      }),
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          callbackFn();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          callbackFn();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          callbackFn();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, callbackFn]);
};

export default useUpdateListener;
