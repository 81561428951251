import { connect } from 'react-redux';
import withErrorGuard from 'components/core/withErrorGuard';
import {
  getContentWidth,
  getSiteId,
  isChannelSelectActive,
  getObjectId,
} from 'services/app/selectors';
import { getAdminBarWidth, getPendingOrActualChannelSelectPages, getPendingOrActualChannelSelectEnabled, isEditMode } from 'services/admin/selectors';
import { trackChannelSelect } from 'services/insights';
import { isFullscreenActive, isTheaterEmbed, isVideoEmbed } from 'services/user-layout';
import { isUserAdmin } from 'services/auth';
import { isMobileLayout } from 'services/device';
import { Feature } from 'hooks/use-feature-gate-enabled';
import { isFeatureEnabled } from 'services/feature-gate';
import ChannelSelect from './view';

const mapStateToProps = state => ({
  adminbarWidth: getAdminBarWidth(state),
  channelSelectActive: isChannelSelectActive(state),
  channelSelectEnabled: getPendingOrActualChannelSelectEnabled(state),
  channelSelectPages: getPendingOrActualChannelSelectPages(state),
  contentWidth: getContentWidth(state),
  currentChannelId: getObjectId(state),
  fullscreenActive: isFullscreenActive(state),
  isAdmin: isUserAdmin(state),
  isEditing: isEditMode(state),
  isMobileLayout: isMobileLayout(state),
  isSimpleModeEnabled: isFeatureEnabled(state, Feature.SIMPLE_MODE),
  isTheaterEmbed: isTheaterEmbed(state),
  isVideoEmbed: isVideoEmbed(state),
  siteId: getSiteId(state),
});

const mapDispatchToProps = dispatch => ({
  onEngage: (doc, extra) => {
    dispatch(trackChannelSelect('engage', doc, extra));
  },
});

export default withErrorGuard()(
  connect(mapStateToProps, mapDispatchToProps)(ChannelSelect),
);
