import styled, { css, IStyledProps, keyframes } from 'styled-components';
import { rgba } from 'colors';
import { TEXT_100 } from 'style/constants';

interface ShimmerProps extends IStyledProps {
  color?: string;
  shimmer?: string;
}

const shimmering = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

type GetColorFn = (props: ShimmerProps) => string;
const getColor: GetColorFn = (props) => {
  const color = props.color || TEXT_100(props);
  return rgba(color, 0.5);
};

type GetShimmerFn = (props: ShimmerProps) => string;
const getShimmer: GetShimmerFn = ({ color, shimmer }) => {
  const shimmerColor = shimmer || color || '#606060';
  return `linear-gradient(
    90deg,
    ${rgba(shimmerColor, 0)} 0,
    ${rgba(shimmerColor, 0.2)} 20%,
    ${rgba(shimmerColor, 0.5)} 60%,
    ${rgba(shimmerColor, 0)} 0
  )`;
};

const SHIMMER_BLOCK = css`
  &::after {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: ${getShimmer};
    animation: ${shimmering} 2s infinite;
    content: '';
  }
`;

export const ShimmerComponent: any = styled.div.attrs(props => ({
  color: props.color || TEXT_100(props),
}))`
  background-color: ${getColor};
  position: relative;
  overflow: hidden;
  ${SHIMMER_BLOCK}
`;
export const Circle: any = styled(ShimmerComponent)`
  background-color: ${getColor};
  width: 22px;
  height: 22px;
  border-radius: 100%;
`;
export const IconWrapper: any = styled.div`
  background: none;
  position: relative;
  overflow: hidden;
  & svg {
    fill: ${getColor};
  }
  & span {
    position: relative;
    ${SHIMMER_BLOCK}
  }
`;
export const Line: any = styled(ShimmerComponent)`
  background-color: ${getColor};
  border-radius: 20px;
  width: ${({ width = '100%' }: { width?: string }) => width};
  height: 15px;
  margin-bottom: 8px;
`;
export const Text: any = styled(ShimmerComponent)`
  background: none;
  color: ${getColor};
`;
