import React, { MouseEventHandler, useEffect, useState } from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import range from 'lodash/range';
import moment from 'moment-timezone';
import CalendarContainer from './Container';
import {
  ButtonsGrid,
  GridButton,
} from '../styles';
import { CalendarStateProps, Picker, DayCalendarProps, RangePickerProps } from '../interfaces';

interface YearPickerProps extends CalendarStateProps, DayCalendarProps, RangePickerProps {
  endPicker?: Picker;
}

export default function YearPicker({
  admin,
  calendarYear,
  endDate,
  isMonthYearSelectorVisible,
  onDatesChange,
  setCalendarYear,
  startDate,
}: YearPickerProps){
  const [yearRange, setYearRange] = useState(range(calendarYear, calendarYear + 12));

  const nextYearRange = () => {
    setYearRange(currRange => range(currRange[0] + 12, currRange[11] + 13));
  };

  const prevYearRange = () => {
    const rangeStart = yearRange[0] - 12;
    if (rangeStart > 0) {
      setYearRange(range(rangeStart, yearRange[0]));
    }
  };

  const selectYear: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!onDatesChange) {
      return;
    }
    const year = parseInt(e.currentTarget.dataset.year || '0', 10);
    onDatesChange(
      moment([year, 0, 1]).startOf('day'),
      moment([year, 11, 31]).endOf('day'),
    );
  };

  useEffect(() => {
    if (startDate || endDate) {
      setCalendarYear(startDate?.year() || endDate?.year() || calendarYear);
    }
  }, [startDate?.valueOf(), endDate?.valueOf()]);

  return (
    <CalendarContainer
      admin={admin}
      containerTitleKey="ADMIN_LABEL_SELECT_YEAR"
      isMonthYearSelectorVisible={isMonthYearSelectorVisible}
      navNext={nextYearRange}
      navPrev={prevYearRange}
      navTitle={calendarYear}
    >
      <ButtonsGrid>
        <NonKeyedListMapper list={yearRange}>
          {(key: string, year: number) => (
            <GridButton
              admin={admin}
              data-year={year}
              isActive={year === calendarYear}
              key={key}
              onClick={selectYear}
            >
              {year}
            </GridButton>
          )}
        </NonKeyedListMapper>
      </ButtonsGrid>
    </CalendarContainer>
  );
}
