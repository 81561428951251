
import styled, { css } from 'styled-components';
import {
  ACCENT_PRIMARY,
  ADMIN_TEXT_100,
  ADMIN_BUTTON_BORDER_RADIUS,
  BUTTON_BORDER_RADIUS,
  ADMIN_ACCENT_PRIMARY,
  ADMIN_HIGHLIGHT_PRIMARY,
  HIGHLIGHT_PRIMARY,
  ADMIN_SURFACE_6,
  ADMIN_SURFACE_2,
  SURFACE_2,
  SURFACE_5,
  TEXT_CONTRAST_ON_ACCENT_PRIMARY,
} from 'style/constants';
import { ADMIN_TEXT_BODY_XS_BOLD, TEXT_BODY_XS_BOLD } from 'style/design-system/textStyles';

export interface StyleProps {
  admin?: boolean;
}

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ disabled }) => disabled && css`
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export const Label = styled.label`
  width: 58px;
  height: 24px;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
`;

export const Slider = styled.span<StyleProps>`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ admin, ...props }) => admin ? ADMIN_SURFACE_2(props) : SURFACE_2(props)};
  transition: background-color 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.admin ? ADMIN_BUTTON_BORDER_RADIUS(props): BUTTON_BORDER_RADIUS(props)};

  &:before {
    content: "";
    position: absolute;
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: ${({ admin, ...props }) => admin ? ADMIN_SURFACE_6(props) : SURFACE_5(props)};
    transition: left 0.4s;
    left: 3px;
    top: 3px;
    bottom: 3px;
    height: 18px;
    width: 18px;
    border-radius: ${props => props.admin ? ADMIN_BUTTON_BORDER_RADIUS(props): BUTTON_BORDER_RADIUS(props)};
  }
`;

export const InnerSliderText = styled.label<StyleProps>`
  ${({ admin }) => admin ? ADMIN_TEXT_BODY_XS_BOLD : TEXT_BODY_XS_BOLD}
  position: absolute;
  text-transform: uppercase;
  color: ${({ admin, ...props }) => admin ? ADMIN_HIGHLIGHT_PRIMARY(props) : HIGHLIGHT_PRIMARY(props)};
`;

export const Input = styled.input<StyleProps>`
  opacity: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  z-index: 99;
  cursor: pointer;

  ${({ checked }) => checked && css`
    & + span:before {
      left: 37px;
    }
  `}
  ${({ checked }) => checked ? css<StyleProps>`
    & + ${Slider} {
      background-color: ${({ admin, ...props }) => admin ? ADMIN_ACCENT_PRIMARY(props) : ACCENT_PRIMARY(props)};
      & > ${InnerSliderText} {
        left: 8px;
        color: ${({ admin, ...props }) => admin ? ADMIN_TEXT_100(props) : TEXT_CONTRAST_ON_ACCENT_PRIMARY(props)};
      }
      &:before {
        background-color: ${({ admin, ...props }) => admin ? ADMIN_HIGHLIGHT_PRIMARY(props) : HIGHLIGHT_PRIMARY(props)};
        left: 37px;
      }
    }
  ` : css`
    & + ${Slider} > ${InnerSliderText} {
      right: 8px;
    }
  `}
  &:focus + span {
    outline: none;
  }
`;
