import React, { memo } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  SURFACE_5,
} from 'style/constants';

interface IProps {
  duration?: number;
}

interface IStyleProps {
  duration: number;
}

const rota = keyframes`
  0% { transform: rotate(180deg); }
  100% { transform: rotate(360deg); }
`;

const opa = keyframes`
  0% { opacity: 1; }
  50%, 100% { opacity: 0; }
`;

const flip = keyframes`
  0% { overflow: visible; transform: rotate(0deg); }
  50%, 100% { overflow: hidden; transform: rotate(180deg); }
`;

const fadeOnDone = keyframes`
  0% { opacity: 0; }
  5%, 95% { opacity: 1; }
  100% { opacity: 0; }
`;

const Wrapper = styled.div<IStyleProps>`
  background: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  left: 0px;
  right: 0px;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  bottom: 30px;
  position: absolute;
  transform: translate3d(0, -50%, 0);
`;

const PieWrapper = styled.div<IStyleProps>`
  animation: ${fadeOnDone} ${(props) => props.duration}s linear 1;
  background: transparent;
  box-sizing: border-box;
  height: 18px;
  position: absolute;
  width: 18px;
  left: 6px;
  top: 6px;
`;

const PieSpinnerMask = styled.div<IStyleProps>`
  animation: ${flip} ${(props) => props.duration}s steps(1, end) infinite reverse;
  background: transparent;
  height: 100%;
  overflow: hidden;
  position: absolute;
  transform-origin: 100% 50%;
  width: 50%;
`;

const PieSpinner = styled.div<IStyleProps>`
  animation: ${rota} ${(props) => props.duration / 2}s linear infinite;
  background: ${SURFACE_5};
  border-radius: 100% 0 0 100% / 50% 0 0 50%;
  border-right: none;
  height: 100%;
  position: absolute;
  transform-origin: 100% 50%;
  width: 100%;
  z-index: 200;
`;

const PieFiller = styled.div<IStyleProps>`
  animation: ${opa} ${(props) => props.duration}s steps(1, end) infinite reverse;
  background: ${SURFACE_5};
  border-left: none;
  border-radius: 0 100% 100% 0 / 0 50% 50% 0;
  height: 100%;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform-origin: 100% 50%;
  width: 50%;
  z-index: 100;
`;

const PieTimer = ({ duration }: IProps) => {
  return (
    <Wrapper duration={duration!}>
      <PieWrapper duration={duration!}>
        <PieSpinnerMask duration={duration!}>
          <PieSpinner duration={duration!} />
        </PieSpinnerMask>
        <PieFiller duration={duration!} />
      </PieWrapper>
    </Wrapper>
  );
};

PieTimer.defaultProps = {
  duration: 5,
};

export default memo(PieTimer);
