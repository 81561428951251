import React, { forwardRef, PropsWithChildren } from 'react';
import useResizeObserver from 'hooks/use-resize-observer';
import {
  ExpandableWrapper,
  ExpandableContainer,
  ExpandableStyleProps,
} from './styles';

export interface ExpandableProps extends PropsWithChildren<ExpandableStyleProps> {
  alwaysExpanded?: boolean;
  fixedExpandedHeight?: string;
  minHeight?: number;
}

const Expandable = forwardRef<HTMLDivElement, ExpandableProps>(({
  alwaysExpanded,
  children,
  collapsedHeight = '0px',
  isExpanded = false,
  backgroundColor,
  minHeight = 0,
  fixedExpandedHeight = '',
  ...props
}, ref) => {
  const [getCardHeight, { height }] = useResizeObserver();
  const expanded = alwaysExpanded || isExpanded;

  return (
    <ExpandableWrapper
      alwaysExpanded={alwaysExpanded}
      collapsedHeight={collapsedHeight}
      height={Math.max(height, minHeight)}
      fixedExpandedHeight={fixedExpandedHeight}
      isExpanded={expanded}
      backgroundColor={backgroundColor}
      ref={ref}
      {...props}
    >
      <ExpandableContainer
        ref={getCardHeight}
        collapsedHeight={collapsedHeight}
        isExpanded={expanded}
      >
        {expanded && children}
      </ExpandableContainer>
    </ExpandableWrapper>
  );
});

export default Expandable;
