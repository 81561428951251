import styled, { css } from 'styled-components';
import PageLink from 'components/ui/PageLink';
import AspectRatio from 'components/ui/AspectRatio';
import { BREAK_WORD, FLEX_CENTER, MULTI_LINE_ELLIPSIS } from 'style/mixins';
import {
  ACCENT_PRIMARY,
  ADMIN_ACCENT_PRIMARY,
  SURFACE_3,
  TEXT_100,
  ADMIN_SURFACE_2,
  SURFACE_2,
} from 'style/constants';
import { ADMIN_TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';

export interface StyleProps {
  active?: boolean;
  fixedHeight?: number;
  fixedWidth?: number;
  fontSize?: string;
  isAdmin?: boolean;
  itemPadding?: string;
}

export const LinkWrap = styled(PageLink)<StyleProps>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 4.5px;
  overflow: hidden;
  background: ${({ isAdmin }) => isAdmin ? ADMIN_SURFACE_2 : SURFACE_3};
`;

export const ChannelSelectContainer = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  cursor: pointer;
  ${({ isAdmin }) => isAdmin && ADMIN_TEXT_BODY_XS_REGULAR};
  ${({ fontSize = '24px' }) => `font-size: ${fontSize}`};
  justify-content: center;
  ${BREAK_WORD};
  position: relative;
  color: ${TEXT_100};
`;

export const Highlight = styled.div<StyleProps>`
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  border-top: 2.27px solid ${({ isAdmin }) => isAdmin ? ADMIN_ACCENT_PRIMARY : ACCENT_PRIMARY};
  pointer-events: none;
`;

export const ChannelImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const ChannelTextWrapper = styled.span<{ noLink?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: ${({ noLink }) => noLink ? 'absolute' : 'relative'};
  overflow: hidden;
  border-radius: 4.5px;
`;

export const SvgContainer = styled.div<Pick<StyleProps, 'itemPadding'>>`
  text-align: center;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  padding: ${({ itemPadding = '0px' }) => itemPadding};
  transition: padding 0.5s;
  color: ${TEXT_100};
  position: relative;
  ${FLEX_CENTER}
  & > svg {
    width: 500px;
    & > .surface2 {
      fill: ${SURFACE_2};
    }
    & > .surface3 {
      fill: ${SURFACE_3};
    }
  }
`;

export const EllipsisText = styled.div`
  ${MULTI_LINE_ELLIPSIS}
  height: auto;
  position: absolute;
`;

export const BadgeContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 7px;
  right: 7px;
  top: 7px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    margin-top: 4px;
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;

export const StyledAspectRatio = styled(AspectRatio)<StyleProps>`
  &, & > * {
    transition: width 0.5s, height 0.5s;
    ${({ fixedHeight }) => fixedHeight && css`
      height: ${fixedHeight}px;
      padding-bottom: 0;
    `}
    ${({ fixedWidth }) => fixedWidth && css`
      width: ${fixedWidth}px;
      padding-bottom: 0;
    `}
  }
`;

export const ActionsContainer = styled.div`
  position: absolute;
  bottom: 13px;
  right: 5px;
  display: flex;
  flex-direction: column;
  font-family: noto-sans-regular,Noto Sans,sans-serif;
  font-size: 9px;
  font-weight: 500;
  line-height: 10px;
`;

export const Action = styled.div`
  background-color: black;
  border-radius: 3px;
  color: white;
  margin: 0;
  margin-bottom: 2px;
  padding: 5px 8px;
  cursor: pointer;
`;

export const Dot = styled.span`
  display: block;
  border-radius: 4px;
  border: 2px solid white;
  background: white;
`;

export const ActionMenu = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  border-radius: 4px;
  background-color: black;
  width: 20px;
`;

export const Container = styled.div``;
