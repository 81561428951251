import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const DivLink = styled.div`
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && 'pointer-events: none;'}
`;

export default class VideoLink extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    'data-testid': PropTypes.string,
    goToVideo: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    playlistId: PropTypes.string,
    playlistIndex: PropTypes.number,
    tag: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    className: '',
    'data-testid': undefined,
    isDisabled: false,
    onClick: undefined,
    playlistId: null,
    playlistIndex: null,
    tag: null,
  };

  render() {
    const {
      children,
      className,
      'data-testid': testId,
      isDisabled,
      onClick,
      playlistId,
      playlistIndex,
      tag,
      goToVideo,
    } = this.props;

    if (!tag) {
      return <span className={className}>{ children }</span>;
    }

    const tagValue = tag.startsWith('v=') ? tag.substr(2) : tag;

    const handleClick = () => {
      onClick?.();
      goToVideo(tagValue, playlistId, playlistIndex);
    };

    return (
      <DivLink
        className={className}
        data-testid={testId}
        isDisabled={isDisabled}
        onClick={handleClick}
      >
        {
          children
        }
      </DivLink>
    );
  }
}
