import {
  DOMConversionMap,
  NodeKey,
  TextNode,
  SerializedTextNode,
} from 'lexical';

export interface SerializedExtendedTextNode extends SerializedTextNode {
  type: 'extended-text';
}

// https://lexical.dev/docs/concepts/serialization#handling-extended-html-styling
export default class ExtendedTextNode extends TextNode {
  constructor(text: string, key?: NodeKey) {
    super(text, key);
  }

  static clone(node: ExtendedTextNode): ExtendedTextNode {
    return new ExtendedTextNode(node.__text, node.__key);
  }

  static getType() {
    return 'extended-text' as const;
  }

  static importDOM(): DOMConversionMap | null {
    return null;
  }

  static importJSON(serializedNode: SerializedTextNode): TextNode {
    return TextNode.importJSON(serializedNode);
  }

  exportJSON(): SerializedExtendedTextNode {
    return {
      ...super.exportJSON(),
      type: ExtendedTextNode.getType(),
      version: 1,
    };
  }

  isSimpleText() {
    return (
      (this.__type === 'text' || this.__type === ExtendedTextNode.getType()) &&
      this.__mode === 0
    );
  }
}
