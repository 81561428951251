import React from 'react';
import { Container } from './styles';
import useTinyMce from './use-tiny-mce';
import { IRichTextEditorV2Props } from './utils';
import Editor from './Editor';

/**
 * @deprecated This component is deprecated and will be removed in the future.
 * Please use the RichTextEditorV3 component instead.
 */
const RichTextEditorV2: React.FC<IRichTextEditorV2Props> = ({
  data,
  onChange,
  maxLength = Infinity,
  readOnly,
  isEditMode,
  disableDebounce,
  toolbarButtons,
  inline,
  codeInjectionClassName,
  htmlTag,
  ...props
}) => {
  const {
    handleBeforeAddUndo,
    handleUpdate,
    plugins,
    value,
    setup,
    toolbar1,
    ref,
  } = useTinyMce({
    data,
    maxLength,
    onChange,
    disableDebounce,
    toolbarButtons,
    codeInjectionClassName,
  });

  return (
    <Container {...props} isEditing={isEditMode} as={htmlTag}>
      <Editor
        ref={ref}
        value={value}
        onEditorChange={handleUpdate}
        onBeforeAddUndo={handleBeforeAddUndo}
        isEditMode={isEditMode}
        inline={inline}
        plugins={plugins}
        readOnly={readOnly}
        setup={setup}
        toolbar1={toolbar1}
      />
    </Container>
  );
};

export default RichTextEditorV2;
