import Icon from 'components/ui/Icon';
import { ACCENT_PRIMARY, SURFACE_3, TEXT_200 } from 'style/constants';
import { TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const MessageContainer = styled.div`
  display: flex;
  width: 60vw;
  column-gap: 14px;
  align-items: center;
  padding: 12px 16px 12px 12px;
  border-radius: 10px;
  background-color: ${SURFACE_3};
`;

export const Message = styled.span`
  ${TEXT_LABEL_M_MEDIUM}
  color: ${TEXT_200};
  text-align: center;
`;

export const StyledIcon = styled(Icon).attrs({
  name: 'rotate',
})`
  & svg {
    width: 40px;
    height: 40px;

    & path {
      stroke: ${ACCENT_PRIMARY};
    }
  }
`;

export const Content = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Blur = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
`;

export const Rotate = styled.div`
  transform: rotate(90deg);
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vw;
  width: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
