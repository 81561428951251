import styled, { DefaultTheme, keyframes } from 'styled-components';
import { rgba } from 'colors';
import { BACKGROUND_ACTIVE, BACKGROUND_TITLE } from 'style/mixins';
import { SURFACE_1, TEXT_100, HIGHLIGHT_PRIMARY } from 'style/constants';

interface IProps {
  isHeader: boolean;
  theme: DefaultTheme;
}

const fadeIn = (props: IProps) => keyframes`
  0% {
    color: ${props.isHeader ? rgba(TEXT_100(props), 0) : rgba(HIGHLIGHT_PRIMARY(props), 0)};
  }
  100% {
    color: ${props.isHeader ? rgba(TEXT_100(props), 1) : rgba(HIGHLIGHT_PRIMARY(props), 1)};
  }
`;

const SidebarActionButton = styled.button<{ isHeader: boolean, loading: boolean }>`
  animation: ${fadeIn} .5s;
  flex-shrink: 0;
  border-bottom: 2px solid ${SURFACE_1};
  text-align: center;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  width: 100%;
  height: 48px;
  ${props => (props.loading ? BACKGROUND_TITLE : BACKGROUND_ACTIVE)};
  cursor: pointer;
  ${props => props.isHeader && `color: ${TEXT_100(props)}`};
`;

export default SidebarActionButton;
