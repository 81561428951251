import styled from 'styled-components';
import Icon from 'components/ui/Icon';
import { TEXT_P1, INNER_BORDER, CIRCLE } from 'style/mixins';
import { ACCENT_PRIMARY, HIGHLIGHT_PRIMARY, SURFACE_1, TITLE_FONT_FAMILY } from 'style/constants';

const SLIDE_POSITION = ({ index, margin, slideWidth }) => {
  return index && (index * slideWidth + index * margin);
};

export const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
  &:empty {
    display: none;
  }
`;

export const NavControls = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
`;

export const CircledButton = styled(Icon).attrs(props => ({
  color: HIGHLIGHT_PRIMARY(props),
  radius: '24px',
}))<{ active: boolean; }>`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 6px;
  ${CIRCLE}
  ${({ active }) => active ? `
    opacity: 1.0;
    cursor: pointer;
  ` : `
    opacity: 0.5;
    pointer-events: none;
  `}
  ${INNER_BORDER}
  &:after {
    border: 2px solid ${HIGHLIGHT_PRIMARY};
    border-radius: 50%;
  }
  & > svg {
    width: 100%;
    height: 100%;
    & > path {
      stroke: ${HIGHLIGHT_PRIMARY} !important;
      stroke-width: 0.4px;
    }
  }
`;

export const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  position: relative;
  height: auto !important;
  z-index: 2;
`;

export const MobileTitle = styled.div`
  ${TEXT_P1}
  font-family: ${TITLE_FONT_FAMILY};
`;

export const SliderContainer = styled.div<{ fullwidth: number; minWidth: number; }>`
  display: flex;
  flex-flow: row nowrap;
  min-width: ${({ minWidth }) => minWidth}px;
  ${({ fullwidth }) => fullwidth && 'width: 100%;'}
`;

export const SliderContainerWithTooltip = styled(SliderContainer)<{ height?: number; }>`
  ${({ height }) => height && `height: ${height}px`};
  align-items: flex-end;
  width: 100%;
`;

export const SnapSlideContainer = styled.div<{ containerWidth: number; margin: number; translation: number; }>`
  display: flex;
  transition: transform 0.25s ease-out;
  transform: ${({ translation }) => `translateX(${translation}px)`};
  width: ${({ containerWidth }) => containerWidth}px;

  & > *:not(:first-child) {
    margin-left: ${({ margin }) => margin}px;
  }
  scroll-snap-type: x mandatory;
`;

export const MomentumSlideContainer = styled.div`
  display: flex;
  overflow: auto hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const SlideContainer = styled.div<{ scrollWidth: number; slideHeight?: number; }>`
  ${({ slideHeight: height }) => height && `min-height: ${height}px`};
  display: flex;
  position: relative;
  min-width: ${({ scrollWidth }) => scrollWidth}px;
  width: 100%;
`;

export const SlideWrapper = styled.div<{ alignRight?: boolean; }>`
  overflow: hidden;
  justify-content: center;
  align-items: flex-${({ alignRight }) => alignRight ? 'end' : 'start'};
  width: 100%;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -5px;
    width: 15px;
    height: 100%;
    background: linear-gradient(to left,rgb(6 6 6 / 0%), ${SURFACE_1});
  }
`;

export const SlideWrapperWithTooltip = styled(SlideWrapper)<{ height?: number; }>`
  ${({ height }) => height && `height: ${height}px`};
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
`;

export const MomentumSlideSlot = styled.div<{ index: number; margin: number; slideWidth: number; width: number; }>`
  flex: 0 0 ${({ width }) => width}px;
  max-width: ${({ width }) => width}px;
  position: absolute;
  left: ${SLIDE_POSITION}px;
  word-break: break-word;
`;

export const SnapSlideSlot = styled.div<{ width: number; }>`
  flex: 0 0 ${({ width }) => width}px;
  scroll-snap-align: center;
  word-break: break-word;
`;

export const ButtonContainer = styled.div<{ active: boolean; }>`
  color: ${ACCENT_PRIMARY};
  cursor: ${props => props.active ? 'pointer' : 'default'};
  font-size: 18px;
  margin: auto 0;
  width: 25px;
`;

export const DefaultArrowIcon = styled(Icon)`
  display: block;
  & svg {
    width: 25px;
    height: 25px;
  }
`;
