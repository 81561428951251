// tslint:disable:jsx-boolean-value
import React from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { PolicyWrapper, StyledLink } from './styles';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'shared/constants';
import { getCustomTextModal } from 'services/app/selectors';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
interface TermsOfUseProps {
  className?: string;
}

export default function TermsOfUse({ className }: TermsOfUseProps) {
  const { privacy, terms } = useSelector(getCustomTextModal, isEqual);

  return (
    <PolicyWrapper className={className}>
      <StyledLink href={terms.length > 0 ? terms : TERMS_OF_USE_URL}>
        <TranslatedText stringKey="TERMS_OF_USE" />
      </StyledLink>
      <StyledLink href={privacy.length > 0 ? privacy : PRIVACY_POLICY_URL}>
        <TranslatedText stringKey="PRIVACY_POLICY" />
      </StyledLink>
    </PolicyWrapper>
  );
}
