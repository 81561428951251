import React, { ComponentType, ReactNode } from 'react';

interface ConditionalWrapperProps<T> {
  children: ReactNode,
  wrap: boolean,
  wrapper: ComponentType<T>,
  wrapperProps?: T,
}

export default function<T>({
  children,
  wrap,
  wrapper: Wrapper,
  wrapperProps = {} as T,
}: ConditionalWrapperProps<T>) {
  return wrap ? (
    <Wrapper {...wrapperProps}>{children}</Wrapper>
  ) : <>{children}</>;
}
