import { RangeSelection } from 'lexical';
import React, { createContext, PropsWithChildren } from 'react';

const Context = createContext<{ selection: RangeSelection | null; setSelection: (selection: RangeSelection | null) => void } | null>(null);

const SelectionProvider = ({ children }: PropsWithChildren) => {
  const [selection, setSelection] = React.useState<RangeSelection | null>(null);

  return <Context.Provider value={{ selection, setSelection }}>{children}</Context.Provider>;
};

export const useSelectionContext = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('useSelectionContext must be used within a SelectionContextProvider');
  }

  return context;
};

export default SelectionProvider;
