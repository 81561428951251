import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackEvent } from 'services/segment-analytics';
import { useAdminTranslation } from 'hooks/use-translation';
import { LoginButton, StartForFreeButton, Wrapper } from './styles';
import { isChannelPageType } from 'services/app/selectors';

type MarketingAuthHeaderProps = {
  onClick: () => void;
};

const MarketingAuthHeader: React.FC<MarketingAuthHeaderProps> = ({ onClick }) => {
  const dispatch = useDispatch();
  const { t } = useAdminTranslation();
  const isChannel = useSelector(isChannelPageType);

  const handleStartForFreeButtonClick = React.useCallback(() => {
    dispatch(
      trackEvent({
        event: 'CTAClicked',
        properties: {
          buttonLocation: 'header',
          buttonTitle: t('START_FOR_FREE'),
          url: document.location.origin + document.location.pathname,
        },
      }),
    );
    onClick();
  }, []);

  return (
    <Wrapper isChannel={isChannel}>
      <StartForFreeButton onClick={handleStartForFreeButtonClick}>{t('START_FOR_FREE')}</StartForFreeButton>
      <LoginButton onClick={onClick}>{t('ACTION_LOG_IN')}</LoginButton>
    </Wrapper>
  );
};

export default MarketingAuthHeader;
