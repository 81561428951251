import React from 'react';
import { OnChangePlugin as LexicalOnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { EditorState } from 'lexical';
import { debounce } from 'lodash';
import hash from 'json-stable-stringify';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

type OnChangePluginProps = {
  data: string | null;
  onChange: (data: string) => void;
};

const OnChangePlugin: React.FC<OnChangePluginProps> = ({ onChange, data }) => {
  const [editor] = useLexicalComposerContext();

  const isEqual = React.useCallback((target: string, editorState: EditorState) => hash(JSON.parse(target)) === hash(editorState.toJSON()), []);

  // When data changes in props (i.e. we discard the changes, exit edit mode, etc)
  React.useEffect(() => {
    if (!data) {
      return;
    }

    if (isEqual(data, editor.getEditorState())) {
      return;
    }

    const editorState = editor.parseEditorState(data);

    // Faced flushSync error when dealing with big editorState. Looks like we need to defer the update
    // https://github.com/facebook/lexical/discussions/3536
    const timeout = setTimeout(() => {
      editor.setEditorState(editorState);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [hash(data), editor]);

  const handleChange = debounce((editorState: EditorState) => {
    if (data && isEqual(data, editorState)) {
      return;
    }

    onChange(JSON.stringify(editorState.toJSON()));
  }, 300);

  return <LexicalOnChangePlugin onChange={handleChange} ignoreSelectionChange={true} />;
};

export default OnChangePlugin;
