// TODO: Make this a little more professional (or not!)
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { bw } from 'colors';
import Link from 'components/ui/Link';
import { SURFACE_1 } from 'style/constants';

const Container = styled.div`
  color: ${props => bw(SURFACE_1(props))};
`;

const NotFound = ({ className }) => (
  <Container className={className}>
    <h1>Not Found</h1>
    <p>Sorry, this page does not exist.</p>
    <p>
      <Link href="/">Return home</Link>
    </p>
  </Container>
);

NotFound.propTypes = {
  className: PropTypes.string,
};

NotFound.defaultProps = {
  className: '',
};

export default NotFound;
