import React from 'react';
import { AddressElement } from '@stripe/react-stripe-js';
import { GOOGLE_MAPS_API_KEY } from 'config';
import { ContactOption, StripeAddressElementChangeEvent, StripeAddressElementOptions } from '@stripe/stripe-js';

type BillingAddressProps = {
  contacts?: ContactOption[];
  mode: 'billing' | 'shipping';
  onChange: (e: StripeAddressElementChangeEvent) => void;
  validation?: StripeAddressElementOptions['validation'];
};

const StripeAddress: React.FC<BillingAddressProps> = ({ onChange, contacts, mode, validation }) => {
  const throttle = (func: (e: StripeAddressElementChangeEvent) => void, limit: number) => {
    let inThrottle: boolean;
    return function () {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  };

  const throttleChange = throttle(onChange, 500);

  return (
    <AddressElement
      onChange={throttleChange}
      options={{
        mode,
        contacts,
        fields: { phone: 'always' },
        autocomplete: {
          mode: 'google_maps_api',
          apiKey: GOOGLE_MAPS_API_KEY!,
        },
        validation,
      }}
    />
  );
};

export default StripeAddress;
