/* tslint:disable:jsx-boolean-value */
import React from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import range from 'lodash/range';
import {
  DaysContainer,
  WeekDay,
} from '../styles';
import { CalendarStyleProps } from '../interfaces';

interface WeekHeaderProps extends CalendarStyleProps {
  weekDays: string[];
}

export default function WeekHeader({ weekDays, maxCols = 7 }: WeekHeaderProps) {
  return (
    <DaysContainer noMargin>
      {maxCols > weekDays.length && (
        <NonKeyedListMapper list={range(0, maxCols - weekDays.length)}>
          {(key: string, _: number, __: number) => <WeekDay key={key} maxCols={maxCols} />}
        </NonKeyedListMapper>
      )}
      <NonKeyedListMapper list={weekDays}>
        {(key: string, weekDay: string) => (
          <WeekDay maxCols={maxCols} key={key}>{weekDay}</WeekDay>
        )}
      </NonKeyedListMapper>
    </DaysContainer>
  );
}
