
import React, { PropsWithChildren } from 'react';
import { StyledButton } from './styles';

interface IAddNewPaymentMethodButtonProps extends PropsWithChildren {
  onClick: () => void;
}

const AddNewPaymentMethodButton: React.FC<IAddNewPaymentMethodButtonProps> = ({ children, onClick }) => {
  return <StyledButton onClick={onClick}>{children}</StyledButton>;
};

export default AddNewPaymentMethodButton;
