import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { trimTextContentFromAnchor } from '@lexical/selection';
import { $getSelection, $isRangeSelection, RootNode } from 'lexical';
import { useEffect, useState } from 'react';

export function MaxLengthPlugin({ maxLength }: {maxLength: number}): null {
  const [initialTextContentLength, setInitialTextContentLength] = useState(0);
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      if (initialTextContentLength) {
        return;
      }
      const textContent = rootNode.getTextContent();
      setInitialTextContentLength(textContent.length);
    });
  }, [initialTextContentLength]);

  useEffect(() => {
    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }
      const prevTextContent = editor
        .getEditorState()
        .read(() => rootNode.getTextContent());
      const textContent = rootNode.getTextContent();
      if (prevTextContent !== textContent) {
        const textLength = textContent.length;
        const delCount = textLength - maxLength;
        const anchor = selection.anchor;

        if (delCount > 0 && initialTextContentLength <= maxLength) {
          trimTextContentFromAnchor(editor, anchor, delCount);
        }
      }
    });
  }, [editor, maxLength, initialTextContentLength]);

  return null;
}
