import React from 'react';
import { StyledTextInput } from './styles';


export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (text: string) => void;
}

export const TextInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(event.target.value);
  }, [props.onChange]);

  return <StyledTextInput ref={ref} {...props} onChange={handleChange} />;
});

export default TextInput;
