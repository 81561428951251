import React, { MouseEventHandler, useMemo } from 'react';
import NonKeyedListMapper from 'components/core/NonKeyedListMapper';
import { DateData, WeekDay } from 'hooks/use-calendar';
import moment from 'moment-timezone';
import chunk from 'lodash/chunk';
import CalendarContainer from './Container';
import WeekHeader from './WeekHeader';
import {
  Checkmark,
  DayButton,
  DaysContainer,
  Radio,
  WeekRow,
} from '../styles';
import { CalendarStateProps, DayCalendarProps, RangePickerProps } from '../interfaces';

interface WeekPickerProps extends CalendarStateProps, RangePickerProps, DayCalendarProps {
  visibleDays: DateData[];
  weekDays: WeekDay[];
}

export default function WeekPicker({
  admin,
  calendarMonth,
  calendarYear,
  dateTitle,
  endDate,
  isMonthYearSelectorVisible,
  monthYearTitle,
  nextMonth,
  onDatesChange,
  prevMonth,
  toggleMonthYearSelector,
  startDate,
  visibleDays,
  weekDays,
}: WeekPickerProps) {
  const visibleWeeks = useMemo(() => (
    chunk(visibleDays, 7)
  ), [calendarMonth, calendarYear]);

  const selectedWeek = useMemo(() => {
    if (!startDate || !endDate) {
      return -1;
    }
    return visibleWeeks.findIndex((week) => {
      for (const day of week) {
        if (
          day.day === startDate.date() &&
          day.month === startDate.month() &&
          day.year === startDate.year()
        ) {
          return true;
        }
      }
      return false;
    });
  }, [startDate?.valueOf(), endDate?.valueOf(), calendarMonth, calendarYear]);

  const selectWeek: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!onDatesChange) {
      return;
    }
    const index = parseInt(e.currentTarget.dataset.week || '0', 10);
    const week = visibleWeeks[index];
    const start = week[0];
    const end = week[6];
    onDatesChange(
      moment([start.year, start.month, start.day]).startOf('day'),
      moment([end.year, end.month, end.day]).endOf('day'),
    );
  };

  return (
    <CalendarContainer
      admin={admin}
      buttonText={monthYearTitle}
      containerTitleKey="ADMIN_LABEL_SELECT_WEEK"
      isMonthYearSelectorVisible={isMonthYearSelectorVisible}
      navNext={nextMonth}
      navPrev={prevMonth}
      navTitle={dateTitle}
      onActionClick={toggleMonthYearSelector}
    >
      <WeekHeader weekDays={weekDays} maxCols={8} />
      <DaysContainer>
        <NonKeyedListMapper list={visibleWeeks}>
          {(weekKey: string, week: DateData[], weekIndex: number) => (
            <WeekRow key={weekKey} admin={admin} data-week={weekIndex} isActive={weekIndex === selectedWeek} onClick={selectWeek}>
              {weekIndex === selectedWeek ? <Checkmark admin={admin} /> : <Radio />}
              <NonKeyedListMapper list={week}>
                {(dayKey: string, { day }: DateData) => (
                  <DayButton
                    key={dayKey}
                    admin={admin}
                    maxCols={8}
                  >
                    {day}
                  </DayButton>
                )}
              </NonKeyedListMapper>
            </WeekRow>
          )}
        </NonKeyedListMapper>
      </DaysContainer>
    </CalendarContainer>
  );
}
