import { PaymentMethod } from '@stripe/stripe-js';
import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchTaxRate, setBillingInfo } from 'services/billing';
import PaymentMethodCard from '../PaymentMethodCard';
import { Wrapper } from './styles';
import { makeBillingInfoFromPaymentMethod } from '../constants';
import usePaymentMethods from 'hooks/use-payment-methods';

interface IPaymentMethodCardsProps {
  onChange: (pm: PaymentMethod) => void;
  selectedPaymentMethod?: PaymentMethod | null;
}

const PaymentMethodCards: React.FC<IPaymentMethodCardsProps> = ({ onChange, selectedPaymentMethod }) => {
  const dispatch = useDispatch();
  const { paymentMethodsAvailable } = usePaymentMethods();

  const handleSelectPaymentMethod = React.useCallback((newPaymentMethod: PaymentMethod) => {
    if (newPaymentMethod.id !== selectedPaymentMethod?.id) {
      dispatch(setBillingInfo(makeBillingInfoFromPaymentMethod(newPaymentMethod)));
      dispatch(fetchTaxRate(newPaymentMethod));
      onChange(newPaymentMethod);
    }
  }, [selectedPaymentMethod, onChange, dispatch]);

  const renderPaymentMethodsCards = React.useCallback(() => {
    return paymentMethodsAvailable.map((payment: PaymentMethod) => {
      const isSelected = payment.id === selectedPaymentMethod?.id;

      return (
        <PaymentMethodCard
          onClick={handleSelectPaymentMethod}
          isSelected={isSelected}
          paymentMethod={payment}
          key={payment.id}
        />
      );
    });
  }, [selectedPaymentMethod, paymentMethodsAvailable, handleSelectPaymentMethod]);

  return <Wrapper>{renderPaymentMethodsCards()}</Wrapper>;
};

export default PaymentMethodCards;
