import styled from 'styled-components';
import {
  BUTTON_TEXT_CAPITALIZATION,
  HIGHLIGHT_PRIMARY,
  TEXT_CONTRAST_ON_HIGHLIGHT_PRIMARY,
} from 'style/constants';
import { TEXT_LABEL_S_BOLD } from 'style/design-system/textStyles';
import Icon from 'components/admin2/Icon';
import Link from 'components/ui/Link';

export const Container = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${HIGHLIGHT_PRIMARY};
  color: ${TEXT_CONTRAST_ON_HIGHLIGHT_PRIMARY};
  border: none;
  border-radius: 100px;
  cursor: pointer;
  padding: 4px 16px 4px 24px;
  gap: 8px;
  height: 24px;
  text-decoration: none;
`;

export const NeedHelpText = styled.span`
  ${TEXT_LABEL_S_BOLD}
  text-transform: ${BUTTON_TEXT_CAPITALIZATION};
  color: ${TEXT_CONTRAST_ON_HIGHLIGHT_PRIMARY};
  text-align: center;
`;

export const NeedHelpIcon = styled(Icon).attrs(() => ({
  name: 'questionMarkFilled',
  width: 14,
  height: 14,
}))`
  & svg {
    width: 13px;
    height: 13px;
    & path {
      fill: ${TEXT_CONTRAST_ON_HIGHLIGHT_PRIMARY};
    }
  }
`;
