import useFocusArea from 'hooks/use-focus-area';
import React, { ComponentProps, MouseEventHandler, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device/selectors';
import { IconButtonBase } from './index';

export default function SingleIconButton({
  onClick,
  variant = 'transparent',
  ...props
}: ComponentProps<typeof IconButtonBase>) {
  const [isHovering, setHovering] = useState(false);
  const isMobile = useSelector(isMobileLayout);
  const iconRef = useFocusArea({
    onExit: () => {
      setHovering(false);
    },
    active: isHovering,
  });

  const handleClick: MouseEventHandler = useCallback((e) => {
    if (isMobile) {
      setHovering(hovering => !hovering);
    }
    onClick?.(e);
  }, [isMobile, setHovering, onClick]);

  return (
    <IconButtonBase
      hovering={isMobile ? isHovering : undefined}
      onClick={handleClick}
      ref={iconRef}
      variant={variant}
      {...props}
    />
  );
}
