import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React from 'react';
import useUpdateListener from '../../use-update-listener';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, LexicalEditor, createCommand } from 'lexical';
import styled from 'styled-components';
import Dropdown from 'components/admin2/ui/Dropdown';
import { FONT_SIZES } from 'components/admin-bridge/CTAEditorModal/utils';
import { ADMIN_ACCENT_PRIMARY, ADMIN_SURFACE_6, ADMIN_TEXT_100 } from 'style/constants';
import { ADMIN_TEXT_LABEL_M_MEDIUM } from 'style/design-system/textStyles';
import { TOOLBAR_BUTTON_ACTIVE_CSS } from '../../styles';
import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';

const APPLY_FONT_SIZE_COMMAND = createCommand<{ fontSize: number; }>('APPLY_FONT_SIZE_COMMAND');

const applyFontSize = (editor: LexicalEditor, fontSize: number) => {
  editor.update(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }

    $patchStyleText(selection, {
      'font-size': `${fontSize}px`,
      'line-height': 'normal',
    });
  });
};

const useFontSizePluginRegisters = () => {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    return editor.registerCommand(APPLY_FONT_SIZE_COMMAND, ({ fontSize }) => {
      applyFontSize(editor, fontSize);
      return true;
    }, COMMAND_PRIORITY_LOW);
  }, []);
};

const DEFAULT_FONT_SIZE = FONT_SIZES[6].value;

const FontSizePlugin = () => {
  useFontSizePluginRegisters();
  const [editor] = useLexicalComposerContext();
  const [fontSize, setFontSize] = React.useState(DEFAULT_FONT_SIZE);

  const handleChangeFontSize = React.useCallback((option: { label: string, value: number; }) => {
    if (fontSize === option.value) {
      return;
    }
    editor.dispatchCommand(APPLY_FONT_SIZE_COMMAND, { fontSize: option.value });
  }, [fontSize, editor]);

  const getValue = React.useCallback((value: number) => FONT_SIZES.find((option) => option.value === value), []);

  const update = () => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }

    const value = $getSelectionStyleValueForProperty(selection, 'font-size', String(DEFAULT_FONT_SIZE));
    if (value !== String(fontSize)) {
      setFontSize(parseInt(value, 10));
    }
  };
  useUpdateListener(editor, update);

  return (
    <FontSizeDropdown
      onChange={handleChangeFontSize}
      options={FONT_SIZES}
      value={getValue(fontSize)}
    />
  );
};

export const FontSizeDropdown = styled(Dropdown).attrs({
  padding: '0',
  labelPadding: '0 0 10px 0',
})`
  width: 70px;
  height: 40px;
  background: transparent;

  & .dropdown-button {
    min-height: 40px;
    max-height: 40px;
    border-radius: 8px;
    border: 1px solid ${ADMIN_SURFACE_6};
    color: ${ADMIN_TEXT_100};
    ${ADMIN_TEXT_LABEL_M_MEDIUM}
  }

  & .dropdown-title {
    line-height: unset;
  }

  &:hover {
    ${TOOLBAR_BUTTON_ACTIVE_CSS}

    & .dropdown-button {
      border-color: ${ADMIN_ACCENT_PRIMARY};
    }
  }
` as typeof Dropdown;

export default FontSizePlugin;
