import React from 'react';
import styled, { css } from 'styled-components';
import AspectRatio from 'components/ui/AspectRatio';
import { useSelector } from 'react-redux';
import { isMobileLayout } from 'services/device/selectors';
import { getCalculatedVideoHeight } from 'services/device/selectors/common';
import { BACKGROUND_CONTENT, desktopOnly, mobileOnly } from 'style/mixins';
import { SURFACE_3, TEXT_100 } from 'style/constants';
// import Spinner from './Spinner';
import { IconWrapper } from './styles';

interface VideoSkeletonProps {
  compact?: boolean;
}

const compactStyle = ({ compact }: VideoSkeletonProps) => compact && desktopOnly`
  flex-grow: 1;
  margin: 0 24px;
`;

const compactMobile = ({ compact }: VideoSkeletonProps) => !compact && css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

const Container = styled.div<VideoSkeletonProps>`
  position: relative;
  background: transparent;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  ${mobileOnly`
    ${compactMobile}
  `}
  ${compactStyle}
`;

const PlayWrapper = styled(IconWrapper).attrs(props => ({
  color: TEXT_100(props),
  shimmer: SURFACE_3(props),
}))`
  ${BACKGROUND_CONTENT}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  width: 64px;
  height: 64px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  & span::after {
    transform: translateX(-50%);
  }
`;

const DesktopWrapper = styled.div`
  margin-bottom: 25px !important;
  & > * {
    margin: auto;
  }
`;

// const spinnerStyle = {
//   height: '40px',
//   transform: 'scale(1.5)',
//   width: '30px',
// };

export default function VideoSkeleton({ compact }: VideoSkeletonProps) {
  const isMobile = useSelector(isMobileLayout);
  const videoHeight = useSelector(getCalculatedVideoHeight);
  const Wrapper = isMobile ? React.Fragment : DesktopWrapper;

  return (
    <Wrapper>
      <AspectRatio disabled={isMobile} maxHeight={videoHeight}>
        {isMobile ? (
          <Container compact={compact}>
            <PlayWrapper>
              {/*<Spinner style={spinnerStyle} />*/}
            </PlayWrapper>
          </Container>
        ) : (
          <PlayWrapper />
        )}
      </AspectRatio>
    </Wrapper>
  );
}
