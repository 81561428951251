import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND, TextFormatType } from 'lexical';
import React from 'react';
import { ToolbarButton } from '../../styles';
import useUpdateListener from '../../use-update-listener';

type FormatTextPluginProps = {
  formatType: TextFormatType;
  icon: string;
};

const FormatTextPlugin: React.FC<FormatTextPluginProps> = ({ formatType, icon }) => {
  const [active, setActive] = React.useState(false);
  const [editor] = useLexicalComposerContext();

  const onClick = React.useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
  }, [editor]);

  const update = React.useCallback(() => {
    const selection = $getSelection();
    let isActive = false;
    if ($isRangeSelection(selection)) {
      isActive = selection.hasFormat(formatType);
    }

    if (isActive !== active) setActive(isActive);
  }, [active]);

  useUpdateListener(editor, update);

  return (
    <ToolbarButton
      aria-label={`Format ${formatType}`}
      active={active}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  );
};

export default FormatTextPlugin;
