import React, { PropsWithChildren } from 'react';
import GoToPage from './GoToPage';
import OpenLoginModal from './OpenLoginModal';
import OpenLink from './OpenLink';
import { CtaBehaviorData, isGoToPageBehavior, isOpenCreateAccountModalBehavior, isOpenLinkBehavior, isOpenLoginModalBehavior } from '../../../admin-bridge/CTAEditorModal/utils';

interface BehaviorActionProps extends PropsWithChildren {
  behavior: CtaBehaviorData;
}

const BehaviorAction: React.FC<BehaviorActionProps> = ({ behavior, children }) => {
  if (isGoToPageBehavior(behavior)) {
    return <GoToPage pageId={behavior.pageId}>{children}</GoToPage>;
  }

  if (isOpenLinkBehavior(behavior)) {
    return (
      <OpenLink behavior={behavior}>
        {children}
      </OpenLink>
    );
  }

  if (isOpenLoginModalBehavior(behavior) || isOpenCreateAccountModalBehavior(behavior)) {
    return (
      <OpenLoginModal>
        {children}
      </OpenLoginModal>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default BehaviorAction;
