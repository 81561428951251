import styled, { css } from 'styled-components';
import { ACCENT_PRIMARY, ADMIN_ACCENT_PRIMARY, ADMIN_ALERT_ERROR } from 'style/constants';
import LabeledInput from 'components/ui/LabeledInput';
import TextInput from 'components/admin2/TextInput';

export const PrimaryButton = styled.div<{ isPrimary?: boolean; showForAdmin?: boolean }>`
  cursor: pointer;
  width: max-content;
  white-space: nowrap;
  ${({ isPrimary, showForAdmin }) =>
    isPrimary && !showForAdmin
      ? css`
          color: ${ACCENT_PRIMARY};
          cursor: default;
        `
      : isPrimary && showForAdmin
      ? css`
          color: ${ADMIN_ACCENT_PRIMARY};
          cursor: default;
        `
      : `
        text-decoration-line: underline;
        text-transform: capitalize;
  `}
`;

export const StyledLabeledInput = styled(LabeledInput)`
  margin-bottom: 0px;
  margin-top: 10px;
`;

export const StyledTextInput = styled(TextInput)<{ error: boolean; }>`
  ${({ error }) => error && css`
    & input {
      border: 1px solid ${ADMIN_ALERT_ERROR};
    }
  `}
`;
