import React, { forwardRef, InputHTMLAttributes, useCallback, useMemo } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import {
  StyleProps,
  Label,
  Input,
  Slider,
  Container,
  InnerSliderText,
} from './styles';

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'defaultChecked'>;

export interface SwitchProps extends InputProps, StyleProps {
  'data-testid'?: string;
  hideLabel?: boolean;
  onChange?: (checked: boolean) => void;
}

export default forwardRef<HTMLInputElement, SwitchProps>(({
  admin,
  className,
  checked = false,
  'data-testid': testId,
  onChange,
  disabled,
  hideLabel = false,
  ...props
}, ref) => {
  const statusKey = useMemo(() => checked ? 'ON' : 'OFF', [checked]);

  const handleChange = useCallback((e) => {
    onChange?.(e.target.checked);
  }, [onChange]);

  return (
    <Container className={className} disabled={disabled}>
      <Label>
        <Input
          checked={checked}
          data-testid={testId}
          onChange={handleChange}
          ref={ref}
          type="checkbox"
          admin={admin}
          {...props}
        />
        <Slider admin={admin}>
          {!hideLabel && (
            <TranslatedText
              component={InnerSliderText}
              componentProps={{ admin }}
              stringKey={statusKey}
            />
          )}
        </Slider>
      </Label>
    </Container>
  );
});
