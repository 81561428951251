import styled from 'styled-components';
import FormattedTimestamp from 'components/i18n/FormattedTimestamp';
import Icon from 'components/ui/Icon';
import { rgba } from 'colors';
import { mobileOnly, BACKGROUND_CONTENT, desktopOnly, TEXT_H6 } from 'style/mixins';
import {
  SURFACE_1,
  TEXT_100,
  SPACING_MEDIUM,
} from 'style/constants';

export const CountdownWrapper = styled.div`
  cursor: pointer;
  align-items: ${props => props.centered ? 'center' : 'flex-end'};
  color: ${TEXT_100};
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 7px;
  overflow: hidden;
  border: 5px;
  background-color: ${SURFACE_1};
  color: ${TEXT_100};
  min-height: 75px;
  ${mobileOnly`
    border: 1px solid ${props => rgba(TEXT_100(props), 0.2)};
    margin: ${SPACING_MEDIUM} auto;
    width: min-content;
    border: none;
    min-height: auto;
    ${BACKGROUND_CONTENT};
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  width: 190px;
  ${desktopOnly`
      height: 75px;
  `}
`;

export const CountdownNumbersWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 4px;
`;

export const BellIcon = styled(Icon).attrs(props => ({
  color: TEXT_100(props),
  name: 'bell',
}))`
  width: 20px;
  height: 20px;
  align-items: center;
  display: flex;
  margin-right: 8px;
  padding: 5px;
  justify-content: center;
  background-color: ${props => rgba(TEXT_100(props), 0.2)};
  fill: ${TEXT_100};
  border-radius: 3px;
  cursor: pointer;
`;

export const FormattedMonth = styled(FormattedTimestamp)`
  ${TEXT_H6}
  text-align:center;
`;
export const FormattedDate = styled(FormattedTimestamp)`
  font-size: 22px;
  line-height: 29px;
  display: flex;
  align-item: center;
  justify-content: center;
  text-align:center;
`;

export const CountdownEndDate = styled.div`
  ${TEXT_H6}
  color: ${TEXT_100};
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  flex-direction: column;
  padding: 10px;
  border-right: 1px solid ${props => rgba(TEXT_100(props), 0.2)};
  height: 75px;
  ${mobileOnly`
    border-right: 1px solid ${SURFACE_1};
    height: auto;
    padding: 8px;
  `}
`;

export const NumberWrapper = styled.div`
  color: ${TEXT_100};
  font-size: 18px;
  display: flex;
  min-width: 22px;
`;

export const Colon = styled.div`
  opacity: .6;
  flex-shrink: 0;
  padding: 0 5px;
`;

export const Message = styled.div`
  text-align: left;
  text-transform: uppercase;
  ${TEXT_H6}
  margin-bottom: 7px;
  line-height: 15px;
`;
