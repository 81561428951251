import styled, { css } from 'styled-components';
import { NoImage } from 'components/page-blocks/ImageAndText/ImageEditorModal/styles';
import type { ImageNodeProps } from '.';
import { ADMIN_ACCENT_PRIMARY } from 'style/constants';

export const ImageWrapper = styled.div<{
  layout: ImageNodeProps['layout'];
}>`
  justify-content: ${({ layout }) =>
    layout === 'left'
      ? 'flex-start'
      : layout === 'right'
      ? 'flex-end'
      : 'center'};
  display: flex;
  img::selection {
    display: none;
  }
`;

const BaseImageSlotCss = css<{
  isEditing: boolean;
  layout: ImageNodeProps['layout'];
}>`
  cursor: default;
  width: ${(props) => (props.layout === 'full-width' ? '100%' : '50%')};
  border: 1px solid transparent;
  border-radius: 0;

  ${(props) =>
    props.isEditing
      ? css`
          cursor: pointer;

          &:hover,
          &.selected {
            border-color: ${ADMIN_ACCENT_PRIMARY};
          }
        `
      : ''}
`;

export const StyledImagePlaceholder = styled(NoImage)`
  ${BaseImageSlotCss};
  aspect-ratio: 16 / 9;
`;

export const StyledImage = styled.img`
  ${BaseImageSlotCss}
`;
