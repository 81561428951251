import React, { forwardRef } from 'react';
import { ACTION_ICON_NORMAL } from 'style/mixins';
import styled, { ThemeProps, DefaultTheme } from 'styled-components';

const isSpin = (name: string): boolean => /^spin\d+$/.test(name);

const computeClassName = (name: string, extra: string): string => (
  `icon-${name} ${isSpin(name) ? 'animate-spin' : ''} ${extra}`
);

const Wrapper = styled.i<{ admin: boolean, color?: string | ((props: ThemeProps<DefaultTheme>) => string); }>`
  -webkit-font-smoothing: antialiased;
  ${(props) => props.admin && ACTION_ICON_NORMAL}
  ${({ color }) => color && `
    color: ${color};
  `}
`;

interface IProps {
  admin?: boolean;
  className?: string;
  color?: string;
  name: string;
  onClick?: () => void;
  style?: Record<string, string>;
  title?: string;
}

const IconFontello = forwardRef<HTMLSpanElement, IProps>(({
  admin,
  className,
  color,
  name,
  onClick,
  style,
  title,
  ...domProps
}, ref) => (
  <Wrapper
    {...domProps}
    admin={admin!}
    className={computeClassName(name, className!)}
    color={color}
    onClick={onClick}
    ref={ref}
    style={style}
    title={title}
  />
));

export default IconFontello;

IconFontello.defaultProps = {
  admin: false,
  className: '',
  style: {},
  title: undefined,
};
