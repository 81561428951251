import { MouseTouchEvent } from './interfaces';

export const throttle = (f: (...args: any[]) => void) => {
  let token: number | null = null;
  let lastArgs: any[] | null = null;
  const invoke = () => {
    if (lastArgs) {
      f(...lastArgs);
    }
    token = null;
  };
  const result = (...args: any[]) => {
    lastArgs = args;
    if (!token) {
      token = requestAnimationFrame(invoke);
    }
  };
  result.cancel = () => token && cancelAnimationFrame(token);
  return result;
};

export const getPagePos = (e: MouseTouchEvent) => {
  return window.TouchEvent && e instanceof TouchEvent ? e.touches[0] : e as MouseEvent;
};
