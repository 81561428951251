import React, { useMemo, useState } from 'react';
import chunk from 'lodash/chunk';
import hash from 'json-stable-stringify';
import { isUserAdmin, isUserChatModeratorRole } from 'services/auth/selectors';
import { useSelector } from 'react-redux';
import SlideNavigation from 'components/ui/SlideNavigation';
import Page from './Page';
import {
  GalleryProps,
  IndexedImage,
} from './interfaces';

const MAX_IMAGES = 9;

export default function Gallery({ admin, filterAdmin, images, ...props }: GalleryProps) {
  const isAdmin = useSelector(isUserAdmin);
  const isChatMod = useSelector(isUserChatModeratorRole);

  const [isTooltipVisible, setTooltipVisible] = useState(true);

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  const filteredImages = useMemo(() => {
    return images.reduce((filtered: IndexedImage[], image, index) => {
      if (!filterAdmin || !image.isAdmin || isAdmin || isChatMod) {
        filtered.push({ ...image, index });
      }
      return filtered;
    }, []);
  }, [images]);

  const pages = useMemo(() => {
    return chunk(filteredImages, MAX_IMAGES);
  }, [hash(filteredImages)]);

  return (
    <SlideNavigation
      admin={admin}
      isTooltipVisible={isTooltipVisible}
      onSlide={hideTooltip}
      onSlideEnd={showTooltip}
      pageRenderer={Page}
      pages={pages}
      {...props}
    />
  );
}
