import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { CardView, CARD_VIEW_PREVIEW_PROPS } from 'components/cards/Card';

const propTypes = {
  className: PropTypes.string,
  renderer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {
  className: '',
};

const PreviewCard = (props) => {
  const { className, renderer } = props;
  const doc = useMemo(
    () => ({
      _id: 'fake_id',
      data: {},
      renderer,
      slug: 'fake_slug',
    }),
    [renderer],
  );

  return (
    <CardView
      {...CARD_VIEW_PREVIEW_PROPS}
      className={className}
      doc={doc}
    />
  );
};

PreviewCard.propTypes = propTypes;
PreviewCard.defaultProps = defaultProps;
export default PreviewCard;
