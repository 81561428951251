import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Icon from 'components/ui/Icon';
import { useSelector } from 'react-redux';
import { isEditMode } from 'services/admin/selectors';
import { CIRCLE, FLEX_CENTER } from 'style/mixins';
import { HIGHLIGHT_PRIMARY } from 'style/constants';

interface ArrowControlsProps {
  absolutelyPositioned?: boolean;
  activeNext: boolean;
  activePrev: boolean;
  children?: ReactNode;
  className?: string;
  controlsCss?: ReturnType<typeof css> | string;
  hideControls?: boolean;
  onNext: () => void;
  onPrev: () => void;
}

const ArrowControls = styled.div<{ absolutelyPositioned: boolean; controlsCss: ReturnType<typeof css> | string; inEditMode: boolean}>`
  ${({ controlsCss }) => controlsCss}
  display: flex;
  gap: 10px;
  margin-left: auto;
  ${(props) => !props.inEditMode && css`z-index: 999;`}
  ${(props) => props.absolutelyPositioned && css<{ inEditMode: boolean; }>`
    position: absolute;
    bottom: calc(100% + 10px);
    right: 0;
  `}
`;

export default function({
  absolutelyPositioned = false,
  activePrev,
  activeNext,
  children,
  className = '',
  controlsCss,
  hideControls = false,
  onNext,
  onPrev,
}: ArrowControlsProps) {
  const inEditMode = useSelector(isEditMode);

  return (
    <ArrowControls
      absolutelyPositioned={absolutelyPositioned}
      className={`arrow-controls ${className}`}
      inEditMode={inEditMode}
      controlsCss={controlsCss}
    >
      {children}
      {!hideControls && (
        <ControlsWrapper>
          <CircledButton
            active={activePrev}
            left={true}
            name="adminbarLt"
            onClick={onPrev}
          />
          <CircledButton
            active={activeNext}
            name="adminbarGt"
            onClick={onNext}
          />
        </ControlsWrapper>
      )}
    </ArrowControls>
  );
}



const ControlsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const CircledButton = styled(Icon).attrs(props => ({
  color: HIGHLIGHT_PRIMARY(props),
  radius: '24px',
}))<{active: boolean; left?: boolean}>`
  ${CIRCLE}
  border: 2px solid ${HIGHLIGHT_PRIMARY};
  ${({ active }) => active ? `
    opacity: 1.0;
    cursor: pointer;
  ` : `
    opacity: 0.5;
    pointer-events: none;
  `}
  & > svg {
    width: 12px;
    height: 12px;
    & > path {
      stroke: ${HIGHLIGHT_PRIMARY} !important;
      stroke-width: 0.4px;
    }
  }
  ${FLEX_CENTER}
`;
