// tslint:disable:jsx-boolean-value
import React, {
  UIEventHandler,
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from 'react';
import PanelBadgeProvider from './PanelBadgeProvider';
import Icon from 'components/ui/Icon';
import MobilePanelSelector from './MobilePanelSelector';
import debounce from 'lodash/debounce';
import { styleVisibleElements } from 'utils';
import { PANEL_SELECT_ID } from 'global-ids';
import { OPTION, ACTIVE, ICON } from 'injection-classes';
import { PLATFORM } from 'config';
import { getIcon, WRAPPER_WIDTH } from './utils';
import {
  PanelButtonWrapper,
  Badge,
  Container,
  MoreButton,
  PanelButton,
  PanelButtonTransform,
  Wrapper,
} from './styles';
import { useAdminTranslation } from 'hooks/use-translation';
import IPanel from 'models/IPanel';
import { useSelector } from 'react-redux';
import { getActivePanelsV2 } from 'services/user-layout/selectors';

export { ICON_MAP } from './utils';

interface PanelSelectorProps {
  activeId?: string;
  className?: string;
  hidden?: boolean;
  ids: string[];
  isMobileLayout: boolean;
  onSelect(id: string): void;
}

export default function PanelSelector(props: PanelSelectorProps) {
  const activePanels = useSelector(getActivePanelsV2);

  return props.isMobileLayout ? (
    <MobilePanelSelector panels={activePanels} {...props} />
  ) : (
    <DesktopPanelSelector panels={activePanels} {...props} />
  );
}

type DesktopPanelSelectorProps = Omit<PanelSelectorProps, 'ids'> & { panels: IPanel<{}>[] };

function DesktopPanelSelector({
  activeId = '',
  className,
  hidden,
  panels,
  isMobileLayout,
  onSelect,
}: DesktopPanelSelectorProps) {
  const { t } = useAdminTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerDimensions = useRef(new DOMRect());
  const [scrollWidth, setScrollWidth] = useState(0);
  const [offset, setOffset] = useState(0);
  const [realOffset, setRealOffset] = useState(0);

  const maxButtons = useMemo(
    () => Math.floor(containerDimensions.current.width / WRAPPER_WIDTH),
    [containerDimensions.current.width],
  );

  const minWidth = useMemo(
    () =>
      Math.max(containerDimensions.current.width / maxButtons, WRAPPER_WIDTH),
    [containerDimensions.current.width, maxButtons],
  );

  const scrollStep = useMemo(
    () => Math.max(maxButtons - 2, 1) * minWidth + 27,
    [maxButtons, minWidth],
  );

  const maxOffset = useMemo(() => {
    const panelCount = panels.length;
    if (panelCount <= maxButtons) {
      return 0;
    }
    return scrollWidth - containerDimensions.current.width;
  }, [panels.length, maxButtons, scrollWidth, containerDimensions.current.width]);

  const scrollTolerance = useMemo(() => minWidth + 5, [minWidth]);

  const scrollTo = (stepLength = scrollStep) => {
    const { current: container } = containerRef;
    if (!container) {
      return;
    }
    container.scrollTo({ left: stepLength, behavior: 'smooth' });
  };

  const setOffsetDelayed = useCallback(
    debounce((newOffset: number) => {
      setRealOffset(newOffset);
      if (newOffset + scrollTolerance >= maxOffset) {
        return setOffset(maxOffset);
      }
      if (newOffset - scrollTolerance <= 0) {
        return setOffset(0);
      }
      setOffset(newOffset);
    }, 25),
    [setOffset, scrollTolerance, maxOffset],
  );

  const hideOverflownElements = () => {
    const { current: scrollContainer } = containerRef;
    if (!scrollContainer) {
      return;
    }
    const scrollContainerOffsetStart = showBack ? 20 : 0;
    const scrollContainerOffsetEnd = showMore ? 20 : 0;
    styleVisibleElements(
      {
        horizontal: true,
        partial: true,
        scrollContainer,
        scrollContainerOffsetStart,
        scrollContainerOffsetEnd,
      },
      (style, isVisible) => {
        style.opacity = isVisible ? '' : '0';
        style.pointerEvents = isVisible ? '' : 'none';
      },
    );
    const last = scrollContainer.lastChild as HTMLElement;
    const first = scrollContainer.firstChild as HTMLElement;
    if (last && !showMore) {
      last.style.opacity = '';
      last.style.pointerEvents = '';
    }
    if (first && !showBack) {
      first.style.opacity = '';
      first.style.pointerEvents = '';
    }
  };

  const handleBackClick = () => {
    scrollTo(Math.max(offset - scrollStep, 0));
  };

  const handleMoreClick = () => {
    const scrollAmount = offset + scrollStep;
    scrollTo(
      scrollAmount + scrollTolerance >= maxOffset ? maxOffset : scrollAmount,
    );
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = ({ target }) => {
    const { scrollLeft } = target as HTMLDivElement;
    setOffsetDelayed(scrollLeft);
    hideOverflownElements();
  };

  const getContainerRef = (node: HTMLDivElement | null) => {
    containerRef.current = node;
  };

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerDimensions.current =
        containerRef.current.getBoundingClientRect();
      setScrollWidth(containerRef.current.scrollWidth);
    }
  }, [containerRef.current, getContainerRef]);

  const showBack = useMemo(() => !!realOffset, [realOffset]);

  const showMore = useMemo(
    () =>
      maxOffset !== 0 &&
      Math.round(realOffset) !== Math.round(maxOffset) &&
      Math.round(realOffset + scrollTolerance) < Math.round(maxOffset),
    [maxOffset, realOffset],
  );

  useEffect(() => {
    if (maxButtons > 0 && panels.length > 0) {
      hideOverflownElements();
    }
  }, [scrollWidth, showBack, showMore, maxButtons, panels]);

  return (
    <Wrapper showBack={showBack} showMore={showMore}>
      <MoreButton
        isMobile={isMobileLayout}
        left
        onClick={handleBackClick}
        visible={showBack}
        title={t('BACK')}
      >
        <Icon name="leftArrowGrad" />
      </MoreButton>
      <Container
        className={className}
        hidden={hidden}
        id={PANEL_SELECT_ID}
        onScroll={handleScroll}
        ref={getContainerRef}
      >
        {panels.map((doc, index) => {
            if (!doc) {
              return null;
            }

            if (
              doc?.renderer?.disableOnMobileEmbedded === true &&
              PLATFORM === 'mobile-embedded'
            ) {
              return null;
            }
            const id = doc._id;
            const panelName = doc.renderer?.panelName || doc.data?.name;
            const panelType = doc.renderer?.panelType || doc.data?.kind;
            const iconName = doc.renderer?.iconName || doc.data?.icon;
            const isActive = id === activeId;
            const fullClassName = `${OPTION} ${isActive && ACTIVE}`;
            const handleSelect = () => onSelect(id);
            return (
              <PanelButtonTransform minWidth={minWidth} key={id}>
                <PanelButtonWrapper
                  active={isActive}
                  className={fullClassName}
                  data-testid={`livePanelButton-${index}`}
                  onClick={handleSelect}
                  title={panelName || ''}
                >
                  <PanelBadgeProvider panelId={id} panelType={panelType!}>
                    {(hasBadge) => {
                      return (
                        <>
                          {hasBadge && <Badge />}
                          <PanelButton
                            className={ICON}
                            isActive={isActive}
                            name={getIcon(
                              panelType as any,
                              true,
                              iconName!,
                            )}
                          />
                        </>
                      );
                    }}
                  </PanelBadgeProvider>
                </PanelButtonWrapper>
              </PanelButtonTransform>
            );
          })}
      </Container>
      <MoreButton
        isMobile={isMobileLayout}
        onClick={handleMoreClick}
        title={t('MORE')}
        visible={showMore}
      >
        <Icon name="rightArrowGrad" />
      </MoreButton>
    </Wrapper>
  );
}
