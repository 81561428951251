import Button from 'components/ui/Button';
import { ACCENT_PRIMARY, MAESTRO_BLACK, MAESTRO_WHITE, SURFACE_4, TEXT_100, TEXT_300 } from 'style/constants';
import { TEXT_LABEL_M_BOLD, TEXT_LABEL_XS_MEDIUM } from 'style/design-system/textStyles';
import styled from 'styled-components';

export const StyledButton = styled(Button).attrs({
  background: MAESTRO_BLACK,
  textColor: MAESTRO_WHITE,
  hoverBackground: MAESTRO_BLACK,
  hoverTextColor: MAESTRO_WHITE,
})`
  margin-left: auto;
  padding: 4px 16px;
`;

export const PaymentMethodNumber = styled.span`
  ${TEXT_LABEL_M_BOLD}
  color: ${TEXT_100};
`;

export const PaymentMethodType = styled.span`
  ${TEXT_LABEL_XS_MEDIUM}
  color: ${TEXT_300};
`;

export const PrimaryLabel = styled.span`
  color: ${ACCENT_PRIMARY};
`;

export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-self: stretch;
  padding: 12px 16px 12px 13px;
  gap: 12px;
  align-items: center;
  border-radius: 4px;
  background: ${SURFACE_4};
`;

export const BrandImage = styled.img`
  width: 34px;
  height: 24px;
  border-radius: 3px;
`;
