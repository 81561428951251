import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useEffect } from 'react';
import { ToolbarButton } from 'components/ui/RichTextEditorV3/styles';
import tableIcon from 'assets/icons/rich-text-editor-v3/table.svg';
import { InsertTableCommandPayload } from '../../TablePlugin';
import { $createTableNodeWithDimensions } from '@lexical/table';
import {
  $createParagraphNode,
  $insertNodes,
  COMMAND_PRIORITY_EDITOR,
  LexicalCommand,
  createCommand,
} from 'lexical';

export const INSERT_NEW_TABLE_COMMAND: LexicalCommand<InsertTableCommandPayload> =
  createCommand('INSERT_NEW_TABLE_COMMAND');

const FIXED_TABLE_SIZE = {
  columns: '3',
  rows: '3',
};

export default function MaestroTablePlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();

  const onClick = () => {
    editor.dispatchCommand(INSERT_NEW_TABLE_COMMAND, FIXED_TABLE_SIZE);
  };

  useEffect(() => {
    return editor.registerCommand<InsertTableCommandPayload>(
      INSERT_NEW_TABLE_COMMAND,
      ({ columns, rows }) => {
        const tableNode = $createTableNodeWithDimensions(
          Number(rows),
          Number(columns),
        );
        const emptyParagraph = $createParagraphNode();
        $insertNodes([tableNode, emptyParagraph]);
        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return (
    <ToolbarButton
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: tableIcon }}
    />
  );
}
