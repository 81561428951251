import styled from 'styled-components';
import { TEXT_100, TEXT_300 } from 'style/constants';
import { TEXT_BODY_S_REGULAR, TEXT_BODY_XS_REGULAR } from 'style/design-system/textStyles';
import { mobileOnly } from 'style/mixins';

export const StyledTextInput = styled.input`
  all: unset;
  height: 35px;
  background-color: transparent;
  border: none;
  width: 100%;
  color: ${TEXT_100};
  ${TEXT_BODY_S_REGULAR}
  ${mobileOnly`${TEXT_BODY_XS_REGULAR}`}

  &::placeholder {
    color: ${TEXT_300};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
  }
  &[data-autocompleted] {
      background-color: transparent !important;
  }
`;
