import React from 'react';
import { Toolbar, ToolbarSection } from '../../styles';
import OrderedListPlugin from './OrderedListPlugin';
import UnorderedListPlugin from './UnorderedListPlugin';
import FontSizePlugin from './FontSizePlugin';
import TextColorPlugin from './TextColorPlugin';
import FontFamilyPlugin from './FontFamilyPlugin';
import LinkPlugin from './LinkPlugin';
import AlignPlugin from './AlignPlugin';
import VideoPlugin from './VideoPlugin';
import alignLeft from 'assets/icons/rich-text-editor-v3/alignLeft.svg';
import alignCenter from 'assets/icons/rich-text-editor-v3/alignCenter.svg';
import alignRight from 'assets/icons/rich-text-editor-v3/alignRight.svg';
import bold from 'assets/icons/rich-text-editor-v3/bold.svg';
import italic from 'assets/icons/rich-text-editor-v3/italic.svg';
import underline from 'assets/icons/rich-text-editor-v3/underline.svg';
import FormatTextPlugin from './FormatTextPlugin';
import MaestroTablePlugin from './TablePlugin';
import ImagePlugin from './ImagePlugin';
import LineHeightPlugin from './LineHeightPlugin';

const ToolbarPlugin: React.FC = () => {
  return (
    <Toolbar className="editor-toolbar">
      <ToolbarSection>
        <FontFamilyPlugin />
        <FontSizePlugin />
        <FormatTextPlugin formatType="bold" icon={bold} />
        <FormatTextPlugin formatType="italic" icon={italic} />
        <FormatTextPlugin formatType="underline" icon={underline} />
        <TextColorPlugin />
      </ToolbarSection>
      <ToolbarSection>
        <LineHeightPlugin />
        <AlignPlugin icon={alignLeft} formatType="left" />
        <AlignPlugin icon={alignCenter} formatType="center" />
        <AlignPlugin icon={alignRight} formatType="right" />
      </ToolbarSection>
      <ToolbarSection>
        <OrderedListPlugin />
        <UnorderedListPlugin />
        <LinkPlugin />
      </ToolbarSection>
      <ToolbarSection>
        <VideoPlugin />
        <ImagePlugin />
        <MaestroTablePlugin />
      </ToolbarSection>
    </Toolbar>
  );
};

export default ToolbarPlugin;
